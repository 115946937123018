import axios from 'config/axios';

export default class CoachService {
  static async getProspects(coachId, params = {}) {
    const { data } = await axios.get(`users/coaches/${coachId}/prospects/`, {
      params,
    });
    return data;
  }
}
