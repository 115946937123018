import { surveyIds } from 'config/config';

import {
  HealthIcon,
  CameraIcon,
  HouseIcon,
  StomachIcon,
  RestaurantIcon,
  DropIcon,
  PillIcon,
  FemaleIcon,
} from 'components/Icon';

export const PAGE_ICON_MAPPING = {
  '1': HealthIcon, // 'Medical History'
  '2': FemaleIcon, // 'Hormone Health'
  '4': PillIcon, // 'Medications & Supplements Taken'
  '6': DropIcon, // 'Skin Services'
  '7': RestaurantIcon, // 'Diet Assessment'
  '8': StomachIcon, // 'Digestive Health'
  '10': HouseIcon, // 'Lifestyle Consideration'
  '11': CameraIcon, // 'Lifestyle Consideration'
};

export const hasTextAnswers = (survey) => {
  return survey.pages.some(({ questions }) =>
    questions.some((question) => question.text_answer),
  );
};

export const isCheckIn = ({ id }) => {
  return id === surveyIds.checkin;
};

export const isHQ = ({ id }) => {
  return id === surveyIds.healthQuestionnaire;
};
