import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import axios from 'config/axios';
import Constant from 'utils/constants';
import { questionIds } from 'config/config';
import { userAware } from 'components/UserProvider';
import { getIdFromUrl } from 'helpers/formatHelpers';
import { isActive } from 'helpers/task';
import { isCoach } from 'helpers/user';

import PhotoViewer from '../../PhotoViewer/PhotoViewer';
import CoachSelectAsr from '../../CoachSelectAsr/CoachSelectAsr';
import theme from './SurveyResults.scss';

class SurveyAnswer extends React.Component {
  state = {
    hasSelectAsrTask: false,
  };

  componentDidMount = () => {
    const { question } = this.props;
    if (
      this.props.surveyType === 'checkin' &&
      question.id === questionIds.checkin.selectASR
    ) {
      this.getHasSelectAsrTask();
    }
  };

  getHasSelectAsrTask = async () => {
    const { user, match, checkinStage } = this.props;

    let hasSelectAsrTask = false;

    if (isCoach(user)) {
      // get coach tasks to ensure they have a task for selecting this client's ASR
      const tasks = await Promise.resolve(this.getTasks());
      const activeASRTasks = tasks.results.filter(
        (task) =>
          task.action === Constant.task.action.selectAcneSeverityRating &&
          isActive(task),
      );
      hasSelectAsrTask = !!activeASRTasks.find(
        ({ linked_user: linkedUser, context }) =>
          checkinStage.id === context.stage_id &&
          getIdFromUrl(linkedUser) === parseInt(match.params.clientId, 10),
      );
    }

    this.setState({
      hasSelectAsrTask,
    });
  };

  getTasks = async () => {
    const { data } = await axios.get('tasks/', {
      params: {
        action: Constant.task.action.selectAcneSeverityRating,
        state: Constant.task.state.assigned,
      },
    });
    return data;
  };

  renderAnswers = (question) => {
    const { surveyType } = this.props;
    switch (question.category) {
      case 'multiple_choice':
        return this.renderMultipleChoice(question.answers);
      case 'datetime':
      case 'open_ended': {
        const displayType =
          question.display_options && question.display_options.display_type
            ? question.display_options.display_type
            : false;

        if (displayType) {
          switch (displayType) {
            case 'file_upload':
              // eslint-disable-next-line max-depth
              if (surveyType === 'checkin') {
                return this.renderCheckinImageUpload();
              }
              return this.renderHQImageUpload();

            case 'slider':
              return this.renderPercentageSlider(question.answers);
            default:
              break;
          }
        }

        if (question.subcategory.toLowerCase() === 'multi') {
          return this.renderOpenEndedMulti(question);
        }
        return this.renderOpenEnded(question.answers);
      }
      case 'single_choice': {
        const hasAnswerChoice =
          question.text_answer && 'is_answer_choice' in question.text_answer
            ? question.text_answer.is_answer_choice
            : false;
        if (hasAnswerChoice) {
          return this.renderSingleChoiceWithAnswer(question);
        }

        const isCheckinImageChoice =
          surveyType === 'checkin' &&
          question.id === questionIds.checkin.selectASR;
        if (isCheckinImageChoice) {
          return this.renderImageChoice(question);
        }

        return this.renderSingleChoice(question);
      }
      case 'matrix': {
        const subcategory = question.subcategory;
        switch (subcategory) {
          case 'ranking':
            return this.renderMatrixRanking(question);
          case 'rating':
            return this.renderMatrixRating(question);
          default:
            return this.renderSingleChoice(question);
        }
      }
      default:
        return null;
    }
  };

  renderMultipleChoice = (answers) => {
    return answers.map((answer, i) => {
      if (
        !this.props.question.choices.find((c) => c.id === answer.choice?.id)
      ) {
        if (answer.text) {
          return (
            <div className={theme.choiceWrapper} key={i}>
              {answer.text}
            </div>
          );
        }

        return false;
      }

      return (
        <div className={theme.choiceWrapper} key={i}>
          {
            this.props.question.choices.find((c) => c.id === answer.choice.id)
              .text
          }
        </div>
      );
    });
  };

  renderOpenEnded = (answers) => {
    return answers[0] ? answers[0].text : '';
  };

  renderCheckinImageUpload = () => {
    const { question, existingCheckins } = this.props;
    const images = question.answers[0].image_group_answer.image_answers.reduce(
      (acc, imageAnswer) => ({
        ...acc,
        [imageAnswer.context]: {
          image: imageAnswer.image.upload,
          thumbnail: imageAnswer.image.thumbnail,
        },
      }),
      {},
    );
    return (
      <PhotoViewer
        images={images}
        existingCheckins={existingCheckins}
        questionId={question.answers[0].question.id}
      />
    );
  };

  renderHQImageUpload = () => {
    const { question } = this.props;
    const images = question.answers[0].image_group_answer.image_answers.reduce(
      (acc, imageAnswer) => ({
        ...acc,
        [imageAnswer.context]: {
          image: imageAnswer.image.upload,
          thumbnail: imageAnswer.image.thumbnail,
        },
      }),
      {},
    );
    return <PhotoViewer images={images} existingCheckins={[]} />;
  };

  renderPercentageSlider = (answers) => {
    return answers[0] ? `${answers[0].text}%` : '';
  };

  renderOpenEndedMulti = (question) => {
    if (question.answers.length) {
      return (
        <ul className={theme.openEndedMulti}>
          {question.answers.map((answer, i) => {
            return <li key={i}>{answer.text}</li>;
          })}
        </ul>
      );
    }

    return '';
  };

  renderSingleChoice = (question) => {
    const answer = this.props.question.choices.find(
      (choice) => choice.id === question.answers[0].choice.id,
    );
    return answer.text;
  };

  renderSingleChoiceWithAnswer = (question) => {
    if (question.answers[0].choice) {
      const answer = this.props.question.choices.find(
        (choice) => choice.id === question.answers[0].choice.id,
      );
      return answer.text;
    } else if (question.answers[0].text) {
      return (
        <div>
          <div>{question.text_answer.text}</div>
          <div>{question.answers[0].text}</div>
        </div>
      );
    }
    return null;
  };

  renderImageChoice = (question) => {
    const { hasSelectAsrTask } = this.state;
    const { clientId, responseId } = this.props.match.params;

    const answerId = this.props.question.choices.find(
      (choice) => choice.id === question.answers[0].choice.id,
    ).id;

    return (
      <div>
        <ul className={theme.imageChoiceContainer}>
          {question.choices.map((choice, i) => {
            return (
              <li
                key={i}
                className={[
                  theme.imageChoice,
                  choice.id === answerId ? theme.activeChoice : '',
                ].join(' ')}
                dangerouslySetInnerHTML={{ __html: choice.text }}
              />
            );
          })}
        </ul>
        {hasSelectAsrTask && (
          <CoachSelectAsr clientId={clientId} responseId={responseId} />
        )}
      </div>
    );
  };

  renderMatrixRanking = (question) => {
    const { answers, choices } = question;

    // check for N/A choice
    let naChoice = choices.filter((choice) => isNaN(Number(choice.text)));
    if (naChoice && naChoice.length) {
      naChoice = naChoice[0];
    }

    if (answers.length === 1) {
      if (answers[0].choice.id === naChoice.id) {
        return naChoice.text;
      }
      return null;
    }
    // eslint-disable-next-line
    let sortedAnswers = question.rows.map((row) => {
      for (let i = 0; i < question.answers.length; i++) {
        const answer = question.answers[i];

        if (row.id === answer.row) {
          return {
            ...row,
            sort: Number(answer.text),
          };
        }
      }
    });

    sortedAnswers = sortedAnswers.sort((a, b) => a.sort - b.sort);

    return (
      <ol>
        {sortedAnswers.map((answer, i) => {
          return <li key={i}>{answer.text}</li>;
        })}
      </ol>
    );
  };

  renderMatrixRating = (question) => {
    const icon =
      question.display_options && question.display_options.display_subtype
        ? question.display_options.display_subtype
        : 'star';

    let choiceIdx = 0;
    for (let c = 0; c < question.choices.length; c++) {
      if (question.answers[0].choice.id === question.choices[c].id) {
        choiceIdx = c + 1;
      }
    }

    return (
      <div className={theme.matrixRating}>
        <ul>
          {question.rows.map((row, i) => {
            return (
              <li key={i}>
                {question.choices.map((choice, c) => {
                  const iconFilled = choiceIdx > 0 && c + 1 <= choiceIdx;
                  const labelFilled = choiceIdx === c + 1;
                  return (
                    <label key={c}>
                      <i
                        className={[
                          `material-icons icon-${icon}`,
                          iconFilled ? theme.filled : '',
                        ].join(' ')}
                      >
                        {icon}
                      </i>
                      <input
                        type="radio"
                        name={`matrix-rating-${question.id}-${i}-choice`}
                        value={choice.id.toString()}
                        checked={question.answers[0].choice.id === choice.id}
                        readOnly
                      />
                      <span
                        className={[
                          theme.choiceLabel,
                          labelFilled ? theme.filled : '',
                        ].join(' ')}
                      >
                        {choice.text}
                      </span>
                    </label>
                  );
                })}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  render() {
    const { question } = this.props;
    return (
      <div className={theme.answerWrapper}>{this.renderAnswers(question)}</div>
    );
  }
}

SurveyAnswer.propTypes = {
  question: PropTypes.object,
  surveyType: PropTypes.string,
  user: PropTypes.object,
  existingCheckins: PropTypes.array,
  checkinStage: PropTypes.object,
  match: PropTypes.object,
};

SurveyAnswer.defaultProps = {
  checkinStage: {},
};

export default withRouter(userAware(SurveyAnswer));
