import React, { useState } from 'react';

import { turquoise01 } from 'styles/colors.sss';
import TextInput from './TextInput';
import { SearchIcon } from 'components/Icon';

const SearchInput = ({
  onChange, // eslint-disable-line react/prop-types
  ...others
}) => {
  const [typingTimeout, setTypingTimeout] = useState();
  return (
    <TextInput
      placeholder="Search a client"
      icon={<SearchIcon fill={turquoise01} />}
      onChange={(ev) => {
        const value = ev.target.value;
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
        setTypingTimeout(setTimeout(() => {
          onChange(value);
        }, 1000));
      }}
      {...others}
    />
  );
};

export default SearchInput;
