/* eslint-disable max-len */

import React from 'react';

const HideIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 12 4 C 4 4 1 12 1 12 C 1 12 4 20 12 20 C 20 20 23 12 23 12 C 23 12 20 4 12 4 z M 12 6 C 17.276 6 19.944594 10.267094 20.808594 11.996094 C 19.943594 13.713094 17.255 18 12 18 C 6.724 18 4.0554062 13.732906 3.1914062 12.003906 C 4.0574062 10.286906 6.745 6 12 6 z M 12 8 C 9.791 8 8 9.791 8 12 C 8 14.209 9.791 16 12 16 C 14.209 16 16 14.209 16 12 C 16 9.791 14.209 8 12 8 z M 12 10 C 13.105 10 14 10.895 14 12 C 14 13.105 13.105 14 12 14 C 10.895 14 10 13.105 10 12 C 10 10.895 10.895 10 12 10 z" />
  </svg>
);

HideIcon.defaultProps = {
  height: 24,
  width: 24,
};

export default HideIcon;
