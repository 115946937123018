import React from 'react';
import PropTypes from 'prop-types';

import { QuantityControl } from 'components/Form';

import theme from './RegimenOrderProduct.sss';

const RegimenOrderProduct = ({ product, limit, onUpdateQty }) => (
  <div className={theme.RegimenOrderProduct}>
    <span>{product.name}</span>
    <span>
      <QuantityControl
        value={product.quantity}
        max={limit}
        onChange={(quantity) => {
          onUpdateQty({
            productId: product.id,
            productBody: {
              quantity,
            },
          });
        }}
        small
      />
    </span>
  </div>
);

RegimenOrderProduct.propTypes = {
  product: PropTypes.object.isRequired,
  limit: PropTypes.number,
  onUpdateQty: PropTypes.func.isRequired,
};

export default RegimenOrderProduct;
