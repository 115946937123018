import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Constant from 'utils/constants';
import * as modalActions from 'components/Modal/Modal.ducks';

import {
  TreatmentPlanForm,
  AcceptTreatmentPlanModal,
  RejectTreatmentPlanModal,
} from './components';

import * as actions from './ReviewTreatmentPlan.ducks';

class ReviewTreatmentPlan extends Component {
  componentDidMount() {
    const { initialize, match } = this.props;
    const { treatmentPlanId } = match.params;
    initialize({ treatmentPlanId });
  }

  ACCEPT_TREATMENT_PLAN_MODAL_ID =
    'ReviewTreatmentPlanWidget/AcceptTreatmentPlanModal';
  REJECT_TREATMENT_PLAN_MODAL_ID =
    'ReviewTreatmentPlanWidget/RejectTreatmentPlanModal';

  openAcceptTreatmentPlanModal = () => {
    const { setOpenModalId } = this.props;
    setOpenModalId(this.ACCEPT_TREATMENT_PLAN_MODAL_ID);
  };

  openRejectTreatmentPlanModal = () => {
    const { setOpenModalId } = this.props;
    setOpenModalId(this.REJECT_TREATMENT_PLAN_MODAL_ID);
  };

  render() {
    const {
      treatmentPlan,
      match,
      programDocuments,
      sunscreenOptions,
      messageCoach,
      acceptTreatmentPlan,
      rejectTreatmentPlan,
    } = this.props;
    const { treatmentPlanId } = match.params;
    return (
      <>
        <TreatmentPlanForm
          treatmentPlan={treatmentPlan}
          programDocuments={programDocuments}
          onAcceptButtonClick={this.openAcceptTreatmentPlanModal}
          onRejectButtonClick={this.openRejectTreatmentPlanModal}
          onMoreQuestionsClick={messageCoach}
        />
        <AcceptTreatmentPlanModal
          modalId={this.ACCEPT_TREATMENT_PLAN_MODAL_ID}
          sunscreenOptions={sunscreenOptions}
          onSubmit={async (values) => {
            const succeeded = await acceptTreatmentPlan({
              ...values,
              acneProgram: treatmentPlan.acne_program,
              treatmentPlanId,
            });
            return succeeded;
          }}
        />
        <RejectTreatmentPlanModal
          modalId={this.REJECT_TREATMENT_PLAN_MODAL_ID}
          onSubmit={async (values) => {
            const succeeded = await rejectTreatmentPlan({
              ...values,
              treatmentPlanId,
            });
            return succeeded;
          }}
        />
      </>
    );
  }
}

ReviewTreatmentPlan.propTypes = {
  treatmentPlan: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  programDocuments: PropTypes.array.isRequired,
  sunscreenOptions: PropTypes.array.isRequired,
  acceptTreatmentPlan: PropTypes.func.isRequired,
  rejectTreatmentPlan: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  messageCoach: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app, reviewTreatmentPlan }) => ({
  programDocuments: app.programDocuments,
  sunscreenOptions: app.productCategories.find(
    (category) =>
      category.name.toLowerCase() ===
      Constant.category.sunscreens.toLowerCase(),
  ).products,
  ...reviewTreatmentPlan,
});

export default withRouter(
  connect(mapStateToProps, {
    ...actions,
    setOpenModalId: modalActions.setOpenModalId,
  })(ReviewTreatmentPlan),
);
