import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { mapQueryParamsToProps } from 'helpers/redux';
import Loader from 'components/Loader';
import ResetPasswordModal from 'components/ResetPasswordModal';

import { ResetPasswordForm, RequestNewToken } from './components';

import * as actions from './ResetPassword.ducks';

function ResetPasswordPage({
  loading,
  submitting,
  isTokenValid,
  showResetPasswordModal,
  resendPasswordResetEmail,
  toggleResetPasswordModal,
  checkToken,
  resetPassword,
}) {
  const history = useHistory();
  const { token, branch: branchId } = mapQueryParamsToProps(history);
  const { userId } = useParams();
  React.useEffect(() => {
    checkToken({ userId: Number(userId), token, branchId });
  }, [userId, token, checkToken, branchId]);

  if (loading) {
    return <Loader type="fixed" loading />;
  }

  return isTokenValid ? (
    <ResetPasswordForm
      submitting={submitting}
      onSubmit={({ password, confirmPassword }) => {
        resetPassword({
          userId: Number(userId),
          token,
          password,
          confirmPassword,
          branchId,
        });
      }}
    />
  ) : (
    <>
      <RequestNewToken
        userId={Number(userId)}
        submitting={submitting}
        onRequestNewToken={() => {
          toggleResetPasswordModal({ showResetPasswordModal: true });
        }}
      />
      <ResetPasswordModal
        open={showResetPasswordModal}
        toggleModal={(open) => {
          toggleResetPasswordModal({ showResetPasswordModal: open });
        }}
        onSubmit={resendPasswordResetEmail}
      />
    </>
  );
}

ResetPasswordPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isTokenValid: PropTypes.bool.isRequired,
  showResetPasswordModal: PropTypes.bool.isRequired,
  toggleResetPasswordModal: PropTypes.func.isRequired,
  checkToken: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  resendPasswordResetEmail: PropTypes.func.isRequired,
};

const mapStateToProps = ({ resetPassword }) => ({
  ...resetPassword,
});

export default connect(mapStateToProps, actions)(ResetPasswordPage);
