import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextInput } from 'components/Form';
import { PeopleIcon, SearchIcon } from 'components/Icon';
import { turquoise01 } from 'styles/colors.sss';

import { TABLE, THEAD, TBODY, TH, TR } from 'components/Table';
import { useBranch } from 'components/BranchProvider';

import CheckInFollowupRow from './CheckInFollowupRow';

import theme from './CheckInFollowupTable.sss';

const CheckInFollowupTable = ({ checkinFollowups }) => {
  const [searchResults, setSearchResults] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [branch] = useBranch();
  const followups = searchResults || checkinFollowups;
  const searchCompare = (followup, clientName) =>
    followup.client.profile.full_name
      .toLowerCase()
      .includes(clientName.toLowerCase());
  return (
    <div className={theme.CheckInFollowupTable}>
      <div className={theme.TableHeader}>
        <div className={theme.Title}>
          <PeopleIcon fill={turquoise01} />
          <h1>Check-In Followups</h1>
        </div>
        <TextInput
          placeholder="Search a client"
          icon={<SearchIcon fill={turquoise01} />}
          onChange={(ev) => {
            const clientName = ev.target.value;
            if (typingTimeout) {
              clearTimeout(typingTimeout);
            }
            setTypingTimeout(
              setTimeout(() => {
                if (clientName) {
                  setSearchResults(
                    checkinFollowups.filter((followup) =>
                      searchCompare(followup, clientName),
                    ),
                  );
                } else {
                  setSearchResults(null);
                }
              }, 1000),
            );
          }}
        />
      </div>
      <div className={theme.ResultsCount}>{`${followups.length} clients`}</div>
      <TABLE>
        <THEAD>
          <TR>
            {branch.client_coach_assignment_enabled && (
              <TH>Clear Skin Specialist</TH>
            )}
            <TH>Client</TH>
            <TH>Status</TH>
            <TH>Action</TH>
          </TR>
        </THEAD>
        <TBODY>
          {followups.map((followup, key) => (
            <CheckInFollowupRow key={key} checkinFollowup={followup} />
          ))}
        </TBODY>
      </TABLE>
    </div>
  );
};

CheckInFollowupTable.propTypes = {
  checkinFollowups: PropTypes.array,
};

CheckInFollowupTable.defaultProps = {
  checkinFollowups: [],
};

export default CheckInFollowupTable;
