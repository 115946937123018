import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';

import theme from './SunscreenForm.sss';

const SunscreenForm = ({ defaultValues, onSubmit, sunscreenOptions }) => {
  const { register, watch, handleSubmit } = useForm({
    defaultValues,
  });
  const sunscreen = watch('sunscreen');
  return (
    <form>
      <h4 className={theme.Title}>Which sunscreen would you like?</h4>
      {sunscreenOptions.map((option, i) => (
        <div className={theme.SunscreenForm} key={i}>
          <input
            id={`sunscreenOption-${option.id}`}
            type="radio"
            name="sunscreen"
            value={option.id}
            ref={register}
          />
          <label htmlFor={`sunscreenOption-${option.id}`}>{option.name}</label>
        </div>
      ))}
      <footer className={theme.Actions}>
        <div className={theme.MainActions}>
          <Button
            classes={[theme.Action]}
            size="big"
            color="secondary"
            onClick={handleSubmit((values) => {
              onSubmit({
                ...values,
                submitAction: 'previous',
              });
            })}
          >
            Previous
          </Button>
          <Button
            classes={[theme.Action]}
            size="big"
            disabled={!!!sunscreen}
            onClick={handleSubmit((values) => {
              onSubmit({
                ...values,
                submitAction: 'next',
              });
            })}
          >
            Next
          </Button>
        </div>
      </footer>
    </form>
  );
};

SunscreenForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  sunscreenOptions: PropTypes.array.isRequired,
};

export default SunscreenForm;
