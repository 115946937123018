import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import history from 'global-history';
import Constant from 'utils/constants';
import { userAware } from 'components/UserProvider';
import Loader from 'components/Loader';

import { GeneralInformationForm, AddressInformationForm } from './components';

import * as actions from './AddPersonalInformation.ducks';
import theme from './AddPersonalInformation.sss';

const STEPS = {
  GENERAL_INFORMATION: 0,
  ADDRESS_INFORMATION: 1,
};

class AddPersonalInformation extends Component {

  componentDidMount() {
    const { user } = this.props;

    if (user.profile.state === Constant.user.profile.state.completed || !user.profile.has_hq_completed) {
      history.replace('/');
    }
  }

  componentDidUpdate() {
    const { user } = this.props;

    if (user.profile.state === Constant.user.profile.state.completed || !user.profile.has_hq_completed) {
      history.replace('/');
    }
  }

  submitStep = (values) => {
    const { activeStep, save} = this.props;
    switch(activeStep) {
      case STEPS.GENERAL_INFORMATION: {
        const { setActiveStep } = this.props;
        setActiveStep({ activeStep: STEPS.ADDRESS_INFORMATION, values });
        break;
      }
      case STEPS.ADDRESS_INFORMATION:
        save(values);
        break;
      default:
        throw new Error(`AddPersonalInformation submitStep: step ${activeStep} not supported`);
    }
  }

  renderActiveStep = () => {
    const { activeStep, setActiveStep } = this.props;

    switch(activeStep) {
      case STEPS.GENERAL_INFORMATION:
        return (
          <GeneralInformationForm onSubmit={this.submitStep} />
        );
      case STEPS.ADDRESS_INFORMATION:
        return (
          <AddressInformationForm
            onSubmit={this.submitStep}
            onGoBackClick={() => setActiveStep({ activeStep: STEPS.GENERAL_INFORMATION })}
          />
        );
      default:
        throw new Error(`AddPersonalInformation renderActiveStep: step ${activeStep} not supported`);
    }
  }

  render({
    loading,
    activeStep,
  } = this.props) {
    if (loading) {
      return (
        <Loader size="large" loading />
      );
    }

    return (
      <div className={theme.AddPersonalInformation}>
        <h2 className={theme.Title}>PERSONAL INFORMATION</h2>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>General information</StepLabel>
          </Step>
          <Step>
            <StepLabel>Shipping and Billing Address</StepLabel>
          </Step>
        </Stepper>
        {this.renderActiveStep()}
      </div>
    );
  }
}

AddPersonalInformation.propTypes = {
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

const mapStateToProps = ({ addPersonalInformation }) => ({
  ...addPersonalInformation,
});

export default connect(mapStateToProps, actions)(
  userAware(AddPersonalInformation)
);
