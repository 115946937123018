import React from 'react';
import PropTypes from 'prop-types';

import FormActionRow from './FormActionRow';

import theme from './FormActions.sss';

const FormActions = ({ children }) => (
  <div className={theme.FormActions}>{children}</div>
);

FormActions.propTypes = {
  children: PropTypes.node.isRequired,
};

FormActions.Row = FormActionRow;

export default FormActions;
