import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterItem from './components/FilterItem';
import theme from './Filter.sss';

const Filter = ({ defaultValue, onChange, filters }) => {
  const [selectedFilter, setSelectedFilter] = useState(
    defaultValue || filters[0],
  );

  return (
    <ul className={theme.Filter}>
      {filters.map((filter, i) => (
        <FilterItem
          key={i}
          name={filter}
          selected={selectedFilter === filter}
          onClick={() => {
            setSelectedFilter(filter);
            onChange(filter);
          }}
        />
      ))}
    </ul>
  );
};

Filter.propTypes = {
  defaultValue: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Filter;
