/* eslint no-use-before-define: 0 */

import axios from 'config/axios';
import { toast } from 'react-toastify';

import history from 'global-history';
import Constant from 'utils/constants';
import { getIdFromUrl } from 'helpers/formatHelpers';
import ThreadService from 'services/thread.service';
import { getErrorMessage } from 'helpers/errorHandling';

// Actions
const INITIALIZE = 'cc/reviewTreatmentPlan/INITIALIZE';
const INITIALIZE_SUCCEEEDED = 'cc/reviewTreatmentPlan/INITIALIZE_SUCCEEEDED';
const INITIALIZE_FAILED = 'cc/reviewTreatmentPlan/INITIALIZE_FAILED';
const MESSAGE_COACH = 'cc/reviewTreatmentPlan/MESSAGE_COACH';
const MESSAGE_COACH_SUCCEEDED =
  'cc/reviewTreatmentPlan/MESSAGE_COACH_SUCCEEDED';
const MESSAGE_COACH_FAILED = 'cc/reviewTreatmentPlan/MESSAGE_COACH_FAILED';
const ACCEPT_TREATMENT_PLAN = 'cc/reviewTreatmentPlan/ACCEPT_TREATMENT_PLAN';
const ACCEPT_TREATMENT_PLAN_SUCCEEDED =
  'cc/reviewTreatmentPlan/ACCEPT_TREATMENT_PLAN_SUCCEEDED';
const ACCEPT_TREATMENT_PLAN_FAILED =
  'cc/reviewTreatmentPlan/ACCEPT_TREATMENT_PLAN_FAILED';
const REJECT_TREATMENT_PLAN = 'cc/reviewTreatmentPlan/REJECT_TREATMENT_PLAN';
const REJECT_TREATMENT_PLAN_SUCCEEDED =
  'cc/reviewTreatmentPlan/REJECT_TREATMENT_PLAN_SUCCEEDED';
const REJECT_TREATMENT_PLAN_FAILED =
  'cc/reviewTreatmentPlan/REJECT_TREATMENT_PLAN_FAILED';

const initialState = {
  treatmentPlan: {},
  program: {},
  error: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_SUCCEEEDED: {
      const { treatmentPlan, program } = action.payload;
      return { ...state, treatmentPlan, program };
    }
    case ACCEPT_TREATMENT_PLAN_SUCCEEDED:
    case REJECT_TREATMENT_PLAN_SUCCEEDED: {
      const { treatmentPlan } = action.payload;
      return { ...state, treatmentPlan };
    }
    case ACCEPT_TREATMENT_PLAN_FAILED:
    case REJECT_TREATMENT_PLAN_FAILED: {
      const { error } = action.payload;
      return { ...state, error };
    }
    default:
      return state;
  }
};

// Actions Creators

const initialize = (payload) => async (dispatch) => {
  const { treatmentPlanId } = payload;
  dispatch({ type: INITIALIZE });
  try {
    const { data: treatmentPlan } = await axios.get(
      `programs/acne/treatment-plans/${treatmentPlanId}/`,
    );
    const programId = getIdFromUrl(treatmentPlan.acne_program);
    const { data: program } = await axios.get(`programs/acne/${programId}/`);
    dispatch(initializeSucceeded({ treatmentPlan, program }));
  } catch (error) {
    dispatch(initializeFailed({ error }));
  }
};

const initializeSucceeded = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCEEEDED, payload });
};

const initializeFailed = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAILED, payload });
  const { error } = payload;
  toast.error(
    getErrorMessage(
      error,
      'Could not initialize task for Reviewing Treatment Plan',
    ),
  );
};

const messageCoach = () => async (dispatch, getState) => {
  dispatch({ type: MESSAGE_COACH });
  const { user } = getState();

  try {
    const thread = await ThreadService.create([user.profile.coach]);
    dispatch(messageCoachSucceeded({ thread }));
  } catch (error) {
    dispatch(messageCoachFailed({ error }));
  }
};

const messageCoachSucceeded = (payload) => (dispatch) => {
  dispatch({ type: MESSAGE_COACH_SUCCEEDED, payload });
  const { thread } = payload;
  history.push(`/messages/${thread.id}`);
};

const messageCoachFailed = (payload) => (dispatch) => {
  dispatch({ type: MESSAGE_COACH_FAILED, payload });
  const { error } = payload;
  toast.error(getErrorMessage(error, 'Something went wrong.'));
};

const acceptTreatmentPlan = (payload) => async (dispatch, getState) => {
  dispatch({ type: ACCEPT_TREATMENT_PLAN, payload });
  const {
    communicationMethod,
    paymentPlan,
    treatmentAreas,
    sunscreen,
    questions,
    acneProgram,
    treatmentPlanId,
  } = payload;
  const { user } = getState();
  try {
    if (questions?.length) {
      const thread = await ThreadService.create([user.profile.coach]);
      const data = new FormData();
      data.append('thread', thread.id);
      data.append('body', questions);
      data.append('recipient', user.profile.coach.id);
      axios.post('messages/', data);
    }
    const acneProgramParams = {
      communication_method: communicationMethod,
      payment_plan: paymentPlan,
      treatment_area: treatmentAreas,
    };
    if (sunscreen) {
      acneProgramParams.initial_products = [sunscreen];
    }
    const [, treatmentPlanResponse] = await Promise.all([
      axios.patch(acneProgram, acneProgramParams),
      axios.patch(`programs/acne/treatment-plans/${treatmentPlanId}/`, {
        state: Constant.treatmentPlan.state.accepted,
      }),
    ]);
    dispatch(
      acceptTreatmentPlanSucceeded({
        treatmentPlan: treatmentPlanResponse.data,
      }),
    );
    return true;
  } catch (error) {
    dispatch(acceptTreatmentPlanFailed({ error }));
    return false;
  }
};

const acceptTreatmentPlanSucceeded = (payload) => (dispatch) => {
  dispatch({ type: ACCEPT_TREATMENT_PLAN_SUCCEEDED, payload });
};

const acceptTreatmentPlanFailed = (payload) => (dispatch) => {
  dispatch({ type: ACCEPT_TREATMENT_PLAN_FAILED, payload });
  const { error } = payload;
  toast.error(
    getErrorMessage(error, 'Could not accept the Treatment Plan right now'),
  );
};

const rejectTreatmentPlan = (payload) => async (dispatch) => {
  dispatch({ type: REJECT_TREATMENT_PLAN, payload });
  const { treatmentPlanId, rejectionReasons, otherReasons } = payload;
  try {
    const { data: treatmentPlan } = await axios.patch(
      `programs/acne/treatment-plans/${treatmentPlanId}/`,
      {
        state: Constant.treatmentPlan.state.rejected,
      },
    );
    await axios.patch(treatmentPlan.rejection, {
      reason: rejectionReasons,
      text: otherReasons,
      treatment_plan_id: treatmentPlanId,
    });
    dispatch(rejectTreatmentPlanSucceeded({ treatmentPlan }));
    return true;
  } catch (error) {
    dispatch(rejectTreatmentPlanFailed({ error }));
    return false;
  }
};

const rejectTreatmentPlanSucceeded = (payload) => (dispatch) => {
  dispatch({ type: REJECT_TREATMENT_PLAN_SUCCEEDED, payload });
};

const rejectTreatmentPlanFailed = (payload) => (dispatch) => {
  dispatch({ type: REJECT_TREATMENT_PLAN_FAILED, payload });
  const { error } = payload;
  toast.error(
    getErrorMessage(error, 'Could not reject the Treatment Plan right now'),
  );
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_SUCCEEEDED,
  INITIALIZE_FAILED,
  MESSAGE_COACH,
  MESSAGE_COACH_SUCCEEDED,
  MESSAGE_COACH_FAILED,
  ACCEPT_TREATMENT_PLAN,
  ACCEPT_TREATMENT_PLAN_SUCCEEDED,
  ACCEPT_TREATMENT_PLAN_FAILED,
  REJECT_TREATMENT_PLAN,
  REJECT_TREATMENT_PLAN_SUCCEEDED,
  REJECT_TREATMENT_PLAN_FAILED,
  // Action Creators
  initialize,
  initializeSucceeded,
  initializeFailed,
  messageCoach,
  messageCoachSucceeded,
  messageCoachFailed,
  acceptTreatmentPlan,
  acceptTreatmentPlanSucceeded,
  acceptTreatmentPlanFailed,
  rejectTreatmentPlan,
  rejectTreatmentPlanSucceeded,
  rejectTreatmentPlanFailed,
};
