import React from 'react';
import { format } from 'date-fns';
import strings from 'assets/strings';
import classNames from 'classnames';

import Constant from 'utils/constants';
import { clientDashboardTour } from 'config/tour';
import { useLoggedInUser } from 'hooks';
import { useBranch } from 'components/BranchProvider';
import TaskListWidget from 'containers/TaskListWidget';
import ClientOverview from 'components/ClientOverview';
import GracePeriodFooter from 'components/GracePeriodFooter';
import { isGracePeriodActive } from 'helpers/user';

import theme from './ClientDashboard.scss';

function ClientDashboard() {
  const user = useLoggedInUser();
  const [branch] = useBranch();
  const renderNextCheckInDate = () => {
    if (
      user?.program?.state !== Constant.stage.state.started ||
      !user.program.latest_stage ||
      user.program.latest_stage?.state === Constant.stage.state.completed
    ) {
      return null;
    }
    return (
      <h2 className={theme.NextCheckInDate}>
        Next Check-in Date:{' '}
        {format(
          new Date(user.program.latest_stage.check_in_scheduled),
          'MMMM d, yyyy',
        )}
      </h2>
    );
  };
  return (
    <div className={theme.dashboardRoot}>
      <div
        className={classNames(theme.dashboardContentContainer, {
          [theme.marginFooter]: isGracePeriodActive(user),
        })}
      >
        <div className={theme.dashboardGreeting}>
          <p>{strings.greet(user.profile.first_name || user.email)}</p>
          {renderNextCheckInDate()}
        </div>
        <div id={clientDashboardTour.tasks.id} className={theme.dashboardRow}>
          <TaskListWidget />
        </div>
        <div className={theme.dashboardRow}>
          <ClientOverview
            client={user}
            skipTreatmentPlan={branch.skip_treatment_plan}
          />
        </div>
      </div>
      {isGracePeriodActive(user) && <GracePeriodFooter />}
    </div>
  );
}

export default ClientDashboard;
