/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import axios from 'config/axios';
import history from 'global-history';
import { updateProfile } from 'actions/userActions';

// Actions
const SET_ACTIVE_STEP = 'cc/addPersonalInformation/SET_ACTIVE_STEP';
const SAVE = 'cc/addPersonalInformation/SAVE';
const SAVE_SUCCESS = 'cc/addPersonalInformation/SAVE_SUCCESS';
const SAVE_FAIL = 'cc/addPersonalInformation/SAVE_FAIL';

const initialState = {
  activeStep: 0,
  loading: false,
  form: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_STEP: {
      const { activeStep, values } = action.payload;
      return {
        ...state,
        activeStep,
        form: {
          ...state.form,
          ...values,
        },
      };
    }
    case SAVE:
      return { ...state, loading: true };
    case SAVE_SUCCESS:
    case SAVE_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const setActiveStep = (payload) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_STEP, payload });
};

const save = (payload) => async (dispatch, getState) => {
  dispatch({ type: SAVE, payload });

  const { jwt, id: userId } = getState().user;
  const values = {
    ...getState().addPersonalInformation.form,
    ...payload,
  };

  const headers = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(
      `/users/${userId}/add-personal-information/`,
      {
        billing_address: {
          street1: values.billingAddressLine1,
          city: values.billingAddressCity,
          state: values.billingAddressState,
          postal_code: values.billingAddressZipCode,
          country: values.billingAddressCountry,
        },
        shipping_address: {
          street1: values.shippingAddressLine1,
          city: values.shippingAddressCity,
          state: values.shippingAddressState,
          postal_code: values.shippingAddressZipCode,
          country: values.shippingAddressCountry,
        },
        time_zone: values.timeZone.name,
        phone_number: values.phone,
        phone_number_alt: values.altPhone,
        occupation: values.occupation,
      },
      headers,
    );

    toast.success('Your personal information is saved! Redirecting to home...');
    setTimeout(() => {
      dispatch(saveSuccess());
      dispatch(
        updateProfile({ state: 'completed', time_zone: values.timeZone.name }),
      );
      history.replace('/');
    }, 3000);
  } catch (error) {
    dispatch(saveFail({ error }));
  }
};

const saveSuccess = (payload) => (dispatch) => {
  dispatch({ type: SAVE_SUCCESS, payload });
};

const saveFail = (payload) => (dispatch) => {
  dispatch({ type: SAVE_FAIL, payload });
  const { error } = payload;
  toast.error(
    error.response?.data?.message ||
      error.message ||
      'Could not save your personal information',
  );
};

export default reducer;
export {
  // Actions
  SET_ACTIVE_STEP,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAIL,
  // Action Creators
  setActiveStep,
  save,
  saveSuccess,
  saveFail,
};
