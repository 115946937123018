import { useQuery } from 'react-query';

import ProductCategoryService from 'services/productCategory.service';

export function useGetProductCategories(params, options) {
  return useQuery(
    ['categories', params],
    () => ProductCategoryService.get(params),
    options,
  );
}
