import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { isPracticeManager } from 'helpers/user';
import { useLoggedInUser } from 'hooks';
import BadgeButton from 'components/BadgeButton';

import UserInboxWidget from './UserInboxWidget';
import CoachesInboxWidget from './CoachesInboxWidget';

import * as actions from './InboxWidget.ducks';
import theme from './InboxWidget.sss';

const INBOX_WIDGETS = {
  userInbox: 'userInbox',
  coachesInbox: 'coachesInbox',
};

const InboxWidget = ({
  open,
  selectedInbox,
  setSelectedInbox,
  setOpen,
  userCount,
  coachesCount,
}) => {
  const user = useLoggedInUser();
  const containerNode = useRef();

  useEffect(() => {
    document.addEventListener('click', (ev) => {
      const component =
        ev.target.getAttribute('target') ||
        ev.target.getAttribute('targetcomponent');
      if (component === InboxWidget.displayName || !containerNode.current) {
        return;
      }
      if (!containerNode.current.contains(ev.target) && open) {
        setOpen(false);
      }
    });
  }, [open, setOpen, containerNode]);

  const isUserWidget = selectedInbox === INBOX_WIDGETS.userInbox;
  const isCoachWidget = selectedInbox === INBOX_WIDGETS.coachesInbox;

  function renderContent() {
    if (isPracticeManager(user)) {
      return (
        <>
          <div className={theme.Filters}>
            <BadgeButton
              count={userCount}
              active={isUserWidget}
              onClick={() => {
                setSelectedInbox(INBOX_WIDGETS.userInbox);
              }}
            >
              Your Messages
            </BadgeButton>
            <BadgeButton
              count={coachesCount}
              active={isCoachWidget}
              onClick={() => {
                setSelectedInbox(INBOX_WIDGETS.coachesInbox);
              }}
            >
              Coach Messages
            </BadgeButton>
          </div>
          {isUserWidget && (
            <UserInboxWidget
              show={open}
              onThreadClick={() => {
                setOpen(false);
              }}
            />
          )}
          {isCoachWidget && (
            <CoachesInboxWidget
              show={open}
              onThreadClick={() => {
                setOpen(false);
              }}
            />
          )}
        </>
      );
    }
    return (
      <UserInboxWidget
        show={open}
        onThreadClick={() => {
          setOpen(false);
        }}
      />
    );
  }

  return (
    <div
      className={classNames([theme.InboxWidget, { [theme.Open]: open }])}
      ref={containerNode}
    >
      <h1 className={theme.Title}>Messages</h1>
      {renderContent()}
    </div>
  );
};

InboxWidget.displayName = 'InboxWidget';

InboxWidget.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedInbox: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelectedInbox: PropTypes.func.isRequired,
  userCount: PropTypes.number.isRequired,
  coachesCount: PropTypes.number.isRequired,
};

const mapStateToProps = ({ inbox, userInbox, coachesInbox }) => ({
  ...inbox,
  userCount: userInbox.unreadCount,
  coachesCount: coachesInbox.unreadCount,
});

export { InboxWidget };
export default connect(mapStateToProps, actions)(InboxWidget);
