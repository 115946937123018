import React from 'react';
import PropTypes from 'prop-types';

import Constant from 'utils/constants';
import Loader from 'components/Loader';

import { useGetClientTasks } from 'features/contact/task.query';
import TaskList from './components';

import theme from './ClientTaskListWidget.sss';

const LIMIT = 50;
function ClientTaskListWidget({ clientId, actions, truncateSize, title }) {
  const params = React.useMemo(() => {
    let obj = {
      state: [Constant.task.state.assigned, Constant.task.state.missed],
      limit: LIMIT,
    };
    if (actions) {
      obj = { ...obj, action: actions };
    }
    return obj;
  }, [actions]);
  const { isLoading, data: tasks } = useGetClientTasks(clientId, params);

  if (isLoading) {
    return (
      <div className={theme.ClientTaskListWidget}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={theme.ClientTaskListWidget}>
      <TaskList tasks={tasks} title={title} truncateSize={truncateSize} />
    </div>
  );
}

ClientTaskListWidget.propTypes = {
  clientId: PropTypes.number.isRequired,
  actions: PropTypes.array,
  title: PropTypes.string,
  truncateSize: PropTypes.number,
};

export default ClientTaskListWidget;
