import React from 'react';
import { render } from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StateInspector } from 'reinspect';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClientProvider } from 'react-query';

import { sentryDsn, sentryEnvironment } from 'config/config';
import queryClient from 'config/react-query';
import BranchProvider from 'components/BranchProvider';

import history from 'global-history';

require('styles/main.scss');

import('intersection-observer'); // eslint-disable-line @babel/no-unused-expressions

import getRoutes from './routes';
import { store } from './store';

const component = (
  <Router history={history}>
    <Route render={({ location }) => getRoutes(location)} />
  </Router>
);

Sentry.init({
  dsn: sentryDsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
    }),
  ],
  tracesSampleRate: sentryEnvironment === 'production' ? 0.0 : 0.0,
  release: `cc-ui@${process.env.APP_VERSION}`,
  environment: sentryEnvironment,
});

render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BranchProvider>
        <StateInspector name="Clear Connection Hooks">
          {component}
        </StateInspector>
      </BranchProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('index'),
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
