import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import identity from 'lodash/identity';

import { useQueryParams } from 'hooks/';
import Form from 'components/Form';
import Button from 'components/Button';
import Loader from 'components/Loader';

import Question from './Question';
import * as actions from './CompleteSurvey.ducks';

import theme from './CompleteSurvey.sss';

import { Navbar } from './components';

const ACTIONS = {
  send: 'send',
  saveAndExit: 'saveAndExit',
};

const CompleteSurvey = ({
  survey,
  stage,
  responseId,
  loading,
  submitting,
  onComplete,
  onSaveAndExit,
  initialize,
  send,
  saveAsDraft,
}) => {
  const { userId } = useQueryParams();
  const [currentPage, setCurrentPage] = React.useState(0);
  React.useEffect(() => {
    initialize({ responseId });
  }, [responseId, currentPage, initialize]);

  const processSubmit = async (values, action) => {
    const questions = survey.pages[currentPage].questions;
    const page = survey.pages[currentPage].id;
    const payload = {
      survey,
      collectorId: survey.collector.id,
      stageId: stage.id,
      userId,
      page,
      questions,
      answers: [
        ...Object.values(pickBy(values, identity)).flatMap((value) => value),
      ],
    };
    const isLastPage = currentPage === survey.pages.length - 1;
    switch (action) {
      case ACTIONS.send:
        if (isLastPage) {
          const success = await send(payload);
          if (success) {
            onComplete();
          }
        } else {
          const success = await saveAsDraft(payload);
          if (success) {
            setCurrentPage(currentPage + 1);
          }
        }
        break;
      case ACTIONS.saveAndExit: {
        const success = await saveAsDraft(payload);
        if (success) {
          onSaveAndExit();
        }
        break;
      }
      default:
        throw new Error(`Action ${action} not supported`);
    }
  };

  const emptyAnswersPredicate = (answer) => {
    return identity(answer);
  };

  if (loading) {
    return <Loader type="fixed" size="large" />;
  }

  return (
    <div className={theme.CompleteSurvey}>
      {submitting && (
        <Loader type="modal" size="large">
          Please do not close this window
        </Loader>
      )}
      <Navbar
        pages={survey.pages.map(({ id }) => ({
          id,
          selected: id === survey.pages[currentPage].id,
        }))}
        onPageClick={setCurrentPage}
      />
      <Form className={theme.Form} title={survey.pages[currentPage].title}>
        {({ handleSubmit, getValues, unregister }) => (
          <>
            {survey.pages[currentPage].questions.map((question) => {
              return (
                <Question
                  key={`question-${question.id}`}
                  question={question}
                  surveyType={survey.title}
                />
              );
            })}
            <Form.Actions>
              <Form.Actions.Row>
                {currentPage > 0 && (
                  <Button
                    color="secondary"
                    size="big"
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                  >
                    Go Back
                  </Button>
                )}
                <Button
                  size="big"
                  onClick={handleSubmit((values) => {
                    processSubmit(values, ACTIONS.send);
                  })}
                >
                  Next
                </Button>
              </Form.Actions.Row>
              <Form.Actions.Row>
                <Button
                  color="sensitive"
                  size="big"
                  onClick={() => {
                    const questions = survey.pages[currentPage].questions;
                    unregister(
                      Object.keys(
                        omitBy(getValues(), (value, questionId) => {
                          const question = questions.find(
                            ({ id }) => id === Number(questionId),
                          );
                          return emptyAnswersPredicate(value, question);
                        }),
                      ),
                    );
                    handleSubmit((values) => {
                      processSubmit(values, ACTIONS.saveAndExit);
                    })();
                  }}
                >
                  Save and Exit
                </Button>
              </Form.Actions.Row>
            </Form.Actions>
          </>
        )}
      </Form>
    </div>
  );
};

CompleteSurvey.propTypes = {
  survey: PropTypes.object.isRequired,
  stage: PropTypes.object,
  responseId: PropTypes.number,
  response: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onSaveAndExit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
  saveAsDraft: PropTypes.func.isRequired,
};

const mapStateToProps = ({ completeSurvey }) => ({
  ...completeSurvey,
});

export default connect(mapStateToProps, actions)(CompleteSurvey);
