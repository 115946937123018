/* eslint no-use-before-define: 0 */

import axios from 'config/axios';
import { toast } from 'react-toastify';

// Actions
const INITIALIZE = 'cc/surveyOverview/INITIALIZE';
const INITIALIZE_FAILED = 'cc/surveyOverview/INITIALIZE_FAILED';
const INITIALIZE_SUCCEEDED = 'cc/surveyOverview/INITIALIZE_SUCCEEDED';

const initialState = {
  loading: true,
  survey: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state, loading: true };
    case INITIALIZE_SUCCEEDED: {
      const { survey } = action.payload;
      return {
        ...state,
        survey,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const initialize = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  const { surveyId } = payload;

  try {
    const { data: survey } = await axios.get(`surveys/${surveyId}/details/`);
    dispatch(
      initializeSuceeded({
        survey,
      }),
    );
  } catch (error) {
    dispatch(initializeFailed({ error }));
  }
};

const initializeSuceeded = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCEEDED, payload });
};

const initializeFailed = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAILED, payload });
  const { error } = payload;
  toast.error(error.message || 'Could not load the Survey', {
    autoClose: false,
  });
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_FAILED,
  INITIALIZE_SUCCEEDED,
  // Action Creators
  initialize,
  initializeSuceeded,
  initializeFailed,
};
