import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Checkbox from './Checkbox';
import { VerticalDragIcon } from 'components/Icon';

import { turquoise01 } from 'styles/colors.sss';

import theme from './Ranking.sss';

const Ranking = forwardRef(
  ({ id, defaultValue, choices, naChoice, error, ...others }, ref) => {
    const [orderedChoices, setOrderedChoices] = useState(
      Array.isArray(defaultValue)
        ? defaultValue
        : choices.map(({ value }) => value),
    );
    const [isNAChoiceSelected, setIsNAChoiceSelected] = useState(
      naChoice && defaultValue === naChoice.value ? defaultValue : false,
    );

    useEffect(() => {
      others.onChange?.(orderedChoices);
    }, [orderedChoices]);

    useEffect(() => {
      if (isNAChoiceSelected) {
        others.onChange?.(naChoice.value);
      } else {
        others.onChange?.(orderedChoices);
      }
    }, [isNAChoiceSelected]);

    const reorder = (startIndex, endIndex) => {
      const result = [...orderedChoices];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const handleOnDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const newOrderedChoices = reorder(
        result.source.index,
        result.destination.index,
      );
      setOrderedChoices(newOrderedChoices);
    };

    return (
      <div className={theme.Ranking}>
        {naChoice && (
          <div className={theme.NAChoice}>
            <Checkbox
              ref={ref}
              id={naChoice.value}
              size="small"
              checked={isNAChoiceSelected}
              onChange={({ target: { checked } }) => {
                setIsNAChoiceSelected(checked);
              }}
            />
            <label htmlFor={naChoice.value}>{naChoice.text}</label>
          </div>
        )}
        {!isNAChoiceSelected && (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={`droppable-ranking-${id}`}>
              {(dropProvided) => (
                <div
                  ref={dropProvided.innerRef}
                  className={classNames({
                    [theme.InputError]: error,
                  })}
                >
                  {orderedChoices.map((value, index) => (
                    <Draggable
                      key={index}
                      draggableId={`draggable-ranking-${value}`}
                      index={index}
                    >
                      {(dragProvided, snapshot) => (
                        <div
                          className={classNames(theme.Rank, {
                            [theme.RankDragging]: snapshot.isDragging,
                          })}
                          style={dragProvided.draggableProps.style}
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <VerticalDragIcon fill={turquoise01} />
                          <span className={theme.Index}>{index + 1}.</span>
                          <p className={theme.Text}>
                            {
                              choices.find((choice) => choice.value === value)
                                .text
                            }
                          </p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {error && <span className={theme.ErrorText}>{error}</span>}
      </div>
    );
  },
);

Ranking.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  naChoice: PropTypes.shape({
    text: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired,
  }),
  error: PropTypes.string,
};

export default Ranking;
