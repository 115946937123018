import React from 'react';
import PropTypes from 'prop-types';

import Task from 'components/Task';

const ConsentProgramWidget = ({
  task,
}) => {
  const { program_id: programId } = task.context;
  return (
    <Task
      task={task}
      title="Consent to your acne program"
      action="Consent To Program"
      link={`/regimen-consent/${programId}`}
    />
  );
};

ConsentProgramWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

export default ConsentProgramWidget;
