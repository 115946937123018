import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Viewer from 'react-viewer';
import Card from '@material-ui/core/Card';
import theme from './PhotoViewer.sss';

const PhotoViewer = ({images, checkin}) => {

  const [viewerVisible, setViewerVisible] = useState();
  const [viewerImages, setViewerImages] = useState();
  const [imageIndex, setImageIndex] = useState();

  const openViewer = (imagesForViewer, index) => {
    setViewerVisible(true);
    setViewerImages(
      Object.keys(imagesForViewer).map((key) => ({
        src: imagesForViewer[key].upload,
        alt: key,
      })),
    );
    setImageIndex(index);
  };

  const checkinLabel = `Week ${checkin} Checkin`;
  return(
    <Card raised classes={{root:theme.PhotoViewer}}>
      {checkin && <span>{checkinLabel}</span>}
      <ul className={theme.Photos}>
        {
          Object.keys(images).map((key, i) => {
            return (
              <li key={i} className={theme.Photo} onClick={() => openViewer(images, i)}>
                <img className={theme.PhotoImage} src={images[key].thumbnail} />
                <span className={theme.PhotoTitle}>{`${key[0].toUpperCase()}${key.substr(1)}`}</span>
              </li>
            );
          })
        }
      </ul>
      <Viewer
        visible={viewerVisible}
        onClose={() => { setViewerVisible(false); }}
        images={viewerImages}
        activeIndex={imageIndex}
      />
    </Card>
  );
};

PhotoViewer.propTypes = {
  images: PropTypes.object,
  checkin: PropTypes.string,
  existingCheckins: PropTypes.array,
  user: PropTypes.object,
};

PhotoViewer.defaultProps = {
  images:{},
};

export default PhotoViewer;
