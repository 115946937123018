import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Constant from 'utils/constants';
import { getIdFromUrl } from 'helpers/formatHelpers';
import { isNew, isStarted, hasTreatmentPlan } from 'helpers/program';
import { isPublished, isAccepted } from 'helpers/treatmentPlan';
import { useGetProfile } from 'features/users/user.query';
import {
  useUpdateProgram,
  useGetProgram,
} from 'features/programs/program.query';
import { useGetTreatmentPlan } from 'features/programs/treatmentPlan.query';
import { useGetQuitReasons } from 'features/programs/quitReason.query';

import ErrorBoundary from 'components/ErrorBoundary';
import * as modalActions from 'components/Modal/Modal.ducks';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import Form, { SingleChoice } from 'components/Form';

import theme from './EndProgramModal.sss';

function EndProgramModal({ modalId, clientId, programId, onProgramEnded }) {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(({ modal }) => modal.openModalId === modalId);

  const { data: profile, isLoading: isProfileLoading } = useGetProfile(
    clientId,
    { enabled: modalIsOpen },
  );
  const { data: program, isLoading: isProgramLoading } =
    useGetProgram(programId);
  const { data: treatmentPlan, isLoading: isTreatmentPlanLoading } =
    useGetTreatmentPlan(getIdFromUrl(program?.treatment_plan), {
      enabled: !!(modalIsOpen && program && hasTreatmentPlan(program)),
    });
  const { data: quitReasons, isLoading: isQuitReasonsLoading } =
    useGetQuitReasons(
      {
        is_active: true,
      },
      {
        enabled: modalIsOpen,
      },
    );
  const { mutate: updateProgram, isLoading: isUpdating } = useUpdateProgram();

  const endChoices = React.useMemo(() => {
    const isLoading = isProgramLoading || isTreatmentPlanLoading;
    if (isLoading || !modalIsOpen) {
      return [];
    }
    const isInAcceptanceProcess =
      isNew(program) &&
      (!hasTreatmentPlan(program) || isPublished(treatmentPlan));
    const choices = [
      {
        title: 'Decline Program',
        value: Constant.program.state.declined,
        disabled: !isInAcceptanceProcess,
        disableReason: 'This client has already accepted the Treatment Plan.',
      },
      {
        title: 'Graduated Program',
        value: Constant.program.state.completed,
        disabled: isInAcceptanceProcess || !isStarted(program),
        disableReason:
          'A client needs to have started the program before being able to graduate.',
      },
      {
        title: 'Quit Program',
        value: Constant.program.state.quit,
        disabled:
          isInAcceptanceProcess ||
          (hasTreatmentPlan(program) && !isAccepted(treatmentPlan)),
        disableReason:
          'A client needs to have accepted the Treatment Plan before being able to quit the program.',
      },
    ];
    return choices;
  }, [
    modalIsOpen,
    isProgramLoading,
    isTreatmentPlanLoading,
    program,
    treatmentPlan,
  ]);

  if (!modalIsOpen) {
    return null;
  }

  const isLoading =
    isProfileLoading ||
    isQuitReasonsLoading ||
    isProgramLoading ||
    isTreatmentPlanLoading;
  if (isLoading) {
    return (
      <Modal id={modalId} size="medium" title="End Acne Program" autoClose>
        <Loader />
      </Modal>
    );
  }

  return (
    <ErrorBoundary>
      <Modal id={modalId} size="medium" title="End Acne Program" autoClose>
        <Form
          title={`Are you sure you want to end ${profile.full_name}'s acne program?`}
          description="If so, please choose why:"
          onSubmit={({ endReason, quitReason }) => {
            const payload = {
              state: endReason,
            };
            if (quitReason) {
              payload.quit_reason = quitReason;
            }
            updateProgram(
              { programId, payload },
              {
                onSuccess: () => {
                  toast.success('The program was ended successfully');
                  onProgramEnded?.();
                  dispatch(modalActions.setOpenModalId(null));
                },
                onError: (error) => {
                  toast.error(getErrorMessage(error));
                },
              },
            );
          }}
        >
          {({ watch }) => {
            const endReason = watch('endReason');
            return (
              <>
                <Form.Row>
                  <Form.Item
                    name="endReason"
                    label={<label htmlFor="endReason">End reason</label>}
                    input={<SingleChoice id="endReason" choices={endChoices} />}
                    registerOptions={{
                      required: 'Please select the end program reason',
                    }}
                  />
                </Form.Row>
                {endReason === Constant.program.state.quit && (
                  <Form.Row className={theme.QuitReasons}>
                    <Form.Item
                      name="quitReason"
                      label={<label htmlFor="quitReason">Quit reason</label>}
                      input={
                        <SingleChoice
                          id="quitReason"
                          choices={quitReasons.map(({ id, reason }) => ({
                            title: reason,
                            value: id,
                          }))}
                        />
                      }
                      registerOptions={{
                        required: 'Please select the quit reason',
                      }}
                    />
                  </Form.Row>
                )}
                <Form.Actions>
                  <Button disabled={isUpdating} type="submit" size="big">
                    End Program
                  </Button>
                </Form.Actions>
              </>
            );
          }}
        </Form>
      </Modal>
    </ErrorBoundary>
  );
}

EndProgramModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  programId: PropTypes.number.isRequired,
  onProgramEnded: PropTypes.func,
};

export default EndProgramModal;
