import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';

import { useGetAllClientsTasks } from 'features/contact/task.query';
import TaskList from './components';

import theme from './AllClientsTaskListWidget.sss';

const LIMIT = 50;

function buildContextParams(context = {}) {
  return Object.entries(context).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`context__${key}`]: value,
    };
  }, {});
}

function AllClientsTaskListWidget({
  actions,
  context,
  truncateSize,
  title,
  readOnly,
}) {
  const params = React.useMemo(() => {
    let obj = {
      limit: LIMIT,
    };
    if (actions) {
      obj = { ...obj, action: actions };
    }
    return { ...obj, ...buildContextParams(context) };
  }, [actions, context]);
  const { isLoading, data: tasks } = useGetAllClientsTasks(params);

  if (isLoading) {
    return (
      <div className={theme.AllClientsTaskListWidget}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={theme.AllClientsTaskListWidget}>
      <TaskList
        tasks={tasks}
        title={title}
        truncateSize={truncateSize}
        readOnly={readOnly}
      />
    </div>
  );
}

AllClientsTaskListWidget.propTypes = {
  actions: PropTypes.array,
  context: PropTypes.object,
  title: PropTypes.string,
  truncateSize: PropTypes.number,
  readOnly: PropTypes.bool,
};

export default AllClientsTaskListWidget;
