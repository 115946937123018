import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SurveyOverviewContent = ({ theme, page }) => {
  function renderFollowupQuestions(questions) {
    return questions.map(({ id, text, choices, text_answer: textAnswer }) => (
      <React.Fragment key={`followup-${id}`}>
        <p>{text}</p>
        {!!choices?.length && renderQuestionChoices(choices, textAnswer)}
      </React.Fragment>
    ));
  }

  function renderQuestionChoices(choices, textAnswer) {
    return (
      <ul className={theme.surveyOverviewQuestionChoices}>
        {choices.map(({ id, text, followup_questions: followupQuestions }) => (
          <li key={id}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
            {!!followupQuestions?.length &&
              renderFollowupQuestions(followupQuestions)}
          </li>
        ))}
        {textAnswer && (
          <li>
            <span dangerouslySetInnerHTML={{ __html: textAnswer.text }} />
          </li>
        )}
      </ul>
    );
  }

  if (!page) {
    return null;
  }

  return (
    <div className={theme.surveyOverviewContent}>
      <p className={theme.surveyOverviewPageTitle}>{page.title}</p>
      {page.questions.map(
        ({ text, choices, text_answer: textAnswer }, index) => (
          <React.Fragment key={`question-${index}`}>
            <p
              className={classNames(
                'survey-question-text',
                theme.surveyOverviewQuestion,
              )}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {!!choices?.length && renderQuestionChoices(choices, textAnswer)}
          </React.Fragment>
        ),
      )}
    </div>
  );
};

SurveyOverviewContent.propTypes = {
  page: PropTypes.object,
  theme: PropTypes.object,
};

export default SurveyOverviewContent;
