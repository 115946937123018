import React, { useRef, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import Message from './Message';
import theme from './MessageList.sss';

const MessageList = forwardRef(
  ({ messages, emptyMessage, loadingRef, showRecipient }, ref) => {
    const lastElementRef = useRef();

    useEffect(() => {
      if (lastElementRef.current) {
        lastElementRef.current.scrollIntoView({ behavior: 'auto' });
      }
    }, [messages.length]);

    if (isEmpty(messages)) {
      return emptyMessage;
    }

    return (
      <div className={theme.MessageList} ref={ref}>
        <div
          ref={lastElementRef}
          style={{
            float: 'left',
            clear: 'both',
          }}
        />
        {Object.keys(messages)
          .sort()
          .reverse()
          .map((day) => (
            <div className={theme.Day} key={moment(day).format('MMMM Do')}>
              {messages[day].map((message) => (
                <div key={`message-${message.id}`} className={theme.Message}>
                  <Message message={message} showRecipient={showRecipient} />
                </div>
              ))}
              <div className={theme.DayHeader}>
                <span>{moment(day).format('MMMM Do')}</span>
              </div>
            </div>
          ))}
        <div className={theme.LoadingNextMessages} ref={loadingRef}>
          Loading...
        </div>
      </div>
    );
  },
);

MessageList.propTypes = {
  loadingRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  messages: PropTypes.object.isRequired,
  emptyMessage: PropTypes.node.isRequired,
  showRecipient: PropTypes.bool,
};

MessageList.defaultProps = {
  showRecipient: false,
};

export default MessageList;
