import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { RegionDropdown } from 'react-country-region-selector';
import classNames from 'classnames';

import theme from './RegionSelector.sss';

// eslint-disable-next-line no-unused-vars
const RegionSelector = forwardRef(({ error, country, ...others }, ref) => {
  return (
    <>
      <RegionDropdown
        classes={classNames(theme.RegionSelector, {
          [theme.InputError]: error,
        })}
        {...others}
        disableWhenEmpty
        countryValueType="short"
        defaultOptionLabel="Select State"
        valueType="short"
        country={country}
      />
      {error && <span className={theme.ErrorText}>{error}</span>}
    </>
  );
});

RegionSelector.propTypes = {
  error: PropTypes.string,
  country: PropTypes.string,
};

export default RegionSelector;
