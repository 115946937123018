import axios from 'axios';
import qs from 'query-string';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import localStorage from 'local-storage';
import * as Sentry from '@sentry/react';

import { logout } from 'pages/App/App.ducks';

import { apiBaseUrl, branches } from './config';

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  paramsSerializer: (params) => qs.stringify(params),
});

const omitByDeeply = (object, predicate) => {
  Object.keys(object).forEach((key) => {
    if (isObject(object[key])) {
      omitByDeeply(object[key], predicate);
    }
    if (predicate(object[key])) {
      delete object[key];
    }
  });
  return object;
};

axiosInstance.interceptors.request.use((config) => {
  const branchID = branches[Number(localStorage.get('branch'))];
  const token = localStorage.get('token');
  if (token) {
    config.headers['Content-Type'] = 'application/json';
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (branchID) {
    config.headers.TenantId = branchID;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const data = omitByDeeply(
      response.data,
      (value) => isUndefined(value) || isNull(value),
    );
    return {
      ...response,
      data,
    };
  },
  (err) => {
    const LOGOUT_STATUS = [401, 404];
    if (LOGOUT_STATUS.includes(err.response?.status)) {
      import('store')
        .then(({ store }) => {
          const { dispatch } = store;
          dispatch(logout());
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
    return Promise.reject(err);
  },
);

export default axiosInstance;
