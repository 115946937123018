import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './TextAreaInput.sss';

const TextAreaInput = forwardRef(
  ({ error, icon, maxLength, cols, rows, ...others }, ref) => {
    const [remainingChars, setRemainingChars] = useState(maxLength);
    return (
      <div className={theme.TextAreaInput}>
        {icon && icon}
        <textarea
          className={classNames({
            [theme.InputError]: error,
            [theme.IconDivider]: !!icon,
          })}
          type="textarea"
          maxLength={maxLength}
          cols={cols}
          rows={rows}
          {...others}
          onChange={(ev) => {
            ev.stopPropagation();
            setRemainingChars(maxLength - ev.target.value.length);
            others.onChange?.(ev);
          }}
          ref={ref}
        />
        <span className={theme.CharsRemaining}>{remainingChars}</span>
        {error && <span className={theme.ErrorText}>{error}</span>}
      </div>
    );
  },
);

TextAreaInput.propTypes = {
  error: PropTypes.string,
  icon: PropTypes.node,
  maxLength: PropTypes.number,
  cols: PropTypes.number,
  rows: PropTypes.number,
};

TextAreaInput.defaultProps = {
  maxLength: 500,
  cols: 50,
  rows: 4,
};

export default TextAreaInput;
