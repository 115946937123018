import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getWeekPeriods } from 'helpers/program';
import { useBranch } from 'components/BranchProvider';

import theme from './ProgramWeek.scss';

const ProgramWeek = ({
  firstName,
  lastName,
  range,
  allowEdit,
  selectedWeek,
  onChangeWeek,
}) => {
  const [editingProgramWeek, setEditingProgramWeek] = useState(false);
  const [branch] = useBranch();
  const handleNewProgramWeekChange = (e) => {
    const value = Number(e.target.value);
    onChangeWeek({ programWeek: value });
  };

  return (
    <div className={theme.changeProgramWeeks}>
      <p>
        <strong>
          {firstName} {`${lastName}'s`} program
        </strong>
      </p>
      <p>
        Week {range}
        {allowEdit && (
          <button onClick={() => setEditingProgramWeek(!editingProgramWeek)}>
            <i className="material-icons edit-icon">edit</i>
          </button>
        )}
      </p>
      {editingProgramWeek && (
        <div>
          <p>
            <label>
              Choose a different program week
              <select
                onChange={handleNewProgramWeekChange}
                value={selectedWeek}
              >
                {getWeekPeriods(branch.program_config.duration).map(
                  (week, i) => (
                    <option key={i} value={i}>{`Week ${week}`}</option>
                  ),
                )}
              </select>
            </label>
          </p>
        </div>
      )}
    </div>
  );
};

ProgramWeek.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  range: PropTypes.string,
  allowEdit: PropTypes.bool,
  selectedWeek: PropTypes.number,
  onChangeWeek: PropTypes.func.isRequired,
};

ProgramWeek.defaultProps = {
  firstName: 'firstName',
  lastName: 'lastName',
  range: '0-0',
  allowEdit: true,
  selectedWeek: 0,
};

export default ProgramWeek;
