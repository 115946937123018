import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextInput } from 'components/Form';
import { PeopleIcon, SearchIcon } from 'components/Icon';
import { turquoise01 } from 'styles/colors.sss';

import ErrorBoundary from 'components/ErrorBoundary';
import { TABLE, THEAD, TBODY, TH, TR } from 'components/Table';
import { useBranch } from 'components/BranchProvider';

import UpcomingCheckInRow from './UpcomingCheckInRow';

import theme from './UpcomingCheckInTable.sss';

const UpcomingCheckInTable = ({ upcomingCheckins }) => {
  const [searchResults, setSearchResults] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [branch] = useBranch();
  const checkins = searchResults || upcomingCheckins;
  const searchCompare = (upcomingCheckin, clientName) =>
    upcomingCheckin.profile.full_name
      .toLowerCase()
      .includes(clientName.toLowerCase());
  return (
    <div className={theme.UpcomingCheckInTable}>
      <div className={theme.TableHeader}>
        <div className={theme.Title}>
          <PeopleIcon fill={turquoise01} />
          <h1>Clients with Upcoming Check-Ins</h1>
        </div>
        <TextInput
          placeholder="Search a client"
          icon={<SearchIcon fill={turquoise01} />}
          onChange={(ev) => {
            const clientName = ev.target.value;
            if (typingTimeout) {
              clearTimeout(typingTimeout);
            }
            setTypingTimeout(
              setTimeout(() => {
                if (clientName) {
                  setSearchResults(
                    upcomingCheckins.filter((upcomingCheckin) =>
                      searchCompare(upcomingCheckin, clientName),
                    ),
                  );
                } else {
                  setSearchResults(null);
                }
              }, 1000),
            );
          }}
        />
      </div>
      <div className={theme.ResultsCount}>{`${checkins.length} clients`}</div>
      <TABLE>
        <THEAD>
          <TR>
            {branch.client_coach_assignment_enabled && (
              <TH>Clear Skin Specialist</TH>
            )}
            <TH>Client</TH>
            <TH>Check-In DUE Date</TH>
            <TH>Week Period</TH>
          </TR>
        </THEAD>
        <TBODY>
          {checkins.map((upcomingCheckin) => (
            <ErrorBoundary
              key={upcomingCheckin.id}
              additionalData={upcomingCheckin}
            >
              <UpcomingCheckInRow upcomingCheckin={upcomingCheckin} />
            </ErrorBoundary>
          ))}
        </TBODY>
      </TABLE>
    </div>
  );
};

UpcomingCheckInTable.propTypes = {
  upcomingCheckins: PropTypes.array,
};

UpcomingCheckInTable.defaultProps = {
  upcomingCheckins: [],
};

export default UpcomingCheckInTable;
