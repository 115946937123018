import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './Checkbox.sss';

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const Checkbox = forwardRef(({ size, error, ...others }, ref) => (
  <div
    className={classNames(theme.Checkbox, theme[size], {
      [theme.InputError]: error,
    })}
  >
    <input className={theme[size]} type="checkbox" {...others} ref={ref} />
    <span className={classNames(theme.OuterBox, theme[size])} />
    <span className={classNames(theme.InnerBox, theme[size])} />
    {error && <span className={theme.ErrorText}>{error}</span>}
  </div>
));

Checkbox.propTypes = {
  size: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.func,
};

Checkbox.defaultProps = {
  size: SIZES.medium,
};

export default Checkbox;
