/* eslint no-use-before-define: 0  */
import { RSAA } from 'redux-api-middleware';
import * as types from './actionTypes';
import { expiredToken } from './userActions';
import { handleRSAAError } from '../helpers/errorHandling';
import moment from 'moment';
import 'moment-recur';

const api = process.env.API_BASE_URL;

export function getAppointments(userId) {
  return function(dispatch) {
    dispatch({ type: types.GET_APPOINTMENTS });

    const promises = [
      dispatch({
        [RSAA]: {
          endpoint: `${api}users/${userId}/appointments/`,
          method: 'GET',
          types: types.MIDDLEWARE_TYPES,
          headers: { 'Content-Type': 'application/json' },
        },
      }),
      dispatch({
        [RSAA]: {
          endpoint: `${api}users/${userId}/appointment-blocks/`,
          method: 'GET',
          types: types.MIDDLEWARE_TYPES,
          headers: { 'Content-Type': 'application/json' },
        },
      }),
    ];

    Promise.all(promises).then((results) => {
      const failed = results.filter((result) =>
        result.type === types.FAILURE || result.error === true
      );

      if (failed.length) {
        if (results.some((result) => result.meta.tokenExpired)) {
          dispatch(expiredToken());
        }
        throw new Error(failed[0].payload);
      }

      const appointments = [
        // regular appointments
        ...results[0].payload,
        // non-recurring appointment blocks
        ...results[1].payload.filter((appointment) => appointment.recurring === null),
      ];

      // generate recurring appointments
      const recurringAppointments = results[1].payload.filter(
        (appointment) => appointment.recurring !== null
      ).reduce((acc, appointment) => {
        let recurring = moment(appointment.start).recur(appointment.until);

        switch (appointment.recurring) {
          case 'daily':
            recurring = recurring.every(1, 'days');
            break;
          case 'weekly':
            recurring = recurring.every(1, 'weeks');
            break;
          case 'monthly':
            recurring = recurring.every(1, 'months');
            break;
          case 'yearly':
            recurring = recurring.every(1, 'years');
            break;
          default:
        }

        const result = acc.concat(recurring.all('L').map((strDate) => {
          const date = moment(strDate, 'MM/DD/YYYY');
          const startHour = moment(appointment.start).hour();
          const startMin  = moment(appointment.start).minutes();
          const endHour   = moment(appointment.end).hour();
          const endMin    = moment(appointment.end).minutes();

          const start = moment(date)
            .hour(startHour)
            .minutes(startMin)
            .format();

          const end = moment(date)
            .hour(endHour)
            .minutes(endMin)
            .format();

          return {
            start,
            end,
            notes: appointment.notes,
          };
        }));

        return result;
      }, []);

      dispatch(getAppointmentsSuccess({
        appointments: [
          ...appointments,
          ...recurringAppointments,
        ],
      }));
    }).catch((error) => {
      dispatch(getAppointmentsError(error));
    });
  };
}

export function getAppointmentsError(error) {
  handleRSAAError(error);
  return {
    type: types.GET_APPOINTMENTS_ERROR,
  };
}

export function getAppointmentsSuccess({ appointments }) {
  return {
    type: types.GET_APPOINTMENTS_SUCCESS,
    appointments,
  };
}
