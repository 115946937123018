/* eslint-disable max-len */

import React from 'react';

const PeopleIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 12 4 C 10.067 4 8.5 5.567 8.5 7.5 C 8.5 9.433 10.067 11 12 11 C 13.933 11 15.5 9.433 15.5 7.5 C 15.5 5.567 13.933 4 12 4 z M 12 6 C 12.827 6 13.5 6.673 13.5 7.5 C 13.5 8.327 12.827 9 12 9 C 11.173 9 10.5 8.327 10.5 7.5 C 10.5 6.673 11.173 6 12 6 z M 4.5 7 C 3.119 7 2 8.119 2 9.5 C 2 10.881 3.119 12 4.5 12 C 5.881 12 7 10.881 7 9.5 C 7 8.119 5.881 7 4.5 7 z M 19.5 7 C 18.119 7 17 8.119 17 9.5 C 17 10.881 18.119 12 19.5 12 C 20.881 12 22 10.881 22 9.5 C 22 8.119 20.881 7 19.5 7 z M 4.5 8.7226562 C 4.929 8.7226562 5.2773438 9.071 5.2773438 9.5 C 5.2773438 9.929 4.929 10.277344 4.5 10.277344 C 4.071 10.277344 3.7226562 9.929 3.7226562 9.5 C 3.7226562 9.071 4.071 8.7226562 4.5 8.7226562 z M 19.5 8.7226562 C 19.929 8.7226562 20.277344 9.071 20.277344 9.5 C 20.277344 9.929 19.929 10.277344 19.5 10.277344 C 19.071 10.277344 18.722656 9.929 18.722656 9.5 C 18.722656 9.071 19.071 8.7226562 19.5 8.7226562 z M 12 13 C 10.525268 13 8.1258302 13.468267 6.5546875 14.398438 C 5.9135338 14.155255 5.2112533 14 4.5 14 C 2.263 14 0 15.397641 0 16.931641 L 0 20 L 4.5 20 L 5 20 L 6 20 L 19 20 L 19.5 20 L 24 20 L 24 16.931641 C 24 15.397641 21.737 14 19.5 14 C 18.788747 14 18.086466 14.155255 17.445312 14.398438 C 15.87417 13.468267 13.474732 13 12 13 z M 12 15 C 14.134 15 16.622 15.961406 17 16.566406 L 17 18 L 7 18 L 7 16.566406 C 7.378 15.961406 9.866 15 12 15 z M 4.5 16 C 4.6979449 16 4.8766997 16.033966 5.0585938 16.0625 C 5.0214433 16.203432 5 16.349386 5 16.5 L 5 18 L 4.5 18 L 2 18 L 2 17.009766 C 2.184 16.689766 3.198 16 4.5 16 z M 19.5 16 C 20.802 16 21.816 16.689766 22 17.009766 L 22 18 L 19.5 18 L 19 18 L 19 16.5 C 19 16.349386 18.978557 16.203432 18.941406 16.0625 C 19.1233 16.033966 19.302055 16 19.5 16 z" />
  </svg>
);

PeopleIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default PeopleIcon;
