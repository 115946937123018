import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isClient, getUserPosition } from 'helpers/user';
import { FILTER_TYPES, getRecipientForUser } from 'helpers/thread';
import { statusForUser } from 'helpers/message';
import { usePaginator, useLoggedInUser, useInterval } from 'hooks';
import ThreadList from 'components/ThreadList';
import Loader from 'components/Loader';
import Avatar from 'components/Avatar';

import * as actions from './UserInboxWidget.ducks';
import { MessageFilter } from './components';

import theme from './UserInboxWidget.sss';

const FILTER_PARAMS_MAPPING = {
  [FILTER_TYPES.unread]: { filter: 'unread' },
  [FILTER_TYPES.all]: undefined,
};

function UserInboxWidget({
  show,
  fetchUnreadCount,
  readThread,
  onThreadClick,
}) {
  const user = useLoggedInUser();

  const [scrollRef, loaderRef, paginatorState, { setParams, reset }] =
    usePaginator({
      debugId: 'UserInboxWidget',
      endpoint: 'messages/inbox/',
      params: FILTER_PARAMS_MAPPING[FILTER_TYPES.unread],
    });

  useEffect(() => {
    fetchUnreadCount();
  }, [fetchUnreadCount]);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [show, reset]);

  const delay = isClient(user) ? 60000 : null;
  useInterval(() => {
    fetchUnreadCount();
  }, delay);

  function renderThreads() {
    if (paginatorState.isLoading && paginatorState.currentPage === 0) {
      return <Loader />;
    }
    const threads = paginatorState.data;
    return (
      <div ref={scrollRef} className={theme.ThreadList}>
        <ThreadList
          threads={threads.map((thread) => {
            const recipient = getRecipientForUser(thread, user);
            const { id, messages } = thread;
            const latestMessage = messages[0];
            return {
              id,
              title: (
                <>
                  {recipient.is_staff && (
                    <div className={theme.Avatar}>
                      <Avatar user={recipient} />
                    </div>
                  )}
                  <h1 className={theme.Recipient}>
                    {getUserPosition(recipient)} {recipient.profile.full_name}
                  </h1>
                </>
              ),
              body: (
                <>
                  {latestMessage.author.id === user.id && (
                    <span className={theme.You}>You:</span>
                  )}
                  <p
                    className={theme.Body}
                    dangerouslySetInnerHTML={{ __html: latestMessage.body }}
                  />
                </>
              ),
              date: latestMessage.created,
              status: statusForUser(latestMessage, user),
            };
          })}
          onThreadClick={onThreadClick}
          onReadThread={async (threadId) => {
            const thread = threads.find(({ id }) => threadId === id);
            readThread({ thread, user });
          }}
        />
        <div ref={loaderRef} className={theme.LoadingNextThreads}>
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (!show) {
    return null;
  }

  return (
    <>
      <MessageFilter
        disabled={paginatorState.isLoading}
        onChange={(filter) => {
          setParams(FILTER_PARAMS_MAPPING[filter]);
        }}
        defaultValue={FILTER_TYPES.unread}
      />
      {renderThreads()}
    </>
  );
}

UserInboxWidget.displayName = 'UserInboxWidget';

UserInboxWidget.propTypes = {
  show: PropTypes.bool,
  fetchUnreadCount: PropTypes.func.isRequired,
  readThread: PropTypes.func.isRequired,
  onThreadClick: PropTypes.func.isRequired,
};

UserInboxWidget.defaultProps = {
  show: true,
};

const mapStateToProps = ({ userInbox }) => ({
  ...userInbox,
});

export default connect(mapStateToProps, {
  ...actions,
})(UserInboxWidget);
