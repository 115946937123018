import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import Tooltip from '@material-ui/core/Tooltip';

import { isUnread } from 'helpers/notification';
import IconButton from 'components/IconButton';
import { CloseIcon } from 'components/Icon';

import theme from './Notification.sss';

const Notification = ({
  notification,
  onClick,
  onClearClick,
  onMarkAsReadClick,
  onMarkAsUnreadClick,
}) => (
  <li
    className={theme.Notification}
    onClick={() => {
      onClick(notification);
    }}
  >
    <div className={theme.Content}>
      <header className={theme.Title}>
        {notification.title}
        <span className={theme.Date}>
          {format(new Date(notification.created), 'MM/dd/yyyy hh:mm a')}
        </span>
      </header>
      <p className={theme.Body}>{notification.body}</p>
    </div>
    <div className={theme.Actions}>
      <Tooltip title="Clear notification" placement="top-start">
        <IconButton
          classes={[theme.Remove]}
          onClick={(ev) => {
            ev.stopPropagation();
            onClearClick(notification);
          }}
          icon={<CloseIcon width="16" height="16" />}
        />
      </Tooltip>
      {isUnread(notification) ? (
        <Tooltip title="Mark as read" placement="top-start">
          <span
            className={theme.MarkAsRead}
            onClick={(ev) => {
              ev.stopPropagation();
              onMarkAsReadClick(notification);
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Mark as unread" placement="top-start">
          <span
            className={theme.MarkAsUnread}
            onClick={(ev) => {
              ev.stopPropagation();
              onMarkAsUnreadClick(notification);
            }}
          />
        </Tooltip>
      )}
    </div>
  </li>
);

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
  onMarkAsReadClick: PropTypes.func.isRequired,
  onMarkAsUnreadClick: PropTypes.func.isRequired,
};

export default Notification;
