import axios from 'config/axios';

export default class ResponseService {
  static async getHqMerge(params = {}) {
    const { data } = await axios.get('surveys/responses/hq/merge/', {
      params,
    });
    return data;
  }
}
