import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { userAware } from 'components/UserProvider';
import { userTypeIds } from 'config/config';
import Loader from 'components/Loader/Loader';
import SurveyResults from 'components/Surveys/SurveyResults/SurveyResults';

import SurveyRouter from './SurveyRouter';
import * as actions from './Survey.ducks';

class SurveyPage extends Component {
  componentDidMount() {
    const { match, initialize } = this.props;
    const { clientId, surveyId, responseId } = match.params;

    initialize({ clientId, surveyId, responseId });
  }

  renderSurvey() {
    const { clientName, match, user, survey } = this.props;
    const { pageId, clientId } = match.params;
    switch (user.userType.id) {
      case userTypeIds.client: {
        const { completed, answers } = this.props;
        if (completed) {
          return (
            <SurveyResults
              {...this.props}
              clientName={clientName}
              survey={survey}
              answers={answers}
              clientId={clientId}
            />
          );
        }
        return <SurveyRouter survey={survey} pageId={parseInt(pageId, 10)} />;
      }
      case userTypeIds.practice_manager:
      case userTypeIds.coach: {
        const { answers, createRegimenTask } = this.props;
        const programId = createRegimenTask.context?.program_id; // eslint-disable-line camelcase
        return (
          <SurveyResults
            {...this.props}
            clientName={clientName}
            survey={survey}
            answers={answers}
            clientId={match.params.clientId}
            createRegimenUrl={
              programId ? `/create-regimen/${clientId}/${programId}` : null
            }
          />
        );
      }
      default:
        return null;
    }
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return <Loader type="fixed" loading size="large" />;
    }
    return this.renderSurvey();
  }
}

SurveyPage.propTypes = {
  user: PropTypes.object.isRequired,
  clientName: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  survey: PropTypes.object.isRequired,
  completed: PropTypes.bool.isRequired,
  answers: PropTypes.array.isRequired,
  createRegimenTask: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired,
};

const mapStateToProps = ({ surveyDucks }) => ({
  ...surveyDucks,
});

export default withRouter(
  connect(mapStateToProps, actions)(userAware(SurveyPage)),
);
