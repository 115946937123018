import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './Table.sss';

const TABLE = ({
  classes,
  ...props
}) => (
  <table {...props} className={classNames(theme.TABLE, ...classes)} />
);

const THEAD = ({
  classes,
  ...props
}) => (
  <thead {...props} className={classNames(theme.THEAD, ...classes)} />
);

const TBODY = ({
  classes,
  ...props
}) => (
  <tbody {...props} className={classNames(theme.TBODY, ...classes)} />
);

const TFOOT = ({
  classes,
  ...props
}) => (
  <tfoot {...props} className={classNames(theme.TFOOT, ...classes)} />
);

const TH = ({ children, ...rest }) => (
  <th {...rest} className={theme.TH}>
    {children}
  </th>
);

const TR = ({ classes, ...props }) => <tr {...props} className={classNames(theme.TR, ...classes)} />;

const TD = ({ classes, ...props }) => <td {...props} className={classNames(theme.TD, ...classes)} />;

const COL = ({ classes, ...props }) => <col {...props} className={classNames(theme.COL, ...classes)} />;

const COLGROUP = ({ classes, ...props }) => <colgroup {...props} className={classNames(theme.COLGROUP, ...classes)} />;

const CAPTION = ({ classes, ...props }) => <caption {...props} className={classNames(theme.CAPTION, ...classes)} />;

const proptypes = {
  classes: PropTypes.array,
};

const defaultProps = {
  classes: [],
};

TABLE.propTypes = proptypes;
TABLE.defaultProps = defaultProps;
THEAD.propTypes = proptypes;
THEAD.defaultProps = defaultProps;
TBODY.propTypes = proptypes;
TBODY.defaultProps = defaultProps;
TFOOT.propTypes = proptypes;
TFOOT.defaultProps = defaultProps;

TH.propTypes = {
  ...proptypes,
  children: PropTypes.node,
};

TR.propTypes = proptypes;
TR.defaultProps = defaultProps;
TD.propTypes = proptypes;
TD.defaultProps = defaultProps;
COL.propTypes = proptypes;
COL.defaultProps = defaultProps;
COLGROUP.propTypes = proptypes;
COLGROUP.defaultProps = defaultProps;
CAPTION.propTypes = proptypes;
CAPTION.defaultProps = defaultProps;

TH.defaultProps = {
  classes: [],
  children: null,
};

export {
  TABLE,
  THEAD,
  TBODY,
  TFOOT,
  TH,
  TR,
  TD,
  COL,
  COLGROUP,
  CAPTION
};
