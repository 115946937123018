import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { mapModalDispatchToProps } from 'helpers/redux';
import IconButton from 'components/IconButton';
import { EditIcon } from 'components/Icon';
import Modal from 'components/Modal';

import MaintenancePlanForm from './MaintenancePlanForm';
import Confirmation from './Confirmation';
import theme from './MaintenancePlan.sss';
import { orange01 } from 'styles/colors.sss';

export const MAINTENANCE_PLAN_MODAL_ID = 'cc/MaintenancePlan';
const SELECT_STEP = 0;
const CONFIRM_STEP = 1;
const STEPS = [SELECT_STEP, CONFIRM_STEP];

const MaintenancePlan = ({ plan, editable, onChange, setOpenModalId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [step, setStep] = useState(SELECT_STEP);
  const [selectedPlan, setSelectedPlan] = useState(plan ? `${plan}` : '');

  const renderStep = () => {
    switch (step) {
      case SELECT_STEP:
        return (
          <MaintenancePlanForm
            defaultValues={{ maintenancePlan: `${selectedPlan.id}` }}
            onSubmit={(maintenancePlan) => {
              setSelectedPlan(maintenancePlan);
              setStep(step + 1);
            }}
          />
        );
      case CONFIRM_STEP:
        return (
          <Confirmation
            submitting={submitting}
            plan={selectedPlan}
            onCancel={() => {
              setStep(step - 1);
            }}
            onConfirm={async () => {
              setSubmitting(true);
              await onChange(selectedPlan);
              setEditing(false);
              setOpenModalId(null);
              setStep(STEPS[0]);
            }}
          />
        );
      default:
        throw new Error(`Step ${step} not supported`);
    }
  };

  return (
    <>
      <p>
        Maintenance Plan:&nbsp;
        <em>{plan?.name || 'None'}</em>
        {editable && !editing && (
          <IconButton
            classes={[theme.Edit]}
            icon={<EditIcon width={18} height={18} fill={orange01} />}
            onClick={() => {
              setEditing(true);
              setOpenModalId(MAINTENANCE_PLAN_MODAL_ID);
            }}
          />
        )}
      </p>
      <Modal
        id={MAINTENANCE_PLAN_MODAL_ID}
        size="small"
        title="Maintenance Plan"
        autoClose
        onClose={() => {
          setEditing(false);
          setStep(STEPS[0]);
          setSelectedPlan(plan ? `${plan}` : '');
        }}
      >
        {renderStep()}
      </Modal>
    </>
  );
};

MaintenancePlan.propTypes = {
  plan: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

MaintenancePlan.defaultProps = {
  editable: true,
};

export default connect(null, mapModalDispatchToProps)(MaintenancePlan);
