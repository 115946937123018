import React from 'react';
import PropTypes from 'prop-types';

import Notification from './Notification';
import theme from './NotificationList.sss';

const NotificationsList = ({
  notifications,
  onNotificationClick,
  onClearNotification,
  onReadNotification,
  onUnreadNotification,
}) => (
  <ul className={theme.NotificationList}>
    {notifications.map((notification, index) => (
      <Notification
        key={index}
        notification={notification}
        onClick={onNotificationClick}
        onClearClick={onClearNotification}
        onMarkAsReadClick={onReadNotification}
        onMarkAsUnreadClick={onUnreadNotification}
      />
    ))}
  </ul>
);

NotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  onClearNotification: PropTypes.func.isRequired,
  onReadNotification: PropTypes.func.isRequired,
  onUnreadNotification: PropTypes.func.isRequired,
};


export default NotificationsList;
