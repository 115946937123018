import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { PAGE_ICON_MAPPING } from 'helpers/survey';

import { black01, turquoise01 } from 'styles/colors.sss';

const style = {
  color: black01,
  fill: turquoise01,
};

const activeStyle = {
  color: turquoise01,
  fill: turquoise01,
  fontWeight: 'bold',
};

const SurveyOverviewNav = ({ theme, pages, surveyId }) => {
  return (
    <nav className={theme.surveyOverviewNav}>
      <ul>
        {pages.map((page, p) => {
          const PageIcon = PAGE_ICON_MAPPING[page.id.toString()];
          return (
            <li key={p}>
              <NavLink
                to={`/survey-overview/${surveyId}/${page.id}`}
                style={style}
                activeStyle={activeStyle}
              >
                <PageIcon fill={turquoise01} />
                <span>{page.title}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

SurveyOverviewNav.propTypes = {
  theme: PropTypes.object,
  pages: PropTypes.array,
  surveyId: PropTypes.number,
};

export default SurveyOverviewNav;
