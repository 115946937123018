import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './Button.sss';

const BUTTON_COLORS = {
  primary: 'primary',
  secondary: 'secondary',
  sensitive: 'sensitive',
  error: 'error',
  alert: 'alert',
};

const SIZES = {
  small: 'small',
  medium: 'medium',
  big: 'big',
};

const Button = forwardRef(
  ({ color, size, classes, children, ...buttonProps }, ref) => (
    <button
      type="button"
      ref={ref}
      {...buttonProps}
      className={classNames(
        theme.button,
        theme[color],
        theme[size],
        ...classes,
      )}
    >
      {children}
    </button>
  ),
);

Button.propTypes = {
  color: PropTypes.oneOf(Object.keys(BUTTON_COLORS)),
  size: PropTypes.oneOf(Object.keys(SIZES)),
  classes: PropTypes.array,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  color: BUTTON_COLORS.primary,
  size: SIZES.medium,
  classes: [],
};

export default Button;
