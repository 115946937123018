import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import history from 'global-history';
import { userAware } from 'components/UserProvider';
import * as surveyActions from 'actions/surveyActions';
import logo from 'assets/images/nac-horizontal-logo_2x.png';
import Button from 'components/Button';
import { isHQ, isCheckIn } from 'helpers/survey';

import theme from './SurveyIntro.sss';

const SurveyIntro = ({
  match,
  user,
  survey,
  setHQSurveyResponse,
  setCheckinSurveyResponse,
}) => {
  const startSurvey = () => {
    if (isHQ(survey)) {
      setHQSurveyResponse(user.id);
      history.push(`/surveys/${survey.id}/${survey.pages[0].id}`);
    } else if (isCheckIn(survey)) {
      const { stageId } = match.params;
      setCheckinSurveyResponse(user.id);
      history.push(`/checkin/${survey.id}/${stageId}/${survey.pages[0].id}`);
    }
  };

  return (
    <div className={theme.SurveyIntro}>
      <img src={logo} className={theme.Logo} />
      <div className={theme.Modal}>
        <header>
          <h2 className={theme.ModalTitle}>{survey.title}</h2>
        </header>
        <div className={theme.ModalContent}>
          <h3>
            {isHQ(survey) ? 'Personal Information' : 'Bi-weekly Check-in'}
          </h3>
          <p>
            {isHQ(survey) // eslint-disable-next-line max-len
              ? 'We believe acne is like a finger print – everyone’s situation is unique and requires a custom treatment plan for optimal results. Please fill out all of the information in the following secure forms, and we will do the rest!'
              : // eslint-disable-next-line max-len
                'It is time for your check-in! Please upload your current set of photos and complete the check-in questions for your Clear Skin Specialist.'}
          </p>
        </div>
        <footer className={theme.ModalFooter}>
          <Button size="big" onClick={startSurvey}>
            Start {survey.title}
          </Button>
        </footer>
      </div>
    </div>
  );
};

SurveyIntro.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object.isRequired,
  survey: PropTypes.object,
  setHQSurveyResponse: PropTypes.func,
  setCheckinSurveyResponse: PropTypes.func,
};

export default connect(null, {
  setHQSurveyResponse: surveyActions.setHQSurveyResponse,
  setCheckinSurveyResponse: surveyActions.setCheckinSurveyResponse,
})(userAware(SurveyIntro));
