import React from 'react';
import PropTypes from 'prop-types';

import theme from './Preferences.scss';

const Preferences = ({
  firstName,
  lastName,
  communicationMethod,
  treatmentAreas,
  products,
}) => (
  <div className={theme.programDetails}>
    <p>
      <strong>
        {`${firstName} ${lastName} chose the following preferences:`}
      </strong>
    </p>
    <p>{`Method of Communication: ${communicationMethod}`}</p>
    <p>{`Treatment Area: ${treatmentAreas.join(', ')}`}</p>
    <p>{`Sunscreen: ${products.join(', ')}`}</p>
  </div>
);

Preferences.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  communicationMethod: PropTypes.string,
  treatmentAreas: PropTypes.array,
  products: PropTypes.array,
};

Preferences.defaultProps = {
  firstName: 'John',
  lastName: 'Doe',
  communicationMethod: 'communication method',
  treatmentAreas: [],
  products: [],
};

export default Preferences;
