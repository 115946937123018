import axios from 'config/axios';

export default class ProductCategoryService {
  static async get(params = {}) {
    const { data } = await axios.get('products/categories/', {
      params,
    });
    return data.results;
  }
}
