import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import { Checkbox } from 'components/Form';

import theme from './TreatmentAreasForm.sss';

const TreatmentAreasForm = ({ defaultValues, onSubmit }) => {
  const { register, watch, handleSubmit } = useForm({
    defaultValues,
  });
  const treatmentAreas = watch(['face', 'back', 'chest']);
  const hasSelected = Object.values(treatmentAreas).some((area) => !!area);
  return (
    <form>
      <h4 className={theme.Title}>Select Treatment Type</h4>
      <div className={theme.Area}>
        <Checkbox
          id="face"
          name="face"
          size="small"
          value="face"
          ref={register}
        />
        <label htmlFor="face">Face</label>
      </div>
      <div className={theme.Area}>
        <Checkbox
          id="back"
          name="back"
          size="small"
          value="back"
          ref={register}
        />
        <label htmlFor="back">Back</label>
      </div>
      <div className={theme.Area}>
        <Checkbox
          id="chest"
          name="chest"
          size="small"
          value="chest"
          ref={register}
        />
        <label htmlFor="chest">Chest</label>
      </div>
      <footer className={theme.Actions}>
        <div className={theme.MainActions}>
          <Button
            classes={[theme.Action]}
            size="big"
            color="secondary"
            onClick={handleSubmit((values) => {
              onSubmit({
                ...values,
                submitAction: 'previous',
              });
            })}
          >
            Previous
          </Button>
          <Button
            classes={[theme.Action]}
            size="big"
            disabled={!hasSelected}
            onClick={handleSubmit((values) => {
              onSubmit({
                ...values,
                submitAction: 'next',
              });
            })}
          >
            Next
          </Button>
        </div>
      </footer>
    </form>
  );
};

TreatmentAreasForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default TreatmentAreasForm;
