import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

const MaintenanceDueModal = ({ modalId }) => (
  <Modal
    id={modalId}
    size="small"
    title="Start your Maintenance Plan today!"
    autoClose
  >
    <div>
      To continue to view all the features of Clear Connection and message your
      Clear Skin Specialist, please select the best plan for you by clicking
      here.
    </div>
  </Modal>
);

MaintenanceDueModal.propTypes = {
  modalId: PropTypes.string.isRequired,
};

export default MaintenanceDueModal;
