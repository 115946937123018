import React from 'react';
import PropTypes from 'prop-types';

import logoNSAC from 'assets/images/NSAC-logo-horizontal.png';
import logoNAC from 'assets/images/nac-horizontal-logo_2x.png';
import Constant from 'utils/constants';
import Button from 'components/Button';
import { isHQ } from 'helpers/survey';
import { useBranch } from 'components/BranchProvider';

import theme from './SurveyIntro.sss';

const SurveyIntro = ({ survey, onStart }) => {
  const [branch] = useBranch();
  const logo =
    branch.name === Constant.branch.nscc ? (
      <img src={logoNSAC} className={theme.Logo} />
    ) : (
      <img src={logoNAC} className={theme.Logo} />
    );
  const welcomeText = isHQ(survey) // eslint-disable-next-line max-len
    ? 'We believe acne is like a finger print – everyone’s situation is unique and requires a custom treatment plan for optimal results. Please fill out all of the information in the following secure forms, and we will do the rest!' // eslint-disable-next-line max-len
    : 'It is time for your check-in! Please upload your current set of photos and complete the check-in questions for your Clear Skin Specialist.';
  return (
    <div className={theme.SurveyIntro}>
      {logo}
      <div className={theme.Modal}>
        <header className={theme.Header}>
          <h2 className={theme.ModalTitle}>{survey.title}</h2>
        </header>
        <div className={theme.ModalContent}>
          <h3>
            {isHQ(survey) ? 'Personal Information' : 'Bi-weekly Check-in'}
          </h3>
          <p>{welcomeText}</p>
        </div>
        <footer className={theme.ModalFooter}>
          <Button size="big" onClick={onStart}>
            Start {survey.title}
          </Button>
        </footer>
      </div>
    </div>
  );
};

SurveyIntro.propTypes = {
  survey: PropTypes.object.isRequired,
  onStart: PropTypes.func.isRequired,
};

export default SurveyIntro;
