import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Pagination from '@material-ui/lab/Pagination';

import { PAGE_SIZE } from 'config/config';
import Constant from 'utils/constants';
import { useGetGraduatedClients } from 'features/users/user.query';

import Loader from 'components/Loader';
import List from 'components/List';
import { SearchInput } from 'components/Form';
import { NoteIcon } from 'components/Icon';
import IconButton from 'components/IconButton';
import * as modalActions from 'components/Modal/Modal.ducks';

import { NOTES_MODAL_ID } from '../Clients';

import theme from './GraduatedClients.sss';

const columns = (onNotesClick) => [
  {
    label: 'Name',
    value: (client) => client.profile.full_name,
  },
  {
    label: 'Current Week in Program',
    value: (client) => {
      if (client?.program?.state !== Constant.stage.state.started) {
        return null;
      }
      const currentWeekPeriod =
        client.latest_stage.state === Constant.stage.state.completed
          ? client.latest_stage.next_week_period
          : client.latest_stage.week_period;
      return `Week ${currentWeekPeriod}`;
    },
  },
  {
    label: 'Most Recent Check-in Date',
    value: (client) => {
      if (!client.latest_completed_stage?.completed) {
        return null;
      }
      return format(
        new Date(client.latest_completed_stage.completed),
        'MMMM d, yyyy',
      );
    },
  },
  {
    label: 'Next Check-in Date',
    value: (client) => {
      if (client?.program?.state !== Constant.stage.state.started) {
        return null;
      }
      if (client.latest_stage?.state === Constant.stage.state.completed) {
        // eslint-disable-line camelcase
        return 'Await next regimen creation';
      }
      return format(
        new Date(client.latest_stage.check_in_scheduled),
        'MMMM d, yyyy',
      );
    },
  },
  {
    label: 'Notes',
    value: (client) => (
      <IconButton
        onClick={(ev) => {
          ev.preventDefault();
          onNotesClick(client.id);
        }}
        icon={<NoteIcon />}
      />
    ),
  },
];

function GraduatedClients({ coachId }) {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setPage(1);
  }, [search]);

  const params = React.useMemo(() => {
    const obj = {
      offset: (page - 1) * PAGE_SIZE,
      ordering: 'profile__first_name',
    };
    if (coachId) {
      obj.coach = coachId;
    }
    if (search.length) {
      obj.query = search;
    }
    return obj;
  }, [coachId, page, search]);

  const { data, isLoading } = useGetGraduatedClients(params);
  const clients = data?.results;
  const total = data?.count ?? 0;

  function handleNotesClick(clientId) {
    dispatch(modalActions.setOpenModalId(NOTES_MODAL_ID, { clientId }));
  }

  let content;
  if (isLoading) {
    content = <Loader />;
  } else {
    content = (
      <List
        columns={columns(handleNotesClick)}
        items={clients}
        itemLink={(client) => `/client-details/${client.id}`}
      />
    );
  }

  return (
    <div className={theme.GraduatedClients}>
      <div className={theme.Header}>
        <h2 className={theme.Title}>Graduated Clients ({total})</h2>
        <SearchInput disabled={isLoading} onChange={setSearch} />
      </div>
      {content}
      <Pagination
        count={Math.ceil(total / PAGE_SIZE)}
        onChange={(_, newPage) => {
          setPage(newPage);
        }}
      />
    </div>
  );
}

GraduatedClients.propTypes = {
  coachId: PropTypes.number,
};

export default GraduatedClients;
