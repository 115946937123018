import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { userAware } from 'components/UserProvider';
import TreatmentPlan from 'components/TreatmentPlan/TreatmentPlan';

class TreatmentPlanPage extends Component {
  state = {};
  render() {
    const { treatmentPlanId } = this.props.match.params;
    return (
      <TreatmentPlan
        user={this.props.user}
        treatmentPlanId={treatmentPlanId}
        programDocuments={this.props.programDocuments}
      />
    );
  }
}

TreatmentPlanPage.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  programDocuments: PropTypes.array,
};

const mapStateToProps = ({ app }) => ({
  programDocuments: app.programDocuments,
});

export default connect(mapStateToProps)(userAware(TreatmentPlanPage));
