import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { questionIds } from 'config/config';

import Form from 'components/Form';

import {
  OpenEnded,
  SingleChoice,
  MultipleChoice,
  Matrix,
  Datetime,
} from './components';

import theme from './Question.sss';

const CATEGORY_COMPONENT_MAPPING = {
  open_ended: OpenEnded,
  single_choice: SingleChoice,
  multiple_choice: MultipleChoice,
  matrix: Matrix,
  datetime: Datetime,
};

const Question = ({ question, answers, ...others }) => {
  const getQuestionClassName = () => {
    switch (question.id) {
      case questionIds.checkin.selectASR:
        return classNames(theme.SelectASR);
      default:
        return '';
    }
  };
  const Component = CATEGORY_COMPONENT_MAPPING[question.category];
  return (
    <Form.Row className={classNames(theme.Question, getQuestionClassName())}>
      <div data-testid={`question-${question.id}`}>
        {<Component question={question} answers={answers} {...others} />}
      </div>
    </Form.Row>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array,
};

Question.defaultProps = {
  answers: [],
};

const mapStateToProps = ({ completeSurvey }, { question }) => ({
  answers: completeSurvey.response.answers?.filter(
    (answer) => answer.question.id === question.id,
  ),
});

export default connect(mapStateToProps)(Question);
