import React from 'react';

import { useGetGroups } from 'features/users/group.query';

import Loader from 'components/Loader';

import MultipleChoice from './MultipleChoice';

const GroupSelect = React.forwardRef((props, ref) => {
  const { data: groups, isLoading } = useGetGroups();
  if (isLoading) {
    return <Loader />;
  }
  return (
    <MultipleChoice
      {...props}
      ref={ref}
      choices={groups.map(({ name, id }) => ({
        title: name,
        value: id.toString(),
      }))}
    />
  );
});

export default GroupSelect;
