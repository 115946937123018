import axios from 'config/axios';

export default class TaskNoteService {
  static async add(payload) {
    const { data } = await axios.post('tasks/notes/', payload);
    return data;
  }
  static async update(taskNoteId, payload) {
    const { data } = await axios.patch(`tasks/notes/${taskNoteId}/`, payload);
    return data;
  }
}
