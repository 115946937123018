import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './ButtonLink.sss';

const BUTTON_COLORS = {
  primary: 'primary',
  secondary: 'secondary',
  sensitive: 'sensitive',
  error: 'error',
  alert: 'alert',
};

const SIZES = {
  small: 'small',
  medium: 'medium',
  big: 'big',
};

const ButtonLink = ({
  color,
  size,
  className,
  children,
  ...buttonProps
}) => (
  <a
    className={classNames(theme.ButtonLink, theme[color], theme[size], className)}
    type="button"
    {...buttonProps}
  >
    {children}
  </a>
);

ButtonLink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(BUTTON_COLORS)),
  size: PropTypes.oneOf(Object.keys(SIZES)),
  children: PropTypes.node.isRequired,
};

ButtonLink.defaultProps = {
  color: BUTTON_COLORS.primary,
  size: SIZES.medium,
};

export default ButtonLink;
