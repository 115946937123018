import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Feedback from 'components/Feedback';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { TextInput, PasswordInput } from 'components/Form';

import theme from './LoginForm.sss';

// stateless component -- presentational
const LoginForm = ({
  error,
  submitting,
  onSubmit,
  onResetPasswordClicked,
}) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <form className={theme.LoginForm} onSubmit={handleSubmit(onSubmit)}>
      <h1 className={theme.FormTitle}>Login</h1>
      <div className={theme.Login}>
        {error !== '' &&
          <Feedback
            message={error}
            type="error"
          />
        }
        <div className={theme.VerticalGroup}>
          <div className={theme.InputGroup}>
            <label htmlFor="loginEmail" className={theme.Label}>Email</label>
            <TextInput
              name="loginEmail"
              id="loginEmail"
              autoComplete="username"
              register={register({ required: 'Enter your email' })}
              error={errors.loginEmail?.message}
            />
          </div>
          <div className={theme.InputGroup}>
            <label htmlFor="loginPassword" className={theme.Label}>Password</label>
            <PasswordInput
              name="loginPassword"
              id="loginPassword"
              autoComplete="current-password"
              register={register({ required: 'Enter your password' })}
              error={errors.loginPassword?.message}
            />
          </div>
        </div>
      </div>
      <Button classes={[theme.Link]} color="sensitive" onClick={onResetPasswordClicked}>Reset Password</Button>
      <footer className={theme.Footer}>
        <Button
          data-testid="loginButton"
          style={{ position: 'relative' }}
          disabled={submitting}
          type="submit"
          size="big"
        >
          Login
          {submitting &&
            <Loader size="small" loading />
          }
        </Button>
      </footer>
    </form>
  );
};

LoginForm.propTypes = {
  submitting: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onResetPasswordClicked: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  submitting: false,
  error: '',
};

export default LoginForm;
