/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import history from 'global-history';
import axios from 'config/axios';
import { resolveBranch } from 'config/config';

// Actions
const TOGGLE_RESET_PASSWORD_MODAL = 'cc/login/TOGGLE_RESET_PASSWORD_MODAL';
const CHECK_TOKEN = 'cc/resetPassword/CHECK_TOKEN';
const CHECK_TOKEN_SUCCESS = 'cc/resetPassword/CHECK_TOKEN_SUCCESS';
const CHECK_TOKEN_FAIL = 'cc/resetPassword/CHECK_TOKEN_FAIL';
const RESEND_PASSWORD_RESET_EMAIL =
  'cc/resetPassword/RESEND_PASSWORD_RESET_EMAIL';
const RESEND_PASSWORD_RESET_EMAIL_SUCCESS =
  'cc/resetPassword/RESEND_PASSWORD_RESET_EMAIL_SUCCESS';
const RESEND_PASSWORD_RESET_EMAIL_FAIL =
  'cc/resetPassword/RESEND_PASSWORD_RESET_EMAIL_FAIL';
const RESET_PASSWORD = 'cc/resetPassword/RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'cc/resetPassword/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'cc/resetPassword/RESET_PASSWORD_FAIL';

const initialState = {
  loading: false,
  submitting: false,
  isTokenValid: false,
  showResetPasswordModal: false,
};

// Reducer
// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_RESET_PASSWORD_MODAL: {
      const { showResetPasswordModal } = action.payload;
      return {
        ...state,
        showResetPasswordModal,
      };
    }
    case CHECK_TOKEN:
      return {
        ...state,
        loading: true,
      };
    case CHECK_TOKEN_SUCCESS: {
      return {
        ...state,
        isTokenValid: true,
        loading: false,
      };
    }
    case CHECK_TOKEN_FAIL: {
      return {
        ...state,
        isTokenValid: false,
        loading: false,
      };
    }
    case RESEND_PASSWORD_RESET_EMAIL:
      return {
        ...state,
        submitting: true,
      };
    case RESEND_PASSWORD_RESET_EMAIL_SUCCESS:
    case RESEND_PASSWORD_RESET_EMAIL_FAIL:
      return {
        ...state,
        showResetPasswordModal: false,
        submitting: false,
      };
    case RESET_PASSWORD: {
      return {
        ...state,
        submitting: true,
      };
    }
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};

// Action Creators

const toggleResetPasswordModal = (payload) => ({
  type: TOGGLE_RESET_PASSWORD_MODAL,
  payload,
});

const checkToken = (payload) => async (dispatch) => {
  dispatch({ type: CHECK_TOKEN, payload });

  const { userId, token, branchId } = payload;
  const branchName = resolveBranch(branchId, userId);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (branchName) {
    config.headers.TenantId = branchName;
  }

  try {
    await axios.post(
      `users/${userId}/reset_password/verify/`,
      {
        token,
      },
      config,
    );
    dispatch(checkTokenSuccess());
  } catch (error) {
    toast.error('Your token is no longer valid');
    dispatch(checkTokenFail({ error }));
  }
};

const checkTokenSuccess = () => ({
  type: CHECK_TOKEN_SUCCESS,
});

const checkTokenFail = (payload) => ({
  type: CHECK_TOKEN_FAIL,
  payload,
});

const resendPasswordResetEmail = (payload) => async (dispatch) => {
  dispatch({ type: RESEND_PASSWORD_RESET_EMAIL });
  const { email } = payload;

  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(
      'users/reset_password/',
      {
        email,
      },
      headers,
    );
    toast.success('Password Recovery email resent!');
    dispatch(resendPasswordResetEmailSuccess());
  } catch (error) {
    toast.success('Password Recovery email failed!');
    dispatch(resendPasswordResetEmailFail({ error }));
  }
};

const resendPasswordResetEmailSuccess = () => ({
  type: RESEND_PASSWORD_RESET_EMAIL_SUCCESS,
});

const resendPasswordResetEmailFail = (payload) => ({
  type: RESEND_PASSWORD_RESET_EMAIL_FAIL,
  payload,
});

const resetPassword = (payload) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD, payload });

  const { userId, token, password, branchId } = payload;
  const branchName = resolveBranch(branchId, userId);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (branchName) {
    config.headers.TenantId = branchName;
  }

  try {
    await axios.post(
      `users/${userId}/reset_password/`,
      {
        token,
        password,
      },
      config,
    );

    toast.success('Your account is now active! Please login');
    history.replace('/login');
  } catch (error) {
    dispatch(resetPasswordFail({ error }));
  }
};

const resetPasswordSuccess = () => (dispatch) => {
  dispatch({ type: RESET_PASSWORD_SUCCESS });
  history.replace('/');
};

const resetPasswordFail = (payload) => (dispatch) => {
  dispatch({ type: RESET_PASSWORD_FAIL, payload });
  const { error } = payload;
  toast.error(
    error.response?.data?.message ||
      error.message ||
      'Could not save your new password',
  );
};

export default reducer;
export {
  // Actions
  TOGGLE_RESET_PASSWORD_MODAL,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_FAIL,
  RESEND_PASSWORD_RESET_EMAIL,
  RESEND_PASSWORD_RESET_EMAIL_SUCCESS,
  RESEND_PASSWORD_RESET_EMAIL_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  // Action Creators
  toggleResetPasswordModal,
  checkToken,
  checkTokenFail,
  checkTokenSuccess,
  resendPasswordResetEmail,
  resendPasswordResetEmailFail,
  resendPasswordResetEmailSuccess,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
};
