import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './GenderSelector.sss';

const GenderSelector = ({
  error,
  register,
  ...others
}) => (
  <div className={theme.GenderSelector}>
    <div className={classNames(theme.Option, { [theme.InputError]: error })}>
      <label>
        <input className={theme.Input} type="radio" value="female" {...others} ref={register} />
        Female
      </label>
    </div>
    <div className={classNames(theme.Option, { [theme.InputError]: error })}>
      <label>
        <input className={theme.Input} type="radio" value="male" {...others} ref={register} />
        Male
      </label>
    </div>
    {error &&
      <span className={theme.ErrorText}>{error}</span>
    }
  </div>
);

GenderSelector.propTypes = {
  error: PropTypes.string,
  register: PropTypes.func,
};

export default GenderSelector;
