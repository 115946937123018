/* eslint no-use-before-define: 0 */

import axios from 'config/axios';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'helpers/errorHandling';

// Actions
const FETCH_UNREAD_COUNT = 'cc/CoachesInboxWidget/FETCH_UNREAD_COUNT';
const FETCH_UNREAD_COUNT_SUCCEEDED =
  'cc/CoachesInboxWidget/FETCH_UNREAD_COUNT_SUCCEEDED';
const FETCH_UNREAD_COUNT_FAILED =
  'cc/CoachesInboxWidget/FETCH_UNREAD_COUNT_FAILED';

const initialState = {
  unreadCount: 0,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNREAD_COUNT_SUCCEEDED: {
      const { unreadCount } = action.payload;
      return { ...state, unreadCount };
    }
    default:
      return state;
  }
};

// Actions

const fetchUnreadCount = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_UNREAD_COUNT, payload });
  try {
    const { data } = await axios.get('users/coaches/inboxes/', {
      params: { filter: 'unread' },
    });
    await dispatch(fetchUnreadCountSucceeded({ unreadCount: data.count }));
  } catch (error) {
    fetchUnreadCountFailed(error);
  }
};

const fetchUnreadCountSucceeded = (payload) => ({
  type: FETCH_UNREAD_COUNT_SUCCEEDED,
  payload,
});

const fetchUnreadCountFailed = (error) => async (dispatch) => {
  dispatch({ type: FETCH_UNREAD_COUNT_FAILED, error });
  toast.error(getErrorMessage(error));
};

export default reducer;
export {
  // Actions
  FETCH_UNREAD_COUNT,
  FETCH_UNREAD_COUNT_FAILED,
  FETCH_UNREAD_COUNT_SUCCEEDED,
  // Action Creators
  fetchUnreadCount,
  fetchUnreadCountSucceeded,
  fetchUnreadCountFailed,
};
