import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { isMaintenance } from 'helpers/program';
import { TYPES } from 'helpers/message';
import { mapModalDispatchToProps } from 'helpers/redux';
import { useLoggedInUser } from 'hooks';
import { white01 } from 'styles/colors.sss';
import ComposeMessage from 'components/ComposeMessage';
import { TruckIcon } from 'components/Icon';

import theme from './Compose.sss';
import MessagesLimitModal from './MessagesLimitModal';
import { isClient } from 'helpers/user';

const MESSAGES_LIMIT_MODAL_ID = 'Conversation/MESSAGES_LIMIT_MODAL_ID';

const Compose = ({ onSubmit, setOpenModalId }) => {
  const user = useLoggedInUser();
  function getLeftItems() {
    const availableMessages =
      user.program?.maintenance_plan_balance?.balance.available_messages;
    if (
      isClient(user) &&
      isMaintenance(user.program) &&
      availableMessages >= 0
    ) {
      return (
        <>
          <span className={theme.MessagesNumber}>
            {user.program.maintenance_plan_balance.balance.available_messages}
          </span>
          Messages left in plan
        </>
      );
    }
    return null;
  }

  function renderButtons() {
    if (isClient(user)) {
      return (
        <div className={theme.MessageTypes}>
          <ComposeMessage.MessageTypeButton id={TYPES.conversation}>
            Message Your Clear Skin Specialist
          </ComposeMessage.MessageTypeButton>
          <ComposeMessage.MessageTypeButton id={TYPES.shipping}>
            <>
              <TruckIcon className={theme.MessageTypeIcon} fill={white01} />
              Shipping Question
            </>
          </ComposeMessage.MessageTypeButton>
          <ComposeMessage.MessageTypeButton id={TYPES.feedback}>
            Send complaint to a Manager
          </ComposeMessage.MessageTypeButton>
        </div>
      );
    }

    return null;
  }
  // const fileInputRef = React.useRef();
  return (
    <div className={theme.Compose}>
      <ComposeMessage
        defaultMessageType={TYPES.conversation}
        onSubmit={(values) => {
          if (
            isClient(user) &&
            isMaintenance(user.program) &&
            user.program.maintenance_plan_balance.balance.available_messages ===
              0
          ) {
            setOpenModalId(MESSAGES_LIMIT_MODAL_ID);
          } else {
            onSubmit(values);
          }
        }}
      >
        {renderButtons()}
        <ComposeMessage.AttachmentPreview />
        <ComposeMessage.TextArea className={theme.TextArea} />
        <ComposeMessage.Bottombar leftItems={getLeftItems()} />
      </ComposeMessage>
      <MessagesLimitModal modalId={MESSAGES_LIMIT_MODAL_ID} />
    </div>
  );
};

Compose.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

export default connect(null, mapModalDispatchToProps)(Compose);
