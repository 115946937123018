import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import startCase from 'lodash/startCase';

import PhotoUpload from './PhotoUpload';

const PhotoUploadArea = forwardRef(
  (
    {
      className,
      defaultValue,
      programIndex,
      surveyType,
      error,
      area,
      positions,
      ...others
    },
    ref,
  ) => {
    const initialImages = () => {
      return positions.reduce(
        (acc, position) => ({
          ...acc,
          [position]: '',
        }),
        {},
      );
    };
    const initialThumbnails = () => {
      return positions.reduce(
        (acc, position) => ({
          ...acc,
          [position]: '',
        }),
        {},
      );
    };
    const [images, setImages] = useState(
      defaultValue?.images ?? initialImages(),
    );
    const [thumbnails, setThumbnails] = useState(
      defaultValue?.thumbnails ?? initialThumbnails(),
    );
    useEffect(() => {
      others.onChange?.({
        image_group_answer: {
          image_answers: Object.entries(images).map(([context, image]) => ({
            context,
            image,
          })),
        },
      });
    }, [images, thumbnails]);

    const handleImageLoaded = (context, file, thumbnail) => {
      setImages({
        ...images,
        [context]: file,
      });
      setThumbnails({
        ...thumbnails,
        [context]: thumbnail,
      });
    };

    const removeImage = (context) => {
      setImages({
        ...images,
        [context]: '',
      });
      setThumbnails({
        ...thumbnails,
        [context]: '',
      });
    };

    const buildFile = (file, position) => {
      const surveyName = surveyType.replace(' ', '-');
      const fileExtension = file.name.split('.').pop();
      const checkInIndex = `_0${programIndex}`.slice(-2); // eslint-disable-line camelcase
      const checkInWeekName =
        surveyType === 'checkin' ? `${checkInIndex}/` : '';
      const positionPath = area === position ? `${position}/` : '';
      const fileName = encodeURIComponent(
        `${surveyName}/${checkInWeekName}${area}/${positionPath}${position}.${fileExtension}`,
      );
      return new File([file], fileName, { type: file.type });
    };

    return (
      <ul className={classNames(className)}>
        {positions.map((position, key) => (
          <PhotoUpload
            key={key}
            id={`${area}-${position}`}
            ref={ref}
            preview={thumbnails[position]}
            error={JSON.parse(error)[position]}
            title={startCase(position)}
            onChange={({ file, preview }) => {
              handleImageLoaded(position, buildFile(file, position), preview);
            }}
            onRemove={() => {
              removeImage(position);
            }}
          />
        ))}
      </ul>
    );
  },
);

PhotoUploadArea.propTypes = {
  defaultValue: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.string,
  programIndex: PropTypes.number,
  surveyType: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  positions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PhotoUploadArea.defaultProps = {
  error: '{}',
};

export default PhotoUploadArea;
