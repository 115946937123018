import React from 'react';
import PropTypes from 'prop-types';

import { TYPES } from 'helpers/message';

import Bottombar from './Bottombar';

const ComposeMessageContext = React.createContext();
ComposeMessageContext.displayName = 'ComposeMessageContext';

function useComposeMessage() {
  return React.useContext(ComposeMessageContext);
}

function ComposeMessage({ onSubmit, children, ...rest }) {
  const [messageType, setMessageType] = React.useState(TYPES.conversation);
  const [message, setMessage] = React.useState('');
  const [attachment, setAttachment] = React.useState({});
  const [messagePlaceholder, setMessagePlaceholder] = React.useState(
    'Start the conversation here...',
  );
  return (
    <ComposeMessageContext.Provider
      value={{
        message,
        setMessage,
        messageType,
        setMessageType,
        attachment,
        setAttachment,
        messagePlaceholder,
        setMessagePlaceholder,
      }}
    >
      {React.Children.map(children, (child) => {
        if (child?.type === Bottombar) {
          return React.cloneElement(child, {
            onSendMessageClick: onSubmit,
            ...rest,
          });
        }
        return child;
      })}
    </ComposeMessageContext.Provider>
  );
}

ComposeMessage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
export { useComposeMessage };

export default ComposeMessage;
