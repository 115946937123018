import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Form, { MultipleChoice } from 'components/Form';
import Modal, { actions as modalActions } from 'components/Modal';
import Button from 'components/Button';

const TREATMENT_AREAS = [
  {
    title: 'Face',
    value: 'face',
  },
  {
    title: 'Back',
    value: 'back',
  },
  {
    title: 'Chest',
    value: 'chest',
  },
];

function AcceptProgramModal({ modalId, onSubmit }) {
  const dispatch = useDispatch();
  return (
    <Modal
      id={modalId}
      size="small"
      title="Select the treatment area"
      autoClose
    >
      <Form
        onSubmit={(values) => {
          onSubmit(values.treatmentAreas);
          dispatch(modalActions.setOpenModalId(null));
        }}
      >
        {() => (
          <>
            <Form.Row>
              <Form.Item
                name="treatmentAreas"
                label={<label>Treatment Areas:</label>}
                input={<MultipleChoice choices={TREATMENT_AREAS} />}
                registerOptions={{
                  required: 'Please select one or more treatment areas',
                }}
              />
            </Form.Row>
            <Form.Actions>
              <Button
                size="big"
                color="sensitive"
                onClick={() => {
                  dispatch(modalActions.setOpenModalId(null));
                }}
              >
                Cancel
              </Button>
              <Button size="big" type="submit">
                Accept Program
              </Button>
            </Form.Actions>
          </>
        )}
      </Form>
    </Modal>
  );
}

AcceptProgramModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
};

export default AcceptProgramModal;
