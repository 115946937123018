import React from 'react';
import PropTypes from 'prop-types';

import { TYPES } from 'helpers/note';
import Button from 'components/Button';
import Wysiwyg from 'components/Wysiwyg';
import Form, { Radio } from 'components/Form';

import theme from './NotesForm.sss';

const NotesForm = ({ onSubmit, note }) => (
  <Form
    className={theme.NotesForm}
    defaultValues={{
      type: TYPES.general,
      title: note?.title,
    }}
    onSubmit={(values) => {
      onSubmit(values);
    }}
  >
    {() => (
      <>
        {!note && (
          <Form.Row>
            <Form.Item
              name="type"
              fill={false}
              inlineLabel
              reverseLabel
              label={<label htmlFor="general">General Notes</label>}
              input={<Radio id="general" value={TYPES.general} />}
            />
            <Form.Item
              name="type"
              fill={false}
              inlineLabel
              reverseLabel
              label={<label htmlFor="consult">Consult Notes</label>}
              input={<Radio id="consult" value={TYPES.consult} />}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Form.Item
            name="body"
            label={<label htmlFor="body">Description</label>}
            onChange={(value) => value}
            input={<Wysiwyg id="body" defaultValue={note?.body} hideToolBar />}
            registerOptions={{
              required: 'Please fill out this field',
            }}
          />
        </Form.Row>
        <Form.Actions>
          <Button classes={[theme.NoteSubmit]} type="submit">
            {note ? 'Save Note' : 'Add Note'}
          </Button>
        </Form.Actions>
      </>
    )}
  </Form>
);

NotesForm.propTypes = {
  submitting: PropTypes.bool,
  note: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

NotesForm.defaultProps = {
  submitting: false,
};

export default NotesForm;
