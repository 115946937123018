import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { TYPES } from 'helpers/note';
import { turquoise01 } from 'styles/colors.sss';

import { useBranch } from 'components/BranchProvider';
import { TextInput } from 'components/Form';
import { PeopleIcon, SearchIcon } from 'components/Icon';
import { TABLE, THEAD, TBODY, TH, TR } from 'components/Table';
import Loader from 'components/Loader';

import NotesModalWidget from 'containers/NotesModalWidget';
import AddNoteModalWidget from 'containers/AddNoteModalWidget';

import MissedCheckInRow from './MissedCheckInsRow';

import theme from './MissedCheckInsTable.sss';

export const NOTES_MODAL_ID = 'MissedCheckInsWidget/NotesWidget';
export const ADD_NOTE_MODAL_ID = 'MissedCheckInsWidget/AddNoteModalWidget';

const MissedCheckInTable = ({ clients, loading, onSearch }) => {
  const queryClient = useQueryClient();
  const [branch] = useBranch();

  const [typingTimeout, setTypingTimeout] = useState();
  return (
    <div className={theme.MissedCheckInTable}>
      <div className={theme.TableHeader}>
        <div className={theme.Title}>
          <PeopleIcon fill={turquoise01} />
          <h1>Missed Checkin Clients</h1>
        </div>
        <TextInput
          placeholder="Search a client"
          icon={<SearchIcon fill={turquoise01} />}
          onChange={(ev) => {
            const clientName = ev.target.value;
            if (typingTimeout) {
              clearTimeout(typingTimeout);
            }
            setTypingTimeout(
              setTimeout(() => {
                onSearch(clientName);
              }, 1000),
            );
          }}
        />
      </div>
      <div className={theme.ResultsCount}>{`${clients.length} clients`}</div>
      <NotesModalWidget modalId={NOTES_MODAL_ID} types={[TYPES.general]} />
      <AddNoteModalWidget
        modalId={ADD_NOTE_MODAL_ID}
        onNoteAdded={() => {
          queryClient.invalidateQueries(['clients', 'missed-checkin']);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <TABLE>
          <THEAD>
            <TR>
              <TH>Name</TH>
              <TH>CheckIn Date</TH>
              <TH>Week in Program</TH>
              {branch.show_next_acne_peel_appointment && (
                <TH>Next Appt Date</TH>
              )}
              <TH>Notes</TH>
              {branch.messaging_enabled && <TH>Action</TH>}
            </TR>
          </THEAD>
          <TBODY>
            {clients.map((client, key) => (
              <MissedCheckInRow
                key={key}
                client={client}
                status="awaitingTreatmentResponse"
              />
            ))}
          </TBODY>
        </TABLE>
      )}
    </div>
  );
};

MissedCheckInTable.propTypes = {
  loading: PropTypes.bool,
  clients: PropTypes.array,
  onSearch: PropTypes.func.isRequired,
};

MissedCheckInTable.defaultProps = {
  clients: [],
};

export default MissedCheckInTable;
