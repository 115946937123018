import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Constant from 'utils/constants';
import strings from 'assets/strings';
import { turquoise01 } from 'styles/colors.sss';

import { TaskListIcon } from 'components/Icon';
import Button from 'components/Button';

import * as taskWidgets from 'containers/TaskWidget';
import theme from './TaskList.sss';

const TASK_MAPPING = {
  [Constant.task.action.publishTreatmentPlan]:
    taskWidgets.PublishTreatmentPlanWidget,
  [Constant.task.action.acceptRejectTreatmentPlan]:
    taskWidgets.ReviewTreatmentPlanWidget,
  [Constant.task.action.publishStage]: taskWidgets.PublishStageWidget,
  [Constant.task.action.startAcneProgram]: taskWidgets.StartProgramWidget,
  [Constant.task.action.completeSurveyResponse]:
    taskWidgets.CompleteSurveyWidget,
  [Constant.task.action.selectAcneSeverityRating]: taskWidgets.SelectASRWidget,
  [Constant.task.action.consentToAcneProgram]: taskWidgets.ConsentProgramWidget,
};

const TaskList = ({ tasks, title, truncateSize }) => {
  const [showAllTasks, setShowAllTasks] = useState(false);
  const shownTasks = showAllTasks ? tasks : tasks.slice(0, truncateSize);

  const renderTask = (task, index) => {
    const TaskWidget = TASK_MAPPING[task.action];
    if (!TaskWidget) {
      throw new Error(`Task action ${task.action} not supported`);
    }
    return <TaskWidget key={index} task={task} />;
  };

  return (
    <div>
      <div className={theme.Title}>
        <TaskListIcon fill={turquoise01} />
        <h1>{`${title} (${tasks.length})`}</h1>
        {!showAllTasks && tasks.length > truncateSize && (
          <Button
            classes={[theme.Link]}
            color="sensitive"
            onClick={() => {
              setShowAllTasks(true);
            }}
          >
            View All
          </Button>
        )}
      </div>
      {tasks.length === 0 && <h1 className={theme.Empty}>No tasks found</h1>}
      <ul className={theme.List}>{shownTasks.map(renderTask)}</ul>
    </div>
  );
};

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  truncateSize: PropTypes.number.isRequired,
};

TaskList.defaultProps = {
  title: strings.tasks,
  truncateSize: 5,
};

export default TaskList;
