/* eslint-disable react/jsx-no-bind */

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import pickBy from 'lodash/pickBy';

import Constant from 'utils/constants';
import { useUpdateProgram } from 'features/programs/program.query';
import { useGetStages } from 'features/programs/stage.query';
import { getErrorMessage } from 'helpers/errorHandling';

import Button from 'components/Button';
import Loader from 'components/Loader';
import Form, { TextInput } from 'components/Form';

import data from './ClientAgreement.json';
import theme from './ProgramConsent.sss';

const PAGES = {
  agreements: 0,
  consent: 1,
};

const ProgramConsentPage = () => {
  const history = useHistory();
  const { programId } = useParams();
  const { data: stages, isLoading } = useGetStages({
    acne_program: programId,
    state: Constant.stage.state.published,
  });
  const { mutateAsync: updateProgram } = useUpdateProgram();

  const [view, setActiveView] = React.useState(PAGES.agreements);
  const [agreements, setAgreements] = React.useState([]);

  const agreementsData = data[PAGES.agreements];
  const consentData = data[PAGES.consent];

  async function onSubmit(values) {
    switch (view) {
      case PAGES.agreements: {
        setAgreements(values);
        setActiveView(PAGES.consent);
        window.scrollTo(0, 0);
        break;
      }
      case PAGES.consent: {
        const payload = {
          state: Constant.program.state.consented,
          consent_form: [
            {
              title: agreementsData.title,
              description: agreementsData.description,
              agreements: Object.values(agreementsData.agreements).map(
                ({ title, items }, i) => {
                  const obj = {
                    title,
                    items: items.map((statement, j) => ({
                      statement,
                      signature: agreements.items[i][j],
                    })),
                  };
                  return pickBy(obj);
                },
              ),
              footer: agreementsData.footer,
              signature: {
                statement: agreementsData.signature,
                signature: agreements.signature,
              },
            },
            {
              title: consentData.title,
              description: consentData.description,
              agreements: Object.values(consentData.agreements).map(
                ({ title, items }, i) => {
                  const obj = {
                    title,
                    items: items.map((statement, j) => ({
                      statement,
                      signature: values.items[i][j],
                    })),
                  };
                  return pickBy(obj);
                },
              ),
              footer: consentData.footer,
              signature: {
                statement: consentData.signature,
                signature: values.signature,
              },
            },
          ],
        };
        await updateProgram(
          { programId, payload },
          {
            onSuccess: () => {
              const stage = stages[0];
              if (!stage.completed && stage.started) {
                history.push(`/view-regimen/${stage.id}`);
              } else {
                history.replace('/');
              }
            },
            onError: (error) => {
              toast.error(getErrorMessage(error));
            },
          },
        );
        break;
      }
      default:
        throw new Error('View ${view} not supported');
    }
  }

  const AgreementsForm = (
    <Form
      className={theme.ProgramConsent}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      title={agreementsData.title}
      description={
        <div className={theme.subtitle}>
          <div
            dangerouslySetInnerHTML={{ __html: agreementsData.description }}
          />
        </div>
      }
    >
      {({ formState: { errors } }) => (
        <>
          {agreementsData.agreements.map(({ title, items }, i) => {
            return (
              <div key={`page-${i}`}>
                <h4>{title}</h4>
                {items.map((statement, j) => (
                  <Form.Row key={`items-${i}-${j}`}>
                    <Form.Item
                      fill={false}
                      name={`items[${i}][${j}]`}
                      inlineLabel
                      inlineLabelClassName={theme.FormItem}
                      reverseLabel
                      label={
                        <div
                          dangerouslySetInnerHTML={{ __html: statement }}
                        ></div>
                      }
                      registerOptions={{
                        required: 'Please fill out this field',
                      }}
                      input={<input className={theme.StatementSignature} />}
                    />
                  </Form.Row>
                ))}
              </div>
            );
          })}
          <Form.Row key="agreementsSignature" className={theme.signature}>
            <Form.Item
              fill={false}
              name="signature"
              label={
                <div
                  dangerouslySetInnerHTML={{ __html: agreementsData.signature }}
                />
              }
              registerOptions={{
                required: 'Please fill out this field',
              }}
              input={
                <TextInput
                  maxLength="100"
                  className={theme.UserName}
                  placeholder="Type your full name here"
                />
              }
            />
          </Form.Row>
          {agreementsData.footer && <p>{agreementsData.footer}</p>}
          {Object.keys(errors).length > 0 && (
            <span className={theme.ErrorText}>Please fill all the fields</span>
          )}
          <Form.Actions>
            <Button type="submit" size="medium">
              Next
            </Button>
          </Form.Actions>
        </>
      )}
    </Form>
  );
  const ConsentForm = (
    <Form
      className={theme.ProgramConsent}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      title={consentData.title}
      description={
        <div className={theme.subtitle}>
          <div dangerouslySetInnerHTML={{ __html: consentData.description }} />
        </div>
      }
    >
      {({ formState: { errors } }) => (
        <>
          {consentData.agreements.map(({ title, items }, i) => {
            return (
              <div key={`consent-page-${i}`}>
                <h4>{title}</h4>
                {items.map((statement, j) => (
                  <Form.Row key={`items-${i}-${j}`}>
                    <Form.Item
                      fill={false}
                      name={`items[${i}][${j}]`}
                      inlineLabel
                      reverseLabel
                      label={
                        <div
                          dangerouslySetInnerHTML={{ __html: statement }}
                        ></div>
                      }
                      registerOptions={{
                        required: 'Please fill out this field',
                      }}
                      input={<input className={theme.StatementSignature} />}
                    />
                  </Form.Row>
                ))}
              </div>
            );
          })}
          <Form.Row key="consentSignature" className={theme.signature}>
            <Form.Item
              fill={false}
              name="signature"
              label={
                <div
                  dangerouslySetInnerHTML={{ __html: consentData.signature }}
                />
              }
              registerOptions={{
                required: 'Please fill out this field',
              }}
              input={
                <TextInput
                  maxLength="100"
                  className={theme.UserName}
                  placeholder="Type your full name here"
                />
              }
            />
          </Form.Row>
          <div dangerouslySetInnerHTML={{ __html: consentData.footer }} />
          {Object.keys(errors).length > 0 && (
            <span className={theme.ErrorText}>Please fill all the fields</span>
          )}
          <Form.Actions>
            <Button type="submit" size="medium">
              Submit
            </Button>
            <Button
              color="secondary"
              size="medium"
              onClick={() => {
                setActiveView(PAGES.agreements);
              }}
            >
              Back
            </Button>
          </Form.Actions>
        </>
      )}
    </Form>
  );

  function renderActiveComponent() {
    switch (view) {
      case PAGES.agreements:
        return AgreementsForm;
      case PAGES.consent:
        return ConsentForm;
      default:
        throw new Error(`Step ${view} not supported`);
    }
  }

  if (isLoading) {
    return <Loader type="fixed" />;
  }

  return renderActiveComponent();
};

export default ProgramConsentPage;
