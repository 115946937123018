/* eslint no-use-before-define: 0 */

// Actions
const SET_OPEN = 'cc/InboxWidget/SET_OPEN';
const SET_SELECTED_INBOX = 'cc/InboxWidget/SET_SELECTED_INBOX';

const INBOX_MAPPING = {
  userInbox: 'userInbox',
  coachesInbox: 'coachesInbox',
};

const initialState = {
  open: false,
  selectedInbox: INBOX_MAPPING.userInbox,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN: {
      const { open = !state.open } = action;
      return { ...state, open };
    }
    case SET_SELECTED_INBOX: return { ...state, selectedInbox: action.inbox };
    default: return state;
  }
};

// Actions

const setOpen = (open) =>
  (dispatch) => {
    dispatch({ type: SET_OPEN, open });
  };

const setSelectedInbox = (inbox) =>
  (dispatch) => {
    dispatch({ type: SET_SELECTED_INBOX, inbox });
  };

export default reducer;
export {
  // Actions
  SET_OPEN,
  SET_SELECTED_INBOX,

  // Action Creators
  setOpen,
  setSelectedInbox,
};
