import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { emailRegex } from 'helpers/validators';
import Button from 'components/Button';
import { TextInput } from 'components/Form';

import theme from './ResetPasswordModal.sss';

const ResetPasswordModal = ({
  open,
  toggleModal,
  onSubmit,
}) => {
  const { register, handleSubmit, errors } = useForm();
  return (
    <Modal
      className={theme.Modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={
        /* istanbul ignore next */
        () => { toggleModal(false); }
      }
    >
      <Fade in={open}>
        <form className={theme.Form} onSubmit={handleSubmit(onSubmit)}>
          <h1 className={theme.FormTitle}>Password Recovery</h1>
          {/* eslint-disable-next-line max-len */}
          <p className={theme.SectionTitle}>Enter your email below and we will send you a link to reset your password</p>
          <div className={theme.VerticalGroup}>
            <div className={theme.InputGroup}>
              <label className={theme.Label} htmlFor="email">Email</label>
              <TextInput
                name="email"
                id="email"
                register={register({
                  required: 'Enter your email',
                  pattern: {
                    value: emailRegex,
                    message: 'Your email is not a valid email address',
                  },
                })}
                error={errors.email?.message}
              />
            </div>
          </div>
          <footer className={theme.Footer}>
            <Button size="big" color="secondary" onClick={() => { toggleModal(false); }}>Cancel</Button>
            <Button size="big" type="submit">Reset Password</Button>
          </footer>
        </form>
      </Fade>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ResetPasswordModal.false = {
  open: false,
};

export default ResetPasswordModal;
