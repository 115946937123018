import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userAware } from 'components/UserProvider';
import Modal from 'components/Modal';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Loader from 'components/Loader';

import { mapModalDispatchToProps } from 'helpers/redux';

import theme from './ConfirmPlanModal.sss';

export const CONFIRM_MAINTENANCE_PLAN_MODAL_ID =
  'MaintenancePlan/CONFIRM_MODAL';

const ConfirmPlanModal = ({
  user,
  plan,
  submitting,
  onConfirm,
  setOpenModalId,
}) => (
  <Modal
    id={CONFIRM_MAINTENANCE_PLAN_MODAL_ID}
    title="Confirmation"
    size="small"
  >
    <div className={theme.ConfirmPlanModal}>
      <h1>Before going further, make sure your selection is correct</h1>
      <p>Selected Plan: {plan.name}</p>
      <footer>
        {submitting && <Loader type="absolute" />}
        <Button
          disabled={submitting}
          size="big"
          onClick={() => {
            setOpenModalId(null);
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <ButtonLink
          href={`${plan.checkout_url}&auth_email=${user.email}`}
          target="_blank"
          disabled={submitting}
          size="big"
          onClick={() => {
            onConfirm(plan);
          }}
        >
          Confirm
        </ButtonLink>
      </footer>
    </div>
  </Modal>
);

ConfirmPlanModal.propTypes = {
  user: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

ConfirmPlanModal.defaultProps = {
  submitting: false,
};

const mapStateToProps = ({ modal }) => ({
  plan: modal.modalProps.plan || {},
});

export default connect(
  mapStateToProps,
  mapModalDispatchToProps,
)(userAware(ConfirmPlanModal));
