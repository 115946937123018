import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import UserService from 'services/user.service';
import Modal, { actions } from 'components/Modal';
import Form, { Autocomplete } from 'components/Form';
import Button from 'components/Button';
import Loader from 'components/Loader';
import IconButton from 'components/IconButton';
import { EditIcon } from 'components/Icon';
import { orange01 } from 'styles/colors.sss';

const COACH_ASSIGNED_MODAL_ID = 'cc/CoachAssigned';

import theme from './CoachAssigned.sss';

function CoachAssigned({ userId }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [profile, setProfile] = React.useState();
  const [coaches, setCoaches] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    Promise.all([
      UserService.getProfile(userId),
      UserService.getCoaches(),
    ]).then(([userProfile, allCoaches]) => {
      setProfile(userProfile);
      setCoaches(allCoaches);
      setIsLoading(false);
    });
  }, [userId]);

  async function updateCoach(coachId) {
    UserService.setCoach(userId, coachId).then(setProfile);
    setIsSubmitting(true);
    dispatch(actions.setOpenModalId(null));
    setIsSubmitting(false);
    setIsEditing(false);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    // <div className={theme.changeCoach}>
    <div>
      <p>
        Clear Skin Specialist:&nbsp;
        <em>
          {profile?.coach?.profile.full_name ?? 'No Clear Skin Specialist'}
        </em>
        <IconButton
          classes={[theme.Edit]}
          icon={<EditIcon width={18} height={18} fill={orange01} />}
          onClick={() => {
            setIsEditing(true);
            dispatch(actions.setOpenModalId(COACH_ASSIGNED_MODAL_ID));
          }}
        />
      </p>
      {isEditing && (
        <Modal
          id={COACH_ASSIGNED_MODAL_ID}
          size="small"
          title="Update Clear Skin Specialist"
          autoClose
          onClose={() => {
            setIsEditing(false);
          }}
        >
          <Form
            title="Update Clear Skin Specialist"
            description={`Please select a new Clear Skin Specialist for ${profile.full_name}`}
            onSubmit={({ coach }) => {
              updateCoach(coach);
            }}
          >
            {() => (
              <>
                <Form.Row>
                  <Form.Item
                    name="coach"
                    label={
                      <label htmlFor="coach">Clear Skin Specialists:</label>
                    }
                    input={
                      <Autocomplete
                        id="coach"
                        getOptionLabel={(coach) => coach.profile?.full_name}
                        options={coaches}
                        disabled={isSubmitting}
                      />
                    }
                    registerOptions={{
                      required: 'Please select a Clear Skin Specialist',
                    }}
                    onChange={(_, value) => value.id}
                  />
                </Form.Row>
                <Form.Actions>
                  <Button size="big" type="submit">
                    Save
                  </Button>
                </Form.Actions>
              </>
            )}
          </Form>
        </Modal>
      )}
    </div>
  );
}

CoachAssigned.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default CoachAssigned;
