import React from 'react';
import PropTypes from 'prop-types';

const NACIcon = ({fill, ...props}) => (
  <svg viewBox="0 0 116 64" {...props} >
    <title>Shape</title>
    <desc>Created with Sketch.</desc>
    <g id="Start" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Client---Questionnaire---Step-1---A"
        transform="translate(-583.000000, -24.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path // eslint-disable-next-line max-len
          d="M609.149809,38.5454545 C622.283358,41.3126031 624.546964,52.2959352 625.373605,59.4269624 C626.601978,69.8057074 634.103552,77.099508 641.110687,77.099508 C648.117822,77.099508 655.619396,69.8134585 656.847769,59.4347135 C657.682136,52.2959352 659.938016,41.3126031 673.071565,38.5454545 C660.818736,45.3044281 677.660581,64.6512149 665.879015,76.4794187 C657.311306,85.0909091 646.472265,75.1772311 641.118413,85.0909091 C635.76456,75.1384755 624.894617,85.0909091 616.350084,76.4871698 C604.560793,64.6512149 621.402638,45.3044281 609.149809,38.5454545 Z M641.110687,73.4545455 C641.110687,73.4545455 652.732824,68.0505468 652.732824,54.8323511 C652.732824,43.2740091 643.772994,35.7554023 641.110687,24 C638.455922,35.7554023 629.48855,43.2740091 629.48855,54.8323511 C629.48855,68.0429676 641.103145,73.4545455 641.110687,73.4545455 Z M583,75.9348213 C592.663743,89.1221494 605.199374,91.2501779 620.771947,83.5360746 C615.366523,83.5360746 612.106763,75.6446356 605.488214,73.8632178 C596.657979,71.5256107 590.171471,80.1666961 583,75.9348213 Z M699,75.9338438 C691.841907,80.1794488 685.344306,71.494523 676.510211,73.8756132 C669.905281,75.6513416 666.602816,83.5614041 661.228053,83.5614041 C676.790921,91.2373934 689.365058,89.1226624 699,75.9257723 L699,75.9338438 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

NACIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

NACIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default NACIcon;
