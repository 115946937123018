/* eslint max-len: 0 */

/**
 * Survey page icons
 * Indexed by page.id
 */

export const iconsByPageId = {
  // 'Medical History'
  '1': `<svg viewBox='0 0 16 16'>
      <path d='M15.2954667,5.19380513 L10.7867487,5.19380513 L10.7867487,0.685087179 C10.7867487,0.306871795 10.4798564,0 10.1016615,0 L5.87889231,0 C5.50067692,0 5.19380513,0.306892308 5.19380513,0.685087179 L5.19380513,5.19380513 L0.685087179,5.19380513 C0.306071795,5.19380513 0,5.50069744 0,5.87889231 L0,10.1024821 C0,10.4806974 0.306092308,10.7875692 0.685087179,10.7875692 L5.19380513,10.7875692 L5.19380513,15.2962872 C5.19380513,15.6745026 5.50069744,15.9813744 5.87889231,15.9813744 L10.1016615,15.9805731 C10.4798769,15.9805731 10.7867487,15.6736808 10.7867487,15.2954859 L10.7867487,10.786768 L15.2954667,10.786768 C15.6736821,10.786768 15.9805538,10.4798757 15.9805538,10.1016808 L15.9805538,5.87809108 C15.9805538,5.49987569 15.6736615,5.1938039 15.2954667,5.1938039 L15.2954667,5.19380513 Z' />
    </svg>`,
  // 'Hormone Health'
  '2': `<svg viewBox='0 0 14 16'>
      <path d='M12.9590451,11.974291 C12.0078256,11.5268763 11.5245865,11.4377056 11.4910646,11.4194032 C11.4621007,11.4041593 9.86987434,10.9857056 9.13886946,10.5878519 C8.96737483,10.4780958 8.85914263,10.3957778 8.85914263,10.3957778 L8.85914263,10.2844978 L8.85838049,10.2844978 L8.85838049,9.60537584 C8.85838049,9.60537584 11.003161,9.67092511 12.020722,8.63204901 C12.020722,8.63204901 10.5397854,8.21284901 10.8057756,5.51692706 C11.0717854,2.82112218 10.4742244,0.454488035 8.30723902,0.654097791 C8.30723902,0.654097791 7.36821463,-0.476243673 5.49845854,0.234897791 C4.85668293,0.479561205 3.13494634,1.0961856 3.2225561,4.83099535 C3.31020878,8.56660511 1.89480976,8.58865389 1.89480976,8.58865389 C1.89480976,8.58865389 2.62423415,9.65039047 5.10066341,9.62828316 L5.10066341,10.3874246 C5.10066341,10.3874246 4.99319415,10.4697427 4.82093659,10.5794988 C4.08999024,10.976611 2.49781463,11.39505 2.46874146,11.41105 C2.4352039,11.4293427 1.95121951,11.5185193 1.00076098,11.9659378 C0.0495414634,12.4133524 0,13.3851378 0,13.3851378 L0,14.2441232 C2.09912195,15.2769036 4.46946341,15.8584451 6.98009756,15.8584451 C9.49073171,15.8584451 11.8612683,15.2768841 13.9596098,14.2433622 L13.9596098,13.3935085 C13.9596098,13.3935085 13.9100663,12.4217036 12.9588488,11.9743085 L12.9590451,11.974291 Z' />
    </svg>`,
  // 'Medications & Supplements Taken'
  '4': `<svg viewBox='0 0 13 16'>
      <path d='M9.14273959,4.5519144e-14 C10.2782445,4.5519144e-14 11.2,0.806570657 11.2,1.80018002 L11.2,2.4 L1.6,2.4 L1.6,1.80018002 C1.6,0.806570657 2.52175553,4.5519144e-14 3.65726041,4.5519144e-14 L9.14273959,4.5519144e-14 Z' />
      <path d='M11.9666773,6.78125536 L9.95584,4.89609851 L9.95584,4 L2.84416,4 L2.84416,4.89609851 L0.833322667,6.78125536 C0.318314667,7.26329456 1.45483625e-12,7.92969345 1.45483625e-12,8.66641222 L1.45483625e-12,14.6664022 C1.45483625e-12,15.403121 0.63584,15.99922 1.42250667,15.99922 L11.3774933,16 C12.162496,16 12.8,15.403121 12.8,14.6671822 L12.8,8.66639222 C12.8,7.92967345 12.481664,7.26327456 11.9666773,6.78123536 L11.9666773,6.78125536 Z M9.24411733,10.6664489 L7.110784,10.6664489 L7.110784,12.6664456 L5.68910933,12.6664456 L5.68910933,10.6664489 L3.555776,10.6664489 L3.555776,9.33285111 L5.68910933,9.33285111 L5.68910933,7.33285445 L7.110784,7.33285445 L7.110784,9.33285111 L9.24411733,9.33285111 L9.24411733,10.6664489 Z' />
    </svg>`,
  // 'Skin Services'
  '6': `<svg viewBox='0 0 12 16'>
      <path d='M6.02040816,0.127714286 C5.88456653,-0.0425714286 5.6122449,-0.0425714286 5.47640816,0.127714286 C4.31952653,1.62453061 0,7.47465306 0,10.2297551 C0,13.3930204 2.58481633,15.9778367 5.74808163,15.9778367 C8.91134694,15.9778367 11.4961633,13.3930204 11.4961633,10.2297551 C11.4961633,7.47465306 7.17665306,1.62453061 6.02040816,0.127714286 Z M7.27869388,13.018658 C9.28571429,12.2023314 9.11542857,9.75335184 9.08163265,9.71955592 C9,9.65196408 10,9.65196408 10.1020408,9.65196408 C10.2378824,10.7399804 9.82971429,13.0869029 7.68685714,13.9714743 C7.61861714,14.0052702 7.27869388,13 7.27869388,13.018658 Z' />
    </svg>`,
  // 'Diet Assessment'
  '7': `<svg viewBox='0 0 18 16'>
      <path d='M18.2602,1.7796 C18.200044,1.639756 18.120356,1.49992 18.02036,1.39992 C17.860204,1.239764 17.64068,1.12024 17.40004,1.09992 C16.9602,1.060076 15.77972,1.76008 15.10004,2.1796 C14.13988,2.7796 12.70004,3.79992 11.26024,5.2398 L8.12044,8.3796 C7.72044,8.7796 7.72044,9.43976 8.12044,9.83976 L8.140752,9.860072 L3.820352,14.139672 C3.420352,14.539672 3.420352,15.199832 3.820352,15.599832 C4.220352,15.999832 4.880512,15.999832 5.280512,15.599832 L18.020312,2.860032 C18.299992,2.579572 18.379692,2.160032 18.260152,1.779572 L18.2602,1.7796 Z' />
      <path d='M2.8796,7.8204 L2.919444,7.860244 L2.919444,7.880556 C2.9202252,7.860244 2.8999128,7.839932 2.8796,7.8204 Z' />
      <path d='M12.3,9.4602 L10.83984,10.92036 L15.56024,15.64076 C15.96024,16.04076 16.6204,16.04076 17.0204,15.64076 C17.4204,15.24076 17.4204,14.5806 17.0204,14.1806 L12.3,9.4602 Z' />
      <path d='M4.3796,0.1796 L7.3398,3.1398 C7.79996,3.6398 7.96012,4.3398 7.79996,4.96012 L9.17964,6.3398 L7.7398,7.77964 L6.36012,6.39996 C5.7398,6.560116 5.0398,6.39996 4.5398,5.9398 L1.5796,2.9796 C1.419444,2.819444 1.419444,2.5796 1.5796,2.43976 C1.739756,2.279604 1.9796,2.279604 2.11944,2.43976 L5.03984,5.36016 C5.199996,5.520316 5.43984,5.56016 5.6,5.400004 C5.76016,5.239848 5.760156,4.960164 5.6,4.800004 L2.7,1.839804 C2.539844,1.679648 2.539844,1.439804 2.7,1.299964 C2.860156,1.139808 3.1,1.139808 3.23984,1.299964 L6.16024,4.220364 C6.320396,4.38052 6.58056,4.400052 6.76024,4.220364 C6.920396,4.060208 6.900084,3.800044 6.739928,3.640684 L6.719616,3.620372 L3.840016,0.720372 C3.67986,0.560216 3.700172,0.320372 3.840016,0.180532 C3.97986,0.020376 4.220476,0.020376 4.379856,0.1797508 L4.3796,0.1796 Z' />
      <path d='M18.2602,1.7796 C18.200044,1.639756 18.120356,1.49992 18.02036,1.39992 C17.860204,1.239764 17.64068,1.12024 17.40004,1.09992 C16.9602,1.060076 15.77972,1.76008 15.10004,2.1796 C14.13988,2.7796 12.70004,3.79992 11.26024,5.2398 L8.12044,8.3796 C7.72044,8.7796 7.72044,9.43976 8.12044,9.83976 L8.140752,9.860072 L3.820352,14.139672 C3.420352,14.539672 3.420352,15.199832 3.820352,15.599832 C4.220352,15.999832 4.880512,15.999832 5.280512,15.599832 L18.020312,2.860032 C18.299992,2.579572 18.379692,2.160032 18.260152,1.779572 L18.2602,1.7796 Z' />
    </svg>`,
  // 'Digestive Health'
  '8': `<svg viewBox='0 0 16 16'>
      <path d='M11.6472991,2.13286761 C10.8127977,2.13286761 9.86208217,2.44272676 9.31377794,2.96473239 C9.01712442,2.3476507 8.67028498,1.27371268 8.98895541,0 L6.68878639,0 C6.25216667,2.65239437 7.47488217,4.47278873 7.59283146,4.79932394 C7.86395259,5.55107606 7.82611597,7.07222535 7.34724273,7.99211268 C5.22487653,12.0705352 2.57789062,8.46129577 0.548820195,10.6584789 C-0.0867403682,11.346862 -0.394841777,13.2536338 0.896538505,15.7588732 L3.04089907,15.7588732 C3.02593434,15.7315854 1.38244273,12.5793803 2.47400329,12.0932958 C2.86924836,11.9172394 3.43262865,14.6064225 8.00662301,14.7130141 C9.3948371,14.7464654 15.5532146,14.2271099 15.6280315,6.41464789 C15.6491582,4.17521127 13.8252146,2.13295775 11.6474117,2.13295775 L11.6472991,2.13286761 Z' />
    </svg>`,
  // 'Lifestyle Consideration'
  '10': `<svg viewBox='0 0 17 16'>
      <path d='M16,7.5796 L12.6398,4.2202 L12.6398,1.7398 C12.6398,1.699956 12.6398,1.660112 12.619488,1.619488 C12.759332,1.599176 12.859328,1.479644 12.859328,1.319488 C12.859328,1.159332 12.719484,1.019488 12.559328,1.019488 L11.279648,1.019488 C11.119492,1.019488 10.979648,1.159332 10.979648,1.319488 C10.979648,1.459332 11.079648,1.599168 11.219488,1.619488 C11.199176,1.659332 11.199176,1.699176 11.199176,1.7398 L11.1999572,2.79996 L8.7203572,0.32036 L8.6805132,0.280516 C8.5406692,0.140672 8.3601932,0.080516 8.1805132,0.080516 C8.0008252,0.080516 7.8203532,0.140672 7.6805132,0.280516 C7.6602012,0.300828 7.6602012,0.300828 7.6406692,0.32036 L0.4000692,7.57976 C0.1203892,7.85944 0.1203892,8.30008 0.4000692,8.57976 C0.6797492,8.85944 1.1203892,8.85944 1.4000692,8.57976 L8.2000692,1.77976 L15.0000692,8.57976 C15.2797492,8.85944 15.7203892,8.85944 16.0000692,8.57976 C16.2602292,8.30008 16.2602292,7.86022 16.0000692,7.57976 L16,7.5796 Z' />
      <path d='M2.3398,8.3 L2.3398,15.4602 C2.3398,15.72036 2.59996,15.92036 2.91948,15.92036 L5.95928,15.92036 C6.21944,15.92036 6.43896,15.70004 6.43896,15.44068 L6.4397414,10.62028 C6.4397414,10.36012 6.6600614,10.16012 6.9194214,10.16012 L9.4990214,10.16012 C9.7591814,10.16012 9.9787014,10.36012 9.9787014,10.62028 L9.9787014,15.44068 C9.9787014,15.70084 10.1787014,15.92036 10.4388614,15.92036 L13.4786614,15.92036 C13.7989814,15.92036 14.0786614,15.70004 14.0786614,15.4602 L14.0794428,8.3 L8.1998428,2.4398 L2.3398,8.3 Z' />
    </svg>`,
  // 'Upload Images'
  '11': `<svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><circle cx="255.81" cy="285.31" r="75.217"/><path d="m477 137h-124.28l-3.718-29c0-16.568-13.432-30-30-30h-128c-16.568 0-30 13.432-30 30l-3.718 29h-123.28c-11.046 0-20 8.454-20 19.5v258c0 11.046 8.954 20.5 20 20.5h443c11.046 0 20-9.454 20-20.5v-258c0-11.046-8.954-19.5-20-19.5zm-221.4 271.56c-67.928 0-122.99-55.066-122.99-122.99 0-67.928 55.066-122.99 122.99-122.99s122.99 55.066 122.99 122.99c0 67.926-55.066 122.99-122.99 122.99zm218.4-218.56h-105v-31h105v31z"/>
    </svg>
    `,
};

/**
 * Define questions that have choices with followup questions
 * {
 *   <questionId>: {
 *     <choiceId>: [...followup question.ids for this choice],
 *     ...
 *   },
 *   ...
 * }
 */

export const followupQuestions = {
  '7': {
    '27': [17],
  },
  '8': {
    '29': [18],
  },
  '11': {
    '35': [19],
  },
  '13': {
    '39': [20],
  },
  '6': {
    '26': [21],
  },
  '22': {
    '71': [24, 25], // antibiotics - when, why
    '72': [26], // accutane
    '73': [27], // benzoyl
    '74': [28], // e-mycin
    '75': [29], // retin-a
    '76': [30], // tazorac
    '77': [31], // differin
    '78': [32], // sulfur
    '79': [33], // androstenedione
    '80': [34], // cortisone
    '81': [35], // testosterone
    '82': [36, 37], // spironolactone - when, dosage
    '83': [38], // progesterone
    '84': [39], // disulfiram
    '85': [40], // cyclosporin
    '86': [41], // thyroid
    '87': [42], // corticosteroids
    '88': [43], // add/adhd
    '89': [44], // lithium
    '90': [45], // gondaotrophin
    '91': [46], // steroids
    '92': [47], // marijuana
    '93': [48], // opioids
    '94': [49], // tobacco
    '95': [50], // amphetamines
  },
  '66': {
    '182': [69],
  },
  '70': {
    '188': [84],
  },
};
