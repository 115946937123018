import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './NumberInput.sss';

const NumberInput = forwardRef(({
  error,
  icon,
  ...others
}, ref) => (
  <div className={theme.NumberInput}>
    {icon && icon}
    <input
      className={classNames({
        [theme.InputError]: error,
        [theme.IconDivider]: !!icon,
      })}
      type="number"
      {...others}
      ref={ref}
    />
    {error &&
      <span className={theme.ErrorText}>{error}</span>
    }
  </div>
));

NumberInput.propTypes = {
  error: PropTypes.string,
  icon: PropTypes.node,
};

export default NumberInput;
