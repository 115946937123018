import axios from 'config/axios';

export default class TreatmentPlanService {
  static async get(treatmentPlanId) {
    const { data } = await axios.get(
      `programs/acne/treatment-plans/${treatmentPlanId}/`,
    );
    return data;
  }

  static async update(treatmentPlanId, payload) {
    const { data } = await axios.patch(
      `programs/acne/treatment-plans/${treatmentPlanId}/`,
      payload,
    );
    return data;
  }
}
