import { bindActionCreators } from 'redux';
import qs from 'query-string';
import isFunction from 'lodash/isFunction';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';

import * as taskDataActions from 'containers/TaskData/TaskData.ducks';
import * as modalActions from 'components/Modal/Modal.ducks';

export const mapQueryParamsToProps = (history) => {
  const queryParams = qs.parse(history.location.search, {
    parseBooleans: true,
    parseNumbers: true,
  });

  return queryParams;
};

export const mapTaskDispatchToProps = (dispatch, ownProps) => {
  const taskId = ownProps.id || ownProps.taskId;
  const actionCreators = pickBy(taskDataActions, (value, key) => {
    return isFunction(value) && key !== 'default';
  });

  const curriedActions = mapValues(actionCreators, (fn) => {
    return function (...args) {
      return fn.apply(this, [taskId, ...args]);
    };
  });
  return bindActionCreators(curriedActions, dispatch);
};

export const mapModalDispatchToProps = (dispatch) => {
  const actionCreators = pickBy(modalActions, (value, key) => {
    return isFunction(value) && key !== 'default';
  });

  const curriedActions = mapValues(actionCreators, (fn) => {
    return function (...args) {
      return fn.apply(this, [...args]);
    };
  });
  return bindActionCreators(curriedActions, dispatch);
};
