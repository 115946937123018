import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Constant from 'utils/constants';
import {
  requireMaintenancePlan as _requireMaintenancePlan,
  requireMaintenancePayment,
} from 'helpers/user';
import { isMaintenanceDue } from 'helpers/program';
import { userAware } from './UserProvider';
import * as modalActions from 'components/Modal/Modal.ducks';

const CAN_VIEW_URL_MAP = {
  [Constant.maintenancePlan.constraints.canViewMessages]: [
    '/messages/:threadId',
  ],
  [Constant.maintenancePlan.constraints.canViewNotifications]: [],
  [Constant.maintenancePlan.constraints.canViewAcneProgramPDFs]: [],
};
const MAINTENANCE_DUE_MODAL_ID = 'Conversation/MAINTENANCE_DUE_MODAL_ID';

const requireMaintenancePlan = (WrappedComponent) => {
  class CheckMaintenancePlan extends Component {
    componentDidMount() {
      const { user, history, setOpenModalId } = this.props;

      if (_requireMaintenancePlan(user) || !this.checkCanViewUrl()) {
        history.replace('/maintenance-plans');
      }
      if (requireMaintenancePayment(user)) {
        setOpenModalId(MAINTENANCE_DUE_MODAL_ID);
      }
      if (isMaintenanceDue(user.program)) {
        history.replace('/maintenance-plans');
      }
    }

    checkCanViewUrl = () => {
      const { user, match } = this.props;
      if (!user.program.maintenance_plan_balance) {
        return true;
      }
      const canViewPermissions = Object.entries(
        user.program.maintenance_plan_balance.balance,
      ).filter(([key, _]) => key.startsWith('can_view')); // eslint-disable-line no-unused-vars
      return canViewPermissions.every(
        ([permission, value]) =>
          !CAN_VIEW_URL_MAP[permission].includes(match.path) ||
          (CAN_VIEW_URL_MAP[permission].includes(match.path) && value),
      );
    };

    render() {
      const { user, ...restProps } = this.props; // eslint-disable-line no-unused-vars
      return <WrappedComponent {...restProps} />;
    }
  }

  CheckMaintenancePlan.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setOpenModalId: PropTypes.func.isRequired,
  };

  return withRouter(
    connect(null, { setOpenModalId: modalActions.setOpenModalId })(
      userAware(CheckMaintenancePlan),
    ),
  );
};

export default requireMaintenancePlan;
