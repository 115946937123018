import Constant from 'utils/constants';
import { questionIds } from 'config/config';

export const isOpenEnded = ({ category }) =>
  category === Constant.question.category.openEnded;
export const isSingleChoice = ({ category }) =>
  category === Constant.question.category.singleChoice;
export const isMultipleChoice = ({ category }) =>
  category === Constant.question.category.multipleChoice;
export const isMatrix = ({ category }) =>
  category === Constant.question.category.matrix;
export const isDatetime = ({ category }) =>
  category === Constant.question.category.datetime;

export const isSingle = ({ subcategory }) =>
  subcategory === Constant.question.subcategory.single;
export const isMulti = ({ subcategory }) =>
  subcategory === Constant.question.subcategory.multi;
export const isRating = ({ subcategory }) =>
  subcategory === Constant.question.subcategory.rating;
export const isRanking = ({ subcategory }) =>
  subcategory === Constant.question.subcategory.ranking;
export const isVerticalTwoCol = ({ subcategory }) =>
  subcategory === Constant.question.subcategory.verticalTwoCol;

export const isFileUpload = (question) =>
  isOpenEnded(question) &&
  isSingle(question) &&
  question.display_options?.display_type === 'file_upload'; // eslint-disable-line camelcase

export const isSlider = (question) =>
  isOpenEnded(question) &&
  isSingle(question) &&
  question.display_options?.display_type === 'slider'; // eslint-disable-line camelcase

export const hasTextAnswer = ({ text_answer: textAnswer }) => textAnswer;

/**
 * Harcoded checks
 */

export const isSelectASR = ({ id }) => id === questionIds.checkin.selectASR;
export const isGender = ({ id }) =>
  id === questionIds.healthQuestionnaire.gender;
