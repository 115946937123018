import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import theme from './TruncatedText.sss';

function TruncatedText({ text, truncateSize }) {
  const [isNoteExpanded, setIsNoteExpanded] = React.useState(false);
  return (
    <div className={theme.TruncatedText}>
      <div
        dangerouslySetInnerHTML={{
          __html: isNoteExpanded ? text : text.slice(0, truncateSize),
        }}
      />
      {!isNoteExpanded && text.length > truncateSize && (
        <Button
          classes={[theme.ExpandTextButton]}
          color="sensitive"
          size="small"
          onClick={() => {
            setIsNoteExpanded(true);
          }}
        >
          ...
        </Button>
      )}
    </div>
  );
}

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  truncateSize: PropTypes.number.isRequired,
};

TruncatedText.defaultProps = {
  truncateSize: 500,
};

export default TruncatedText;
