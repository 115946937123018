import axios from 'config/axios';
import { url } from 'helpers/user';

export default class ThreadService {
  static async create(participants) {
    const { data } = await axios.post('messages/threads/', {
      participants: participants.map(url),
    });
    return data;
  }

  static async getByParticipants(participants) {
    const { data } = await axios.get('messages/threads/', {
      params: {
        participants: participants.map(({ id }) => id),
      },
    });
    return data.results;
  }

  static async getByOnlyParticipants(participants) {
    const { data } = await axios.get('messages/threads/', {
      params: {
        only_participants: participants.map(({ id }) => id),
      },
    });
    return data.results[0];
  }
}
