import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';

import {
  clientDashboardTour,
  coachDashboardTour,
  managerDashboardTour,
} from 'config/tour';
import { isClient, isCoach, isPracticeManager } from 'helpers/user';
import { userAware } from 'components/UserProvider';
import { useBranch } from 'components/BranchProvider';
import { isMobile } from 'helpers/browser';

const CLIENT_STEPS = [
  clientDashboardTour.inbox.id,
  clientDashboardTour.documents.id,
  clientDashboardTour.tasks.id,
  clientDashboardTour.regimens.id,
  clientDashboardTour.checkins.id,
];
const MOBILE_CLIENT_STEPS = [
  clientDashboardTour.inbox.id,
  clientDashboardTour.tasks.id,
  clientDashboardTour.regimens.id,
  clientDashboardTour.checkins.id,
];
const COACH_STEPS = [];
const MANAGER_STEPS = [];

const AppTour = ({ user }) => {
  const [branch] = useBranch();

  const excludedSteps = [];
  if (!branch.messaging_enabled) {
    excludedSteps.push(clientDashboardTour.inbox.id);
  }

  const clientSteps = CLIENT_STEPS.filter(
    (step) => !excludedSteps.includes(step),
  );
  const mobileClientSteps = MOBILE_CLIENT_STEPS.filter(
    (step) => !excludedSteps.includes(step),
  );

  const getAppTourSteps = () => {
    if (isClient(user)) {
      if (isMobile()) {
        return Object.values(clientDashboardTour)
          .filter(({ id }) => mobileClientSteps.includes(id))
          .map(({ joyrideStepProps }) => joyrideStepProps({ user }));
      }
      return Object.values(clientDashboardTour)
        .filter(({ id }) => clientSteps.includes(id))
        .map(({ joyrideStepProps }) => joyrideStepProps({ user }));
    }
    if (isCoach(user)) {
      return Object.values(coachDashboardTour)
        .filter(({ id }) => COACH_STEPS.includes(id))
        .map(({ joyrideStepProps }) => joyrideStepProps({ user }));
    }
    if (isPracticeManager(user)) {
      return Object.values(managerDashboardTour)
        .filter(({ id }) => MANAGER_STEPS.includes(id))
        .map(({ joyrideStepProps }) => joyrideStepProps({ user }));
    }
    return [];
  };
  return (
    <Joyride
      steps={getAppTourSteps()}
      continuous
      showSkipButton
      disableCloseOnEsc
      disableOverlayClose
      locale={{
        skip: 'End tutorial',
        last: 'Done',
      }}
    />
  );
};

AppTour.propTypes = {
  user: PropTypes.object.isRequired,
};

export default userAware(AppTour);
