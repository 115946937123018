import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import uniqBy from 'lodash/uniqBy';

import { mapQueryParamsToProps } from 'helpers/redux';
import Constant from 'utils/constants';
import Loader from 'components/Loader';
import Button from 'components/Button';
import { useBranch } from 'components/BranchProvider';
import * as modalActions from 'components/Modal/Modal.ducks';

import PrepareRegimen from './PrepareRegimen';
import CustomizeOrder from './CustomizeOrder';
import ConfirmRegimenModal from './ConfirmRegimen';
import ConfirmOrderModal from './ConfirmOrder';
import * as actions from './CreateRegimen.ducks';
import theme from './CreateRegimen.sss';

const STEPS = {
  PREPARE_REGIMEN: 0,
  CUSTOMIZE_ORDER: 1,
};

const CONFIRM_ORDER_MODAL_ID = 'CreateRegimen/ConfirmOrder';

function CreateRegimenPage({
  history,
  match,
  profile,
  editingPublishedStage,
  initialize,
  loading,
  activeStep,
  updateStep,
  setActiveStep,
  productCategories,
  sendRegimen,
  setOpenModalId,
  program,
  stage,
  clientOrderedProducts,
  updateOrderProduct,
  regimens,
  steps,
  editingProgramWeek,
  changeProgramWeek,
  updateStage,
  saveAsDraft,
}) {
  const [branch] = useBranch();
  const duration = branch.program_config.duration;
  const { clientId, programId } = match.params;

  React.useEffect(() => {
    if (!clientId || !programId) {
      history.push('/');
      return;
    }
  }, [clientId, programId, history]);

  React.useEffect(() => {
    initialize({
      programId,
      clientId,
      editingPublishedStage,
      duration,
    });
  }, [programId, clientId, editingPublishedStage, duration, initialize]);

  function getActiveComponent() {
    switch (activeStep) {
      case STEPS.PREPARE_REGIMEN: {
        return (
          <PrepareRegimen
            stage={stage}
            profile={profile}
            program={program}
            clientOrderedProducts={clientOrderedProducts}
            regimens={regimens}
            steps={steps}
            editingProgramWeek={editingProgramWeek}
            editingPublishedStage={editingPublishedStage}
            onChangeProgramWeek={changeProgramWeek}
            onChangePersonalMessage={(message) => {
              updateStage({
                stageBody: {
                  text: message,
                },
              });
            }}
            onChangeStep={updateStep}
          />
        );
      }
      case STEPS.CUSTOMIZE_ORDER: {
        return (
          <CustomizeOrder
            productCategories={
              program.plan
                ? productCategories.filter(
                    ({ name }) =>
                      name.toLowerCase() !==
                      Constant.category.enhanceYourProgram.toLowerCase(),
                  )
                : productCategories
            }
            orderItemLimit={program.plan?.order_item_limit} // eslint-disable-line camelcase
            onUpdateProductQuantity={updateOrderProduct}
          />
        );
      }
      default:
        return null;
    }
  }

  function getFooterButtons() {
    switch (activeStep) {
      case STEPS.PREPARE_REGIMEN: {
        return (
          <>
            <Button
              size="big"
              onClick={() => {
                setOpenModalId(CONFIRM_ORDER_MODAL_ID);
              }}
            >
              Send Regimen
            </Button>
            {branch.coach_order_products_enabled && (
              <Button
                size="big"
                color="secondary"
                onClick={() => {
                  setActiveStep({ activeStep: STEPS.CUSTOMIZE_ORDER });
                }}
              >
                Customize Order
              </Button>
            )}
            {!editingPublishedStage && (
              <Button
                size="big"
                color="secondary"
                onClick={() => {
                  saveAsDraft({
                    clientId: match.params.clientId,
                  });
                }}
              >
                Save as Draft
              </Button>
            )}
          </>
        );
      }
      case STEPS.CUSTOMIZE_ORDER:
        return (
          <>
            <Button
              size="big"
              color="secondary"
              onClick={() => {
                setActiveStep({ activeStep: STEPS.PREPARE_REGIMEN });
              }}
            >
              Go Back
            </Button>
            <Button
              size="big"
              onClick={() => {
                setOpenModalId(CONFIRM_ORDER_MODAL_ID);
              }}
            >
              Finish Regimen
            </Button>
          </>
        );
      default:
        return null;
    }
  }

  function checkOrderItemLimit(orderedProducts) {
    const validateMaxItem = (product) =>
      // eslint-disable-next-line camelcase
      !program.plan?.order_item_limit ||
      product.quantity <= program.plan.order_item_limit;
    return orderedProducts.every(validateMaxItem);
  }

  if (loading) {
    return <Loader type="fixed" loading />;
  }
  const orderedProducts = uniqBy(
    productCategories
      .flatMap(({ products }) => products)
      .filter(({ quantity }) => !!quantity),
    'id',
  );
  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Create Regimen</StepLabel>
        </Step>
        <Step>
          <StepLabel>Customize Order (optional)</StepLabel>
        </Step>
      </Stepper>
      <div className="activeStep">{getActiveComponent()}</div>
      {orderedProducts.length > 0 ? (
        <ConfirmOrderModal
          sendEnable={checkOrderItemLimit(orderedProducts)}
          modalId={CONFIRM_ORDER_MODAL_ID}
          products={orderedProducts}
          onSendRegimenClicked={(products) => {
            sendRegimen({ clientId, editingPublishedStage, products });
            setOpenModalId(null);
          }}
        />
      ) : (
        <ConfirmRegimenModal
          modalId={CONFIRM_ORDER_MODAL_ID}
          onSaveRegimenClicked={() => {
            sendRegimen({
              clientId,
              editingPublishedStage,
              products: orderedProducts,
            });
            setOpenModalId(null);
          }}
        />
      )}
      <footer className={theme.RegimenFooter}>{getFooterButtons()}</footer>
    </div>
  );
}

CreateRegimenPage.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  stage: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
  clientOrderedProducts: PropTypes.array.isRequired,
  regimens: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  productCategories: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  editingProgramWeek: PropTypes.bool.isRequired,
  editingPublishedStage: PropTypes.bool.isRequired,
  match: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  changeProgramWeek: PropTypes.func.isRequired,
  updateStage: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
  updateOrderProduct: PropTypes.func.isRequired,
  saveAsDraft: PropTypes.func.isRequired,
  sendRegimen: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

CreateRegimenPage.defaultProps = {
  editingPublishedStage: false,
};

const mapStateToProps = ({ createRegimen }, { history }) => ({
  ...createRegimen,
  ...mapQueryParamsToProps(history),
});

export default withRouter(
  connect(mapStateToProps, {
    setOpenModalId: modalActions.setOpenModalId,
    ...actions,
  })(CreateRegimenPage),
);
