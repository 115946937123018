import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Loader from 'components/Loader';

import theme from './Confirmation.sss';

const Confirmation = ({ plan, submitting, onCancel, onConfirm }) => (
  <div className={theme.Confirmation}>
    <h2 className={theme.Title}>Confirm your selections</h2>
    <h2 className={theme.Description}>
      Before going further, make sure all your selections are correct
    </h2>
    <div className={theme.Selection}>
      <p>
        Chosen Plan: <b>{plan.name}</b>
      </p>
    </div>
    <div className={theme.Actions}>
      {submitting && <Loader type="absolute" />}
      <Button
        disabled={submitting}
        size="big"
        color="secondary"
        onClick={onCancel}
      >
        Previous
      </Button>
      <Button disabled={submitting} size="big" onClick={onConfirm}>
        Confirm
      </Button>
    </div>
  </div>
);

Confirmation.propTypes = {
  plan: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

Confirmation.defaultProps = {
  submitting: false,
};

export default Confirmation;
