import axios from 'config/axios';

export default class QuitReasonService {
  static async get(params = {}) {
    const { data } = await axios.get(`programs/acne/quit-reasons/`, {
      params,
    });
    return data.results;
  }
}
