import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import startCase from 'lodash/startCase';

import axios from 'config/axios';
import { googleDocuments } from 'config/config';

import Loader from 'components/Loader';
import List from 'components/List';
import ListFilters from 'components/ListFilters';
import ListHeader from 'components/ListHeader';

import useDocumentsReducer, {
  fetchDocumentsStarted,
  fetchDocumentsSuccess,
  fetchDocumentsFailure,
  setFilter,
} from './Documents.ducks';

const COLUMNS = [
  {
    label: 'Name',
    value: (document) => document.name,
  },
  {
    label: 'Type',
    value: (document) => startCase(document.type),
  },
];

const Documents = ({ history }) => {
  const [state, dispatch] = useDocumentsReducer();

  const fetchDocuments = async () => {
    dispatch(fetchDocumentsStarted());
    try {
      const { data: response } = await axios.get('users/coaches/assets/');
      const documents = [
        ...googleDocuments,
        ...response.results.map((asset) => ({
          ...asset,
          name: asset.name ?? asset.title,
        })),
      ];
      dispatch(
        fetchDocumentsSuccess({
          documents,
        }),
      );
    } catch (error) {
      dispatch(fetchDocumentsFailure(error));
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const getActiveFilter = () =>
    state.filters.find((filter) => filter.id === state.activeFilterId);

  const renderListContent = () => {
    if (state.loading) {
      return <Loader />;
    }
    const activeFilter = getActiveFilter();
    let documents = state.documents;
    if (activeFilter.title !== 'All Documents') {
      documents = documents.filter(
        (document) =>
          activeFilter.title.toLowerCase().indexOf(document.type) > -1,
      );
    }

    return (
      <List
        columns={COLUMNS}
        items={documents}
        onItemClick={(document) => {
          if (document.upload) {
            window.open(document.upload);
          } else if (document.type.toLowerCase() === 'survey') {
            history.push(`/survey-overview/${document.id}`);
          } else {
            window.open(document.url);
          }
        }}
      />
    );
  };

  return (
    <div className="list-page-container document-list-container">
      <h1>Your Documents</h1>
      <div className="list-page-columns">
        <div className="list-page-sidebar">
          <ListFilters
            setActiveFilter={(filter) => {
              dispatch(setFilter(filter));
            }}
            activeFilterId={state.activeFilterId}
            filters={state.filters}
          />
        </div>
        <div className="list-page-content">
          <ListHeader
            title="All Documents"
            activeFilter={getActiveFilter()}
            currentQuery=""
          />
          {renderListContent()}
        </div>
      </div>
    </div>
  );
};

Documents.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Documents);
