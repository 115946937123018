import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import Button from 'components/Button';
import Wysiwyg from 'components/Wysiwyg';

import theme from './OtherReasonsForm.sss';

const OtherReasonsForm = ({ defaultValues, onSubmit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
  });
  return (
    <form>
      <h4 className={theme.Title}>
        Are there any other reasons for not joining the program?
      </h4>
      <Controller
        as={Wysiwyg}
        name="otherReasons"
        size="small"
        control={control}
      />
      <footer className={theme.Actions}>
        <div className={theme.MainActions}>
          <Button
            classes={[theme.Action]}
            size="big"
            color="secondary"
            onClick={handleSubmit((values) => {
              onSubmit({
                ...values,
                submitAction: 'previous',
              });
            })}
          >
            Previous
          </Button>
          <Button
            classes={[theme.Action]}
            size="big"
            onClick={handleSubmit((values) => {
              onSubmit({
                ...values,
                submitAction: 'submit',
              });
            })}
          >
            Submit
          </Button>
        </div>
      </footer>
    </form>
  );
};

OtherReasonsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default OtherReasonsForm;
