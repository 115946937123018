import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  FILTER_TYPES,
  getCoachInThread,
  getClientInThread,
} from 'helpers/thread';
import { usePaginator } from 'hooks';
import ThreadList from 'components/ThreadList';
import Loader from 'components/Loader';
import Avatar from 'components/Avatar';

import * as actions from './CoachesInboxWidget.ducks';
import theme from './CoachesInboxWidget.sss';
import { MessageFilter } from './components';

const FILTER_PARAMS_MAPPING = {
  [FILTER_TYPES.unread]: { filter: 'unread' },
  [FILTER_TYPES.all]: undefined,
};

function CoachesInboxWidget({ show, fetchUnreadCount, onThreadClick }) {
  const [scrollRef, loaderRef, paginatorState, { setParams, reset }] =
    usePaginator({
      debugId: 'CoachesInboxWidget',
      endpoint: 'users/coaches/inboxes/',
      params: FILTER_PARAMS_MAPPING[FILTER_TYPES.unread],
    });

  useEffect(() => {
    fetchUnreadCount();
  }, [fetchUnreadCount]);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [reset, show]);

  function renderThreads() {
    if (paginatorState.isLoading && paginatorState.currentPage === 0) {
      return <Loader />;
    }
    const threads = paginatorState.data;
    return (
      <div ref={scrollRef} className={theme.ThreadList}>
        <ThreadList
          threads={threads.map((thread) => {
            const recipient = getCoachInThread(thread);
            const client = getClientInThread(thread);
            const { id, messages } = thread;
            const latestMessage = messages[0];
            return {
              id,
              title: (
                <>
                  <div className={theme.Avatar}>
                    <Avatar user={recipient} />
                  </div>
                  <h1 className={theme.Recipient}>
                    {`Coach ${recipient.profile.full_name}`}
                  </h1>
                </>
              ),
              body: (
                <>
                  <span className={theme.Client}>
                    {client.profile.full_name}
                  </span>
                  <p
                    className={theme.Body}
                    dangerouslySetInnerHTML={{ __html: latestMessage.body }}
                  />
                </>
              ),
              date: latestMessage.created,
            };
          })}
          onThreadClick={onThreadClick}
          emptyMessage="Your coaches have no unread messages! 👏👏"
        />
        <div ref={loaderRef} className={theme.LoadingNextThreads}>
          Loading...
        </div>
      </div>
    );
  }

  if (!show) {
    return null;
  }

  return (
    <>
      <MessageFilter
        disabled={paginatorState.isLoading}
        onChange={(filter) => {
          setParams(FILTER_PARAMS_MAPPING[filter]);
        }}
        defaultValue={FILTER_TYPES.unread}
      />
      {renderThreads()}
    </>
  );
}

CoachesInboxWidget.displayName = 'CoachesInboxWidget';

CoachesInboxWidget.propTypes = {
  show: PropTypes.bool,
  fetchUnreadCount: PropTypes.func.isRequired,
  onThreadClick: PropTypes.func.isRequired,
};

CoachesInboxWidget.defaultProps = {
  show: true,
};

const mapStateToProps = ({ coachesInbox }) => ({
  ...coachesInbox,
});

export default connect(mapStateToProps, {
  ...actions,
})(CoachesInboxWidget);
