/* eslint-disable max-len */

import React from 'react';

const DropIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M12,0.143c0,0-8,8.486-8,13.857c0,4.457,3.543,8,8,8s8-3.543,8-8C20,8.629,12,0.143,12,0.143z" />
  </svg>
);

DropIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default DropIcon;
