import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import axios from 'config/axios';
import { followupQuestions } from 'config/surveys';
import { checkinFemaleQuestions } from 'config/config';
import { isCoach, isPracticeManager } from 'helpers/user';
import Button from 'components/Button';
import { userAware } from 'components/UserProvider';
import Loader from 'components/Loader';

import Constant from 'utils/constants';
import HQPhotosWidget from 'containers/PhotosWidget/HQPhotosWidget';
import ExistingCheckin from '../ExistingCheckin/ExistingCheckin';
import SurveyResultsNav from './SurveyResultsNav';
import ResultsSection from './ResultsSection';
import theme from './SurveyResults.scss';

class SurveyResults extends Component {
  state = {
    loading: true,
    enhancedSurvey: null,
    sticky: false,
    programStageWeeks: false,
    existingCheckins: [],
    activeExistingCheckin: false,
  };

  async componentDidMount() {
    const { survey } = this.props;

    this.enhanceSurvey(survey);
    this.headerRef = React.createRef();
    this.existingCheckinRef = React.createRef();

    window.addEventListener('scroll', this.stickyNav);

    if (survey.title.toLowerCase() === 'checkin') {
      await this.getCheckinData();
    }

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.stickyNav);
  }

  getFollowups = async (question) => {
    const possibleFollowups = followupQuestions[question.id];
    const followups = question.answers.map((a) => {
      return possibleFollowups[a.choice.id];
    });
    if (followups.some((fu) => !!fu)) {
      const promises = followups.flatMap((followup) => {
        return followup.map(async (id) => {
          const res = await axios.get(`surveys/questions/${id}`);
          return await res.data;
        });
      });
      const enhancedQ = await Promise.all(promises).then((results) => {
        const qfus = results.map((qfu) => this.enhanceQuestion(qfu));
        return {
          ...question,
          followups: qfus,
        };
      });
      return enhancedQ;
    }
    return question;
  };

  getUserProgram = async () => {
    const { data: program } = await axios.get(
      `programs/acne/?user=${this.props.clientId}`,
    );
    return program;
  };

  getStagesByProgram = async (programId) => {
    const { data: stages } = await axios.get(
      `programs/acne/${programId}/stages/`,
    );
    return stages;
  };

  getCheckinData = async () => {
    const { user } = this.props;
    const userProgram = await this.getUserProgram();

    if (userProgram && userProgram.results && userProgram.results.length) {
      const program = userProgram.results[0];
      const stages = await this.getStagesByProgram(program.id);
      const thisResponseId = this.props.match.params.responseId;

      // set current checkin weeks
      const checkinStage = stages.find(
        (stage) => stage.check_in === parseInt(thisResponseId, 10),
      );

      const programStageWeeks = `${
        checkinStage?.program_view_index * 2 + 3 // eslint-disable-line max-len, camelcase
      } - ${checkinStage?.program_view_index * 2 + 4}`; // eslint-disable-line max-len, camelcase
      this.setState({
        programStageWeeks,
      });

      // dipslay existing checkins if coach or practice manager are viewing
      if (isCoach(user) || isPracticeManager(user)) {
        const existingCheckins = stages.filter(
          (stage) =>
            !!stage.check_in && stage.check_in !== parseInt(thisResponseId, 10),
        );
        this.setState({ existingCheckins, checkinStage });
      }
    }
  };

  setActiveExistingCheckin = (checkin) => {
    this.setState({
      activeExistingCheckin: checkin,
    });
  };

  defineRef = (refName) => {
    if (!this[refName]) {
      this[refName] = React.createRef();
    }
    return this[refName];
  };

  enhanceSurvey = (survey) => {
    const enhancedSurvey = {
      ...survey,
      pages: survey.pages.map((page) => this.enhancePage(page)),
    };
    this.setState({
      enhancedSurvey,
    });
  };

  enhancePage = (page) => {
    const mapAnswersToQuestion = (question) => {
      const enhancedQuestion = this.enhanceQuestion(question);
      // check if this question may have followup question
      if (
        enhancedQuestion.answers.length &&
        followupQuestions[enhancedQuestion.id]
      ) {
        // if so, fetch it and enhance the question with followup property
        Promise.resolve(this.getFollowups(enhancedQuestion)).then((enQ) => {
          this.setState((prevState) => ({
            enhancedSurvey: {
              ...prevState.enhancedSurvey,
              pages: prevState.enhancedSurvey.pages.map((pg) => {
                if (page.id !== pg.id) {
                  return pg;
                }
                return {
                  ...pg,
                  questions: pg.questions.map((q) => {
                    if (q.id !== enQ.id) {
                      return q;
                    }
                    return enQ;
                  }),
                };
              }),
            },
          }));
        });
      }
      return enhancedQuestion;
    };

    if (page.id === 1) {
      if (
        this.props.answers.some(
          (answer) => answer.question.id === 2 && answer.choice.id === 1,
        )
      ) {
        this.setState({ gender: 'male' });
      } else {
        this.setState({ gender: 'female' });
      }
    } else if (page.id === 13) {
      if (
        this.props.answers.some((answer) =>
          checkinFemaleQuestions.includes(answer.question.id),
        )
      ) {
        this.setState({ gender: 'female' });
      } else {
        this.setState({ gender: 'male' });
      }
    }

    return {
      ...page,
      questions: page.questions.map((question) =>
        mapAnswersToQuestion(question),
      ),
    };
  };

  enhanceQuestion = (question) => {
    const { answers } = this.props;
    return {
      ...question,
      answers: answers.filter((ans) => ans.question.id === question.id),
    };
  };

  scrollToRef = (refName) => {
    window.scrollTo({
      top: this[refName].current.offsetTop,
      behavior: 'smooth',
    });
  };

  stickyNav = () => {
    const current = this.headerRef && this.headerRef.current;
    if (
      (current && current.clientHeight <= window.scrollY) !== this.state.sticky
    ) {
      this.setState({
        sticky: current && current.clientHeight <= window.scrollY,
      });
    }
  };

  renderHQPhotos = () => {
    const { survey, clientId } = this.props;
    if (survey.title === Constant.survey.type.checkin) {
      return <HQPhotosWidget clientId={Number(clientId)} />;
    }
    return null;
  };

  render() {
    const { user, clientName, clientId, createRegimenUrl } = this.props;
    const {
      loading,
      enhancedSurvey,
      gender,
      sticky,
      existingCheckins,
      checkinStage,
      programStageWeeks,
      activeExistingCheckin,
    } = this.state;
    const survey = enhancedSurvey;
    if (!survey) {
      return null;
    }

    if (loading) {
      return <Loader />;
    }

    const surveyType = survey.title.toLowerCase();

    return (
      <div
        className={[
          theme.root,
          theme[surveyType],
          survey.pages.length > 1 ? theme.grid : '',
        ].join(' ')}
      >
        <header ref={this.headerRef}>
          <h1>
            {isCoach(user) || isPracticeManager(user) ? (
              <Link to={`/client-details/${clientId}`}>
                <span
                  className={[theme.clientName, theme.clientLink].join(' ')}
                >
                  {`${clientName}'s`}{' '}
                </span>
              </Link>
            ) : (
              <span className={theme.clientName}>{`${clientName}'s`} </span>
            )}
            {programStageWeeks
              ? `Week ${programStageWeeks} ${survey.title}`
              : survey.title}
          </h1>
          {createRegimenUrl && (
            <div className={theme.SurveyActions}>
              <Link to={createRegimenUrl}>
                <Button size="big">Create Next Regimen</Button>
              </Link>
            </div>
          )}
        </header>
        <div className={theme.surveyResults}>
          <SurveyResultsNav
            sticky={sticky}
            scrollToRef={this.scrollToRef}
            theme={theme}
            pages={survey.pages}
          />
          <div className={theme.resultPages}>
            {this.renderHQPhotos()}
            {survey.pages.map((page, i) => (
              <ResultsSection
                gender={gender}
                sectionRef={this.defineRef(page.title)}
                key={i}
                page={page}
                survey={this.props.survey}
                existingCheckins={existingCheckins}
                checkinStage={checkinStage}
                clientId={clientId}
              />
            ))}
          </div>
          {existingCheckins.length > 0 && (
            <div
              className={theme.existingCheckinsNav}
              ref={this.existingCheckinRef}
            >
              <h3>Other Checkins</h3>
              <ul>
                {existingCheckins.map((checkin, i) => {
                  // eslint-disable-next-line max-len
                  const weekOfProgramStage = `${
                    checkin.program_view_index * 2 + 3
                  } - ${checkin.program_view_index * 2 + 4}`;
                  const completed = moment(checkin.completed).format(
                    'MM/DD/YYYY',
                  );
                  return (
                    <li key={i}>
                      <button
                        className={
                          activeExistingCheckin === checkin.check_in
                            ? theme.active
                            : ''
                        }
                        onClick={() =>
                          this.setActiveExistingCheckin(checkin.check_in)
                        }
                      >
                        <h4>{`Week ${weekOfProgramStage} checkin`}</h4>
                        <p>{completed}</p>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {activeExistingCheckin && (
            <div>
              <ExistingCheckin
                {...this.props}
                checkin={activeExistingCheckin}
                scrollToRef={this.scrollToRef}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

SurveyResults.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  answers: PropTypes.array,
  clientName: PropTypes.string,
  survey: PropTypes.object,
  clientId: PropTypes.string,
  createRegimenUrl: PropTypes.string,
};

const mapStateToProps = ({ survey }) => ({
  questionsFlat: survey.surveyQuestions,
});

export default withRouter(connect(mapStateToProps)(userAware(SurveyResults)));
