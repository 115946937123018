/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

const LoaderIcon = ({ fill, ...others }) => (
  <svg viewBox="0 0 100 100" {...others} >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke={fill}
      strokeWidth="10"
      r="30"
      strokeDasharray="141.37166941154067 49.12388980384689"
      transform="rotate(54 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

LoaderIcon.propTypes = {
  fill: PropTypes.string,
};

LoaderIcon.defaultProps = {
  height: 44,
  width: 44,
  fill: 'black',
};

export default LoaderIcon;
