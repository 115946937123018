import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class SurveysOverviewPage extends Component {
  state = {
    surveys: [
      { id: 1, title: 'Health Questionnaire' },
    ],
  };

  render() {
    return (
      <div>
        {this.state.surveys.map((survey) => (
          <NavLink to={`/surveys/${survey.id}`} key={survey.id}>
            {survey.title}
          </NavLink>
        ))}
      </div>
    );
  }
}

export default SurveysOverviewPage;
