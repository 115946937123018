import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './TextInput.sss';

const TextInput = forwardRef(
  ({ className, error, register, icon, ...others }, ref) => (
    <div className={theme.TextInput}>
      {icon && icon}
      <input
        className={classNames(
          {
            [theme.InputError]: error,
            [theme.IconDivider]: !!icon,
          },
          className,
        )}
        type="text"
        {...others}
        ref={ref || register}
      />
      {error && <span className={theme.ErrorText}>{error}</span>}
    </div>
  ),
);

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.func,
  icon: PropTypes.node,
};

export default TextInput;
