/* istanbul ignore file */

export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[a-z]).{8,}$/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const phoneValidator = (phone) => {
  const number = phone.replace(/[^0-9]+/g, '');
  if (number.length < 10 && number.length > 0) {
    return 'Valid phone number required';
  }
  return null;
};

export const optionalValidator = () => {
  return 'optional';
};

export const required = (value) => {
  return value === '' ? ' is required' : null;
};

export const dateTimeValidator = (date) => {
  if (!date) {return date;}
  // tests that a date is formated as valid MM/DD/YYYY string
  // valid month regex
  const m = new RegExp(/(0[1-9]|1[0-2])/);
  // valid days regex
  const d = new RegExp(/([0-2][0-9]|3[0-2])/);
  // valid year regex
  const y = new RegExp(/(1|2)[0-9]{3}/);

  const [month, days, year] = date.split('/');
  return m.test(month) && d.test(days) && y.test(year);
};

export function dtErrors(question) {
  if (question.answers.length) {
    // something has been input
    const errors = question.answers.map((ans) => {
      if (!question.is_required && ans.text === '') {
        // multiple rows, optional question, no answer given for option rowal, is valid.
        return null;
      }
      if (question.is_required && ans.text === '') {
        return { [question.id]: 'This question is required.' };
      }
      if (!dateTimeValidator(ans.text)) {
        return { [question.id]: 'Date format should be MM/DD/YYYY' };
      }
      return null;
    });
    return errors.length ? errors[0] : null;
  }
  return question.is_required ? { [question.id]: 'This question is required.' } : null;

}

export function mcErrors(question) {
  if (question.is_required) {
    return question.answers.length && question.answers.every(
      (a) => a.question && (a.choice || (a.category === 'text_answer' && a.text))
    )
      ? null
      : { [question.id]: 'This question is required' };
  }
  return null;
}

export function oeErrors(question) {
  if (question.is_required) {
    return question.answers.length && question.answers.every((a) => a.text !== '')
      ? null
      : { [question.id]: 'This question is required' };
  }
  return null;
}

// make sure all photos have been uploaded
export function checkinPhotoUploadErrors(question) {
  if (question.is_required) {
    let missingImagesErrors;
    const title = question.text.toLowerCase();

    switch (true) {
      case title.indexOf('of your face') > -1:
        missingImagesErrors = {
          left: 'This image is required',
          front: 'This image is required',
          right: 'This image is required',
        };
        break;

      case title.indexOf('of your chest') > -1:
        missingImagesErrors = {
          chest: 'This image is required',
        };
        break;

      case title.indexOf('of your back') > -1:
        missingImagesErrors = {
          back: 'This image is required',
        };
        break;

      default:
        break;
    }

    if (!question.answers || !question.answers.length) {
      return { [question.id]: missingImagesErrors };
    }

    const answer = question.answers[0];

    if (!('images' in answer) && !answer.image_group_answer) {
      return { [question.id]: missingImagesErrors };
    }

    if ('images' in answer) {
      const imageKeys = Object.keys(answer.images);
      for (let k = 0; k < imageKeys.length; k++) {
        const image = answer.images[imageKeys[k]];
        // eslint-disable-next-line max-depth
        if (image && image !== '') {
          delete missingImagesErrors[imageKeys[k]];
        }
      }

      if (Object.keys(missingImagesErrors).length > 0) {
        return { [question.id]: missingImagesErrors };
      }
    }
  }

  return null;
}

// make sure all photos have been uploaded
export function hqPhotoUploadErrors(question) {
  if (question.is_required) {
    let missingImagesErrors;
    const title = question.text.toLowerCase();

    switch (true) {
      case title.indexOf('of your face') > -1:
        missingImagesErrors = {
          left: 'This image is required',
          front: 'This image is required',
          right: 'This image is required',
        };
        break;

      default:
        break;
    }

    if (!question.answers || !question.answers.length) {
      return { [question.id]: missingImagesErrors };
    }

    const answer = question.answers[0];

    if (!('images' in answer) && !answer.image_group_answer) {
      return { [question.id]: missingImagesErrors };
    }

    if ('images' in answer) {
      const imageKeys = Object.keys(answer.images);
      for (let k = 0; k < imageKeys.length; k++) {
        const image = answer.images[imageKeys[k]];
        // eslint-disable-next-line max-depth
        if (image && image !== '') {
          delete missingImagesErrors[imageKeys[k]];
        }
      }

      if (Object.keys(missingImagesErrors).length > 0) {
        return { [question.id]: missingImagesErrors };
      }
    }
  }

  return null;
}

export function scErrors(question) {
  if (!question.is_required) {
    return null;
  }
  if (question.answers.length !== 1 || !question.answers[0].choice || !question.answers[0].question) {
    return {
      [question.id]: 'This question is required.',
    };
  }
  return null;
}

export function singleChoiceWithAnswerErrors(question) {
  if (!question.is_required) {
    return null;
  }

  const answersLength = question.answers.length;
  if (
    answersLength !== 1 ||
    (!question.answers[0].choice && !question.answers[0].text_answer) ||
    !question.answers[0].question
  ) {
    return {
      [question.id]: 'This question is required.',
    };
  }

  if (answersLength === 1  && question.text_answer.url === question.answers[0].choice) {
    if (!question.answers[0].text || question.answers[0].text === '') {
      return {
        [question.id]: 'This answer is required.',
      };
    }
  }

  return null;
}

export function matrixRatingErrors(question) {
  if (!question.is_required) {
    return null;
  }
  if (question.answers.length !== 1 || !question.answers[0].choice || !question.answers[0].question) {
    return {
      [question.id]: 'This question is required.',
    };
  }
  return null;
}
