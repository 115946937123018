import { useState } from 'react';
import { useInterval } from 'hooks';

function useCountdownTimer({ endTime }) {
  const calculateTimeLeft = () => {
    const difference = Number(new Date(endTime)) - Number(new Date());
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useInterval(() => {
    setTimeLeft(calculateTimeLeft());
  }, 1000);

  return timeLeft;
}

export default useCountdownTimer;
