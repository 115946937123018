import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Plan from './Plan';

import theme from './Plans.sss';

const Plans = forwardRef(({ plans, onPlanClick, isOldCC, altHeader }, ref) => {
  let title = (
    <>
      <h1 className={theme.Title}>
        CHOOSE YOUR{' '}
        <span className={theme.Green}>
          PERFECT CLEAR CONNECTION MAINTENANCE PLAN{' '}
        </span>
        FOR A LIFETIME OF CLEAR SKIN
        <br />
      </h1>
      <div className={theme.SubTitle}>
        FINALLY! A SOLUTION THAT CONTINUES TO WORK! <br />
        STAY ON TRACK WITH THE SUCCESS YOU ACHIEVED DURING YOUR ONLINE ACNE
        PROGRAM.
      </div>
      <h5 className={theme.Orange}>
        Just click the right plan for you and you’re on your way.
      </h5>
    </>
  );
  if (isOldCC) {
    title = altHeader ? (
      <>
        <h1 className={theme.Title}>
          CHOOSE YOUR PERFECT
          <br />
          <span className={theme.Green}>
            CLEAR CONNECTION MAINTENANCE PLAN
            <br />
          </span>
          FOR A LIFETIME OF CLEAR SKIN
          <br />
        </h1>
      </>
    ) : (
      <>
        <h1 className={theme.Title}>
          FINALLY! A SOLUTION THAT CONTINUES TO WORK! <br />
          STAY ON TRACK WITH THE SUCCESS YOU ACHIEVED DURING YOUR ONLINE ACNE
          PROGRAM.
        </h1>
        <h5 className={theme.Orange}>
          Just click the right plan for you and you’re on your way.
        </h5>
      </>
    );
  }

  return (
    <div className={theme.Plans} ref={ref}>
      {title}
      <ul>
        {plans.map((plan, key) => (
          <Plan key={key} {...plan} onClick={onPlanClick} />
        ))}
      </ul>
    </div>
  );
});

Plans.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      benefits: PropTypes.arrayOf(PropTypes.node),
    }),
  ),
  onPlanClick: PropTypes.func.isRequired,
  isOldCC: PropTypes.bool,
  altHeader: PropTypes.bool,
};

Plans.defaultProps = {
  plans: [],
};

export default Plans;
