import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { CloseIcon } from 'components/Icon';

import * as actions from 'components/Modal/Modal.ducks';

import theme from './Modal.sss';

const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const Modal = forwardRef(
  (
    {
      className,
      open,
      title,
      autoClose,
      children,
      size,
      showTopActions,
      setOpenModalId,
      onClose,
    },
    ref,
  ) => (
    <MUIModal
      className={classNames(className, theme.Modal, theme[size])}
      ref={ref}
      open={open}
      onClose={(ev) => {
        ev.preventDefault();
        if (autoClose) {
          setOpenModalId(null);
        }
        onClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={theme.Content}>
          <div className={theme.Top}>
            {title && <h1 className={theme.Title}>{title}</h1>}
            {showTopActions && (
              <div className={theme.Actions}>
                <CloseIcon
                  data-testid="closeIcon"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setOpenModalId(null);
                    onClose();
                  }}
                />
              </div>
            )}
          </div>
          <div className={theme.Body}>{React.Children.only(children)}</div>
        </div>
      </Fade>
    </MUIModal>
  ),
);

Modal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  autoClose: PropTypes.bool,
  showTopActions: PropTypes.bool,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  onClose: PropTypes.func,
  setOpenModalId: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  size: SIZES.medium,
  showTopActions: true,
  autoClose: false,
  onClose: () => undefined,
};

const mapStateToProps = ({ modal }, ownProps) => ({
  open: ownProps.open || ownProps.id === modal.openModalId,
});

export default connect(
  mapStateToProps,
  {
    ...actions,
  },
  undefined,
  { forwardRef: true },
)(Modal);
