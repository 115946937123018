import React from 'react';
import PropTypes from 'prop-types';

import theme from './ClientOrderedProducts.scss';

export const ClientOrderedProducts = ({
  firstName,
  lastName,
  products,
}) => (
  <div className={theme.clientOrderedProducts}>
    <p>
      <strong>
        {`${firstName} ${lastName} requested for the following products during his/her last check-in:`}
      </strong>
    </p>
    <ul>
      {products.map(({ name, quantity }, i) => (
        <li key={i}>
          {name} x{quantity}
        </li>
      ))}
    </ul>
  </div>
);

ClientOrderedProducts.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  products: PropTypes.array,
};

ClientOrderedProducts.defaultProps = {
  firstName: 'John',
  lastName: 'Doe',
  products: [],
};

export default ClientOrderedProducts;
