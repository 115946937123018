import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './Feedback.sss';

const FEEDBACK_TYPES = {
  error: 'error',
  info: 'info',
  success: 'success',
};

const Feedback = ({ dialogFeedback, message, type }) => (
  <div
    className={classNames(theme.root, theme[type], {
      [theme.dialogFeedback]: dialogFeedback,
    })}
  >
    {message}
  </div>
);

Feedback.propTypes = {
  dialogFeedback: PropTypes.bool,
  message: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES)),
};

Feedback.defaultProps = {
  message: '',
  type: FEEDBACK_TYPES.success,
};

export default Feedback;
