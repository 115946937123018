import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import history from 'global-history';
import localStorage from 'local-storage';

import ResetPasswordModal from 'components/ResetPasswordModal';
import { useBranch } from 'components/BranchProvider';
import { useGetBranches } from 'features/users/user.query';
import BranchService from 'services/branch.service';
import { initialize } from 'pages/App/App.ducks';
import * as modalActions from 'components/Modal/Modal.ducks';

import { LoginForm, HappyFoxChat, BranchSelectModal } from './components';
import { BRANCH_SELECT_MODAL_ID } from './components/BranchSelectModal';
import * as actions from './Login.ducks';
import theme from './Login.sss';

function LoginPage({
  showResetPasswordModal,
  submitting,
  error,
  login,
  toggleResetPasswordModal,
  resetPassword,
  userId,
}) {
  const dispatch = useDispatch();
  const [, setBranch] = useBranch();
  const [selectedBranch, setSelectedBranch] = React.useState(false);
  React.useEffect(() => {
    const bootstrapApp = (id) => {
      const branchId = Number(id);
      return BranchService.get(branchId).then(setBranch);
    };
    if (selectedBranch) {
      dispatch(initialize({ bootstrapApp }));
      history.replace('/');
    }
  }, [selectedBranch, setBranch, dispatch]);

  const { isSuccess, data: branches } = useGetBranches(userId, {
    enabled: !!userId,
  });

  React.useEffect(() => {
    if (isSuccess && branches.length === 1) {
      localStorage.set('branch', branches[0].id);
      setSelectedBranch(true);
    }
    if (isSuccess && branches.length > 1) {
      dispatch(modalActions.setOpenModalId(BRANCH_SELECT_MODAL_ID));
    }
  }, [isSuccess, branches, dispatch]);

  return (
    <div className={theme.Login}>
      <div className={theme.LeftPane} />
      <div className={theme.RightPane}>
        <div className={theme.RightPaneFill}>
          <img
            className={theme.LogoImage}
            src="https://cc-production-media.s3-us-west-2.amazonaws.com/static/cc_logo.png"
          />
          <BranchSelectModal
            userId={userId}
            onSubmit={(branch) => {
              localStorage.set('branch', branch);
              setSelectedBranch(true);
            }}
          />
          <LoginForm
            submitting={submitting}
            error={error}
            onSubmit={(values) => {
              // SNAC-190 - iOS Mobile Bugs
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              login({ ...values });
            }}
            onResetPasswordClicked={() => {
              toggleResetPasswordModal({ showResetPasswordModal: true });
            }}
          />
          <Link className={theme.Eula} target="_blank" to="/eula">
            End User License Agreement
          </Link>
          <HappyFoxChat />
        </div>
      </div>
      <ResetPasswordModal
        open={showResetPasswordModal}
        toggleModal={(open) => {
          toggleResetPasswordModal({ showResetPasswordModal: open });
        }}
        onSubmit={resetPassword}
      />
    </div>
  );
}

LoginPage.propTypes = {
  showResetPasswordModal: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  toggleResetPasswordModal: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

const mapStateToProps = ({ login, user }) => ({
  ...login,
  error:
    !login.error && user.jwt === 'expired_token'
      ? 'Your session has expired, please log in again.'
      : login.error,
});

export default connect(mapStateToProps, actions)(LoginPage);
