import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import theme from './Note.sss';

const Note = ({ note }) => (
  <li className={theme.Note}>
    <div className={theme.Header}>
      <div className={theme.Author}>{note.author.profile.full_name}</div>
      <span className={theme.Date}>
        {format(new Date(note.created), 'MMM dd, hh:mma')}
      </span>
    </div>
    <div className={theme.Body}>
      <div className={theme.Title}>{note.title}</div>
      <div dangerouslySetInnerHTML={{ __html: note.body }}></div>
    </div>
  </li>
);

Note.propTypes = {
  note: PropTypes.object.isRequired,
};

export default Note;
