import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import theme from './FormItem.sss';

const FormItem = ({
  name,
  fill,
  inlineLabel,
  inlineLabelClassName,
  reverseLabel,
  label,
  input,
  registerOptions,
  onChange,
  ...others
}) => {
  const { register, errors, control } = useFormContext();
  const inputError = errors[name];
  const inputContent = onChange ? (
    <Controller
      name={name}
      render={({ onChange: rhfOnChange, ref }) =>
        React.cloneElement(input, {
          ...input.props,
          ref,
          error: inputError?.message,
          // Custom Props for Uncontrolled
          onChange: (...args) => {
            const data = onChange(...args);
            rhfOnChange(data);
          },
        })
      }
      control={control}
      rules={registerOptions}
      {...others}
      defaultValue={others.defaultValue ?? null}
    />
  ) : (
    React.cloneElement(input, {
      ...input.props,
      name,
      ref: register(registerOptions),
      error: inputError?.message,
      ...others,
    })
  );
  return (
    <div
      className={classNames(
        inlineLabel
          ? classNames(inlineLabelClassName, theme.HorizontalFormItem)
          : theme.VerticalFormItem,
        {
          [theme.FormItemFill]: fill,
          [theme.ReverseFormItem]: reverseLabel,
        },
      )}
    >
      <div
        className={classNames(
          inlineLabel ? theme.InlineLabel : theme.VerticalLabel,
          {
            [theme.ReverseLabel]: reverseLabel,
          },
        )}
      >
        {registerOptions.required && (
          <span className={theme.RequiredMark}>*</span>
        )}
        {label}
      </div>
      {inputContent}
    </div>
  );
};

FormItem.propTypes = {
  // Common Props
  name: PropTypes.string.isRequired,
  fill: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  inlineLabelClassName: PropTypes.string,
  reverseLabel: PropTypes.bool,
  label: PropTypes.node,
  input: PropTypes.node.isRequired,
  registerOptions: PropTypes.object,
  // Uncontrolled Props
  onChange: PropTypes.func,
};

FormItem.defaultProps = {
  fill: true,
  reverseLabel: false,
  inlineLabel: false,
  registerOptions: {},
};

export default FormItem;
