import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Viewer from 'react-viewer';
import format from 'date-fns/format';

import { useLoggedInUser } from 'hooks';
import Loader from 'components/Loader/Loader';
import Constant from 'utils/constants';
import { isCoach, isPracticeManager } from 'helpers/user';
import { useGetClient } from 'features/users/user.query';
import { useGetHqResponseMerge } from 'features/surveys/response.query';
import {
  BloodIcon,
  DietIcon,
  MedicalIcon,
  MedicationIcon,
  PersonIcon,
  StomachIcon,
  HouseIcon,
  CameraIcon,
} from 'components/Icon';

import theme from './SurveyRemote.sss';

function SurveyRemotePage() {
  const params = useParams();
  const user = useLoggedInUser();
  const [viewerVisible, setViewerVisible] = React.useState();
  const [imageIndex, setImageIndex] = React.useState();
  const pageNavsRef = React.useRef(
    Constant.surveyPages.map(() => React.createRef()),
  );
  const { data: client, isLoading: isLoadingClient } = useGetClient(
    params.clientId,
  );
  const { data: response, isLoading: isLoadingResponse } =
    useGetHqResponseMerge({
      user: params.clientId,
    });

  const images = React.useMemo(() => {
    const photoPage = Constant.surveyPages[Constant.surveyPages.length - 1];
    const obj = [];
    if (response?.pages?.[photoPage].length > 0) {
      response.pages[photoPage]?.forEach((question) => {
        if (question.image) {
          obj.push({ src: question.image.upload });
        }
      });
    }
    return obj;
  }, [response]);

  if (isLoadingClient || isLoadingResponse) {
    return <Loader loading />;
  }

  function openViewer(index) {
    setViewerVisible(true);
    setImageIndex(index);
  }

  function renderAnswer(question, index) {
    const { text, multiple_text: multi, yes_no: yesNo, image } = question;
    if (text) {
      let content = text;
      if (question.tags.find((val) => val === 'Format Date')) {
        content = format(new Date(text), 'MM/dd/yyyy');
      }
      return <div className={theme.Answer}>{content}</div>;
    }
    if (multi) {
      return multi?.map((answer) => (
        <div className={theme.Answer}>{answer}</div>
      ));
    }
    if (image) {
      return (
        <img
          onClick={() => openViewer(index)}
          className={theme.PhotoImage}
          src={image.thumbnail}
        />
      );
    }
    if (yesNo === null || yesNo === undefined) {
      return null;
    }
    return yesNo ? (
      <div className={theme.Answer}>Yes</div>
    ) : (
      <div className={theme.Answer}>No</div>
    );
  }

  function renderIcon(index) {
    switch (index) {
      case 0:
        return <MedicalIcon className={theme.Icon} />;
      case 1:
        return <PersonIcon className={theme.Icon} />;
      case 2:
        return <MedicationIcon className={theme.Icon} />;
      case 3:
        return <BloodIcon className={theme.Icon} />;
      case 4:
        return <DietIcon className={theme.Icon} />;
      case 5:
        return <StomachIcon className={theme.Icon} />;
      case 6:
        return <HouseIcon className={theme.Icon} />;
      case 7:
        return <CameraIcon className={theme.Icon} />;
      default:
        return null;
    }
  }

  return (
    <div className={theme.SurveyRemote}>
      <header className={theme.SurveyRemoteHeader}>
        <h1>
          {isCoach(user) || isPracticeManager(user) ? (
            <Link to={`/client-details/${client.id}`}>
              <span className={[theme.clientName, theme.clientLink].join(' ')}>
                {`${client.profile.full_name}'s`}{' '}
              </span>
            </Link>
          ) : (
            <span className={theme.clientName}>
              {`${client.profile.full_name}'s`}{' '}
            </span>
          )}
          {response.title}
        </h1>
      </header>
      <div className={theme.SurveyResults}>
        <nav className={theme.SurveyResultsNav}>
          <ul className={theme.PageList}>
            {Constant.surveyPages.map((page, p) => (
              <li key={p}>
                <a
                  className={theme.PageLink}
                  onClick={() => {
                    window.scrollTo({
                      top: pageNavsRef.current[p].current.offsetTop,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <div className={theme.IconContainer}>{renderIcon(p)}</div>

                  <span>{page}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className={theme.ResultsPages}>
          {Constant.surveyPages.map((page, i) => (
            <div key={String(i) + 'page'} className={theme.PageSection}>
              <header>
                <h2 ref={pageNavsRef.current[i]} className={theme.PageTitle}>
                  {page}
                </h2>
              </header>
              {response.pages[page]?.map((question, j) => (
                <div
                  key={String(i) + String(j)}
                  className={theme.QuestionContainer}
                >
                  <div className={theme.Question}>{question.question}</div>
                  {renderAnswer(question, j)}
                </div>
              ))}
            </div>
          ))}
        </div>
        <Viewer
          visible={viewerVisible}
          onClose={() => {
            setViewerVisible(false);
          }}
          images={images}
          activeIndex={imageIndex}
        />
      </div>
    </div>
  );
}

export default SurveyRemotePage;
