import axios from 'config/axios';

export default class ProgramService {
  static async get(programId) {
    const { data } = await axios.get(`programs/acne/${programId}/`);
    return data;
  }

  static async getByUser(userId) {
    const { data } = await axios.get('programs/acne/', {
      params: { user: userId },
    });
    return data.results[0];
  }
  // NOTE: NO LONGER USED
  static async getOrderedProducts(programId) {
    const { data } = await axios.get(
      `programs/acne/${programId}/ordered-products/`,
    );
    return data;
  }
  static async getProgramProducts(programId) {
    const { data } = await axios.get(
      `programs/acne/${programId}/program-products/`,
    );
    return data;
  }

  static async update(programId, payload) {
    const { data } = await axios.patch(`programs/acne/${programId}/`, payload);
    return data;
  }
}
