import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';

import { getErrorMessage } from 'helpers/errorHandling';
import { useLoggedInUser } from 'hooks';
import {
  useAddTaskNote,
  useUpdateTaskNote,
} from 'features/contact/taskNote.query';
import Button from 'components/Button';
import NoteModal from 'components/NoteModal';

import theme from './Task.sss';

const Task = ({
  title,
  subtitle,
  link,
  task,
  action,
  additionalAction,
  onClick,
  onSaveNote,
  onClickAdditional,
  disableToolTip,
  allowNote,
  readOnly,
}) => {
  const user = useLoggedInUser();
  const { mutate: addTaskNote } = useAddTaskNote();
  const { mutate: updateTaskNote } = useUpdateTaskNote();
  const [showNoteModal, setshowNoteModal] = React.useState(false);

  const renderButton = () => {
    if (link) {
      const isExternal = link.startsWith('https');
      if (isExternal) {
        return (
          <a
            className={theme.Link}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
          >
            {action}
          </a>
        );
      }
      return (
        <Link className={theme.Link} to={link} onClick={onClick}>
          {action}
        </Link>
      );
    }
    if (disableToolTip) {
      return (
        <Tooltip title={disableToolTip}>
          <span>
            <Button disabled classes={[theme.Button]} onClick={onClick}>
              {action}
            </Button>
          </span>
        </Tooltip>
      );
    }
    if (additionalAction) {
      return (
        <>
          <Button classes={[theme.Button]} onClick={onClick}>
            {action}
          </Button>
          <Button
            classes={[theme.Button, theme.Additional]}
            onClick={onClickAdditional}
          >
            {additionalAction}
          </Button>
        </>
      );
    }
    return (
      <Button classes={[theme.Button]} onClick={onClick}>
        {action}
      </Button>
    );
  };

  const renderNoteButton = () => {
    const buttonText = task.note ? 'Edit Note' : 'Add Note';
    return (
      <Button
        classes={[theme.Button]}
        onClick={() => {
          setshowNoteModal(true);
        }}
      >
        {buttonText}
      </Button>
    );
  };
  function renderNote() {
    return (
      <>
        <div className={theme.TaskRow}>
          <div className={theme.Description}>
            {task.note && (
              <>
                <div className={theme.NoteBody}>{task.note.body}</div>
                <div className={theme.Subtitle}>
                  {task.note.author.profile.full_name}{' '}
                  {format(new Date(task.note.created), 'MMMM d, yyyy')}
                </div>
                {task.note.edited_by && (
                  <div className={theme.Subtitle}>
                    Edited by: {task.note.edited_by.profile.full_name}{' '}
                    {format(new Date(task.note.edited), 'MMMM d, yyyy')}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={theme.Action}>{renderNoteButton()}</div>
        </div>
        <NoteModal
          open={showNoteModal}
          note={task?.note?.body}
          onClose={() => {
            setshowNoteModal(false);
          }}
          onSaveClicked={(body) => {
            if (task.note) {
              updateTaskNote(
                { taskNoteId: task.note.id, payload: { body } },
                {
                  onSuccess: () => {
                    onSaveNote?.();
                    toast.success('Note edited succesfully');
                  },
                  onError: (error) => {
                    toast.error(getErrorMessage(error));
                  },
                },
              );
            } else {
              addTaskNote(
                { author: user.id, body, task: task.id },
                {
                  onSuccess: () => {
                    onSaveNote?.();
                    toast.success('Note added succesfully');
                  },
                  onError: (error) => {
                    toast.error(getErrorMessage(error));
                  },
                },
              );
            }
          }}
        />
      </>
    );
  }
  return (
    <li className={theme.Task}>
      <div className={theme.TaskRow}>
        <div className={theme.Description}>
          <div className={theme.Title}>{title}</div>
          {subtitle && <div className={theme.Subtitle}>{subtitle}</div>}
        </div>
        {!readOnly && <div className={theme.Action}>{renderButton()}</div>}
      </div>
      {allowNote && renderNote()}
    </li>
  );
};

Task.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  action: PropTypes.node.isRequired,
  additionalAction: PropTypes.node,
  link: PropTypes.node,
  task: PropTypes.object.isRequired,
  disableToolTip: PropTypes.string,
  onClick: PropTypes.func,
  onSaveNote: PropTypes.func,
  onClickAdditional: PropTypes.func,
  allowNote: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Task.defaultProps = {
  allowNote: false,
  readOnly: false,
};

export default Task;
