/* eslint-disable no-use-before-define */
import axios from 'config/axios';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

// Actions
const FETCH_PHOTOS = 'cc/HQPhotosWidget/FETCH_PHOTOS';
const FETCH_PHOTOS_FAILED = 'cc/HQPhotosWidget/FETCH_PHOTOS_FAILED';
const FETCH_PHOTOS_SUCCEEDED = 'cc/HQPhotosWidget/FETCH_PHOTOS_SUCCEEDED';

// Per-taskList initial state
const initialState = {
  loading: false,
  photos: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PHOTOS: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_PHOTOS_SUCCEEDED: {
      const { photos } = action.payload;
      return {
        ...state,
        photos,
        loading: false,
      };
    }
    case FETCH_PHOTOS_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

//helpers

const sortFacePhotos = (photos) => {
  if (isEmpty(photos)) {
    return {};
  }
  const sortedPhotos = photos;
  const FACE_IMAGES_ORDER = ['left', 'front', 'right'];
  const unsortedFace = photos.face;
  const sortedImage = {};
  sortedImage[FACE_IMAGES_ORDER[0]] = unsortedFace[FACE_IMAGES_ORDER[0]];
  sortedImage[FACE_IMAGES_ORDER[1]] = unsortedFace[FACE_IMAGES_ORDER[1]];
  sortedImage[FACE_IMAGES_ORDER[2]] = unsortedFace[FACE_IMAGES_ORDER[2]];
  sortedPhotos.face = sortedImage;
  return sortedPhotos;
};

const fetchPhotos = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_PHOTOS, payload });
  const { clientId } = payload;

  try {
    const { data: photos } = await axios.get(
      `/users/clients/${clientId}/hq-images/`,
    );

    dispatch(fetchPhotosSuceeded({ photos: sortFacePhotos(photos) }));
  } catch (error) {
    dispatch(fetchPhotosFailed({ error }));
  }
};

const fetchPhotosSuceeded = (payload) => (dispatch) => {
  dispatch({ type: FETCH_PHOTOS_SUCCEEDED, payload });
};

const fetchPhotosFailed = (payload) => (dispatch) => {
  dispatch({ type: FETCH_PHOTOS_FAILED, payload });
  const { error } = payload;
  toast.error(
    error.response?.data?.message ||
      error.message ||
      'Could not save your personal information',
  );
};

export default reducer;
export {
  // Actions
  FETCH_PHOTOS,
  FETCH_PHOTOS_FAILED,
  FETCH_PHOTOS_SUCCEEDED,
  // Action Creators
  fetchPhotos,
  fetchPhotosSuceeded,
  fetchPhotosFailed,
};
