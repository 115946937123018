import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { TYPES } from 'helpers/note';
import { getErrorMessage } from 'helpers/errorHandling';
import { useAddNote } from 'features/notes/note.query';

import { useOpenModalProps } from 'hooks';
import ErrorBoundary from 'components/ErrorBoundary';
import * as modalActions from 'components/Modal/Modal.ducks';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Wysiwyg from 'components/Wysiwyg';
import Form, { Radio } from 'components/Form';

function AddNoteModalWidget({ modalId, onNoteAdded }) {
  const dispatch = useDispatch();

  const { clientId } = useOpenModalProps(modalId);
  const { mutate: addNote, isLoading } = useAddNote();
  return (
    <ErrorBoundary>
      <Modal id={modalId} title="Add a note" autoClose>
        <Form
          defaultValues={{
            type: TYPES.general,
            body: '',
          }}
          onSubmit={({ type, body }) => {
            if (!clientId) {
              throw new Error('Need to specify a client');
            }
            addNote(
              {
                body,
                type,
                subject: clientId,
              },
              {
                onSuccess: () => {
                  toast.success('Note added succesfully');
                  onNoteAdded?.();
                  dispatch(modalActions.setOpenModalId(null));
                },
                onError: (error) => {
                  toast.error(getErrorMessage(error));
                },
              },
            );
          }}
        >
          {() => (
            <>
              <Form.Row>
                <Form.Item
                  name="type"
                  fill={false}
                  inlineLabel
                  reverseLabel
                  label={<label htmlFor="general">General Notes</label>}
                  input={<Radio id="general" value={TYPES.general} />}
                />
                <Form.Item
                  name="type"
                  fill={false}
                  inlineLabel
                  reverseLabel
                  label={<label htmlFor="consult">Consult Notes</label>}
                  input={<Radio id="consult" value={TYPES.consult} />}
                />
              </Form.Row>
              <Form.Row>
                <Form.Item
                  name="body"
                  label={<label htmlFor="body">Description</label>}
                  onChange={(value) => value}
                  input={<Wysiwyg id="body" hideToolBar />}
                  registerOptions={{
                    required: 'Please fill out this field',
                  }}
                />
              </Form.Row>
              <Form.Actions>
                <Button type="submit" disabled={isLoading}>
                  Add Note
                </Button>
              </Form.Actions>
            </>
          )}
        </Form>
      </Modal>
    </ErrorBoundary>
  );
}

AddNoteModalWidget.propTypes = {
  modalId: PropTypes.string.isRequired,
  onNoteAdded: PropTypes.func,
};

export default AddNoteModalWidget;
