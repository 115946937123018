import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { EditIcon } from 'components/Icon';
import { DatePicker } from '@material-ui/pickers';

import theme from './NextCheckInDate.sss';
import { orange01 } from 'styles/colors.sss';

const NextCheckInDate = ({
  date,
  onChange,
}) => {
  const [editingCheckIn, setEditingCheckIn] = useState(false);
  return (
    <>
      <p>Next Check-in: <em>{date}</em>
        {!editingCheckIn &&
          <Button
            color="sensitive"
            classes={[theme.EditCheckin]}
            onClick={() => { setEditingCheckIn(true); }}
          >
            <EditIcon width={18} height={18} fill={orange01} />
          </Button>
        }
      </p>
      <DatePicker
        classes={{
          root: theme.BirthDateInput,
        }}
        disablePast
        onClose={() => { setEditingCheckIn(false); }}
        open={editingCheckIn}
        value={date}
        onChange={onChange}
      />
    </>
  );
};

NextCheckInDate.propTypes = {
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NextCheckInDate;
