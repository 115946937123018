/* eslint-disable max-len */

import React from 'react';

const PillIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 16 2.0078125 C 14.462555 2.0078125 12.925349 2.5908738 11.757812 3.7578125 L 3.7578125 11.757812 C 1.422423 14.092007 1.4227166 17.907091 3.7578125 20.242188 L 3.7578125 20.244141 C 4.925619 21.410705 6.4658333 22 8 22 C 9.5341667 22 11.075162 21.409212 12.242188 20.242188 L 20.242188 12.244141 L 20.242188 12.242188 C 22.576376 9.9078856 22.576884 6.0925086 20.242188 3.7578125 C 19.074651 2.5908738 17.537445 2.0078125 16 2.0078125 z M 16 3.9941406 C 17.021555 3.9941406 18.042661 4.3868137 18.828125 5.171875 C 20.399029 6.7427791 20.398735 9.2583187 18.828125 10.828125 L 15.078125 14.578125 L 9.421875 8.921875 L 13.171875 5.171875 C 13.957339 4.3868137 14.978445 3.9941406 16 3.9941406 z" />
  </svg>
);

PillIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default PillIcon;
