import React from 'react';

import theme from './StayClear.sss';

const StayClear = () => (
  <div className={theme.StayClear}>
    <h1>
      GETTING <span className={theme.Green}>CLEAR</span> IS THE FIRST STEP…
    </h1>
    <div className={theme.Subtitle}>
      STAYING CLEAR IS WHERE YOU FIND TRUE FREEDOM!
    </div>
    <div className={theme.Content}>
      <div className={theme.Description}>
        <strong>
          The #1 fear Online Acne Program graduates have once they’ve achieved
          clear skin is… <br />
          Not Being Able To Stay Clear For Life…
        </strong>
        And this is where our Clear Connection Maintenance Plans come in! <br />
        <br />
        <br />
        Here are just a few of the benefits you will receive with your
        subscription:
        <ul className={theme.Checked}>
          <li>
            Continued access to your online profile so you can remind yourself
            of the regimen tweaks your Clear Skin Specialist made helping your
            skin get to the next level.{' '}
          </li>
          <li>
            Visibility into the recorded messages with your clear skin
            specialist so you can revisit tips and tricks you received along the
            way.
          </li>
          <li>
            If your skin starts to respond in an unexpected way, you will have
            access to your Clear Skin Specialist a minimum of 10 times per year
            depending on the plan you choose.{' '}
          </li>
          <li>
            Having the comfort of knowing you can chat with your clear skin
            specialist and get the information you need at your fingertips
            anytime you need it!
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default StayClear;
