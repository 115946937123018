import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import formatDistance from 'date-fns/formatDistance';

import { getIdFromUrl } from 'helpers/formatHelpers';
import { useLoggedInUser } from 'hooks';
import { isClient } from 'helpers/user';
import Task from 'components/Task';

import StartProgramModal from './components/StartProgramModal';
import * as actions from './StartProgramWidget.ducks';

function StartProgramWidget({ task, initialize, stage, startProgram }) {
  const user = useLoggedInUser();
  const [showDateModal, setshowDateModal] = React.useState(false);

  React.useEffect(() => {
    initialize({ task });
  }, [task, initialize]);

  const { client_name: clientName } = task.context;
  const userId = getIdFromUrl(task.linked_user);
  const title = isClient(user) ? (
    'I have received my products'
  ) : (
    <p>
      {/* eslint-disable-next-line camelcase */}
      <Link to={`/client-details/${userId}`}>{clientName}</Link>
      &nbsp;has accepted the program
    </p>
  );
  const subtitle = isClient(user)
    ? null
    : `Completed: ${formatDistance(new Date(task.created), new Date())}`;
  return (
    <>
      <Task
        task={task}
        title={title}
        subtitle={subtitle}
        action="Start Program"
        onClick={() => {
          setshowDateModal(true);
        }}
      />
      <StartProgramModal
        open={showDateModal}
        onClose={() => {
          setshowDateModal(false);
        }}
        onStartClicked={(startProgramDate) => {
          startProgram({ task, stage, startProgramDate });
        }}
      />
    </>
  );
}

StartProgramWidget.propTypes = {
  task: PropTypes.object.isRequired,
  stage: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired,
  startProgram: PropTypes.func.isRequired,
};

const mapStateToProps = ({ startProgram }, { task }) => {
  const defaultState = {
    stage: {},
    submitting: false,
  };
  const startProgramState = startProgram[task.id]
    ? startProgram[task.id]
    : defaultState;
  return {
    ...startProgramState,
  };
};

export default connect(mapStateToProps, actions)(StartProgramWidget);
