/* eslint-disable max-len */
import React from 'react';

const MaintenancePlanIcon = (props) => (
  <svg viewBox="0 0 12 18" {...props}>
    <path
      d="M2.54915 6.16669C2.54915 4.14164 4.19078 2.50002 6.21582 2.50002C8.24086 2.50002 9.88249 4.14164 9.88249 6.16669C9.88249 8.19173 8.24086 9.83335 6.21582 9.83335C4.19078 9.83335 2.54915 8.19173 2.54915 6.16669ZM6.21582 0.666687C3.17825 0.666687 0.71582 3.12912 0.71582 6.16669C0.71582 7.811 1.4374 9.28678 2.58117 10.2947C2.52511 10.3908 2.48573 10.4988 2.46757 10.6154L1.56007 16.4423C1.50705 16.7827 1.64942 17.1242 1.92861 17.3261C2.2078 17.528 2.57665 17.5563 2.88338 17.3994L6.21582 15.6942L9.54825 17.3994C9.85497 17.5563 10.2238 17.528 10.503 17.3262C10.7821 17.1243 10.9246 16.7829 10.8716 16.4424L9.96409 10.6091C9.94621 10.4942 9.9077 10.3876 9.85292 10.2925C10.9953 9.28467 11.7158 7.80982 11.7158 6.16669C11.7158 3.12912 9.25339 0.666687 6.21582 0.666687ZM8.21495 11.2921C7.59533 11.5339 6.92107 11.6667 6.21582 11.6667C5.51089 11.6667 4.83694 11.5341 4.21756 11.2924L3.64813 14.9487L5.79825 13.8485C6.06046 13.7143 6.37117 13.7143 6.63338 13.8485L8.78384 14.9488L8.21495 11.2921Z"
      fill="#2EC5B6"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

MaintenancePlanIcon.defaultProps = {
  height: 18,
  width: 12,
  fill: 'none',
};

export default MaintenancePlanIcon;
