import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ClientOverview from 'components/ClientOverview';
import { useBranch } from 'components/BranchProvider';

const ConsentForm = ({ details, program, name, type, jwt }) => {
  const [branch] = useBranch();
  return (
    <ClientOverview
      program={program}
      client={{ name, id: details.user_id, jwt }}
      coachView
      type={type}
      skipTreatmentPlan={branch.skip_treatment_plan}
    />
  );
};

ConsentForm.propTypes = {
  jwt: PropTypes.string,
  details: PropTypes.object,
  program: PropTypes.object,
  type: PropTypes.string,
  name: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  jwt: user.jwt,
});

export default withRouter(connect(mapStateToProps)(ConsentForm));
