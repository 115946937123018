import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loader from 'components/Loader';

import * as actions from './CheckInFollowupWidget.ducks';
import CheckInFollowupTable from './components';

import theme from './CheckInFollowupWidget.sss';

class CheckInFollowupWidget extends Component {

  componentDidMount() {
    const { fetchCheckinFollowup } = this.props;
    fetchCheckinFollowup();
  }

  render() {
    const {
      loading,
      checkinFollowups,
    } = this.props;
    if (loading) {
      return (
        <div className={theme.CheckInFollowupWidget}>
          <Loader />
        </div>
      );
    }
    return (
      <div className={theme.CheckInFollowupWidget}>
        <CheckInFollowupTable checkinFollowups={checkinFollowups} />
      </div>
    );
  }
}

CheckInFollowupWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  checkinFollowups: PropTypes.array.isRequired,
  fetchCheckinFollowup: PropTypes.func.isRequired,
};

const mapStateToProps = ({ checkinFollowup }) => ({
  ...checkinFollowup,
});

export default connect(mapStateToProps, actions)(CheckInFollowupWidget);
