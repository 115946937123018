/* eslint no-use-before-define: 0 */

import { RSAA } from 'redux-api-middleware';
import * as types from './actionTypes';
import { handleRSAAError } from '../helpers/errorHandling';
import { apiBaseUrl as api } from '../config/config';

export function getNotifications(token) {
  return async function (dispatch) {
    dispatch({ type: types.GET_NOTIFICATIONS });

    const headers = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers.Authorization = 'Bearer ' + token;
    }

    try {
      const { type, payload } = await dispatch({
        [RSAA]: {
          endpoint: api + 'notifications/',
          method: 'GET',
          types: types.MIDDLEWARE_TYPES,
          headers,
        },
      });

      if (type === types.FAILURE) {
        throw new Error(payload);
      }

      dispatch(getNotificationsSuccess({ notifications: payload }));
    } catch (error) {
      dispatch(getNotificationsError(error));
    }
  };
}

export function getNotificationsError(error) {
  handleRSAAError(error);
  return { type: types.GET_NOTIFICATIONS_ERROR };
}

export function getNotificationsSuccess(notifications) {
  return {
    type: types.GET_NOTIFICATIONS_SUCCESS,
    notifications,
  };
}

export function setNotificationListActive(active) {
  return {
    type: types.SET_NOTIFICATION_LIST_ACTIVE,
    active,
  };
}

export function toggleNotificationList() {
  return {
    type: types.TOGGLE_NOTIFICATION_LIST_ACTIVE,
  };
}

export function updateNotification(notification) {
  return {
    type: types.UPDATE_NOTIFICATION,
    notification,
  };
}
