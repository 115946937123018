import axios from 'config/axios';

export default class TaskService {
  static async get(params = {}) {
    const { data } = await axios.get('tasks/', {
      params,
    });
    return data.results;
  }
  static async getClientTasks(clientId, params = {}) {
    const { data } = await axios.get(`users/clients/${clientId}/tasks/`, {
      params,
    });
    return data;
  }

  static async getAllClientsTasks(params = {}) {
    const { data } = await axios.get('users/clients/tasks/', {
      params,
    });
    return data;
  }

  static async update(taskId, payload) {
    const { data } = await axios.patch(`tasks/${taskId}/`, payload);
    return data;
  }
}
