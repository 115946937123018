import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './Filter.sss';

const Filter = ({ name, selected, onClick }) => (
  <li
    className={classNames(theme.Filter, {
      [theme.SelectedFilter]: selected,
    })}
    onClick={onClick}
  >
    {name}
  </li>
);

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  selected: false,
};

export default Filter;
