import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

import { useLoggedInUser, usePaginator } from 'hooks';
import { isPracticeManager } from 'helpers/user';
import Loader from 'components/Loader';
import MessageList from 'components/MessageList';

import { Details } from './components';
import theme from './Conversation.sss';

function ConversationHistoryPage() {
  const { clientId } = useParams();
  const history = useHistory();
  const user = useLoggedInUser();

  const [scrollRef, loaderRef, paginatorState] = usePaginator({
    debugId: `Client-${clientId}`,
    endpoint: `users/clients/${clientId}/messages`,
    direction: 'top',
  });

  useEffect(() => {
    if (!isPracticeManager(user)) {
      history.replace('/');
    }
  });

  const messages = useMemo(() => {
    return uniqBy(paginatorState.data, 'id').reduce((acc, message) => {
      const day = moment(message.created).format('YYYY-MM-DD');
      return {
        ...acc,
        [day]: [...(acc[day] ?? []), message],
      };
    }, {});
  }, [paginatorState.data]);

  function getEmptyMessage() {
    return (
      <p className={theme.EmptyMessageList}>
        There is no messages with this user
      </p>
    );
  }

  if (paginatorState.isLoading && paginatorState.currentPage === 0) {
    return <Loader />;
  }

  return (
    <div className={theme.ConversationPage}>
      <Details clientId={clientId} />
      <MessageList
        messages={messages}
        emptyMessage={getEmptyMessage()}
        ref={scrollRef}
        loadingRef={loaderRef}
        showRecipient
      />
    </div>
  );
}

export default ConversationHistoryPage;
