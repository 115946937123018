import React from 'react';
import PropTypes from 'prop-types';
import { LockIcon } from 'components/Icon';
import Button from 'components/Button';

import theme from './ChatRedirect.sss';
import { getCoachInThread, getClientInThread } from 'helpers/thread';

const ChatRedirect = ({ thread, openThread }) => {
  const coach = getCoachInThread(thread);
  const client = getClientInThread(thread);
  return (
    <div className={theme.ChatRedirect}>
      <div className={theme.ChatRedirectInfo}>
        <LockIcon className={theme.ChatRedirectLockIcon} />
        <div className={theme.ChatRedirectMessage}>
          <h1 className={theme.ChatRedirectHeadMessage}>
            This Chat is blocked
          </h1>
          {/* eslint-disable-next-line camelcase */}
          <span>{`Only ${coach?.profile?.full_name} can answer`}</span>
        </div>
      </div>
      <Button
        onClick={() => openThread()}
        classes={[theme.ChatRedirectMessageButton]}
      >
        {/* eslint-disable-next-line camelcase */}
        {`Click here to message ${client?.profile?.full_name} `}
      </Button>
    </div>
  );
};

ChatRedirect.propTypes = {
  thread: PropTypes.object.isRequired,
  openThread: PropTypes.func.isRequired,
};

export default ChatRedirect;
