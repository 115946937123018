import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import theme from './MenuItem.sss';

// eslint-disable-next-line react/prop-types
const Circle = ({ children }) => <div className={theme.Circle}>{React.Children.only(children)}</div>;

// eslint-disable-next-line react/prop-types
const ClickMenuItem = ({ children, target, onClick }) => (
  <div onClick={onClick} className={theme.MenuItem} target={target}>
    <Circle>{children}</Circle>
  </div>
);

// eslint-disable-next-line react/prop-types
const LinkMenuItem = ({ children, link }) => (
  <NavLink to={link} className={theme.MenuItem}>
    <Circle>{children}</Circle>
  </NavLink>
);

const MenuItem = ({
  children,
  target,
  onClick,
  link,
}) => {
  if (onClick) {
    return <ClickMenuItem onClick={onClick} target={target}>{children}</ClickMenuItem>;
  }
  return <LinkMenuItem link={link}>{children}</LinkMenuItem>;
};

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
};

export default MenuItem;
