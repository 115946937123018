import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import format from 'date-fns/format';

import { url } from 'helpers/user';
import ThreadService from 'services/thread.service';

import { useLoggedInUser } from 'hooks';
import { useGetNextAcnePeelAppointment } from 'features/users/user.query';
import { useBranch } from 'components/BranchProvider';
import * as inboxActions from 'containers/InboxWidget/UserInboxWidget/UserInboxWidget.ducks';
import { TR, TD } from 'components/Table';
import Loader from 'components/Loader';
import Button from 'components/Button';
import TDNote from 'components/Table/TDNote';

import { NOTES_MODAL_ID, ADD_NOTE_MODAL_ID } from './MissedCheckInsTable';

import theme from './MissedCheckInsRow.sss';

const STRINGS = {
  profileLink: ({ id: clientId }) => `/client-details/${clientId}`,
  checkInDate: ({ latest_stage: latestStage }) =>
    format(new Date(latestStage.check_in_scheduled), 'MM/dd/yyyy'),
  currentWeek: ({ latest_stage: latestStage }) =>
    `Week-${latestStage.week_period}`,
  action: 'Message Client',
};

const MissedCheckInRow = ({ client }) => {
  const history = useHistory();
  const user = useLoggedInUser();
  const [branch] = useBranch();
  const dispatch = useDispatch();
  const { data: nextAcnePeelAppt, isLoading: isNextAcnePeelApptLoading } =
    useGetNextAcnePeelAppointment(client.id, {
      enabled: !!branch.show_next_acne_peel_appointment,
      refetchOnWindowFocus: false,
    });

  const [thread, setThread] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ThreadService.getByOnlyParticipants([user, client]).then((userThread) => {
      setThread(userThread);
      setLoading(false);
    });
  }, [user, client]);

  function renderNextAppt() {
    if (isNextAcnePeelApptLoading) {
      return <Loader size="small" />;
    }
    if (!nextAcnePeelAppt.start) {
      return 'None';
    }
    return format(new Date(nextAcnePeelAppt.start), 'MM/dd/yyyy');
  }

  function renderMessageClient() {
    if (loading) {
      return <Loader size="small" />;
    }
    if (thread) {
      return (
        <Link className={[theme.Link]} to={`/messages/${thread.id}`}>
          {STRINGS.action}
        </Link>
      );
    }
    return (
      <Button
        classes={[theme.ActionButton]}
        color="sensitive"
        onClick={async () => {
          const newThread = await dispatch(
            inboxActions.createThread({
              participants: [url(client)],
            }),
          );
          history.push(`/messages/${newThread.id}`);
        }}
      >
        {STRINGS.action}
      </Button>
    );
  }

  return (
    <TR>
      <TD>
        <Link className={theme.Link} to={STRINGS.profileLink(client)}>
          {`${client.profile.first_name} ${client.profile.last_name}`}
        </Link>
      </TD>
      <TD>{STRINGS.checkInDate(client)}</TD>
      <TD>{STRINGS.currentWeek(client)}</TD>
      {branch.show_next_acne_peel_appointment && (
        <TD>
          <span style={{ position: 'relative' }}>
            Next Appt Date: {renderNextAppt()}
          </span>
        </TD>
      )}
      <TDNote
        clientID={client.id}
        note={client.latest_note}
        viewNotesModalID={NOTES_MODAL_ID}
        addNoteModalID={ADD_NOTE_MODAL_ID}
      />
      <TD>{branch.messaging_enabled && renderMessageClient()}</TD>
    </TR>
  );
};

MissedCheckInRow.propTypes = {
  client: PropTypes.object.isRequired,
};

export default MissedCheckInRow;
