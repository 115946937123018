import React from 'react';
import PropTypes from 'prop-types';

import { black01 } from 'styles/colors.sss';
import Button from 'components/Button';
import { FileInput } from 'components/Form';
import { AttachIcon } from 'components/Icon';

import { useComposeMessage } from './ComposeMessage';

import theme from './Bottombar.sss';
import { isEmpty } from 'lodash';

function Bottombar({ leftItems, defaultMessageType, onSendMessageClick }) {
  const fileInputRef = React.useRef();
  const {
    message,
    messageType,
    attachment,
    setMessage,
    setMessageType,
    setAttachment,
    setMessagePlaceholder,
  } = useComposeMessage();
  React.useEffect(() => {
    if (isEmpty(attachment)) {
      fileInputRef.current.value = '';
    }
  }, [attachment]);
  return (
    <div className={theme.Bottombar}>
      {leftItems && <div className={theme.LeftItems}>{leftItems}</div>}
      <div className={theme.Actions}>
        <FileInput
          ref={fileInputRef}
          onChange={(file) => {
            setAttachment(file);
            setMessagePlaceholder(
              'Enter a message for sending your attachment',
            );
          }}
        >
          <AttachIcon fill={black01} width="24" height="24" />
        </FileInput>
        <div className={theme.Extra} />
        <Button
          classes={[theme.SendMessageButton]}
          disabled={!message.length || /^\s+$/.test(message)}
          onClick={() => {
            setMessage('');
            setMessagePlaceholder('Start the conversation here');
            setMessageType(defaultMessageType);
            setAttachment({});
            onSendMessageClick({ message, messageType, attachment });
          }}
        >
          Send message
        </Button>
      </div>
    </div>
  );
}

Bottombar.propTypes = {
  leftItems: PropTypes.node,
  defaultMessageType: PropTypes.string,
  onSendMessageClick: PropTypes.func,
};

Bottombar.defaultProps = {
  leftItems: [],
};

export default Bottombar;
