import React from 'react';
import PropTypes from 'prop-types';

import Task from 'components/Task';

const ReviewTreatmentPlanWidget = ({
  task,
}) => (
  <Task
    task={task}
    title="Your treatment plan is ready"
    action="View Treatment Plan"
    link={`/review-treatment-plan/${task.context.treatment_plan_id}/${task.id}`}
    extraDataCalls={{
      treatmentPlan: `programs/acne/treatment-plans/${task.context.treatment_plan_id}/`,
    }}
  />
);

ReviewTreatmentPlanWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

export default ReviewTreatmentPlanWidget;
