/* eslint-disable no-use-before-define */
import { toast } from 'react-toastify';

import axios from 'config/axios';
import history from 'global-history';
import { updateProfile } from 'actions/userActions';
import { getErrorMessage } from 'helpers/errorHandling';

// Actions
const SAVE = 'cc/addTimeZone/SAVE';
const SAVE_SUCCESS = 'cc/addTimeZone/SAVE_SUCCESS';
const SAVE_FAIL = 'cc/addTimeZone/SAVE_FAIL';

const initialState = {
  loading: false,
  form: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE:
      return { ...state, loading: true };
    case SAVE_SUCCESS:
    case SAVE_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const save = (payload) => async (dispatch, getState) => {
  dispatch({ type: SAVE, payload });

  const { profile } = getState().user;

  try {
    await axios.patch(`/users/profiles/${profile.id}/`, {
      time_zone: payload.timeZone.name,
    });
    dispatch(saveSuccess());
    dispatch(updateProfile({ time_zone: payload.timeZone.name }));
    history.replace('/');
  } catch (error) {
    dispatch(saveFail({ error }));
  }
};

const saveSuccess = (payload) => (dispatch) => {
  dispatch({ type: SAVE_SUCCESS, payload });
  toast.success('Your personal information is saved! Redirecting to home...');
};

const saveFail = (payload) => (dispatch) => {
  dispatch({ type: SAVE_FAIL, payload });
  const { error } = payload;
  toast.error(
    getErrorMessage(error, 'Could not save your personal information'),
  );
};

export default reducer;
export {
  // Actions
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAIL,
  // Action Creators
  save,
  saveSuccess,
  saveFail,
};
