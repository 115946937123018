import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import formatDistance from 'date-fns/formatDistance';
import { useQueryClient } from 'react-query';

import { getIdFromUrl } from 'helpers/formatHelpers';

import Task from 'components/Task';

const PublishTreatmentPlanWidget = ({ task }) => {
  const queryClient = useQueryClient();
  const userId = Number.isInteger(task.linked_user)
    ? task.linked_user
    : getIdFromUrl(task.linked_user);
  const { client_name: clientName } = task.context;
  const title = (
    <p>
      <Link to={`/client-details/${userId}`}>{clientName}</Link>
      &nbsp;has completed the health questionnaire
    </p>
  );
  const subtitle = `Completed: ${formatDistance(
    new Date(task.created),
    new Date(),
  )}`;
  const action = 'Complete Treatment Plan';
  return (
    <Task
      allowNote
      task={task}
      title={title}
      subtitle={subtitle}
      action={action}
      link={`/create-treatment-plan/${userId}`}
      extraDataCalls={{
        user: `users/${userId}/profile/`,
        programs: `programs/acne/?user=${userId}`,
      }}
      onSaveNote={() => {
        queryClient.invalidateQueries(['tasks']);
      }}
    />
  );
};

PublishTreatmentPlanWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

export default PublishTreatmentPlanWidget;
