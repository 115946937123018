/* istanbul ignore file */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'components/Modal';
import { LoaderIcon } from 'components/Icon';

import { bluePrimary } from 'styles/colors.sss';

import theme from './Loader.sss';

const LOADER_TYPES = {
  absolute: 'absolute',
  fixed: 'fixed',
  modal: 'modal',
};

const LOADER_SIZES = {
  small: 32,
  medium: 48,
  large: 64,
};

const LOADER_MODAL_ID = 'Loader';

const Loader = ({ className, children, type, size }) => {
  const container = useRef();

  const getLoaderStyles = () => {
    const containerWidth = container.current?.clientWidth ?? 0;
    const containerHeight = container.current?.clientHeight ?? 0;
    return {
      marginTop: `-${Number(containerHeight / 2).toFixed()}px`,
      marginLeft: `-${Number(containerWidth / 2).toFixed()}px`,
    };
  };

  if (type === LOADER_TYPES.modal) {
    return (
      <Modal ref={container} id={LOADER_MODAL_ID} open showTopActions={false}>
        <div
          className={classNames(theme.Content, theme[`${theme[size]}Content`])}
        >
          <LoaderIcon
            width={LOADER_SIZES[size]}
            height={LOADER_SIZES[size]}
            fill={bluePrimary}
          />
          {children}
        </div>
      </Modal>
    );
  }

  return (
    <div
      ref={container}
      style={getLoaderStyles()}
      className={classNames(theme[type], className)}
    >
      <div
        className={classNames(theme.Content, theme[`${theme[size]}Content`])}
      >
        <LoaderIcon
          width={LOADER_SIZES[size]}
          height={LOADER_SIZES[size]}
          fill={bluePrimary}
        />
        {children}
      </div>
    </div>
  );
};

Loader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(LOADER_TYPES)),
  size: PropTypes.oneOf(Object.keys(LOADER_SIZES)),
};

Loader.defaultProps = {
  type: LOADER_TYPES.absolute,
  size: 'medium',
};

export default Loader;
