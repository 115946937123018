/* eslint-disable max-len */

import React from 'react';

const TaskListIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props} >
    <path d="M 5.5859375 3.4140625 C 5.3300625 3.4140625 5.0744063 3.5115312 4.8789062 3.7070312 L 3.5859375 5 L 3 5 C 2.448 5 2 5.448 2 6 C 2 6.552 2.448 7 3 7 L 4.4140625 7 L 6.2929688 5.1210938 C 6.6839687 4.7310937 6.6839688 4.0980313 6.2929688 3.7070312 C 6.0979688 3.5115312 5.8418125 3.4140625 5.5859375 3.4140625 z M 10 5 C 9.448 5 9 5.448 9 6 C 9 6.552 9.448 7 10 7 L 21 7 C 21.552 7 22 6.552 22 6 C 22 5.448 21.552 5 21 5 L 10 5 z M 5.5859375 9.4140625 C 5.3300625 9.4140625 5.0744063 9.5115313 4.8789062 9.7070312 L 3.5859375 11 L 3 11 C 2.448 11 2 11.448 2 12 C 2 12.552 2.448 13 3 13 L 4.4140625 13 L 6.2929688 11.121094 C 6.6839687 10.731094 6.6839688 10.098031 6.2929688 9.7070312 C 6.0979688 9.5115312 5.8418125 9.4140625 5.5859375 9.4140625 z M 10 11 C 9.448 11 9 11.448 9 12 C 9 12.552 9.448 13 10 13 L 21 13 C 21.552 13 22 12.552 22 12 C 22 11.448 21.552 11 21 11 L 10 11 z M 4 16.5 A 1.5 1.5 0 0 0 2.5 18 A 1.5 1.5 0 0 0 4 19.5 A 1.5 1.5 0 0 0 5.5 18 A 1.5 1.5 0 0 0 4 16.5 z M 10 17 C 9.448 17 9 17.448 9 18 C 9 18.552 9.448 19 10 19 L 21 19 C 21.552 19 22 18.552 22 18 C 22 17.448 21.552 17 21 17 L 10 17 z" />
  </svg>
);

TaskListIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default TaskListIcon;
