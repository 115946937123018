import React from 'react';
import PropTypes from 'prop-types';

import { emailRegex } from 'helpers/validators';
import { apiBaseUrl } from 'config/config';
import Form, { TextInput, Autocomplete } from 'components/Form';
import { useBranch } from 'components/BranchProvider';
import Button from 'components/Button';
import Loader from 'components/Loader';

function InviteClientForm({ coaches, submitting, onSubmit }) {
  const [branch] = useBranch();
  return (
    <Form
      defaultValues={{
        firstName: '',
        lastName: '',
        email: '',
        coach: '',
      }}
      submitting={submitting}
      description="An email will be send to the client to complete the account setup"
      onSubmit={({ coach, ...rest }) => {
        const values = {
          ...rest,
          branchId: branch.id,
          groups: [`${apiBaseUrl}groups/1/`],
        };
        if (coach) {
          values.coachId = coach.id;
        }
        onSubmit(values);
      }}
    >
      {() => (
        <>
          <Form.Row>
            <Form.Item
              name="firstName"
              label={<label htmlFor="firstName">First Name</label>}
              input={<TextInput id="firstName" disabled={submitting} />}
              registerOptions={{
                required: 'Please enter a first name',
              }}
            />
          </Form.Row>
          <Form.Row>
            <Form.Item
              name="lastName"
              label={<label htmlFor="lastName">Last Name</label>}
              input={<TextInput id="lastName" disabled={submitting} />}
              registerOptions={{
                required: 'Please enter a last name',
              }}
            />
          </Form.Row>
          <Form.Row>
            <Form.Item
              name="email"
              label={<label htmlFor="email">Email</label>}
              input={<TextInput id="email" disabled={submitting} />}
              registerOptions={{
                required: 'Please enter an email address',
                pattern: {
                  value: emailRegex,
                  message: 'Your email is not a valid email address',
                },
              }}
            />
          </Form.Row>
          {branch.client_coach_assignment_enabled && (
            <Form.Row>
              <Form.Item
                name="coach"
                label={<label htmlFor="coach">Coach</label>}
                input={
                  <Autocomplete
                    id="coach"
                    getOptionLabel={(coach) =>
                      coach.profile?.full_name || 'Please select a coach'
                    }
                    options={coaches}
                    disabled={submitting}
                  />
                }
                registerOptions={{
                  required: 'Please select a coach',
                }}
                onChange={(_, value) => value}
              />
            </Form.Row>
          )}
          <Form.Actions>
            {submitting && <Loader />}
            <Button disabled={submitting} size="big" type="submit">
              Send Invitation
            </Button>
          </Form.Actions>
        </>
      )}
    </Form>
  );
}

InviteClientForm.propTypes = {
  coaches: PropTypes.array,
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

InviteClientForm.defaultProps = {
  coaches: [],
  submitting: false,
};

export default InviteClientForm;
