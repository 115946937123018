/* eslint-disable max-len */

import React from 'react';

const PDFIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 9 C 2.895 9 2 9.895 2 11 L 2 16 C 2 17.105 2.895 18 4 18 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 7.4140625 A 1.0001 1.0001 0 0 0 19.707031 6.7070312 L 15.292969 2.2929688 A 1.0001 1.0001 0 0 0 14.585938 2 L 6 2 z M 6 4 L 14 4 L 14 7 C 14 7.552 14.448 8 15 8 L 18 8 L 18 9 L 6 9 L 6 4 z M 4 11 L 5.5 11 C 6.328 11 7 11.672 7 12.5 C 7 13.328 6.328 14 5.5 14 L 5 14 L 5 16 L 4 16 L 4 11 z M 13 11 L 16 11 L 16 12 L 14 12 L 14 13 L 15.599609 13 L 15.599609 14 L 14 14 L 14 16 L 13 16 L 13 11 z M 8 11.023438 L 9.6289062 11.023438 C 11.210906 11.023438 12 11.974906 12 13.128906 L 12 13.900391 C 12 15.068391 11.209234 16 9.6152344 16 L 8 16 L 8 11.023438 z M 5 12 L 5 13 L 5.5 13 C 5.776 13 6 12.776 6 12.5 C 6 12.224 5.776 12 5.5 12 L 5 12 z M 9 12.023438 L 9 15 L 9.6152344 15 C 10.247234 15 11 14.808391 11 13.900391 L 11 13.128906 C 11 12.214906 10.254906 12.023438 9.6289062 12.023438 L 9 12.023438 z M 6 18 L 18 18 L 18 20 L 6 20 L 6 18 z" />
  </svg>
);

PDFIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default PDFIcon;
