/* istanbul ignore file */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CKEditor from 'ckeditor4-react';

import { TextInput } from 'components/Form';

import { createConfig } from 'config/ckeditor';

import theme from './Wysiwyg.sss';

const SIZES = {
  xsmall: 100,
  small: 200,
  medium: 400,
  large: 600,
};

/**
 * NOTE: Adding CKEditor to jsdom for unit testing is not easy at all.
 * Instead we use the TextInput component for "test" environments
 */

const InputComponent =
  process.env.NODE_ENV === 'test'
    ? ({
        size, // eslint-disable-line no-unused-vars
        onChange,
        ...others
      }) => (
        <TextInput
          data-testid="Wysiwyg"
          {...others}
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
        />
      )
    : ({ value, defaultValue, size, onChange, hideToolBar }) => (
        <CKEditor
          data={value ?? defaultValue}
          config={createConfig(SIZES[size], hideToolBar)}
          onChange={(ev) => {
            onChange(ev.editor.getData());
          }}
        />
      );

// eslint-disable-next-line no-unused-vars
const Wysiwyg = forwardRef(({ className, error, ...others }, ref) => (
  <div className={classNames(className)}>
    <InputComponent {...others} />
    {error && <span className={theme.ErrorText}>{error}</span>}
  </div>
));

Wysiwyg.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  onChange: PropTypes.func,
  hideToolBar: PropTypes.bool,
};

Wysiwyg.defaultProps = {
  size: 'medium',
  hideToolBar: false,
};

export default Wysiwyg;
