import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as MUIDatePicker } from '@material-ui/pickers';

import { errorRed } from 'styles/colors.sss';

const DatePicker = forwardRef(
  ({ defaultValue, error, label, register, ...others }, ref) => {
    const [selectedDate, setSelectedDate] = useState(defaultValue ?? null);
    useEffect(() => {
      others.onChange?.(selectedDate);
    }, [selectedDate]);
    return (
      <div>
        <MUIDatePicker
          disableFuture
          openTo="year"
          format="MM/dd/yyyy"
          label={label}
          views={['year', 'month', 'date']}
          inputRef={register}
          {...others}
          onChange={setSelectedDate}
          value={selectedDate}
          inputRef={ref}
        />
        {error && (
          <span style={{ display: 'block', color: errorRed }}>{error}</span>
        )}
      </div>
    );
  },
);

DatePicker.propTypes = {
  defaultValue: PropTypes.object,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  register: PropTypes.func,
};

export default DatePicker;
