/* eslint no-use-before-define: 0 */

// Actions
const SET_OPEN_MODAL_ID = 'cc/Modal/SET_OPEN_MODAL_ID';

const initialState = {
  openModalId: null,
  modalProps: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_MODAL_ID:
      return { ...state, openModalId: action.openModalId, modalProps: action.modalProps };
    default: return state;
  }
};

const setOpenModalId = (openModalId, modalProps = {} ) =>
  (dispatch) => {
    dispatch({ type: SET_OPEN_MODAL_ID, openModalId, modalProps });
  };

export default reducer;
export {
  // Actions
  SET_OPEN_MODAL_ID,

  // Action Creators
  setOpenModalId,
};
