import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { coachDashboardTour } from 'config/tour';

import * as appointmentActions from 'actions/appointmentActions';

import theme from './Calendar.scss';

const CELL_HEIGHT = 100; // cell height in px
const START_OF_DAY = 8; // starting hour
const HOURS_TO_RENDER = 24; // num hours to render

function Calendar({ user, appointments, getAppointments }) {
  const [now, setNow] = React.useState(moment());
  const [currentDay, setCurrentDay] = React.useState(
    moment().startOf('day').hours(START_OF_DAY),
  );

  React.useEffect(() => {
    getAppointments(user.id);
  }, [user.id, getAppointments]);

  const changeDay = (interval) => {
    setNow(moment());
    setCurrentDay(currentDay.add(interval, 'days'));
  };

  function getCurrentDayAppointments() {
    return (appointments || []).filter((appointment) => {
      return moment(appointment.start).isSame(currentDay, 'day');
    });
  }

  function getOffset(time) {
    const offsetMinutes = time.diff(currentDay, 'minutes');
    const offsetPixels = offsetMinutes * (CELL_HEIGHT / 60);

    return offsetPixels;
  }

  function getAppointmentBounds(appointment) {
    const appointmentStart = moment(appointment.start);
    const appointmentEnd = moment(appointment.end);
    const duration = appointmentEnd.diff(appointmentStart, 'minutes');

    return {
      appointmentStart,
      appointmentEnd,
      duration,
    };
  }

  function getAppointmentStyle(appointment) {
    const { appointmentStart, duration } = getAppointmentBounds(appointment);

    const appointmentOffset = getOffset(appointmentStart);
    const appointmentHeight = duration * (CELL_HEIGHT / 60);

    return {
      top: `${appointmentOffset + 1}px`,
      height: `${appointmentHeight - 2}px`,
    };
  }

  function getAppointmentClassName(appointment) {
    const { appointmentEnd, duration } = getAppointmentBounds(appointment);

    const classNames = [theme.calendarAppointment];

    // appointment is in past, use faded style
    if (now.format('x') > appointmentEnd.format('x')) {
      classNames.push(theme.calendarAppointmentPast);
    }

    // appointment duration >= 30 min, use expanded appointment style
    if (duration >= 30) {
      classNames.push(theme.calendarAppointmentExpanded);
    }

    return classNames.join(' ');
  }

  function getAppointmentTimeStr(appointment) {
    const { appointmentStart, appointmentEnd, duration } =
      getAppointmentBounds(appointment);

    let appointmentTimeStr = '';

    // show end time if duration >= 30 min
    if (duration >= 30) {
      const start =
        parseInt(appointmentStart.format('m'), 10) === 0
          ? appointmentStart.format('h') // 12
          : appointmentStart.format('h:mm'); // 12:15

      const end =
        parseInt(appointmentEnd.format('m'), 10) === 0
          ? appointmentEnd.format('ha') // 12am
          : appointmentEnd.format('h:mma'); // 12:15am

      appointmentTimeStr += `${start} - ${end}`;
    } else {
      const start =
        parseInt(appointmentStart.format('m'), 10) === 0
          ? appointmentStart.format('ha') // 12am
          : appointmentStart.format('h:mma'); // 12:15am

      appointmentTimeStr += `${start}`;
    }

    return appointmentTimeStr;
  }

  function getAppointmentTitleStr(appointment) {
    return appointment.title || appointment.notes;
  }

  function getAppointmentDetails(appointment) {
    const details = [];

    if (appointment.first_name && appointment.last_name) {
      details.push(
        <i key={'spacer'}>|</i>,
        <span
          key={'name'}
        >{`${appointment.first_name} ${appointment.last_name}`}</span>,
      );

      return details;
    }

    return null;
  }

  function isToday() {
    return currentDay.isSame(now, 'day');
  }

  function renderCurrentDay() {
    return `${isToday() ? 'Today, ' : ''} ${currentDay.format('MMM D')}`;
  }

  function renderCurrentTimeIndicator() {
    if (!isToday()) {
      return null;
    }

    const indicatorOffsetTop = getOffset(now);

    return (
      <div
        style={{ top: `${indicatorOffsetTop}px` }}
        className={theme.calendarCurrentTimeIndicator}
      />
    );
  }

  function renderAppointment(appointment, a) {
    const appointmentTimeStr = getAppointmentTimeStr(appointment);
    const appointmentTitleStr = getAppointmentTitleStr(appointment);
    const appointmentDetails = getAppointmentDetails(appointment);

    return (
      <div
        className={getAppointmentClassName(appointment)}
        style={getAppointmentStyle(appointment)}
        key={a}
      >
        <b title={appointmentTitleStr}>{appointmentTitleStr}</b>
        <span>
          {appointmentTimeStr} {appointmentDetails}
        </span>
      </div>
    );
  }

  const cells = new Array(HOURS_TO_RENDER).fill(0, 0, HOURS_TO_RENDER);

  return (
    <div
      id={coachDashboardTour.calendar.id}
      className={theme.calendarContainer}
    >
      <header>
        <div className={theme.calendarControls}>
          <i onClick={() => changeDay(-1)} />
          <i onClick={() => changeDay(1)} />
        </div>
        <span>{renderCurrentDay()}</span>
      </header>
      <div className={theme.calendarScrollContainer}>
        <div className={theme.calendarCellContainer}>
          {renderCurrentTimeIndicator()}
          {cells.map((index, i) => (
            <div
              className={theme.calendarCell}
              key={i}
              style={{ height: `${CELL_HEIGHT}px` }}
            >
              <span>{currentDay.clone().add(i, 'hours').format('h:mm')}</span>
              <div />
            </div>
          ))}
        </div>
        <div className={theme.calendarAppointmentsContainer}>
          {getCurrentDayAppointments().map((appointment, a) =>
            renderAppointment(appointment, a),
          )}
        </div>
      </div>
    </div>
  );
}

Calendar.propTypes = {
  user: PropTypes.object,
  appointments: PropTypes.array,
  getAppointments: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    appointments: state.appointments.appointments,
  };
};

export default connect(mapStateToProps, {
  getAppointments: appointmentActions.getAppointments,
})(Calendar);
