import React from 'react';
import PropTypes from 'prop-types';

import { useGetBranches } from 'features/users/user.query';
import Loader from 'components/Loader';

import Select from './Select';

const BranchSelect = React.forwardRef(({ userId, ...rest }, ref) => {
  const { data: branches, isLoading } = useGetBranches(userId, {
    enabled: !!userId,
  });
  if (isLoading) {
    return <Loader />;
  }
  const choices = branches.length
    ? branches.map(({ name, id }) => ({
        title: name,
        value: id.toString(),
      }))
    : [
        {
          title: 'No branches found',
          value: null,
        },
      ];
  return (
    <Select data-testid="branch_select" ref={ref} choices={choices} {...rest} />
  );
});

BranchSelect.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default BranchSelect;
