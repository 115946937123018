import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { isPublished } from 'helpers/treatmentPlan';
import { isClient } from 'helpers/user';
import { userAware } from 'components/UserProvider';
import { useBranch } from 'components/BranchProvider';
import { Checkbox } from 'components/Form';
import Button from 'components/Button';

import theme from './TreatmentPlanForm.sss';

const TreatmentPlanForm = ({
  user,
  treatmentPlan,
  programDocuments,
  onAcceptButtonClick,
  onRejectButtonClick,
  onMoreQuestionsClick,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [branch] = useBranch();

  return (
    <>
      <header className={theme.Header}>
        <h4 className={theme.Title}>
          {isClient(user) ? 'YOUR TREATMENT PLAN' : 'TREATMENT PLAN'}
        </h4>
      </header>
      <div className={theme.Content}>
        <div dangerouslySetInnerHTML={{ __html: treatmentPlan.body }} />
        <div className={theme.Separator}></div>
        {errors.consent && (
          <div className={theme.ConsentError}>{errors.consent?.message}</div>
        )}
        <form>
          {isPublished(treatmentPlan) && (
            <div className={theme.Consent}>
              <Checkbox
                name="consent"
                id="consent"
                ref={register({
                  required: 'Confirm that you read the treatment plan',
                })}
                value="consent"
              />
              <label htmlFor="consent" className={theme.Label}>
                Yes, I have read my treatment plan
              </label>
            </div>
          )}
          {isClient(user) && (
            <>
              <header className={theme.Documents}>
                <h4>ACNE PROGRAM PDFS</h4>
              </header>
              <ul className={theme.DocumentsList}>
                {programDocuments.map((document, i) => (
                  <li key={i}>
                    <a href={document.upload}>{document.name}</a>
                  </li>
                ))}
              </ul>
            </>
          )}
          <footer className={theme.Actions}>
            <div>
              {isClient(user) && branch.messaging_enabled && (
                <Button
                  classes={[theme.Action]}
                  color="secondary"
                  size="big"
                  onClick={onMoreQuestionsClick}
                >
                  More Questions?
                </Button>
              )}
              {isPublished(treatmentPlan) && (
                <Button
                  classes={[theme.Action]}
                  color="secondary"
                  size="big"
                  onClick={handleSubmit(() => {
                    onRejectButtonClick();
                  })}
                >
                  Not Interested
                </Button>
              )}
            </div>
            <div className={theme.MainActions}>
              {isPublished(treatmentPlan) && (
                <Button
                  classes={[theme.Action]}
                  size="big"
                  onClick={handleSubmit(() => {
                    onAcceptButtonClick();
                  })}
                >
                  I am ready to get started
                </Button>
              )}
            </div>
          </footer>
        </form>
      </div>
    </>
  );
};

TreatmentPlanForm.propTypes = {
  user: PropTypes.object,
  treatmentPlan: PropTypes.object,
  programDocuments: PropTypes.array,
  onAcceptButtonClick: PropTypes.func.isRequired,
  onRejectButtonClick: PropTypes.func.isRequired,
  onMoreQuestionsClick: PropTypes.func.isRequired,
};

TreatmentPlanForm.defaultProps = {
  programDocuments: [],
};

export default userAware(TreatmentPlanForm);
