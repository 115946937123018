import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import { DatePicker } from 'components/Form';

const DateOnly = forwardRef(
  ({ defaultValue = [], rows, onChange, ...others }, ref) => {
    function buildDefaultValue() {
      return defaultValue.map(({ row, defaultValue: date }) => ({
        row,
        text: format(date, 'MM/dd/yyyy'),
      }));
    }
    const [dates, setDates] = useState(buildDefaultValue());
    useEffect(() => {
      if (onChange) {
        onChange(dates);
      }
    }, [dates]);
    return rows.map(({ id, text }) => (
      <DatePicker
        key={`date-picker-${id}`}
        ref={ref}
        id={`date-${id}`}
        label="Date"
        helperText={text}
        defaultValue={
          defaultValue?.find(({ row }) => row === id)?.defaultValue ?? null
        }
        onChange={(date) => {
          if (date) {
            setDates([
              ...dates.filter(({ row }) => row !== id),
              {
                row: id,
                text: format(date, 'MM/dd/yyyy'),
              },
            ]);
          }
        }}
        {...others}
      />
    ));
  },
);

DateOnly.propTypes = {
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number.isRequired,
      defaultValue: PropTypes.object.isRequired,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ),
  onChange: PropTypes.func,
};

export default DateOnly;
