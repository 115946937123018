/* eslint no-use-before-define: 0 */

import axios from 'config/axios';
import Constant from 'utils/constants';

// Actions
const FETCH_NOTIFICATIONS = 'cc/NotificationList/FETCH_NOTIFICATIONS';
const FETCH_NOTIFICATIONS_SUCCEEDED = 'cc/NotificationList/FETCH_NOTIFICATIONS_SUCCEEDED';
const FETCH_NOTIFICATIONS_FAILED = 'cc/NotificationList/FETCH_NOTIFICATIONS_FAILED';
const REMOVE_NOTIFICATION = 'cc/NotificationList/REMOVE_NOTIFICATION';
const REMOVE_NOTIFICATION_SUCCEEDED = 'cc/NotificationList/REMOVE_NOTIFICATION_SUCCEEDED';
const REMOVE_NOTIFICATION_FAILED = 'cc/NotificationList/REMOVE_NOTIFICATION_FAILED';
const UNREAD_NOTIFICATION = 'cc/NotificationList/UNREAD_NOTIFICATION';
const UNREAD_NOTIFICATION_SUCCEEDED = 'cc/NotificationList/UNREAD_NOTIFICATION_SUCCEEDED';
const UNREAD_NOTIFICATION_FAILED = 'cc/NotificationList/UNREAD_NOTIFICATION_FAILED';
const READ_NOTIFICATION = 'cc/NotificationList/READ_NOTIFICATION';
const READ_NOTIFICATION_SUCCEEDED = 'cc/NotificationList/READ_NOTIFICATION_SUCCEEDED';
const READ_NOTIFICATION_FAILED = 'cc/NotificationList/READ_NOTIFICATION_FAILED';
const SET_OPEN = 'cc/NotificationList/OPEN';

const initialState = {
  loading: true,
  open: false,
  notifications: [],
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return { ...state, loading: true };
    case FETCH_NOTIFICATIONS_SUCCEEDED: {
      const { notifications } = action.payload;
      return {
        ...state,
        notifications,
        loading: false,
      };
    }
    case FETCH_NOTIFICATIONS_FAILED:
      return { ...state, loading: false };
    case REMOVE_NOTIFICATION_SUCCEEDED: {
      const { notification } = action;
      return {
        ...state,
        notifications: state.notifications.filter(({ id }) => id !== notification.id),
      };
    }
    case READ_NOTIFICATION_SUCCEEDED:
    case UNREAD_NOTIFICATION_SUCCEEDED: {
      const readNotification = action.notification;
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.id === readNotification.id) {
            return readNotification;
          }
          return notification;
        }),
      };
    }
    case SET_OPEN: {
      const { open = !state.open } = action;
      return { ...state, open };
    }
    default: return state;
  }
};

const fetchNotifications = (payload) =>
  async (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS, payload });

    try {
      const response = await axios.get('notifications/');
      const notifications = response.data.results;
      dispatch(fetchNotificationsSucceeded({ notifications }));
    } catch(error) {
      fetchNotificationsFailed({ error });
    }
  };

const fetchNotificationsSucceeded = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_SUCCEEDED, payload });
  };

const fetchNotificationsFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATIONS_FAILED, payload });
  };

const unreadNotification = (notification) =>
  async (dispatch) => {
    try {
      dispatch({ type: UNREAD_NOTIFICATION });
      const { data: newNotification } = await axios.patch(notification.url, {
        ...notification,
        state: Constant.notification.state.unread,
      });
      dispatch(unreadNotificationSucceeded(newNotification));
    } catch (error) {
      dispatch(unreadNotificationFailed({ error }));
    }
  };

const unreadNotificationSucceeded = (notification) =>
  (dispatch) => {
    dispatch({ type: UNREAD_NOTIFICATION_SUCCEEDED, notification });
  };

const unreadNotificationFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: UNREAD_NOTIFICATION_FAILED, payload });
  };

const readNotification = (notification) =>
  async (dispatch) => {
    dispatch({ type: READ_NOTIFICATION });
    try {
      const { data: newNotification } = await axios.patch(notification.url, {
        ...notification,
        state: Constant.notification.state.read,
      });
      dispatch(readNotificationSucceeded(newNotification));
    } catch (error) {
      dispatch(readNotificationFailed({ error }));
    }
  };

const readNotificationSucceeded = (notification) =>
  (dispatch) => {
    dispatch({ type: READ_NOTIFICATION_SUCCEEDED, notification });
  };

const readNotificationFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: READ_NOTIFICATION_FAILED, payload });
  };

const removeNotification = (notification) =>
  async (dispatch) => {
    try {
      dispatch({ type: REMOVE_NOTIFICATION });
      await axios.delete(notification.url);
      dispatch(removeNotificationSucceeded(notification));
    } catch (error) {
      dispatch(removeNotificationFailed({ error }));
    }
  };

const removeNotificationSucceeded = (notification) =>
  (dispatch) => {
    dispatch({ type: REMOVE_NOTIFICATION_SUCCEEDED, notification });
  };

const removeNotificationFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: REMOVE_NOTIFICATION_FAILED, payload });
  };

const setOpen = (open) =>
  (dispatch) => {
    dispatch({ type: SET_OPEN, open });
  };

export default reducer;
export {
  // Actions
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCEEDED,
  FETCH_NOTIFICATIONS_FAILED,
  SET_OPEN,
  UNREAD_NOTIFICATION,
  UNREAD_NOTIFICATION_SUCCEEDED,
  UNREAD_NOTIFICATION_FAILED,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCEEDED,
  READ_NOTIFICATION_FAILED,

  // Action Creators
  fetchNotifications,
  fetchNotificationsSucceeded,
  fetchNotificationsFailed,
  removeNotification,
  removeNotificationSucceeded,
  removeNotificationFailed,
  unreadNotification,
  unreadNotificationSucceeded,
  unreadNotificationFailed,
  readNotification,
  readNotificationSucceeded,
  readNotificationFailed,
  setOpen,
};
