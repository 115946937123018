import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TYPES } from 'helpers/note';

import * as actions from './NotesModalWidget.ducks';
import NotesModal from './components';

function NotesModalWidget({
  modalId,
  modalIsOpen,
  fetchClientProfile,
  clientId,
  loading,
  clientProfile,
  notes,
  types,
  fetchNotes,
}) {
  React.useEffect(() => {
    if (modalIsOpen) {
      fetchNotes({ clientId, types: [types[0]] });
      fetchClientProfile({ clientId });
    }
  }, [modalIsOpen, fetchNotes, clientId, types, fetchClientProfile]);

  return (
    <NotesModal
      clientName={clientProfile.full_name}
      loading={loading}
      modalId={modalId}
      notes={notes}
      types={types}
      changeFilter={(newFilter) => {
        fetchNotes({ clientId, types: [newFilter] });
      }}
    />
  );
}

NotesModalWidget.propTypes = {
  modalId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  clientId: PropTypes.number.isRequired,
  clientProfile: PropTypes.object.isRequired,
  notes: PropTypes.array.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  types: PropTypes.array.isRequired,
  fetchNotes: PropTypes.func.isRequired,
  fetchClientProfile: PropTypes.func.isRequired,
};

NotesModalWidget.defaultProps = {
  types: [TYPES.general, TYPES.consult],
};

const mapStateToProps = ({ notes, modal }, { modalId }) => ({
  ...notes,
  modalIsOpen: modal.openModalId === modalId,
  clientId: modal.modalProps.clientId || 0,
});

export default connect(mapStateToProps, actions)(NotesModalWidget);
