import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userTypeIds } from 'config/config';
import history from 'global-history';

const requireAuth = (
  WrappedComponent,
  allowedRoles = [
    userTypeIds.client,
    userTypeIds.coach,
    userTypeIds.practice_manager,
  ],
) => {
  class CheckAuthentication extends Component {
    componentDidMount() {
      const { isLoggedIn, role, isAdmin } = this.props;
      if (!isLoggedIn) {
        history.replace('/login');
      } else if (!isAdmin && !allowedRoles.includes(role)) {
        history.replace('/');
      }
    }

    render() {
      const { isLoggedIn, role, isAdmin, ...restProps } = this.props; // eslint-disable-line no-unused-vars
      return <WrappedComponent {...restProps} />;
    }
  }

  CheckAuthentication.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    role: PropTypes.number,
  };

  const mapStateToProps = ({ app, user }) => ({
    isLoggedIn: app.isLoggedIn,
    isAdmin: user.email === 'eric.garcia+admin@grillotech.com', // TODO: Remove this static string comparisson
    role: user.userType?.id,
  });

  return connect(mapStateToProps)(CheckAuthentication);
};

export default requireAuth;
