/* eslint no-use-before-define: 0 */

import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import history from 'global-history';
import axios from 'config/axios';
import Constant from 'utils/constants';
import { getErrorMessage } from 'helpers/errorHandling';

// Actions
const INITIALIZE = 'cc/ClientOrderProducts/INITIALIZE';
const INITIALIZE_SUCCESS = 'cc/ClientOrderProducts/INITIALIZE_SUCCESS';
const INITIALIZE_FAIL = 'cc/ClientOrderProducts/INITIALIZE_FAIL';
const CREATE_ORDER = 'cc/ClientOrderProducts/CREATE_ORDER';
const CREATE_ORDER_SUCCESS = 'cc/ClientOrderProducts/CREATE_ORDER_SUCCESS';
const CREATE_ORDER_FAIL = 'cc/ClientOrderProducts/CREATE_ORDER_FAIL';

const initialState = {
  loading: true,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state, loading: true };
    case INITIALIZE_SUCCESS:
    case INITIALIZE_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

// Helpers

const initialize = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  const { clientId, stageId } = payload;
  try {
    const stageOrderResponse = await axios.get(
      `programs/acne/stages/orders/?stage=${stageId}&placed_by=${clientId}`,
    );
    if (stageOrderResponse.data.results.length) {
      history.replace('/');
    }
    dispatch(initializeSuccess());
  } catch (error) {
    dispatch(initializeFail({ error }));
  }
};

const initializeSuccess = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCESS, payload });
};

const initializeFail = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  const { error } = payload;
  toast.error(
    getErrorMessage(error, 'Could not initialize the Client Order Page'),
  );
  history.replace('/');
};

const createOrder = (payload) => async (dispatch, getState) => {
  dispatch({ type: CREATE_ORDER, payload });
  const { stageId, orderedProducts } = payload;
  const { program } = getState().user;
  try {
    if (!isEmpty(orderedProducts)) {
      await axios.patch(`stages/${stageId}/`, {
        service: program.plan ? Constant.services.qboss : undefined,
        orders: [
          {
            items: Object.entries(orderedProducts).map(
              ([product, quantity]) => ({
                product,
                quantity,
              }),
            ),
          },
        ],
      });
    }
    dispatch(createOrderSuccess());
    return true;
  } catch (error) {
    dispatch(createOrderFail({ error }));
    return false;
  }
};

const createOrderSuccess = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_ORDER_SUCCESS, payload });
};

const createOrderFail = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_ORDER, payload });
  const { error } = payload;
  toast.error(getErrorMessage(error));
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_SUCCESS,
  INITIALIZE_FAIL,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  // Action Creators
  initialize,
  initializeSuccess,
  initializeFail,
  createOrder,
  createOrderSuccess,
  createOrderFail,
};
