import React from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';

import { isSelectASR } from 'helpers/question';
import { formatUpdateAnswer } from 'helpers/answer';

import Question from 'pages/NewSurvey/CompleteSurvey/Question';

import Form, {
  SingleChoice as SingleChoiceInput,
  Select,
} from 'components/Form';

const SUBCATEGORIES = {
  vertical: 'vertical',
  verticalTwoCol: 'vertical_two_col',
  horizontal: 'horiz',
  menu: 'menu',
};

const SingleChoice = ({ question, answers }) => {
  const getOther = () => {
    if (!question.text_answer) {
      return undefined;
    }
    return {
      title: question.text_answer.text,
      value: question.text_answer.id.toString(),
      type: question.text_answer.is_answer_choice ? 'choice' : 'field',
      inputType: 'text',
    };
  };
  const getOrientation = () => {
    switch (question.subcategory) {
      case SUBCATEGORIES.vertical:
        return 'vertical';
      case SUBCATEGORIES.horizontal:
        return 'horizontal';
      case SUBCATEGORIES.verticalTwoCol:
        return 'vertical_two_col';
      default:
        return 'vertical';
    }
  };
  const getDefaultValue = () => {
    const answer = answers[0];
    if (!answer) {
      return '';
    }
    if (answer.text_answer) {
      return {
        [answer.text_answer]: answer.text,
      };
    }
    return answer.choice.id.toString();
  };
  const renderInput = () => {
    switch (question.subcategory) {
      case SUBCATEGORIES.vertical:
      case SUBCATEGORIES.horizontal:
      case SUBCATEGORIES.verticalTwoCol:
        return (
          <Form.Item
            name={question.id.toString()}
            onChange={(choiceValue) => {
              let text;
              let textAnswer;
              let choice = Number(choiceValue);
              if (isObject(choiceValue)) {
                text = choiceValue[question.text_answer.id];
                textAnswer = Number(Object.keys(choiceValue)[0]);
                choice = undefined;
              }
              return [
                {
                  question: question.id,
                  choice,
                  text_answer: textAnswer,
                  text,
                },
              ];
            }}
            label={<label>{question.text}</label>}
            input={
              <SingleChoiceInput
                id={question.id.toString()}
                defaultValue={getDefaultValue()}
                choices={question.choices.map(
                  ({ text, id, followup_questions: followupQuestions }) => ({
                    title: isSelectASR(question) ? (
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    ) : (
                      text
                    ),
                    value: id.toString(),
                    followupQuestions: followupQuestions.map(
                      (followUpQuestion) => (
                        <Question
                          key={`question-${followUpQuestion.id}`}
                          question={followUpQuestion}
                        />
                      ),
                    ),
                  }),
                )}
                other={getOther()}
                orientation={getOrientation()}
              />
            }
            registerOptions={{
              required: question.is_required && 'Please select a choice',
              validate: ([answer]) => {
                if (answer.text_answer && !answer.text) {
                  return 'Please specify an answer';
                }
                return true;
              },
            }}
          />
        );
      case SUBCATEGORIES.menu:
        return (
          <Form.Item
            name={question.id.toString()}
            defaultValue={
              answers[0] ? [formatUpdateAnswer(answers[0])] : undefined
            }
            label={<label>{question.text}</label>}
            onChange={({ target: { value } }) => {
              if (!value) {
                return undefined;
              }
              return [
                {
                  question: question.id,
                  choice: Number(value),
                },
              ];
            }}
            input={
              <Select
                id={question.id.toString()}
                choices={[
                  {
                    title: 'Please select a choice',
                    value: '',
                  },
                  ...question.choices.map(({ text, id }) => ({
                    title: text,
                    value: id.toString(),
                  })),
                ]}
                defaultValue={answers[0]?.choice?.id?.toString()}
              />
            }
            registerOptions={{
              required: question.is_required && 'Please select a choice',
            }}
          />
        );
      default:
        throw new Error(`subcategory ${question.subcategory} not supported`);
    }
  };
  return renderInput();
};

SingleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array,
};

SingleChoice.defaultProps = {
  answers: [],
};

export default SingleChoice;
