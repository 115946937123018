import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
  isFetching: false,
  appointments: null,
};

const appointments = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_APPOINTMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_APPOINTMENTS_ERROR:
      return {
        ...state,
        isFetching: false,
        appointments: []
      };
    case types.GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        appointments: action.appointments,
      };
    default:
      return state;
  }
};

export default appointments;
