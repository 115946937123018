import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'config/axios';
import { surveyIds } from 'config/config';

import Loader from 'components/Loader';

import ExistingCheckinResults from './ExistingCheckinResults';
import theme from '../SurveyResults/SurveyResults.scss';

class ExistingCheckin extends Component {
  constructor(props) {
    super(props);
    const { checkin: responseId } = this.props;
    this.state = {
      answers: null,
      survey: null,
      loading: true,
      responseId,
    };
  }

  componentDidMount = () => {
    const { checkin: responseId } = this.props;
    this.getSurveyAndResponse(responseId);
  };

  componentDidUpdate = (prevProps) => {
    const { checkin } = prevProps;

    if (checkin && this.props.checkin && this.props.checkin !== checkin) {
      const { checkin: responseId } = this.props;
      this.updateSurveyAndResponse(responseId);
    }
  };

  async getSurveyAndResponse(responseId) {
    const [{ data: survey }, { data: response }] = await Promise.all([
      axios.get(`surveys/${surveyIds.checkin}/details/`),
      axios.get(`surveys/responses/${responseId}/details/`),
    ]);

    this.setState({
      responseId,
      survey,
      answers: response.answers,
      loading: false,
    });

    setTimeout(() => {
      this.props.scrollToRef('existingCheckinRef');
    });
  }

  updateSurveyAndResponse = async (responseId) => {
    this.setState({
      loading: true,
      answers: null,
      survey: null,
    });
    await this.getSurveyAndResponse(responseId);
  };

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className={theme.existingCheckinLoading}
          loading={this.state.loading}
        />
      );
    }
    return (
      this.state.survey && (
        <ExistingCheckinResults
          {...this.props}
          clientName={this.props.clientName}
          survey={this.state.survey}
          answers={this.state.answers}
          clientId={this.props.match.params.clientId}
          responseId={this.state.responseId.toString()}
        />
      )
    );
  }
}

ExistingCheckin.propTypes = {
  checkin: PropTypes.number,
  match: PropTypes.object,
  answers: PropTypes.array,
  clientName: PropTypes.string,
  survey: PropTypes.object,
  clientId: PropTypes.string,
  user: PropTypes.object,
  scrollToRef: PropTypes.func,
};

export default ExistingCheckin;
