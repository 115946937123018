import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SliderMUI from '@material-ui/core/Slider';
import classNames from 'classnames';

import theme from './Slider.sss';

const Slider = forwardRef(({ error, ...others }, ref) => (
  <div className={theme.Slider}>
    <SliderMUI
      ref={ref}
      classes={{
        root: classNames({ [theme.InputError]: error }),
      }}
      {...others}
    />
    {error && <span className={theme.ErrorText}>{error}</span>}
  </div>
));

Slider.propTypes = {
  error: PropTypes.string,
};

export default Slider;
