import { useQuery } from 'react-query';

import ClientService from 'services/client.service';

export function useGetProspects(params, options) {
  return useQuery(
    ['clients', 'prospects', params],
    () => ClientService.getProspects(params),
    options,
  );
}

export function useGetMissedCheckIn(params, options) {
  return useQuery(
    ['clients', 'missed-checkin', params],
    () => ClientService.getMissedCheckIn(params),
    options,
  );
}

export function useGetMessageHistory(clientId, params, options) {
  return useQuery(
    ['clients', clientId, 'messageHistory', params],
    () => ClientService.getMessageHistory(clientId, params),
    options,
  );
}
