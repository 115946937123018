import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import format from 'date-fns/format';

import { userAware } from 'components/UserProvider';
import Avatar from 'components/Avatar';

import theme from './Message.sss';

const Message = ({ user, message, showRecipient }) => {
  const author = showRecipient
    ? `${message.author.profile.full_name} ->
  ${message.recipient_obj.profile.full_name}`
    : message.author.profile.full_name;
  return (
    <div className={theme.Message}>
      <Avatar className={theme.Avatar} style="icon" user={message.author} />
      <div className={theme.MessageBody}>
        <header className={theme.Header}>
          <span className={theme.Author}>{author}</span>
          <div className={theme.CircleSeparator} />
          <span className={theme.Status}>
            {format(new Date(message.created), 'hh:mm a')}
            <i>
              {message.status === 'unread' && message.recipient.id === user.id
                ? '(New)'
                : ''}
            </i>
          </span>
        </header>
        <p
          className={theme.Body}
          dangerouslySetInnerHTML={{ __html: message.body }}
        />
        {message.attachment && (
          <ImageGallery
            additionalClass={theme.MessageAttachment}
            items={[
              {
                original: message.attachment.upload,
                thumbnail: message.attachment.thumb,
              },
            ]}
            showPlayButton={false}
            showThumbnails={false}
          />
        )}
      </div>
    </div>
  );
};

Message.propTypes = {
  user: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  showRecipient: PropTypes.bool,
};

Message.defaultProps = {
  showRecipient: false,
};

export default userAware(Message);
