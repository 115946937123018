/* eslint no-use-before-define: 0 */

import { useReducer } from 'react';

// Actions
const FETCH_DOCUMENTS_STARTED = 'cc/Documents/FETCH_DOCUMENTS_STARTED';
const FETCH_DOCUMENTS_SUCCESS = 'cc/Documents/FETCH_DOCUMENTS_SUCCESS';
const FETCH_DOCUMENTS_FAILURE = 'cc/Documents/FETCH_DOCUMENTS_FAILURE';
const SET_FILTER = 'cc/Documents/SET_FILTER';

const initialState = {
  loading: false,
  documents: [],
  filters: [
    { id: 1, title: 'All Documents', count: 0 },
    { id: 2, title: 'Documents', count: 0 },
    { id: 3, title: 'Surveys', count: 0 },
  ],
  activeFilterId: 1,
};

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_STARTED:
      return { ...state, loading: true };
    case FETCH_DOCUMENTS_SUCCESS: {
      const { documents } = action.payload;
      return {
        ...state,
        loading: false,
        documents,
        filters: state.filters.map((filter) => ({
          ...filter,
          count:
            filter.title === 'All Documents'
              ? documents.length
              : documents.filter(
                  (document) =>
                    filter.title
                      .toLowerCase()
                      .indexOf(document.type.toLowerCase()) > -1,
                ).length,
        })),
      };
    }
    case FETCH_DOCUMENTS_FAILURE:
      return { ...state, loading: false };
    case SET_FILTER:
      return { ...state, activeFilterId: action.filter };
    default:
      return state;
  }
};

const fetchDocumentsStarted = (payload) => ({
  type: FETCH_DOCUMENTS_STARTED,
  payload,
});

const fetchDocumentsSuccess = (payload) => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  payload,
});

const fetchDocumentsFailure = (payload) => ({
  type: FETCH_DOCUMENTS_FAILURE,
  payload,
});

const setFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

export {
  // Actions
  fetchDocumentsStarted,
  fetchDocumentsSuccess,
  fetchDocumentsFailure,
  setFilter,
  // Action Creators
  FETCH_DOCUMENTS_STARTED,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  SET_FILTER,
};

const useDocumentsReducer = () => useReducer(reducer, initialState);

export default useDocumentsReducer;
