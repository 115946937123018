/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import axios from 'config/axios';

// Actions
const FETCH_CHECKIN_FOLLOWUP = 'cc/ProspectsWidget/FETCH_CHECKIN_FOLLOWUP';
const FETCH_CHECKIN_FOLLOWUP_FAILED = 'cc/ProspectsWidget/FETCH_CHECKIN_FOLLOWUP_FAILED';
const FETCH_CHECKIN_FOLLOWUP_SUCCEEDED = 'cc/ProspectsWidget/FETCH_CHECKIN_FOLLOWUP_SUCCEEDED';

const initialState = {
  loading: false,
  checkinFollowups: [],
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHECKIN_FOLLOWUP: return { ...state, loading: true };
    case FETCH_CHECKIN_FOLLOWUP_SUCCEEDED: {
      const { checkinFollowups } = action.payload;
      return {...state, checkinFollowups, loading: false };
    }
    case FETCH_CHECKIN_FOLLOWUP_FAILED:
      return {...state, loading: false};
    default: return state;
  }
};

const fetchCheckinFollowup = (payload) =>
  async (dispatch) => {
    dispatch({ type: FETCH_CHECKIN_FOLLOWUP, payload });
    try {
      const { data: checkinFollowups } = await axios.get('users/coaches/checkin-followup/');
      dispatch(fetchCheckinFollowupSuceeded({ checkinFollowups }));
    } catch(error) {
      dispatch(fetchCheckinFollowupFailed({ error }));
    }
  };


const fetchCheckinFollowupSuceeded = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_CHECKIN_FOLLOWUP_SUCCEEDED, payload });
  };

const fetchCheckinFollowupFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_CHECKIN_FOLLOWUP_FAILED, payload });
    const { error } = payload;
    toast.error(error.response?.data?.message || error.message || 'Could not load the prospects');
  };

export default reducer;
export {
  // Actions
  FETCH_CHECKIN_FOLLOWUP,
  FETCH_CHECKIN_FOLLOWUP_SUCCEEDED,
  FETCH_CHECKIN_FOLLOWUP_FAILED,

  // Action Creators
  fetchCheckinFollowup,
  fetchCheckinFollowupSuceeded,
  fetchCheckinFollowupFailed,
};
