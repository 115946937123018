import axios from 'config/axios';

export default class UserService {
  static async get(userId) {
    const { data } = await axios.get(`users/${userId}/`);
    return data;
  }

  static async getProfile(userId) {
    const { data } = await axios.get(`users/${userId}/profile/`);
    return data;
  }

  static async getNextAppointment(userId) {
    const { data } = await axios.get(`users/${userId}/next-appointment/`);
    return data;
  }

  static async getLatestHACAppointment(userId) {
    const { data } = await axios.get(`users/${userId}/latest-hac-appointment/`);
    return data;
  }

  static async getNextAcnePeelAppointment(userId) {
    const { data } = await axios.get(
      `users/${userId}/next-acne-peel-appointment/`,
    );
    return data;
  }

  static async getBranches(userId) {
    const { data } = await axios.get(`users/${userId}/branches/`);
    return data;
  }

  static async getActiveClients(params = {}) {
    const { data } = await axios.get('users/clients/active/', { params });
    return data;
  }

  static async getActiveProgramClients(params = {}) {
    const { data } = await axios.get('users/clients/active-program/', {
      params,
    });
    return data;
  }

  static async getStartedProgramClients(params = {}) {
    const { data } = await axios.get('users/clients/started-program/', {
      params,
    });
    return data;
  }

  static async getDeclinedClients(params = {}) {
    const { data } = await axios.get('users/clients/declined/', {
      params,
    });
    return data;
  }

  static async getGraduatedClients(params = {}) {
    const { data } = await axios.get('users/clients/graduated/', {
      params,
    });
    return data;
  }

  static async getMaintenanceClients(params = {}) {
    const { data } = await axios.get('users/clients/maintenance/', {
      params,
    });
    return data;
  }

  static async getQuitClients(params = {}) {
    const { data } = await axios.get('users/clients/quit/', {
      params,
    });
    return data;
  }

  static async getNewClients(params = {}) {
    const { data } = await axios.get('users/clients/new/', {
      params,
    });
    return data;
  }

  static async getNoConsultClients(params = {}) {
    const { data } = await axios.get('users/clients/no-consult/', {
      params,
    });
    return data;
  }

  static async getCoaches() {
    const { data } = await axios.get('users/coaches/');
    return data.results;
  }

  static async getClient(userId) {
    const { data } = await axios.get(`users/clients/${userId}/`);
    return data;
  }

  static async getClientProgramPlanPaid(userId) {
    const { data } = await axios.get(
      `users/clients/${userId}/program-plan-paid/`,
    );
    return data.program_plan_paid;
  }

  static async setCoach(userId, coachId) {
    const { data } = await axios.post(`users/clients/${userId}/set-coach/`, {
      coach: coachId,
    });
    return data;
  }

  static async add({ email, firstName, lastName, branchId, coachId, groups }) {
    const { data } = await axios.post('users/', {
      email,
      profile: {
        first_name: firstName,
        last_name: lastName,
      },
      branches: [branchId],
      coach: coachId,
      groups,
    });
    return data;
  }

  static async update(userId, payload) {
    const { data } = await axios.patch(`users/${userId}/`, payload);
    return data;
  }
}
