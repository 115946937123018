/* eslint no-use-before-define: 0 */

import { RSAA } from 'redux-api-middleware';

import { apiBaseUrl as api, collectorIds } from 'config/config';
import * as types from './actionTypes';

const toggleSingleChoice = (questionId, choice) => {
  return {
    type: types.TOGGLE_SINGLE_CHOICE,
    questionId,
    choice,
  };
};

const addQuestion = (question) => {
  return {
    type: types.ADD_QUESTION,
    question,
  };
};

const checkForGender = (answer) => {
  if (answer.question === `${api}surveys/questions/2/`) {
    return function (dispatch) {
      dispatch(setGender(answer));
    };
  }
  return {
    type: types.NO_ACTION,
  };
};

const clearReduxSurvey = () => {
  return {
    type: types.CLEAR_REDUX_SURVEY,
  };
};

const createNewSurveyResponse = (collector) => {
  return function (dispatch) {
    dispatch({
      [RSAA]: {
        endpoint: `${api}surveys/responses/`,
        method: 'POST',
        types: types.MIDDLEWARE_TYPES,
        body: JSON.stringify({
          collector,
        }),
      },
    })
      .then((result) => {
        const { type, payload } = result;
        if (type === types.FAILURE) {
          throw new Error(payload);
        }
        dispatch({
          type: types.SET_SURVEY_RESPONSE_SUCCESS,
          response: payload.url,
        });
      })
      .catch(() => {
        dispatch({ type: types.SET_SURVEY_RESPONSE_ERROR });
      });
  };
};

const getSurveyTypes = (jwt) => {
  return function (dispatch) {
    dispatch({ type: types.GET_SURVEY_TYPES });
    dispatch({
      [RSAA]: {
        endpoint: `${api}surveys/`,
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
        method: 'GET',
        types: types.MIDDLEWARE_TYPES,
      },
    })
      .then((result) => {
        const { type, payload } = result;
        if (type === types.FAILURE) {
          throw new Error(payload);
        }
        dispatch({
          type: types.SET_SURVEY_TYPES,
          types: payload.results,
        });
      })
      .catch((error) => {
        dispatch({ type: types.GET_SURVEY_TYPES_ERROR, error });
      });
  };
};

const getSurveyAnswers = (responseUrl) => (dispatch, getState) => {
  const questionsFlat = getState().survey.surveyQuestions;
  dispatch({
    type: types.GET_SURVEY_ANSWERS,
  });
  dispatch({
    [RSAA]: {
      endpoint: responseUrl + 'details/',
      method: 'GET',
      types: types.MIDDLEWARE_TYPES,
    },
  })
    .then((result) => {
      const { type, payload } = result;
      if (type === types.FAILURE) {
        throw new Error(payload);
      }
      const surveyQuestions = questionsFlat.map((q) => {
        const answers = payload.answers.filter((a) => a.question.id === q.id);
        return {
          ...q,
          answers,
        };
      });
      dispatch(setSurveyQuestions(surveyQuestions));
      dispatch(setSurveyAnswers(payload.answers));
    })
    .catch(() => {
      dispatch({ type: types.GET_SURVEY_ANSWERS_ERROR });
    });
};

const removeQuestion = (questionId) => {
  return {
    type: types.REMOVE_QUESTION,
    questionId,
  };
};

const registerPage = (page) => {
  return function (dispatch) {
    dispatch({ type: types.REGISTER_PAGE });
    page.questions.forEach((question) => dispatch(addQuestion(question)));
  };
};

const setGender = (answer) => {
  const gender = answer.choice === 1 ? 'male' : 'female';
  return {
    type: types.SET_GENDER,
    gender,
  };
};

const setSurveyAnswers = (ans) => {
  return function (dispatch) {
    const answers = {};
    ans.forEach((a) => {
      dispatch(checkForGender(a));
      if (answers[a.question]) {
        // prop already exists for answers to this question, combine here
        answers[a.question] = [...answers[a.question], a];
      } else {
        // prop doesn't exist yet, create here
        answers[a.question] = [a];
      }
    });
    dispatch({
      type: types.SET_SURVEY_ANSWERS,
      answers,
    });
  };
};

const setSurveyQuestions = (questions) => {
  // FIXME: This is definitely WRONG, Implement Redux should be
  // eslint-disable-next-line no-param-reassign
  questions = questions.map((q) => {
    q.answers = q.answers || [];
    return q;
  });
  return {
    type: types.SET_SURVEY_QUESTIONS,
    questions,
  };
};

// TODO: This can be removed
const setHQSurveyResponse = (userId) => {
  return function (dispatch) {
    dispatch({ type: types.SET_SURVEY_RESPONSE });
    dispatch({
      [RSAA]: {
        endpoint: `${api}surveys/responses/?user=${userId}`,
        method: 'GET',
        types: types.MIDDLEWARE_TYPES,
      },
    })
      .then((result) => {
        const { type, payload } = result;
        if (type === types.FAILURE) {
          throw new Error(payload);
        }
        const hqResponse = payload.results.filter(
          (response) => response.collector === collectorIds.questionnaire,
        )[0];
        if (hqResponse) {
          // response object already exists, doesn't need to be created
          dispatch({
            type: types.SET_SURVEY_RESPONSE_SUCCESS,
            response: hqResponse.id,
          });
          // check for any existing answers
          dispatch(
            getSurveyAnswers(`${api}surveys/responses/${hqResponse.id}/`),
          );
        } else {
          dispatch(createNewSurveyResponse(collectorIds.questionnaire));
        }
      })
      .catch(() => {
        dispatch({ type: types.SET_SURVEY_RESPONSE_ERROR });
      });
  };
};

// TODO: This can be removed
const setCheckinSurveyResponse = (userId) => {
  return function (dispatch) {
    dispatch({ type: types.SET_SURVEY_RESPONSE });
    dispatch({
      [RSAA]: {
        endpoint: `${api}surveys/responses/?user=${userId}`,
        method: 'GET',
        types: types.MIDDLEWARE_TYPES,
      },
    })
      .then((result) => {
        const { type, payload } = result;
        if (type === types.FAILURE) {
          throw new Error(payload);
        }
        const checkinResponse = payload.results.filter(
          (response) =>
            response.collector === collectorIds.checkin &&
            response.state !== 'completed',
        )[0];
        if (checkinResponse) {
          // response object already exists, doesn't need to be created
          dispatch({
            type: types.SET_SURVEY_RESPONSE_SUCCESS,
            response: checkinResponse.url,
          });
          // check for any existing answers
          dispatch(getSurveyAnswers(checkinResponse.url));
        } else {
          dispatch(createNewSurveyResponse(collectorIds.checkin));
        }
      })
      .catch(() => {
        dispatch({ type: types.SET_SURVEY_RESPONSE_ERROR });
      });
  };
};

const setValidationErrors = (errors) => {
  return {
    type: types.SET_SURVEY_VALIDATION_ERRORS,
    errors,
  };
};

const toggleMultipleChoice = (questionId, answers) => {
  return {
    type: types.TOGGLE_MULTIPLE_CHOICE,
    questionId,
    answers,
  };
};

const updateOpenEnded = (questionId, answers) => {
  return {
    type: types.UPDATE_OPEN_ENDED,
    questionId,
    answers,
  };
};

const updateQuestionText = (questionId, text) => {
  return {
    type: types.UPDATE_QUESTION_TEXT,
    questionId,
    text,
  };
};

const updateRows = (questionId, rows) => {
  return {
    type: types.UPDATE_ROWS,
    questionId,
    rows,
  };
};

const updateMatrixRows = (questionId, rows) => {
  return {
    type: types.UPDATE_MATRIX_ROWS,
    questionId,
    rows,
  };
};

const replaceSurveyAnswer = (questionUrl, answer) => {
  return {
    type: types.UPDATE_SURVEY_ANSWER,
    questionUrl,
    answers: answer,
  };
};

const updateSurveyAnswers = (questions) => {
  return function (dispatch) {
    questions.forEach((question) => {
      dispatch({
        type: types.UPDATE_SURVEY_ANSWER,
        questionUrl: question.url,
        answers: question.answers,
      });
      if (question.url === `${api}surveys/questions/2/`) {
        dispatch(setGender(question.answers[0]));
      }
    });
  };
};

const updateTextAnswer = (questionId, textAnswer) => {
  return {
    type: types.UPDATE_TEXT_ANSWER,
    questionId,
    textAnswer,
  };
};

export {
  getSurveyAnswers,
  toggleSingleChoice,
  addQuestion,
  checkForGender,
  clearReduxSurvey,
  createNewSurveyResponse,
  getSurveyTypes,
  removeQuestion,
  registerPage,
  setGender,
  setSurveyAnswers,
  setSurveyQuestions,
  setHQSurveyResponse,
  setCheckinSurveyResponse,
  setValidationErrors,
  toggleMultipleChoice,
  updateOpenEnded,
  updateQuestionText,
  updateRows,
  updateMatrixRows,
  replaceSurveyAnswer,
  updateSurveyAnswers,
  updateTextAnswer,
};
