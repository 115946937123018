import React from 'react';
import PropTypes from 'prop-types';

export default function Sidebar({ theme, details }) {
  return (
    <div className={theme.sidebarContainer}>
      <header>
        <h1>Details</h1>
      </header>
      <div className={theme.sidebarDetails}>
        <h3>About</h3>
        <p>Time Zone: {details.time_zone || 'Not Given'}</p>
        <p>Gender: {details.gender || 'Not Given'}</p>
        {/* <p>Parent: {details.parent || 'Not Given'}</p> */}
        {details.parent &&
          <p>{details.parent}</p>
        }
      </div>
      <div className={theme.sidebarDetails}>
        <h3>Billing Address</h3>
        <p>
          {details.billingAddress ?
            `${details.billingAddress.street1} ${details.billingAddress.street2}` :
            'Not Given'
          }
        </p>
        <p>
          {details.billingAddress ?
            `${details.billingAddress.city}, ${details.billingAddress.state} ${details.billingAddress.postal_code}` :
            'Not Given'
          }
        </p>
        <p>{details.billingAddress ? `${details.billingAddress.country}` : 'Not Given'}</p>
      </div>
      <div className={theme.sidebarDetails}>
        <h3>Shipping Address</h3>
        <p>
          {details.shippingAddress ?
            `${details.shippingAddress.street1} ${details.shippingAddress.street2}` :
            'Not Given'
          }
        </p>
        <p>
          {details.shippingAddress ?
            `${details.shippingAddress.city}, ${details.shippingAddress.state} ${details.shippingAddress.postal_code}` :
            'Not Given'
          }
        </p>
        <p>
          {details.shippingAddress ?
            `${details.shippingAddress.country}` :
            'Not Given'
          }
        </p>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  details: PropTypes.object,
  theme: PropTypes.object,
};
