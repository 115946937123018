import React from 'react';

import Constant from 'utils/constants';
import { managerDashboardTour } from 'config/tour';
import strings from 'assets/strings';
import { useLoggedInUser } from 'hooks';
import { useBranch } from 'components/BranchProvider';
import TaskListWidget from 'containers/TaskListWidget';
import ProspectsWidget from 'containers/ProspectsWidget';
import CheckInFollowupWidget from 'containers/CheckInFollowupWidget';
import UpcomingCheckInWidget from 'containers/UpcomingCheckInWidget';

import theme from './ManagerDashboard.scss';

const ManagerDashboard = () => {
  const user = useLoggedInUser();
  const [branch] = useBranch();
  return (
    <div className={theme.dashboardRoot}>
      <div className={theme.dashboardContentContainer}>
        <div className={theme.dashboardGreeting}>
          {strings.greet(user.profile.first_name || user.email)}
        </div>
        <div id={managerDashboardTour.tasks.id} className={theme.dashboardRow}>
          <TaskListWidget
            id="ManagerDashboard-MyTasks"
            actions={Object.values(Constant.task.action).filter(
              (action) =>
                ![
                  Constant.task.action.completeAcneProgram,
                  Constant.task.action.noop,
                ].includes(action),
            )}
          />
        </div>
        <div className={theme.dashboardRow}>
          <CheckInFollowupWidget />
        </div>
        {branch.show_prospects && (
          <div className={theme.dashboardRow}>
            <ProspectsWidget />
          </div>
        )}
        <div className={theme.dashboardRow}>
          <UpcomingCheckInWidget />
        </div>
      </div>
    </div>
  );
};

export default ManagerDashboard;
