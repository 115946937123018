import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Form, { SingleChoice } from 'components/Form';
import Button from 'components/Button';

const MaintenancePlanForm = ({ maintenancePlans, defaultValues, onSubmit }) => (
  <Form
    defaultValues={defaultValues}
    onSubmit={({ maintenancePlan }) => {
      onSubmit(
        maintenancePlans.find(({ id }) => id === parseInt(maintenancePlan, 10)),
      );
    }}
    title="Select a maintenance plan"
    description="An invoice will be sent to your client"
  >
    {() => (
      <>
        <Form.Row>
          <Form.Item
            name="maintenancePlan"
            label={<label>Maintenance Plan:</label>}
            input={
              <SingleChoice
                choices={maintenancePlans.map(({ name: title, id: value }) => ({
                  title,
                  value: `${value}`,
                }))}
              />
            }
            registerOptions={{
              required: 'Please select a maintenance plan',
            }}
          />
        </Form.Row>
        <Form.Actions>
          <Button size="big" type="submit">
            Next
          </Button>
        </Form.Actions>
      </>
    )}
  </Form>
);

MaintenancePlanForm.propTypes = {
  maintenancePlans: PropTypes.array.isRequired,
  defaultValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({
  maintenancePlans: app.maintenancePlans,
});

export default connect(mapStateToProps)(MaintenancePlanForm);
