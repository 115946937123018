import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import formatDistance from 'date-fns/formatDistance';
import { useQueryClient } from 'react-query';

import { getIdFromUrl } from 'helpers/formatHelpers';

import Task from 'components/Task';

const SelectASRWidget = ({ task }) => {
  const queryClient = useQueryClient();
  const userId = getIdFromUrl(task.linked_user);
  const {
    check_in_id: checkInId,
    survey_id: surveyId,
    client_name: clientName,
  } = task.context;
  const title = (
    <p>
      {/* eslint-disable-next-line camelcase */}
      Select <Link to={`/client-details/${userId}`}>{clientName}</Link> ASR
    </p>
  );
  const subtitle = `Check-in Completed: ${formatDistance(
    new Date(task.created),
    new Date(),
  )}`;
  return (
    <Task
      allowNote
      task={task}
      title={title}
      subtitle={subtitle}
      action="View Checkin"
      link={`/client-details/${userId}/surveys/${surveyId}/${checkInId}/`}
      extraDataCalls={{
        user: `users/${userId}/profile/`,
      }}
      onSaveNote={() => {
        queryClient.invalidateQueries(['tasks']);
      }}
    />
  );
};

SelectASRWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

export default SelectASRWidget;
