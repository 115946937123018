/* eslint no-use-before-define: 0 */

import axios from 'config/axios';
import { PAGE_SIZE } from 'config/config';

// Actions
const FETCH_CLIENTS = 'cc/QUIT_CLIENTS/FETCH_CLIENTS';
const FETCH_CLIENTS_SUCCEEDED = 'cc/QUIT_CLIENTS/FETCH_CLIENTS_SUCCEEDED';
const FETCH_CLIENTS_FAILED = 'cc/QUIT_CLIENTS/FETCH_CLIENTS_FAILED';

const initialState = {
  loading: false,
  clients: [],
  total: 0,
  query: '',
};

// Reducer
// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS: {
      const { query } = action.payload;
      return { ...state, query, loading: true };
    }
    case FETCH_CLIENTS_SUCCEEDED: {
      const { clients, total } = action.payload;
      return { ...state, loading: false, clients, total };
    }
    case FETCH_CLIENTS_FAILED: return { ...state, loading: false };
    default: return state;
  }
};

// Action Creators

const fetchClients = (payload) =>
  async (dispatch) => {
    dispatch({ type: FETCH_CLIENTS, payload });
    const { coachId, query = '', page } = payload;
    const offset = (page - 1) * PAGE_SIZE;
    const params = {
      offset,
    };
    if (coachId) {
      params.coach = coachId;
    }
    if (query.length) {
      params.query = query;
    }
    try {
      const response = await axios.get('users/clients/quit/', { params });
      dispatch(fetchClientsSucceeded({
        clients: response.data.results,
        total: response.data.count,
      }));
    } catch(error) {
      fetchClientsFailed({ error });
    }
  };

const fetchClientsSucceeded = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_CLIENTS_SUCCEEDED, payload });
  };

const fetchClientsFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_CLIENTS_FAILED, payload });
  };

export default reducer;
export {
  // Actions
  FETCH_CLIENTS,
  FETCH_CLIENTS_SUCCEEDED,
  FETCH_CLIENTS_FAILED,

  // Action Creators
  fetchClients,
  fetchClientsSucceeded,
  fetchClientsFailed,
};
