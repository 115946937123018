/**
 *
 * @param {Array} products the products to sort alphabetically by their name
 * @returns {Array} the sorted products
 */
export function sortByAlphabeticalOrder(products) {
  return [...products].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}
