import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from 'components/Loader';
import * as modalActions from 'components/Modal/Modal.ducks';
import {
  SendTreatmentPlanModal,
  CreateTreatmentPlanForm,
} from './components';
import * as actions from './CreateTreatmentPlan.ducks';
import theme from './CreateTreatmentPlan.sss';

class CreateTreatmentPlan extends Component {
  componentDidMount() {
    const { initialize } = this.props;
    const { clientId } = this.props.match.params;
    initialize({ clientId });
  }

  SEND_TREATMENT_PLAN_MODAL_ID = 'CreateTreatmentPlan/SendTreatmentPlanModal';

  openSendTreatmentPlanModal = ( body ) => {
    const { setOpenModalId } = this.props;
    setOpenModalId(this.SEND_TREATMENT_PLAN_MODAL_ID, body);
  }

  render() {
    const {
      profile,
      loading,
      treatmentPlan,
      programPlans,
      saveAsDraft,
      send,
    } = this.props;
    if (loading) {
      return <Loader loading={loading} />;
    }
    return (
      <div className={theme.CreateTreatmentPlan}>
        <CreateTreatmentPlanForm
          profile={profile}
          save={saveAsDraft}
          treatmentPlan={treatmentPlan}
          send={this.openSendTreatmentPlanModal}
        />
        <SendTreatmentPlanModal
          profile={profile}
          modalId={this.SEND_TREATMENT_PLAN_MODAL_ID}
          planOptions={programPlans}
          onSubmit={async (values) => {
            const succeeded = await send(values);
            return succeeded;
          }}
        />
      </div>
    );
  }
}

CreateTreatmentPlan.propTypes = {
  match: PropTypes.object,
  loading: PropTypes.bool,
  profile: PropTypes.object,
  treatmentPlan: PropTypes.object,
  programPlans: PropTypes.array.isRequired,
  initialize: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
  saveAsDraft: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,

};

const mapStateToProps = ({ createTreatmentPlan, app }) => ({
  ...createTreatmentPlan,
  programPlans: app.programPlans,
});

export default withRouter(connect(mapStateToProps, {
  ...actions,
  setOpenModalId: modalActions.setOpenModalId,
})(CreateTreatmentPlan));
