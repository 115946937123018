/* eslint max-len: 0 */
import { isClient } from 'helpers/user';

/**
 * See Step properties of react-joyride package
 * https://docs.react-joyride.com/step
 */

const commonTour = {
  notifications: {
    id: 'NotificationsTour',
    joyrideStepProps: (...props) => ({
      target: '#NotificationsTour',
      title: 'Your Notifications',
      content: 'Get informed!',
      ...props,
    }),
  },
  inbox: {
    id: 'InboxTour',
    joyrideStepProps: ({ user, ...others }) => ({
      target: '#InboxTour',
      title: 'Your Inbox',
      content: isClient(user)
        ? "Receive and send messages from your Clear Skin Specialist here. Don't forget to check this often!"
        : "Receive and send messages from all your clients here. Don't forget to check this often!",
      ...others,
    }),
  },
  documents: {
    id: 'DocumentsTour',
    joyrideStepProps: ({ user, ...others }) => ({
      target: '#DocumentsTour',
      title: isClient(user) ? 'Your Homework' : 'NAC Documents',
      content: isClient(user)
        ? "Here is where you download your list of acne triggering foods, pore clogger list, and other reference materials discussed during your consultation. Don't forget to print these out!"
        : 'Download Natural Acne Clinic Documents',
      ...others,
    }),
  },
  tasks: {
    id: 'TasksTour',
    joyrideStepProps: ({ user, ...others }) => ({
      target: '#TasksTour',
      title: 'Your Tasks',
      content: isClient(user)
        ? 'Every 2-weeks you will perform a check-in and we will remind you here 🙂'
        : 'Here is where you keep track of the tasks you need to perform for a client',
      ...others,
    }),
  },
};

export const clientDashboardTour = {
  ...commonTour,
  regimens: {
    id: 'RegimensTour',
    joyrideStepProps: (...props) => ({
      target: '#RegimensTour',
      title: 'Your Regimens',
      content:
        'Your bi-weekly regimens will appear here from your Clear Skin Specialist. After submitting your check-in, be sure to check back here for your new skincare and supplement routine! Remember it is these changes to your regimen that keep your skin improving!',
      ...props,
    }),
  },
  hq: {
    id: 'HQTour',
    joyrideStepProps: (...props) => ({
      target: '#HQTour',
      title: 'Your Health Questionnaire',
      content: 'See the Health Questionnaire you submitted to us',
      ...props,
    }),
  },
  checkins: {
    id: 'CheckinsTour',
    joyrideStepProps: (...props) => ({
      target: '#CheckinsTour',
      title: 'Your Check-ins',
      content:
        'Click here to view your previous check-ins and track your progress by viewing your uploaded photos.',
      ...props,
    }),
  },
  treatmentPlan: {
    id: 'TreatmentPlanTour',
    joyrideStepProps: (...props) => ({
      target: '#TreatmentPlanTour',
      title: 'Your Treatment Plan',
      content:
        'See the Treatment Plan your Clear Skin Specialist has created for you',
      ...props,
    }),
  },
};

export const coachDashboardTour = {
  ...commonTour,
  upcomingConsultationTasks: {
    id: 'UpcomingConsultationTasksTour',
    joyrideStepProps: (...props) => ({
      target: '#UpcomingConsultationTasksTour',
      title: 'Your Upcoming Consultation Tasks',
      content:
        'See all your incoming clients and make sure you create their treatment plans',
      ...props,
    }),
  },
  prospects: {
    id: 'ProspectsTour',
    joyrideStepProps: (...props) => ({
      target: '#ProspectsTour',
      title: 'Your Prospects',
      content:
        'Highlight the prospects to make sure they join us in the revolution of acne!',
      ...props,
    }),
  },
  calendar: {
    id: 'CalendarTour',
    joyrideStepProps: (...props) => ({
      target: '#CalendarTour',
      title: 'Your Calendar',
      content: 'See your today appointments. Never ever miss an appointment!',
      ...props,
    }),
  },
};

export const managerDashboardTour = {
  ...commonTour,
};
