/* eslint-disable max-len */

import React from 'react';

const TruckIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 3 5 C 1.895 5 1 5.895 1 7 L 1 18 L 3 18 C 3 19.657 4.343 21 6 21 C 7.657 21 9 19.657 9 18 L 14 18 L 15 18 C 15 19.657 16.343 21 18 21 C 19.657 21 21 19.657 21 18 L 22 18 L 23 18 L 23 13 L 20.275391 7.5527344 C 20.106391 7.2137344 19.761813 7 19.382812 7 L 16 7 L 16 5 L 3 5 z M 3 7 L 14 7 L 14 11 L 14 13 L 14 16 L 8.2207031 16 C 7.6716274 15.390667 6.8849156 15 6 15 C 5.1150844 15 4.3283726 15.390667 3.7792969 16 L 3 16 L 3 7 z M 16 9 L 18.763672 9 L 19.763672 11 L 16 11 L 16 9 z M 16 13 L 20.763672 13 L 21 13.472656 L 21 14 L 21 15.357422 L 21 16 L 20.220703 16 C 19.671627 15.390667 18.884916 15 18 15 C 17.227916 15 16.53164 15.300231 16 15.779297 L 16 14.537109 L 16 14 L 16 13 z M 6 16.75 C 6.689 16.75 7.25 17.311 7.25 18 C 7.25 18.689 6.689 19.25 6 19.25 C 5.311 19.25 4.75 18.689 4.75 18 C 4.75 17.311 5.311 16.75 6 16.75 z M 18 16.75 C 18.689 16.75 19.25 17.311 19.25 18 C 19.25 18.689 18.689 19.25 18 19.25 C 17.311 19.25 16.75 18.689 16.75 18 C 16.75 17.311 17.311 16.75 18 16.75 z" />
  </svg>
);

TruckIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default TruckIcon;
