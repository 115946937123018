/* eslint-disable max-len */

import React from 'react';

const AttachIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 11 1 C 8.8026661 1 7 2.8026661 7 5 L 7 17.5 C 7 20.525577 9.4744232 23 12.5 23 C 15.525577 23 18 20.525577 18 17.5 L 18 6 L 16 6 L 16 17.5 C 16 19.444423 14.444423 21 12.5 21 C 10.555577 21 9 19.444423 9 17.5 L 9 5 C 9 3.8833339 9.8833339 3 11 3 C 12.116666 3 13 3.8833339 13 5 L 13 15.5 C 13 15.786937 12.786937 16 12.5 16 C 12.213063 16 12 15.786937 12 15.5 L 12 6 L 10 6 L 10 15.5 C 10 16.869063 11.130937 18 12.5 18 C 13.869063 18 15 16.869063 15 15.5 L 15 5 C 15 2.8026661 13.197334 1 11 1 z" />
  </svg>
);

AttachIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default AttachIcon;
