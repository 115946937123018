import isEmpty from 'lodash/isEmpty';

import { apiBaseUrl, userTypeIds } from 'config/config';

import { isCompleted, isMaintenance } from './program';

export const isCoach = (user) =>
  user.userType?.id === userTypeIds.coach ||
  (user.groups && user.groups[0]?.id === userTypeIds.coach);

export const isClient = (user) =>
  user.userType?.id === userTypeIds.client ||
  (user.groups && user.groups[0]?.id === userTypeIds.client);

export const isPracticeManager = (user) =>
  user.userType?.id === userTypeIds.practice_manager ||
  (user.groups && user.groups[0]?.id === userTypeIds.practice_manager);

export const url = ({ id }) => `${apiBaseUrl}users/${id}/`;

export const isActiveProgram = ({ program }) =>
  isEmpty(program) || program?.is_active; // eslint-disable-line camelcase

export const requireMaintenancePlan = (user) =>
  isCompleted(user.program) && !user.program.grace_period_active;

export const requireMaintenancePayment = (user) =>
  isMaintenance(user.program) && user.program.maintenance_plan_payment?.is_due;

export const isGracePeriodActive = (user) =>
  isCompleted(user.program) && user.program.grace_period_active;

export function getUserPosition(recipient) {
  if (isCoach(recipient)) {
    return 'Coach';
  }
  if (isPracticeManager(recipient)) {
    return 'Manager';
  }
  return '';
}
