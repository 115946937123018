import React from 'react';
import { isEmpty } from 'lodash';

import { white01, turquoise01 } from 'styles/colors.sss';
import { CloseIcon } from 'components/Icon';

import { useComposeMessage } from './ComposeMessage';

import theme from './AttachmentPreview.sss';

const AttachmentPreview = () => {
  const {
    attachment,
    setAttachment,
    setMessagePlaceholder,
  } = useComposeMessage();
  if (isEmpty(attachment)) {
    return null;
  }
  return (
    <div className={theme.AttachmentPreview}>
      <CloseIcon
        className={theme.CloseAttachment}
        fill={white01}
        width="32"
        height="32"
        style={{
          backgroundColor: turquoise01,
        }}
        onClick={() => {
          setAttachment({});
          setMessagePlaceholder('Start the conversation here');
        }}
      />
      <img className={theme.ImagePreview} src={attachment.preview} />
    </div>
  );
};

export default AttachmentPreview;
