import React from 'react';
import PropTypes from 'prop-types';

import { useBranch } from 'components/BranchProvider';
import { QuantityControl } from 'components/Form';

import theme from './StepOptions.scss';

const StepOptions = ({
  product,
  qty,
  options,
  maxQuantity,
  onChange,
  onQuantityChange,
}) => {
  const [branch] = useBranch();
  return (
    <div className={theme.StepOptions}>
      <select
        value={product}
        onChange={(ev) => {
          const selectedOption = options.find(
            (option) => option.product.id === parseInt(ev.target.value, 10),
          );
          onChange(selectedOption);
        }}
      >
        {options.map((option, i) => (
          <option key={i} value={option.product.id}>
            {option.product.name}
          </option>
        ))}
      </select>
      {options.length > 0 && (
        <div className={theme.StepQty}>
          {product !== -1 && branch.coach_order_products_enabled && (
            <QuantityControl
              value={qty}
              max={maxQuantity}
              onChange={onQuantityChange}
            />
          )}
        </div>
      )}
    </div>
  );
};

StepOptions.propTypes = {
  product: PropTypes.number,
  qty: PropTypes.number,
  options: PropTypes.array,
  maxQuantity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
};

StepOptions.defaultProps = {
  options: [],
};

export default StepOptions;
