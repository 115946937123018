/* eslint-disable max-len */

import React from 'react';

const CameraIcon = (props) => (
  <svg viewBox="0 0 18 16" {...props}>
    <path d="M18.2602,1.7796 C18.200044,1.639756 18.120356,1.49992 18.02036,1.39992 C17.860204,1.239764 17.64068,1.12024 17.40004,1.09992 C16.9602,1.060076 15.77972,1.76008 15.10004,2.1796 C14.13988,2.7796 12.70004,3.79992 11.26024,5.2398 L8.12044,8.3796 C7.72044,8.7796 7.72044,9.43976 8.12044,9.83976 L8.140752,9.860072 L3.820352,14.139672 C3.420352,14.539672 3.420352,15.199832 3.820352,15.599832 C4.220352,15.999832 4.880512,15.999832 5.280512,15.599832 L18.020312,2.860032 C18.299992,2.579572 18.379692,2.160032 18.260152,1.779572 L18.2602,1.7796 Z" />
    <path d="M2.8796,7.8204 L2.919444,7.860244 L2.919444,7.880556 C2.9202252,7.860244 2.8999128,7.839932 2.8796,7.8204 Z" />
    <path d="M12.3,9.4602 L10.83984,10.92036 L15.56024,15.64076 C15.96024,16.04076 16.6204,16.04076 17.0204,15.64076 C17.4204,15.24076 17.4204,14.5806 17.0204,14.1806 L12.3,9.4602 Z" />
    <path d="M4.3796,0.1796 L7.3398,3.1398 C7.79996,3.6398 7.96012,4.3398 7.79996,4.96012 L9.17964,6.3398 L7.7398,7.77964 L6.36012,6.39996 C5.7398,6.560116 5.0398,6.39996 4.5398,5.9398 L1.5796,2.9796 C1.419444,2.819444 1.419444,2.5796 1.5796,2.43976 C1.739756,2.279604 1.9796,2.279604 2.11944,2.43976 L5.03984,5.36016 C5.199996,5.520316 5.43984,5.56016 5.6,5.400004 C5.76016,5.239848 5.760156,4.960164 5.6,4.800004 L2.7,1.839804 C2.539844,1.679648 2.539844,1.439804 2.7,1.299964 C2.860156,1.139808 3.1,1.139808 3.23984,1.299964 L6.16024,4.220364 C6.320396,4.38052 6.58056,4.400052 6.76024,4.220364 C6.920396,4.060208 6.900084,3.800044 6.739928,3.640684 L6.719616,3.620372 L3.840016,0.720372 C3.67986,0.560216 3.700172,0.320372 3.840016,0.180532 C3.97986,0.020376 4.220476,0.020376 4.379856,0.1797508 L4.3796,0.1796 Z" />
    <path d="M18.2602,1.7796 C18.200044,1.639756 18.120356,1.49992 18.02036,1.39992 C17.860204,1.239764 17.64068,1.12024 17.40004,1.09992 C16.9602,1.060076 15.77972,1.76008 15.10004,2.1796 C14.13988,2.7796 12.70004,3.79992 11.26024,5.2398 L8.12044,8.3796 C7.72044,8.7796 7.72044,9.43976 8.12044,9.83976 L8.140752,9.860072 L3.820352,14.139672 C3.420352,14.539672 3.420352,15.199832 3.820352,15.599832 C4.220352,15.999832 4.880512,15.999832 5.280512,15.599832 L18.020312,2.860032 C18.299992,2.579572 18.379692,2.160032 18.260152,1.779572 L18.2602,1.7796 Z" />
  </svg>
);

CameraIcon.defaultProps = {
  height: 18,
  width: 16,
  fill: 'black',
};

export default CameraIcon;
