import axios from 'config/axios';

export default class ClientService {
  static async getProspects(params = {}) {
    const { data } = await axios.get('users/clients/prospects/', {
      params,
    });
    return data;
  }
  static async getMissedCheckIn(params = {}) {
    const { data } = await axios.get('users/clients/missed-checkin/', {
      params,
    });
    return data;
  }
  static async getMessageHistory(clientId, params = {}) {
    const { data } = await axios.get(`users/clients/${clientId}/messages`, {
      params,
    });
    return data;
  }
}
