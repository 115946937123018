import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getIdFromUrl } from 'helpers/formatHelpers';
import ClientOverview from 'components/ClientOverview';
import { useBranch } from 'components/BranchProvider';

const Surveys = ({ details, name, type, jwt }) => {
  const [branch] = useBranch();
  return (
    <ClientOverview
      client={{ name, id: getIdFromUrl(details.client), jwt }}
      coachView
      type={type}
      skipTreatmentPlan={branch.skip_treatment_plan}
    />
  );
};

Surveys.propTypes = {
  details: PropTypes.object,
  jwt: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  jwt: user.jwt,
});

export default connect(mapStateToProps)(Surveys);
