import { useQuery } from 'react-query';

import QuitReasonService from 'services/quitReason.service';

export function useGetQuitReasons(params, options) {
  return useQuery(
    ['quitReasons', params],
    () => QuitReasonService.get(params),
    options,
  );
}
