import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MenuItem from './MenuItem';
import { getUnreadNotificationCount } from 'helpers/notification';
import { NotificationIcon } from 'components/Icon';
import { NotificationListWidget } from 'containers/NotificationListWidget';

const NotificationItem = ({
  notificationCount,
  onClick,
}) => (
  <MenuItem target={NotificationListWidget.displayName} onClick={onClick}>
    <NotificationIcon
      count={notificationCount}
    />
  </MenuItem>
);

NotificationItem.propTypes = {
  notificationCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ notificationList }) => ({
  notificationCount: getUnreadNotificationCount(notificationList.notifications),
});

export default connect(mapStateToProps)(NotificationItem);
