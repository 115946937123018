import axios from 'config/axios';

export default class StageService {
  static async get(params = {}) {
    const { data } = await axios.get('stages/', {
      params,
    });
    return data.results;
  }
}
