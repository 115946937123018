import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import { STATUS } from 'helpers/message';

import theme from './Thread.sss';

function Thread({ id, title, body, date, status, onClick, onMarkAsReadClick }) {
  return (
    <Link className={theme.Link} to={`/messages/${id}`}>
      <Card
        classes={{
          root: theme.Thread,
        }}
        onClick={() => {
          onClick(id);
        }}
      >
        <header className={theme.Header}>
          {title}
          <div className={theme.Actions}>
            {status === STATUS.unread && (
              <Tooltip title="Mark as read" placement="top-start">
                <span
                  className={theme.MarkAsRead}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    onMarkAsReadClick(id);
                  }}
                />
              </Tooltip>
            )}
          </div>
        </header>
        {body}
        <footer>
          <span className={theme.Time}>
            {format(new Date(date), 'hh:mm a')}
          </span>
          <span className={theme.Date}>
            {format(new Date(date), 'MM/dd/yyyy')}
          </span>
        </footer>
      </Card>
    </Link>
  );
}

Thread.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(STATUS)),
  onClick: PropTypes.func.isRequired,
  onMarkAsReadClick: PropTypes.func,
};

Thread.defaultProps = {
  status: STATUS.read,
};

export default Thread;
