import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { border01 } from 'styles/colors.sss';
import { FileInput } from 'components/Form';
import IconButton from 'components/IconButton';
import { CloudUploadIcon, CloseIcon } from 'components/Icon';

import theme from './PhotoUpload.sss';

const PhotoUpload = forwardRef(
  ({ title, preview, error, onChange, onRemove, ...others }, ref) => {
    let content;
    if (preview) {
      content = (
        <div className={theme.Preview}>
          <IconButton
            classes={[theme.RemovePreview]}
            onClick={onRemove}
            icon={<CloseIcon />}
          />
          <img className={theme.ImagePreview} src={preview} />
        </div>
      );
    } else {
      content = (
        <>
          <FileInput
            className={theme.FileInput}
            maxSize={16}
            onChange={onChange}
            {...others}
            ref={ref}
          >
            <CloudUploadIcon
              className={theme.UploadIcon}
              fill={border01}
              width={80}
              height={80}
            />
          </FileInput>
          <label htmlFor={others.id} className={theme.Title}>
            {title}
          </label>
        </>
      );
    }
    return (
      <li
        className={classNames(theme.PhotoUpload, { 'invalid-input': !!error })}
      >
        {content}
        {error && <span className={theme.Error}>{error}</span>}
      </li>
    );
  },
);

PhotoUpload.propTypes = {
  title: PropTypes.string.isRequired,
  preview: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PhotoUpload;
