/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loader from 'components/Loader';
import PhotoViewer from '../components';

import * as actions from './CheckinPhotosWidget.ducks';
import theme from './CheckinPhotosWidget.sss';

class CheckinPhotosWidget extends Component {
  componentDidMount() {
    const { clientId, fetchPhotos } = this.props;
    fetchPhotos({ clientId });
  }

  renderPhotos(photos) {
    let result = photos.map((photo) => (
      <>
        {photo?.face && (
          <PhotoViewer images={photo?.face} checkin={photo?.week_period} />
        )}
        {photo?.chest && (
          <PhotoViewer images={photo?.chest} checkin={photo?.week_period} />
        )}
        {photo?.back && (
          <PhotoViewer images={photo?.back} checkin={photo?.week_period} />
        )}
      </>
    ));
    if (result.length < 1) {
      result = (
        <h2 className={theme.CheckinMessage}>
          You have not completed any check-in yet
        </h2>
      );
    }
    return result;
  }

  render() {
    const { loading, photos } = this.props;

    let content = (
      <>
        <h1 className={theme.CheckinPhotosHeader}>Checkin Photos</h1>
        {this.renderPhotos(photos)}
      </>
    );
    if (loading) {
      content = <Loader />;
    }

    return <div className={theme.CheckinPhotosWidget}>{content}</div>;
  }
}

CheckinPhotosWidget.propTypes = {
  clientId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchPhotos: PropTypes.func.isRequired,
  photos: PropTypes.array.isRequired,
};

const mapStateToProps = ({ checkinPhotos }) => ({
  ...checkinPhotos,
});

export default connect(mapStateToProps, actions)(CheckinPhotosWidget);
