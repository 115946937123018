import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UserIcon } from 'components/Icon';

import theme from './Avatar.sss';

const STYLES = {
  letters: 'letters',
  icon: 'icon',
};

const Avatar = ({ className, style, user }) => {
  function renderContent() {
    switch (style) {
      case STYLES.letters:
        return (
          <h1 className={theme.Title}>
            {user.profile.first_name[0].toUpperCase()}
            {user.profile.last_name[0].toUpperCase()}
          </h1>
        );
      case STYLES.icon:
        return <UserIcon />;
      default:
        throw new Error(`Style ${style} not supported`);
    }
  }
  return (
    <div className={classNames(theme.Avatar, className)}>{renderContent()}</div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOf(Object.keys(STYLES)),
  user: PropTypes.object,
};

Avatar.defaultProps = {
  style: STYLES.letters,
};

export default Avatar;
