import React from 'react';
import PropTypes from 'prop-types';

import Form, { BranchSelect } from 'components/Form';
import Modal from 'components/Modal';
import Button from 'components/Button';

export const BRANCH_SELECT_MODAL_ID =
  'cc/pages/Login/components/BranchSelectModal';

function BranchSelectModal({ onSubmit, userId }) {
  const id = userId;
  if (!id) {
    return null;
  }
  return (
    <Modal
      id={BRANCH_SELECT_MODAL_ID}
      size="small"
      title="Select Application"
      showTopActions={false}
    >
      <Form
        onSubmit={({ branch }) => {
          onSubmit(branch);
        }}
      >
        {() => (
          <>
            <Form.Row>
              <Form.Item
                name="branch"
                label={<label>Applications:</label>}
                input={<BranchSelect userId={id} />}
                registerOptions={{
                  required: 'Please select an application',
                }}
              />
            </Form.Row>
            <Form.Actions>
              <Button size="big" type="submit">
                Get In
              </Button>
            </Form.Actions>
          </>
        )}
      </Form>
    </Modal>
  );
}

BranchSelectModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default BranchSelectModal;
