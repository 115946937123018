import React from 'react';
import { Link } from 'react-router-dom';

import ButtonLink from 'components/ButtonLink';
import CountdownTimer from 'components/CountdownTimer';

import theme from './GracePeriodFooter.sss';

const GracePeriodFooter = () => {
  return (
    <div className={theme.root}>
      <div className={theme.footerLinks}>
        <h3 className={theme.header}>Your full access is going to end soon!</h3>
        <h3 className={theme.header}>
          <CountdownTimer
            timeUp={() => {
              location.reload();
            }}
          />
        </h3>
        <p className={theme.text}>
          Please{' '}
          <Link className={theme.link} to={'/maintenance-plans'}>
            choose a plan
          </Link>{' '}
          to maintain your access to your Clear Skin Specialist and save money
          on future orders.
        </p>
        <ButtonLink href={'/maintenance-plans'}>Select a plan</ButtonLink>
      </div>
    </div>
  );
};

export default GracePeriodFooter;
