import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Constant from 'utils/constants';
import { isCoach } from 'helpers/user';
import { properNounCase, getIdFromUrl } from 'helpers/formatHelpers';
import TaskListWidget from 'containers/TaskListWidget';
import ClientTaskListWidget from 'containers/ClientTaskListWidget';
import Notes from 'components/Details/Notes';
import Surveys from 'components/Details/Surveys';
import Regimens from 'components/Details/Regimens/Regimens';
import ConsentForm from 'components/Details/ConsentForm/ConsentForm';
import TreatmentPlan from 'components/TreatmentPlan/TreatmentPlan';
import { useBranch } from 'components/BranchProvider';

import Sidebar from './Sidebar';
import theme from './DetailsBody.scss';

const DetailsBody = ({
  details,
  submit,
  submitEditNote,
  onFilterChange,
  notes,
  clientProgram,
  user,
}) => {
  const [branch] = useBranch();
  const tabs = branch.skip_treatment_plan
    ? ['checkins', 'regimens', 'notes', 'questionnaire', 'consent Form']
    : [
        'checkins',
        'regimens',
        'notes',
        'questionnaire',
        'treatment Plan',
        'consent Form',
      ];
  const [currentTab, setTabs] = useState('notes');

  function clientName(clientDetails) {
    return properNounCase(
      `${clientDetails.first_name} ${clientDetails.last_name}`,
    );
  }

  function renderCurrentTab() {
    if (currentTab === 'notes') {
      return (
        <Notes
          onFilterChange={onFilterChange}
          onSubmit={submit}
          onSubmitEdit={submitEditNote}
          notes={notes}
          clientId={details.user_id}
        />
      );
    } else if (currentTab === 'questionnaire') {
      return (
        <Surveys
          details={details}
          name={clientName(details)}
          type="questionnaire"
        />
      );
    } else if (currentTab === 'checkins') {
      return (
        <Surveys details={details} name={clientName(details)} type="checkins" />
      );
    } else if (currentTab === 'regimens') {
      return (
        <Regimens
          details={details}
          program={clientProgram}
          name={clientName(details)}
          type="regimens"
        />
      );
    } else if (currentTab === 'treatment Plan') {
      let treatmentId = getIdFromUrl(clientProgram.treatment_plan);
      treatmentId = treatmentId && treatmentId.toString();
      return <TreatmentPlan treatmentPlanId={treatmentId} user={user} />;
    } else if (currentTab === 'consent Form') {
      return (
        <ConsentForm
          details={details}
          program={clientProgram}
          name={clientName(details)}
          type="consentForm"
        />
      );
    }
    return null;
  }

  function renderTabs() {
    return (
      <ul className={theme.tabsWrapper}>
        {tabs.map((tab, i) => (
          <li
            className={currentTab === tab ? theme.currentTab : null}
            onClick={() => setTabs(tab)}
            key={i}
          >
            {properNounCase(tab)}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className={theme.root}>
      <div className={theme.bodyContent}>
        {isCoach(user) && (
          <TaskListWidget
            actions={[Constant.task.action.publishTreatmentPlan]}
            linkedUser={details.user_id}
          />
        )}
        <ClientTaskListWidget
          id="DetailsBody-ClientTasks"
          clientId={details.user_id}
          actions={[
            Constant.task.action.acceptRejectTreatmentPlan,
            Constant.task.action.startAcneProgram,
          ]}
          title={`${details.full_name} tasks`}
        />
        {renderTabs()}
        {renderCurrentTab()}
      </div>
      <Sidebar theme={theme} details={details} />
    </div>
  );
};

DetailsBody.propTypes = {
  user: PropTypes.object,
  tasks: PropTypes.array,
  details: PropTypes.object,
  notes: PropTypes.object,
  clientProgram: PropTypes.object,
  submit: PropTypes.func,
  submitEditNote: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,
};

export default withRouter(DetailsBody);
