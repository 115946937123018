import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userAware } from 'components/UserProvider';
import history from 'global-history';
import axios from 'config/axios';
import * as surveyActions from 'actions/surveyActions';
import logo from 'assets/images/nac-horizontal-logo_2x.png';

import theme from './SurveyComplete.scss';

class SurveyComplete extends Component {
  state = {
    headers: {
      Authorization: `Bearer ${this.props.user.jwt}`,
      'Content-Type': 'application/json',
    },
    stage: false,
  };

  componentDidMount() {
    const { survey, clearReduxSurvey } = this.props;

    if (survey.title.toLowerCase() === 'checkin') {
      clearReduxSurvey();
      this.getCheckinData();
    }
  }

  getCheckinData = async () => {
    const { stageId } = this.props.match.params;
    const stage = await Promise.resolve(this.getProgramStage(stageId));
    this.setState({
      stage,
    });
  };

  getProgramStage = async (stageId) => {
    return axios
      .get(`programs/acne/stages/${stageId}/`)
      .then((response) => response.data);
  };

  render() {
    const { survey } = this.props;

    return (
      <div className={theme.surveyCompleteContainer}>
        <img src={logo} className={theme.surveyCompleteLogo} />
        <div className={theme.surveyCompleteModal}>
          <header>
            <span>{`${survey.title} Complete`}</span>
          </header>
          <div className={theme.surveyCompleteModalContent}>
            <p>Your responses have been recorded.</p>
            <button onClick={() => history.push('/')}>Back to Dashboard</button>
          </div>
        </div>
      </div>
    );
  }
}

SurveyComplete.propTypes = {
  user: PropTypes.object,
  survey: PropTypes.object,
  clearReduxSurvey: PropTypes.func,
  match: PropTypes.object,
};

export default connect(null, {
  clearReduxSurvey: surveyActions.clearReduxSurvey,
})(userAware(SurveyComplete));
