import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { apiBaseUrl } from 'config/config';
import { emailRegex } from 'helpers/validators';
import { mapModalDispatchToProps } from 'helpers/redux';
import { getErrorMessage } from 'helpers/errorHandling';
import { useBranch } from 'components/BranchProvider';
import { useAddUser } from 'features/users/user.query';

import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Form, { TextInput, GroupSelect } from 'components/Form';

function InviteMemberWidget({ modalId, setOpenModalId }) {
  const [branch] = useBranch();
  const { mutate: inviteMember, isLoading: isSubmitting } = useAddUser();
  return (
    <Modal id={modalId} size="small" title="Invite a member" autoClose>
      <Form
        defaultValues={{
          firstName: '',
          lastName: '',
          email: '',
          groups: [],
        }}
        isSubmitting={isSubmitting}
        description="An email will be send to the member to complete the account setup"
        onSubmit={({ groups, ...rest }) => {
          const values = {
            ...rest,
            branchId: branch.id,
            groups: groups.map((group) => `${apiBaseUrl}groups/${group}/`),
          };
          inviteMember(values, {
            onSuccess: () => {
              setOpenModalId(null);
              toast.success(
                'New member added! The invitation email will be send shortly',
              );
            },
            onError: (error) => {
              toast.error(getErrorMessage(error, 'Something went wrong.'));
            },
          });
        }}
      >
        {() => (
          <>
            <Form.Row>
              <Form.Item
                name="firstName"
                label={<label htmlFor="firstName">First Name</label>}
                input={<TextInput id="firstName" disabled={isSubmitting} />}
                registerOptions={{
                  required: 'Please enter a first name',
                }}
              />
            </Form.Row>
            <Form.Row>
              <Form.Item
                name="lastName"
                label={<label htmlFor="lastName">Last Name</label>}
                input={<TextInput id="lastName" disabled={isSubmitting} />}
                registerOptions={{
                  required: 'Please enter a last name',
                }}
              />
            </Form.Row>
            <Form.Row>
              <Form.Item
                name="email"
                label={<label htmlFor="email">Email</label>}
                input={<TextInput id="email" disabled={isSubmitting} />}
                registerOptions={{
                  required: 'Please enter an email address',
                  pattern: {
                    value: emailRegex,
                    message: 'Your email is not a valid email address',
                  },
                }}
              />
            </Form.Row>
            <Form.Row>
              <Form.Item
                name="groups"
                label={<label>Groups:</label>}
                input={<GroupSelect />}
                registerOptions={{
                  required: 'Please select one or more groups',
                }}
              />
            </Form.Row>
            <Form.Actions>
              {isSubmitting && <Loader />}
              <Button disabled={isSubmitting} size="big" type="submit">
                Send Invitation
              </Button>
            </Form.Actions>
          </>
        )}
      </Form>
    </Modal>
  );
}

InviteMemberWidget.propTypes = {
  modalId: PropTypes.string.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

export default connect(null, mapModalDispatchToProps)(InviteMemberWidget);
