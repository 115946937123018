import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLoggedInUser } from 'hooks';
import { mapQueryParamsToProps } from 'helpers/redux';
import Loader from 'components/Loader';
import { RequestNewToken, CreatePasswordForm } from './components';

import * as actions from './CreateAccount.ducks';

function CreateAccountPage({
  checkToken,
  loading,
  submitting,
  isTokenValid,
  resendRegistrationEmail,
  activateAccount,
}) {
  const user = useLoggedInUser();
  const history = useHistory();
  const { token, branch: branchId } = mapQueryParamsToProps(history);
  const { userId } = useParams();

  React.useEffect(() => {
    if (user.jwt) {
      toast.success(
        'You already have an account activated. Redirecting to login...',
      );
      setTimeout(() => {
        history.replace('/');
        location.reload();
      }, 2000);
    } else {
      checkToken({ userId: Number(userId), token, branchId });
    }
  }, [token, userId, branchId, user.jwt, history, checkToken]);

  if (loading) {
    return <Loader type="fixed" loading />;
  }

  return isTokenValid ? (
    <CreatePasswordForm
      submitting={submitting}
      onSubmit={({ password, confirmPassword }) => {
        activateAccount({
          userId: Number(userId),
          token,
          branchId,
          password,
          confirmPassword,
        });
      }}
    />
  ) : (
    <RequestNewToken
      userId={Number(userId)}
      submitting={submitting}
      onRequestNewToken={() => {
        resendRegistrationEmail({ userId: Number(userId), branchId });
      }}
    />
  );
}

CreateAccountPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isTokenValid: PropTypes.bool.isRequired,
  checkToken: PropTypes.func.isRequired,
  activateAccount: PropTypes.func.isRequired,
  resendRegistrationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = ({ createAccount }) => ({
  ...createAccount,
});

export default connect(mapStateToProps, actions)(CreateAccountPage);
