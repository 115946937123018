import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import Constant from 'utils/constants';
import {
  isCompleted,
  isQuitted,
  isDeclined,
  isMaintenance,
  isMaintenanceDue,
} from 'helpers/program';
import { isPracticeManager } from 'helpers/user';
import ThreadService from 'services/thread.service';
import { useLoggedInUser } from 'hooks';

import * as modalActions from 'components/Modal/Modal.ducks';
import { useBranch } from 'components/BranchProvider';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { PhoneNumberInput } from 'components/Form';

import theme from './DetailsHeader.scss';
import NextCheckInDate from './NextCheckInDate';
import ProgramWeekPeriod from './ProgramWeekPeriod';
import TreatmentArea from './TreatmentArea';
import MaintenancePlan from './MaintenancePlan';
import CommunicationMethod from './CommunicationMethod';
import CoachAssigned from './CoachAssigned';
import EndProgramModal from './EndProgramModal';
import RemoveClientModal from './RemoveClientModal';

export const END_PROGRAM_MODAL_ID =
  'ClientDetails/DetailsHeader/EndProgramModal';
export const REMOVE_CLIENT_MODAL_ID =
  'ClientDetails/DetailsHeader/RemoveClientModal';

// eslint-disable-next-line complexity
function DetailsHeader({
  clientProgram,
  details,
  programIndex,
  lastCheckinDate,
  activateProgram,
  createThread,
  nextCheckinDate,
  updateCheckin,
  updateProgramWeek,
  onTreatmentAreaChange,
  onMaintenancePlanChange,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useLoggedInUser();
  const [branch] = useBranch();

  const [fetchingThread, setFetchingThread] = React.useState(false);
  const [thread, setThread] = React.useState();

  React.useEffect(() => {
    setFetchingThread(true);
    ThreadService.getByOnlyParticipants([user, { id: details.user_id }]).then(
      (newThread) => {
        setFetchingThread(false);
        setThread(newThread);
      },
    );
  }, [user, details.user_id]);

  function renderCheckInDate(programState) {
    const inactiveProgramStates = [
      Constant.program.state.completed,
      Constant.program.state.quit,
      Constant.program.state.new,
      Constant.program.state.consented,
    ];
    if (inactiveProgramStates.includes(programState)) {
      return null;
    }
    if (!nextCheckinDate) {
      return <p>Awaiting next regimen creation</p>;
    }
    return <NextCheckInDate date={nextCheckinDate} onChange={updateCheckin} />;
  }

  function renderMessageClient() {
    if (fetchingThread) {
      return <Loader size="small" />;
    }
    if (thread) {
      return (
        <Link className={theme.messageClient} to={`/messages/${thread.id}`}>
          Message Client
        </Link>
      );
    }
    return (
      <Button classes={[theme.messageClient]} onClick={createThread}>
        Message Client
      </Button>
    );
  }

  return (
    <header className={theme.root}>
      <div className={theme.clientName}>
        <h1>
          {details.first_name}
          <br />
          {details.last_name}
        </h1>
      </div>
      <div className={theme.clientStatus}>
        <h5>Program Information</h5>
        {clientProgram?.id ? (
          <>
            <p>
              State:&nbsp;
              <em>
                {clientProgram.state.charAt(0).toUpperCase() +
                  clientProgram.state.slice(1)}
                {isQuitted(clientProgram) &&
                  ` (${clientProgram.quit_reason?.reason ?? 'Unknown'})`}
              </em>
            </p>
            {isCompleted(clientProgram) && (
              <MaintenancePlan
                plan={clientProgram.maintenance_plan}
                onChange={onMaintenancePlanChange}
                /*
                 * NOTE: Editing disabled; review the comments section in the ticket:
                 * https://naturalacneclinic.atlassian.net/browse/SNAC-580
                 */
                editable={false}
              />
            )}
            {clientProgram.payment_plan && (
              <p>
                Payment Plan:&nbsp;
                <em>
                  {clientProgram.payment_plan.charAt(0).toUpperCase() +
                    clientProgram.payment_plan.slice(1)}
                </em>
              </p>
            )}
            <CommunicationMethod userId={details.user_id} />
            {clientProgram.treatment_area.length > 0 && (
              <TreatmentArea
                editable={!!!clientProgram.plan}
                treatmentAreas={clientProgram.treatment_area}
                onChange={onTreatmentAreaChange}
              />
            )}
            {!isUndefined(programIndex) && (
              <ProgramWeekPeriod
                onSave={updateProgramWeek}
                programIndex={programIndex}
              />
            )}
            {lastCheckinDate && (
              <p>
                Most Recent Check-in: <em></em>
                {lastCheckinDate}
              </p>
            )}
            {renderCheckInDate(clientProgram.state)}
          </>
        ) : (
          <p>
            State: <em>Not Started</em>
          </p>
        )}
        {isPracticeManager(user) && <CoachAssigned userId={details.user_id} />}
        {!isEmpty(clientProgram) &&
          !isCompleted(clientProgram) &&
          !isQuitted(clientProgram) &&
          !isMaintenance(clientProgram) &&
          !isMaintenanceDue(clientProgram) &&
          !isDeclined(clientProgram) && (
            <Button
              color="sensitive"
              onClick={() => {
                dispatch(modalActions.setOpenModalId(END_PROGRAM_MODAL_ID));
              }}
            >
              End Client Program
            </Button>
          )}
        {(clientProgram?.state === Constant.program.state.quit ||
          clientProgram?.state === Constant.program.state.completed ||
          clientProgram?.state === Constant.program.state.declined) && (
          <Button
            color="sensitive"
            onClick={() => {
              activateProgram(clientProgram.id, Boolean(clientProgram.started));
            }}
          >
            Reactivate Program
          </Button>
        )}
        <Button
          color="sensitive"
          onClick={() => {
            dispatch(modalActions.setOpenModalId(REMOVE_CLIENT_MODAL_ID));
          }}
        >
          Remove Client
        </Button>
      </div>
      <div></div>
      <div className={theme.contactInfo}>
        <h5>Contact</h5>
        <p>Email: {details.email}</p>
        <PhoneNumberInput value={details.phone_number} disabled />
        <div className={theme.messageClientContainer}>
          {branch.messaging_enabled && renderMessageClient()}
          {branch.messaging_enabled && isPracticeManager(user) && (
            <Link
              className={theme.messageClient}
              to={`/client/${details.user_id}/messages`}
            >
              Message History
            </Link>
          )}
        </div>
      </div>
      {clientProgram?.id && (
        <EndProgramModal
          modalId={END_PROGRAM_MODAL_ID}
          clientId={details.user_id}
          programId={clientProgram.id}
          onProgramEnded={() => {
            window.location.reload();
          }}
        />
      )}
      <RemoveClientModal
        modalId={REMOVE_CLIENT_MODAL_ID}
        clientId={details.user_id}
        onClientRemoved={() => {
          history.replace('/clients');
        }}
      />
    </header>
  );
}

DetailsHeader.propTypes = {
  details: PropTypes.object,
  clientProgram: PropTypes.object,
  programIndex: PropTypes.number,
  nextCheckinDate: PropTypes.string,
  thread: PropTypes.object,
  lastCheckinDate: PropTypes.string,
  activateProgram: PropTypes.func,
  updateCheckin: PropTypes.func,
  updateProgramWeek: PropTypes.func,
  onTreatmentAreaChange: PropTypes.func,
  onMaintenancePlanChange: PropTypes.func,
  createThread: PropTypes.func,
};

export default DetailsHeader;
