import { RSAA } from 'redux-api-middleware';
import localStorage from 'local-storage';

import { branches } from 'config/config';

import { store } from '../store';

export default function () {
  return function (next) {
    return function (action) {
      const callApi = action[RSAA];
      // Check if this action is a redux-api-middleware action.
      if (callApi) {
        const jwt = store.getState().user.jwt || '';
        // Inject the Authorization header from redux store.
        callApi.headers = Object.assign(
          {},
          {
            Authorization: 'Bearer ' + jwt,
            'Content-Type': 'application/json',
          },
          callApi.headers,
        );

        const branchID = branches[Number(localStorage.get('branch'))];
        if (branchID) {
          callApi.headers.TenantId = branchID;
        }
      }

      // Pass the FSA to the next action.
      return next(action);
    };
  };
}
