/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import axios from 'config/axios';

// Actions
const FETCH_NOTES = 'cc/NotesModalWidget/FETCH_NOTES';
const FETCH_NOTES_FAILED = 'cc/NotesModalWidget/FETCH_NOTES_FAILED';
const FETCH_NOTES_SUCCEEDED = 'cc/NotesModalWidget/FETCH_NOTES_SUCCEEDED';
const FETCH_CLIENT_PROFILE = 'cc/NotesModalWidget/FETCH_CLIENT_PROFILE';
const FETCH_CLIENT_PROFILE_FAILED =
  'cc/NotesModalWidget/FETCH_CLIENT_PROFILE_FAILED';
const FETCH_CLIENT_PROFILE_SUCCEEDED =
  'cc/NotesModalWidget/FETCH_CLIENT_PROFILE_SUCCEEDED';

const initialState = {
  loading: false,
  notes: [],
  clientProfile: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTES:
      return { ...state, loading: true };
    case FETCH_NOTES_SUCCEEDED: {
      const { notes } = action.payload;
      return { ...state, notes, loading: false };
    }
    case FETCH_NOTES_FAILED:
      return { ...state, loading: false };
    case FETCH_CLIENT_PROFILE:
      return { ...state, loading: true };
    case FETCH_CLIENT_PROFILE_SUCCEEDED: {
      const { clientProfile } = action.payload;
      return { ...state, clientProfile, loading: false };
    }
    case FETCH_CLIENT_PROFILE_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const fetchNotes = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_NOTES, payload });
  const { clientId, types } = payload;
  try {
    const response = await axios.get('notes/', {
      params: {
        subject: clientId,
        type: types,
      },
    });
    dispatch(
      fetchNotesSuceeded({
        notes: [
          ...response.data.results.sort(
            (a, b) => new Date(b.created) - new Date(a.created),
          ),
        ],
      }),
    );
  } catch (error) {
    dispatch(fetchNotesFailed({ error }));
  }
};

const fetchNotesSuceeded = (payload) => (dispatch) => {
  dispatch({ type: FETCH_NOTES_SUCCEEDED, payload });
};

const fetchNotesFailed = (payload) => (dispatch) => {
  dispatch({ type: FETCH_NOTES_FAILED, payload });
  const { error } = payload;
  toast.error(
    error.response?.data?.message ||
      error.message ||
      'Could not load the notes',
  );
};

const fetchClientProfile = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_CLIENT_PROFILE, payload });
  const { clientId } = payload;
  try {
    const { data: clientProfile } = await axios.get(
      `users/${clientId}/profile/`,
    );
    dispatch(fetchClientProfileSuceeded({ clientProfile }));
  } catch (error) {
    dispatch(fetchClientProfileFailed({ error }));
  }
};

const fetchClientProfileSuceeded = (payload) => (dispatch) => {
  dispatch({ type: FETCH_CLIENT_PROFILE_SUCCEEDED, payload });
};

const fetchClientProfileFailed = (payload) => (dispatch) => {
  dispatch({ type: FETCH_CLIENT_PROFILE_FAILED, payload });
  const { error } = payload;
  toast.error(
    error.response?.data?.message ||
      error.message ||
      'Could not load the notes',
  );
};

export default reducer;
export {
  // Actions
  FETCH_NOTES,
  FETCH_NOTES_SUCCEEDED,
  FETCH_NOTES_FAILED,
  FETCH_CLIENT_PROFILE,
  FETCH_CLIENT_PROFILE_SUCCEEDED,
  FETCH_CLIENT_PROFILE_FAILED,
  // Action Creators
  fetchNotes,
  fetchNotesSuceeded,
  fetchNotesFailed,
  fetchClientProfile,
  fetchClientProfileSuceeded,
  fetchClientProfileFailed,
};
