import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { mapModalDispatchToProps } from 'helpers/redux';
import Modal from 'components/Modal';
import Button from 'components/Button';

import theme from './ConfirmRegimenModal.sss';

const ConfirmRegimenModal = ({
  modalId,
  setOpenModalId,
  onSaveRegimenClicked,
}) => (
  <Modal id={modalId}>
    <div className={theme.ConfirmRegimenModal}>
      <h2 className={theme.Title}>Confirm Your Regimen</h2>
      {/* eslint-disable-next-line max-len */}
      <p className={theme.TitleText}>Please make sure the regimens and steps selections are correct</p>
      <hr />
      <p className={theme.SubtitleText}>The regimen is not going to generate any order</p>
      <footer className={theme.Footer}>
        <Button color="secondary" onClick={() => { setOpenModalId(null); }}>Go Back</Button>
        <Button onClick={() => { onSaveRegimenClicked(); }}>Save Regimen</Button>
      </footer>
    </div>
  </Modal>
);

ConfirmRegimenModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  onSaveRegimenClicked: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

ConfirmRegimenModal.defaultProps = {
  products: [],
};

export default connect(null, mapModalDispatchToProps)(ConfirmRegimenModal);
