/* istanbul ignore file */

export const createConfig = (height, hide) => {
  if (hide) {
    return {
      toolbar: [],
      removeButtons: 'Subscript, Superscript', // https://github.com/codeslayer1/react-ckeditor/issues/33
      scayt_autoStartup: true,
      height,
    };
  }
  return {
    toolbar: [
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Strike', '-', 'RemoveFormat'],
      },
      {
        name: 'clipboard',
        items: [
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'Undo',
          'Redo',
        ],
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
        ],
      },
      { name: 'editing', items: ['Scayt'] },
      { name: 'tools', items: ['Maximize'] },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    ],
    removeButtons: 'Subscript, Superscript', // https://github.com/codeslayer1/react-ckeditor/issues/33
    scayt_autoStartup: true,
    height,
  };
};
