import * as types from '../actions/actionTypes';

import { UPDATE_USER_PROGRAM_MAINTENANCE_PLAN_BALANCE } from 'actions/userActions';

const INITIAL_STATE = {
  email: '',
  error: '',
  groups: [],
  jwt: '',
  profile: {
    billing_address: '', // address url
    first_name: '',
    gender: '',
    id: '',
    last_name: '',
    occupation: '',
    phone_number: '', // unique
    phone_number_alt: '', // optional
    shipping_address: '', // address url
    time_zone: '',
    url: '', // profile url
    formattedBilling: {
      address: '',
      country: '',
    },
    formattedShipping: {
      address: '',
      country: '',
    },
    errors: {
      phone: '',
      skypeId: '',
    },
    state: '',
  },
  success: '',
  url: '',
  treatmentPlan: '',
  userType: {},
  startedTreatment: false,
  program: {},
  migrated: false,
};

// eslint-disable-next-line complexity
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CLEAR_FEEDBACK:
      return {
        ...state,
        error: '',
      };
    case types.DEFINE_USER_TYPE_SUCCESS: {
      const { userType } = action.payload;
      return {
        ...state,
        userType,
      };
    }
    case types.EXPIRED_TOKEN_LOGOUT:
      return {
        ...INITIAL_STATE,
        jwt: 'expired_token',
      };
    case types.GET_FORMATTED_ADDRESSES_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          formattedBilling: action.formattedBilling,
          formattedShipping: action.formattedShipping,
        },
      };
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        jwt: action.jwt,
      };
    case types.SET_TOKEN_FROM_STORAGE:
      return {
        ...state,
        jwt: action.token,
      };
    case types.UNIVERSE_CALL_SUCCESS: {
      const { email, groups, id, profile, treatmentPlan, program, migrated } =
        action.payload;
      return {
        ...state,
        email,
        groups,
        id,
        profile: {
          ...state.profile,
          ...profile,
        },
        treatmentPlan,
        program,
        migrated,
      };
    }
    case types.UPDATE_PROFILE: {
      const profile = action.payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          ...profile,
        },
      };
    }
    case types.UPDATE_USER_GENDER:
      return {
        ...state,
        profile: {
          ...state.profile,
          gender: action.gender,
        },
      };
    case types.UPDATE_USER_TREATMENT_PLAN:
      return {
        ...state,
        treatmentPlan: action.treatmentPlan,
      };
    case types.UPDATE_USER_PROGRAM: {
      const { program } = action;
      return { ...state, program };
    }
    case UPDATE_USER_PROGRAM_MAINTENANCE_PLAN_BALANCE: {
      const { maintenancePlanBalance } = action;
      return {
        ...state,
        program: {
          ...state.program,
          maintenance_plan_balance: maintenancePlanBalance,
        },
      };
    }
    case 'SET_USER_STARTED_PROGRAM':
      return {
        ...state,
        startedTreatment: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
