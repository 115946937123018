import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapModalDispatchToProps } from 'helpers/redux';
import Modal from 'components/Modal';

import SelectPlan from './SelectPlan';
import ConfirmSendTreatmentPlan from './ConfirmSendTreatmentPlan';

const SendTreatmentPlanModal = ({
  history,
  profile,
  body,
  modalId,
  planOptions,
  onSubmit,
  setOpenModalId,
}) => {
  const STEPS = {
    SELECT_PLAN: 0,
    CONFIRM_SEND: 1,
  };

  const REGULAR_PLAN_INDEX = 0;

  const SUBMIT_TYPES = {
    previous: 'previous',
    next: 'next',
    cancel: 'cancel',
    submit: 'submit',
    done: 'done',
  };

  const [activeStep, setActiveStep] = useState(STEPS.SELECT_PLAN);
  const [plan, setPlan] = useState('0');

  const getPlanName = () => {
    return parseInt(plan, 10) === REGULAR_PLAN_INDEX
      ? 'Regular Plan'
      : planOptions.find((option) => option.id === parseInt(plan, 10))?.name;
  };
  const processSubmitAction = async (submitAction) => {
    switch (submitAction) {
      case SUBMIT_TYPES.previous:
        setActiveStep(activeStep - 1);
        break;
      case SUBMIT_TYPES.next:
        setActiveStep(activeStep + 1);
        break;
      case SUBMIT_TYPES.cancel:
        setOpenModalId(null);
        setActiveStep(STEPS.SELECT_PLAN);
        break;
      case SUBMIT_TYPES.submit: {
        const suscriptionPlan =
          plan === REGULAR_PLAN_INDEX
            ? undefined
            : planOptions.find((option) => option.id === parseInt(plan, 10));
        const succeeded = await onSubmit({
          suscriptionPlan,
          body,
        });
        if (succeeded) {
          setOpenModalId(null);
          history.replace('/');
        }
        break;
      }
      default:
        throw new Error(`Submit action ${submitAction} not supported`);
    }
  };

  const getActiveComponent = () => {
    switch (activeStep) {
      case STEPS.SELECT_PLAN:
        return (
          <SelectPlan
            defaultValues={{
              plan,
            }}
            planOptions={planOptions}
            onSubmit={(values) => {
              setPlan(values.plan);
              processSubmitAction(values.submitAction);
            }}
          />
        );
      case STEPS.CONFIRM_SEND:
        return (
          <ConfirmSendTreatmentPlan
            profile={profile}
            selectedPlan={getPlanName()}
            onPrevious={() => {
              processSubmitAction(SUBMIT_TYPES.previous);
            }}
            onCancel={() => {
              processSubmitAction(SUBMIT_TYPES.cancel);
            }}
            onConfirm={() => {
              processSubmitAction(SUBMIT_TYPES.submit);
            }}
          />
        );
      default:
        throw new Error(`Step ${activeStep} not supported`);
    }
  };

  return (
    <Modal
      id={modalId}
      onClose={() => {
        processSubmitAction(SUBMIT_TYPES.cancel);
      }}
    >
      {getActiveComponent()}
    </Modal>
  );
};

SendTreatmentPlanModal.propTypes = {
  history: PropTypes.object,
  profile: PropTypes.object,
  body: PropTypes.string,
  modalId: PropTypes.string.isRequired,
  planOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal }) => ({
  body: modal.modalProps.body || '',
});

export default withRouter(
  connect(mapStateToProps, mapModalDispatchToProps)(SendTreatmentPlanModal),
);
