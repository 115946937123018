import { useQuery } from 'react-query';

import ProgramOrderService from 'services/programOrder.service';

export function useGetProgramOrders(params, options) {
  return useQuery(
    ['programOrders', params],
    () => ProgramOrderService.get(params),
    options,
  );
}
