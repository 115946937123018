import React from 'react';
import PropTypes from 'prop-types';
import SurveyPageIcon from '../SurveyPageIcon/SurveyPageIcon';

const SurveyResultsNav = (props) => {
  if (props.pages.length <= 1) {
    return null;
  }
  return (
    <nav className={`${props.theme.surveyResultsNav} ${props.sticky ? props.theme.stickyNav : null}`}>
      <ul>
        {props.pages.map((page, p) => (
          <li key={p}>
            <a onClick={() => props.scrollToRef(page.title)}>
              <SurveyPageIcon page={page} />
              <span>{page.title}</span>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

SurveyResultsNav.propTypes = {
  theme: PropTypes.object,
  pages: PropTypes.array,
  scrollToRef: PropTypes.func,
  sticky: PropTypes.bool,
};

export default SurveyResultsNav;
