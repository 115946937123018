import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ShowIcon, HideIcon } from 'components/Icon';
import theme from './PasswordInput.sss';

const PasswordInput = ({
  error,
  register,
  ...others
}) => {
  const [ showPassword, setShowPassword] = useState(false);
  const Icon = showPassword ? ShowIcon : HideIcon;
  const type = showPassword ? 'text' : 'password';
  return (
    <div className={theme.PasswordInput}>
      <input
        className={classNames(theme.Input, { [theme.InputError]: error })}
        type={type}
        ref={register}
        {...others}
      />
      <Icon className={theme.PasswordIcon} onClick={() => { setShowPassword(!showPassword); }} />
      {error &&
        <span className={theme.ErrorText}>{error}</span>
      }
    </div>
  );
};

PasswordInput.propTypes = {
  error: PropTypes.string,
  register: PropTypes.func,
};

export default PasswordInput;
