/**
 * This file should be migrated later to each model when Typescript is added
 */

export default {
  branch: {
    online: 'Online',
    nscc: 'NSCC',
  },
  user: {
    type: {
      coach: 'Coach',
      client: 'Client',
      practiceManager: 'Practice Manager',
    },
    profile: {
      state: {
        partial: 'partial',
        completed: 'completed',
      },
    },
    state: {
      inactive: 'inactive',
    },
  },
  stage: {
    state: {
      drafted: 'drafted',
      started: 'started',
      published: 'published',
      completed: 'completed',
      upcomingCheckinSent: 'upcoming_check_in_sent',
      checkinMissed: 'check_in_missed',
      checkinSent: 'check_in_sent',
    },
    weekPeriod: [
      { value: '0', title: '1-2' },
      { value: '1', title: '3-4' },
      { value: '2', title: '5-6' },
      { value: '3', title: '7-8' },
      { value: '4', title: '9-10' },
      { value: '5', title: '11-12' },
      { value: '6', title: '13-14' },
      { value: '7', title: '15-16' },
    ],
    maxProgramIndex: 7,
    minProgramIndex: 0,
  },
  task: {
    state: {
      assigned: 'assigned',
      completed: 'completed',
      unassigned: 'unassigned',
      missed: 'missed',
    },
    action: {
      noop: 'T000',
      publishTreatmentPlan: 'T001',
      watchVideo: 'T000_02',
      acceptRejectTreatmentPlan: 'T002',
      acceptRejectProgram: 'T002_01',
      publishStage: 'T003',
      startAcneProgram: 'T004',
      completeSurveyResponse: 'T005',
      completeAcneProgram: 'T006',
      linkCoachToClient: 'T007',
      selectAcneSeverityRating: 'T008',
      consentToAcneProgram: 'T009',
    },
    actionLabels: {
      T001: '| Client Needs Treatment Plan',
      T002: 'Accept Reject Treatment Plan',
      T002_01: '| Accept/Reject Program',
      T003: '| Client Needs New Regimen',
      T004: 'Start Acne Program',
      T005: '| Waiting for Client HQ Completion',
      T006: 'Complete Acne Program',
      T007: 'Link Coach To Client',
      T008: '| Client Needs New ASR',
      T009: 'Consent To Acne Program',
    },
  },
  program: {
    state: {
      maintenance: 'maintenance',
      maintenanceDue: 'maintenance_due',
      completed: 'completed',
      declined: 'declined',
      new: 'new',
      quit: 'quit',
      consented: 'consented',
      started: 'started',
    },
    paymentPlan: {
      payAsYouGo: 'pay_as_you_go',
      payUpfront: 'pay_upfront',
    },
  },
  treatmentPlan: {
    state: {
      accepted: 'accepted',
      drafted: 'drafted',
      published: 'published',
      rejected: 'rejected',
    },
    communicationMethod: {
      email: 'email',
      onlineVideo: 'online_video',
    },
  },
  survey: {
    state: {
      completed: 'completed',
    },
    type: {
      checkin: 'Checkin',
      healtQuestionnaire: 'Health Questionnaire',
    },
  },
  order: {
    state: {
      new: 'new',
      placed: 'placed',
      duplicated: 'duplicated',
    },
  },
  message: {
    status: {
      read: 'read',
      unread: 'unread',
    },
  },
  notification: {
    trigger: {
      noop: 'N000',
      surveyResponseCompleted: 'N001',
      treatmentPlanAccepted: 'N002',
      treatmentPlanRejected: 'N003',
      stagePublished: 'N004',
      checkInMissed: 'N005',
      stageDrafted: 'N006',
    },
    state: {
      read: 'read',
      unread: 'unread',
    },
  },
  treatmentPlanRejections: {
    APTPR001: 'APTPR001',
    APTPR002: 'APTPR002',
    APTPR003: 'APTPR003',
    APTPR004: 'APTPR004',
    APTPR005: 'APTPR005',
    APTPR006: 'APTPR006',
    APTPR007: 'APTPR007',
    APTPR008: 'APTPR008',
  },
  category: {
    enhanceYourProgram: 'Enhance your program',
    sunscreens: 'Acne Sunscreens',
  },
  services: {
    acuity: 'acuity',
    infusionsoft: 'infusionsoft',
    surveyMonkey: 'survey_monkey',
    qboss: 'quick_books_ship_station',
    surveySparrow: 'survey_sparrow',
  },
  question: {
    category: {
      openEnded: 'open_ended',
      singleChoice: 'single_choice',
      multipleChoice: 'multiple_choice',
      matrix: 'matrix',
      datetime: 'datetime',
    },
    subcategory: {
      single: 'single',
      essay: 'essay',
      multi: 'multi',
      numerical: 'numerical',
      vertical: 'vertical',
      verticalTwoCol: 'vertical_two_col',
      horizontal: 'horiz',
      menu: 'menu',
      rating: 'rating',
      ranking: 'ranking',
      dateOnly: 'date_only',
      both: 'both',
      timeOnly: 'time_only',
    },
  },
  response: {
    state: {
      completed: 'completed',
      partial: 'partial',
    },
  },
  timeZones: [
    'Pacific/Pago_Pago',
    'Pacific/Honolulu',
    'America/Adak',
    'Pacific/Marquesas',
    'America/Anchorage',
    'Pacific/Gambier',
    'America/Santa_Isabel',
    'America/Los_Angeles',
    'Pacific/Pitcairn',
    'America/Mazatlan',
    'America/Denver',
    'America/Phoenix',
    'America/Guatemala',
    'America/Chicago',
    'America/Mexico_City',
    'Pacific/Easter',
    'America/Bogota',
    'America/Havana',
    'America/New_York',
    'America/Campo_Grande',
    'America/Halifax',
    'America/Santo_Domingo',
    'America/Goose_Bay',
    'America/Santiago',
    'America/Asuncion',
    'America/Caracas',
    'America/St_Johns',
    'America/Argentina/Buenos_Aires',
    'America/Sao_Paulo',
    'Atlantic/Stanley',
    'America/Miquelon',
    'America/Montevideo',
    'America/Godthab',
    'America/Noronha',
    'Atlantic/Azores',
    'Atlantic/Cape_Verde',
    'UTC',
    'Europe/London',
    'Africa/Windhoek',
    'Europe/Berlin',
    'Africa/Lagos',
    'Africa/Cairo',
    'Asia/Gaza',
    'Asia/Beirut',
    'Asia/Damascus',
    'Asia/Jerusalem',
    'Africa/Johannesburg',
    'Asia/Baghdad',
    'Europe/Moscow',
    'Europe/Minsk',
    'Europe/Istanbul',
    'Asia/Tehran',
    'Asia/Yerevan',
    'Asia/Baku',
    'Asia/Dubai',
    'Asia/Kabul',
    'Asia/Karachi',
    'Asia/Yekaterinburg',
    'Asia/Kolkata',
    'Asia/Kathmandu',
    'Asia/Dhaka',
    'Asia/Omsk',
    'Asia/Rangoon',
    'Asia/Krasnoyarsk',
    'Asia/Jakarta',
    'Asia/Shanghai',
    'Asia/Irkutsk',
    'Australia/Eucla',
    'Asia/Tokyo',
    'Asia/Yakutsk',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Sydney',
    'Australia/Brisbane',
    'Asia/Vladivostok',
    'Australia/Lord_Howe',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Pacific/Fiji',
    'Pacific/Tarawa',
    'Pacific/Majuro',
    'Pacific/Auckland',
    'Asia/Kamchatka',
    'Pacific/Chatham',
    'Pacific/Tongatapu',
    'Pacific/Apia',
    'Pacific/Kiritimati',
  ],
  maintenancePlan: {
    constraints: {
      availableMessages: 'available_messages',
      canViewMessages: 'can_view_messages',
      canViewNotifications: 'can_view_notifications',
      canViewAcneProgramPDFs: 'can_view_program_pdfs',
    },
  },
  surveyPages: [
    'Medical History',
    'Hormone Health',
    'Medications and Supplements Taken',
    'Skin Services',
    'Diet Assessment',
    'Digestive Health',
    'Lifestyle Consideration',
    'Upload Images',
  ],
};
