import React from 'react';

/*
 * To prevent multiple React.createContext() calls from occurring across bundles, we must store
 * the context globally. I'm following this pattern to do this in the safest way possible:
 * https://derickbailey.com/2016/03/09/creating-a-true-singleton-in-node-js-with-es6-symbols
 */
const getContext = (contextName, defaultValue = false) => {
  const contextKey = Symbol.for(contextName);
  const globalSymbols = Object.getOwnPropertySymbols(global);
  /* istanbul ignore else  */
  if (globalSymbols.indexOf(contextKey) === -1) {
    global[contextKey] = React.createContext(defaultValue);
  }
  return global[contextKey];
};

export { getContext };
