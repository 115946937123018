/* eslint-disable max-len */

import React from 'react';

const CameraIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 7.4179688 2 L 5.5625 4 L 4 4 C 2.897 4 2 4.897 2 6 L 2 15 C 2 16.103 2.897 17 4 17 L 16 17 C 17.103 17 18 16.103 18 15 L 18 6 C 18 4.897 17.103 4 16 4 L 14.289062 4 L 12.4375 2 L 7.4179688 2 z M 10 6 C 12.206 6 14 7.794 14 10 C 14 12.206 12.206 14 10 14 C 7.794 14 6 12.206 6 10 C 6 7.794 7.794 6 10 6 z M 10 8 A 2 2 0 0 0 8 10 A 2 2 0 0 0 10 12 A 2 2 0 0 0 12 10 A 2 2 0 0 0 10 8 z M 20 9 L 20 19 L 6 19 L 6 21 L 20 21 C 21.093063 21 22 20.093063 22 19 L 22 9 L 20 9 z" />
  </svg>
);

CameraIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default CameraIcon;
