import React from 'react';

import AlannaJ from 'assets/images/testimonial-AlannaJ.png';
import JessicaT from 'assets/images/testimonial-JessicaT.png';
import CarrieL from 'assets/images/testimonial-CarrieL.png';
import AnnaC from 'assets/images/testimonial-AnnaC.png';

import theme from './SuccessStory.sss';

const SuccessStory = () => (
  <div className={theme.SuccessStory}>
    <div>
      <img src={AlannaJ} />
    </div>
    <div>
      <img src={JessicaT} />
    </div>
    <div>
      <img src={CarrieL} />
    </div>
    <div>
      <img src={AnnaC} />
    </div>
  </div>
);

export default SuccessStory;
