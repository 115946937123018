/* eslint-disable max-len */

import React from 'react';

const FemaleIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 12 2 C 8 2 2 5 2 20 L 6.7285156 20 C 8.1385798 21.240809 9.9815281 22 12 22 C 14.018472 22 15.86142 21.240809 17.271484 20 L 22 20 C 22 5 16 2 12 2 z M 8 11 L 16 11 C 17.105 11 18 11.895 18 13 L 18 14 C 18 17.325562 15.325562 20 12 20 C 8.674438 20 6 17.325562 6 14 L 6 13 C 6 11.895 6.895 11 8 11 z" />
  </svg>
);

FemaleIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default FemaleIcon;
