/* eslint no-console: 0 */
const env = process.env.NODE_ENV;

export const getErrorMessage = (
  error,
  defaultMessage = 'Something went wrong',
) => {
  return (
    error.response?.data?.[0] ||
    error.response?.data?.message ||
    error.message ||
    defaultMessage
  );
};

export function handleRSAAError(error) {
  if (env === 'production') {
    return;
  }

  const { name, message, status } = error;
  // Built mostly to elucidate API errors and to remind me of common issues
  console.log('RSAA Error: ', name);
  console.log(message);
  if (name === 'ApiError') {
    if (status === 400) {
      console.log('Check that the request is formatted to spec');
    } else if (status === 404) {
      console.log('Check that the API endpoint is correct');
    }
  }
}
