/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import axios from 'config/axios';

// Actions
const FETCH_UPCOMING_CHECKIN = 'cc/ProspectsWidget/FETCH_UPCOMING_CHECKIN';
const FETCH_UPCOMING_CHECKIN_FAILED = 'cc/ProspectsWidget/FETCH_UPCOMING_CHECKIN_FAILED';
const FETCH_UPCOMING_CHECKIN_SUCCEEDED = 'cc/ProspectsWidget/FETCH_UPCOMING_CHECKIN_SUCCEEDED';

const initialState = {
  loading: false,
  upcomingCheckins: [],
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPCOMING_CHECKIN: return { ...state, loading: true };
    case FETCH_UPCOMING_CHECKIN_SUCCEEDED: {
      const { upcomingCheckins } = action.payload;
      return {...state, upcomingCheckins, loading: false };
    }
    case FETCH_UPCOMING_CHECKIN_FAILED:
      return {...state, loading: false};
    default: return state;
  }
};

const fetchUpcomingCheckin = (payload) =>
  async (dispatch) => {
    dispatch({ type: FETCH_UPCOMING_CHECKIN, payload });
    try {
      const { data: upcomingCheckins } = await axios.get('users/clients/upcoming-checkin/');
      dispatch(fetchUpcomingCheckinSuceeded({ upcomingCheckins }));
    } catch(error) {
      dispatch(fetchUpcomingCheckinFailed({ error }));
    }
  };


const fetchUpcomingCheckinSuceeded = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_UPCOMING_CHECKIN_SUCCEEDED, payload });
  };

const fetchUpcomingCheckinFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_UPCOMING_CHECKIN_FAILED, payload });
    const { error } = payload;
    toast.error(error.response?.data?.message || error.message || 'Could not load the prospects');
  };

export default reducer;
export {
  // Actions
  FETCH_UPCOMING_CHECKIN,
  FETCH_UPCOMING_CHECKIN_SUCCEEDED,
  FETCH_UPCOMING_CHECKIN_FAILED,

  // Action Creators
  fetchUpcomingCheckin,
  fetchUpcomingCheckinSuceeded,
  fetchUpcomingCheckinFailed,
};
