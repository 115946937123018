import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import theme from './List.scss';

function List({ className, items, columns, itemLink, onItemClick }) {
  if (!items.length) {
    return (
      <div>
        <p>No results</p>
      </div>
    );
  }

  const renderItem = (item, i) => {
    const liItem = itemLink ? (
      <Link className={theme.LinkItem} to={itemLink(item)} key={i}>
        <li>
          {columns.map((column, c) => (
            <span key={c}>{column.value(item)}</span>
          ))}
        </li>
      </Link>
    ) : (
      <li key={i} onClick={() => onItemClick(item)}>
        {columns.map((column, c) => (
          <span key={c}>{column.value(item)}</span>
        ))}
      </li>
    );
    return liItem;
  };

  return (
    <div className={classNames(className, theme.listContainer)}>
      <header>
        {columns.map((column, c) => (
          <span key={c}>{column.label}</span>
        ))}
      </header>
      <ul>{items.map((item, i) => renderItem(item, i))}</ul>
    </div>
  );
}

List.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  columns: PropTypes.array,
  onItemClick: PropTypes.func,
  itemLink: PropTypes.func,
};

export default List;
