import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import Constant from 'utils/constants';
import Button from 'components/Button';
import { STRINGS_BY_COMMUNICATION_METHOD } from 'containers/TaskWidget/ReviewTreatmentPlanWidget/ReviewTreatmentPlan/components/AcceptTreatmentPlanModal/unused/CommunicationMethodForm';
import { STRINGS_BY_PAYMENT_PLAN } from './PaymentPlanForm';

import theme from './ConfirmSelections.sss';

const ConfirmSelections = ({
  sunscreen,
  treatmentAreas,
  paymentPlan,
  onPrevious,
  onConfirm,
}) => (
  <form>
    <h4 className={theme.Title}>
      Before going further, make sure all your selections are correct
    </h4>
    <div className={theme.Selection}>
      <p>Chosen Coaching Plan:</p>
      <label>
        {
          STRINGS_BY_COMMUNICATION_METHOD[
            Constant.treatmentPlan.communicationMethod.email
          ].message
        }
      </label>
    </div>
    <div className={theme.Selection}>
      <p>Chosen sunscreen:</p>
      <label>{sunscreen}</label>
    </div>
    <div className={theme.Selection}>
      <p>Chosen treatment area(s):</p>
      <ul className={theme.TreatmentAreas}>
        {Object.values(treatmentAreas)
          .filter((treatmentArea) => treatmentArea)
          .map((treatmentArea, index) => (
            <li key={index}>{startCase(treatmentArea)}</li>
          ))}
      </ul>
    </div>
    <div className={theme.Selection}>
      <p>Chosen payment plan:</p>
      <label>{STRINGS_BY_PAYMENT_PLAN[paymentPlan].message}</label>
    </div>
    <footer className={theme.Actions}>
      <div className={theme.MainActions}>
        <Button
          classes={[theme.Action]}
          size="big"
          color="secondary"
          onClick={onPrevious}
        >
          Previous
        </Button>
        <Button classes={[theme.Action]} size="big" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </footer>
  </form>
);

ConfirmSelections.propTypes = {
  sunscreen: PropTypes.string.isRequired,
  treatmentAreas: PropTypes.object.isRequired,
  paymentPlan: PropTypes.string.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmSelections;
