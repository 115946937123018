import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import isObject from 'lodash/isObject';
import { isMobile } from 'helpers/browser';

import Radio from './Radio';
import TextInput from './TextInput';
import TextAreaInput from './TextAreaInput';

import theme from './SingleChoice.sss';

const ORIENTATION = {
  vertical: 'vertical',
  horizontal: 'horizontal',
  verticalTwoCol: 'vertical_two_col',
};

const OTHER_TYPES = {
  choice: 'choice',
  field: 'field',
};

const OTHER_INPUT_TYPE_COMPONENT_MAPPING = {
  text: TextInput,
  textArea: TextAreaInput,
};

const SingleChoice = forwardRef(
  (
    { className, defaultValue, choices, other, error, orientation, ...rest },
    ref,
  ) => {
    const buildInitialSelectedChoice = () => {
      if (typeof defaultValue === 'string') {
        return defaultValue;
      }
      return '';
    };
    const buildInitialOtherChoice = () => {
      if (isObject(defaultValue)) {
        return defaultValue;
      }
      if (other?.value) {
        return {
          [Number(other.value)]: '',
        };
      }
      return {};
    };

    const [selectedChoice, setSelectedChoice] = useState(
      buildInitialSelectedChoice(),
    );
    const [otherChoiceSelected, setOtherChoiceSelected] = useState(
      isObject(defaultValue),
    );
    const [otherChoice, setOtherChoice] = useState(buildInitialOtherChoice());
    useEffect(() => {
      if (rest.onChange && selectedChoice) {
        rest.onChange(selectedChoice);
      }
    }, [selectedChoice]);
    useEffect(() => {
      if (rest.onChange && otherChoiceSelected) {
        rest.onChange(otherChoice);
      }
    }, [otherChoiceSelected, otherChoice]);
    const renderOther = () => {
      const OtherInputComponent =
        OTHER_INPUT_TYPE_COMPONENT_MAPPING[other.inputType];
      switch (other.type) {
        case OTHER_TYPES.choice:
          return (
            <>
              <div className={theme.Choice}>
                <Radio
                  {...rest}
                  ref={ref}
                  id={`other-${other.value}`}
                  checked={otherChoiceSelected}
                  onChange={() => {
                    setSelectedChoice('');
                    setOtherChoiceSelected(true);
                  }}
                />
                <label htmlFor={`other-${other.value}`}>{other.title}</label>
              </div>
              <OtherInputComponent
                {...rest}
                ref={ref}
                disabled={!otherChoiceSelected}
                value={otherChoice[Number(other.value)]}
                onChange={(ev) => {
                  setOtherChoice({
                    [Number(other.value)]: ev.target.value,
                  });
                }}
              />
            </>
          );
        case OTHER_TYPES.field:
          return <OtherInputComponent />;
        default:
          throw new Error(`The type ${other.type} is not supported`);
      }
    };

    const getChoicesFromOrientation = () => {
      switch (orientation) {
        case ORIENTATION.horizontal:
        case ORIENTATION.vertical:
          return choices;
        case ORIENTATION.verticalTwoCol: {
          if (isMobile()) {
            return choices;
          }
          const halfIndex = Math.ceil(choices.length / 2);
          const orderedChoices = [];
          for (let i = 0; i < halfIndex; i++) {
            orderedChoices[i * 2] = choices[i];
            if (i + halfIndex < choices.length) {
              orderedChoices[i * 2 + 1] = choices[i + halfIndex];
            }
          }
          return orderedChoices;
        }
        default:
          throw new Error(`Orientation ${orientation} not supported`);
      }
    };

    function renderChoice({ title, value, disabled = false }) {
      return (
        <div className={theme.Choice}>
          <Radio
            {...rest}
            disabled={disabled}
            ref={ref}
            id={`choice-${value}`}
            value={value}
            checked={selectedChoice === value}
            onChange={() => {
              setSelectedChoice(value);
              setOtherChoiceSelected(false);
            }}
          />
          <label
            style={{ opacity: disabled ? 0.5 : 1.0 }}
            htmlFor={`choice-${value}`}
          >
            {title}
          </label>
        </div>
      );
    }

    function renderChoices(layoutChoices) {
      switch (orientation) {
        case ORIENTATION.vertical:
        case ORIENTATION.horizontal:
        case ORIENTATION.verticalTwoCol:
          return (
            <ul
              className={classNames(
                theme.Choices,
                {
                  [theme.InputError]: error,
                  [theme.VerticalChoices]: orientation === ORIENTATION.vertical,
                  [theme.HorizontalChoices]:
                    orientation === ORIENTATION.horizontal,
                  [theme.VerticalTwoCol]:
                    orientation === ORIENTATION.verticalTwoCol,
                },
                className,
              )}
            >
              {layoutChoices.map((choice, index) => (
                <li key={index}>
                  {choice.disabled && choice.disableReason ? (
                    <Tooltip title={choice.disableReason} placement="top-start">
                      {renderChoice(choice)}
                    </Tooltip>
                  ) : (
                    renderChoice(choice)
                  )}
                </li>
              ))}
            </ul>
          );
        default:
          throw new Error(`Orientation ${orientation} not supported`);
      }
    }

    const renderFollowupQuestion = () => {
      const followupChoice = choices.find(
        ({ value }) => value === selectedChoice,
      );
      if (followupChoice) {
        return followupChoice.followupQuestions;
      }
      return null;
    };

    return (
      <div className={theme.SingleChoice}>
        {renderChoices(getChoicesFromOrientation())}
        {other && renderOther()}
        {renderFollowupQuestion()}
        {error && <span className={theme.ErrorText}>{error}</span>}
      </div>
    );
  },
);

SingleChoice.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.string,
      disableReason: PropTypes.string,
      followupQuestions: PropTypes.array,
    }),
  ).isRequired,
  other: PropTypes.shape({
    title: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(OTHER_TYPES)).isRequired,
    inputType: PropTypes.oneOf(Object.keys(OTHER_INPUT_TYPE_COMPONENT_MAPPING))
      .isRequired,
  }),
  orientation: PropTypes.oneOf(Object.values(ORIENTATION)),
  error: PropTypes.string,
};

SingleChoice.defaultProps = {
  orientation: ORIENTATION.vertical,
};

export default SingleChoice;
