import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';

import { collectorIds, resolveService } from 'config/config';
import Constant from 'utils/constants';
import axios from 'config/axios';
import { clientDashboardTour } from 'config/tour';
import history from 'global-history';
import { isDraft as isTreatmentPlanDraft } from 'helpers/treatmentPlan';
import { getIdFromUrl } from 'helpers/formatHelpers';

import HQPhotosWidget from 'containers/PhotosWidget/HQPhotosWidget';
import CheckinPhotosWidget from 'containers/PhotosWidget/CheckinPhotosWidget';
import IconButton from 'components/IconButton';
import { EditIcon } from 'components/Icon';

import RegimenList from './RegimenList';

import theme from './ClientOverview.scss';

const SurveyItem = ({ link, userSurvey, surveyInfo }) => {
  const programStageWeeks = userSurvey?.week_period; // eslint-disable-line camelcase
  let title = false;
  if (surveyInfo && surveyInfo.title) {
    title = programStageWeeks
      ? `Week ${programStageWeeks} ${surveyInfo.title}`
      : surveyInfo.title;
  }

  return (
    <li>
      <Link className={theme.Link} to={link}>
        <div className={theme.title}>{title}</div>
        <div className={theme.completed}>
          {userSurvey.state === 'completed' &&
            format(new Date(userSurvey.updated), 'MM/dd/yyyy hh:mm a')}
        </div>
      </Link>
    </li>
  );
};

const ProgramStageItem = ({
  program,
  programStage,
  programStageTitle,
  clientId,
  canEdit,
}) => (
  <li>
    <Link className={theme.Link} to={`/view-regimen/${programStage.id}`}>
      <div className={theme.title}>{programStageTitle}</div>
      <div className={theme.completed}>
        {programStage.started
          ? format(new Date(programStage.started), 'MM/dd/yyyy hh:mm a')
          : 'Not started'}
      </div>
    </Link>
    {canEdit && (
      <IconButton
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          history.push(
            `/create-regimen/${clientId}/${program.id}?editingPublishedStage=true`,
          );
        }}
        icon={<EditIcon />}
      />
    )}
  </li>
);

const CoachMessage = ({ message }) => (
  <div className={theme.root}>
    <p>{message}</p>
  </div>
);

CoachMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

const LIMIT = 50;

class ClientOverview extends Component {
  constructor(props) {
    super(props);

    const { type } = this.props;

    this.state = {
      activeView: type || 'questionnaire',
      surveys: [],
      userSurveys: [],
      userProgramStages: [],
      userProgram: [],
      treatmentPlan: {},
      fetchingIndex: true,
      fetchingUserSurveys: true,
      fetchingUserProgramStages: true,
    };
  }

  async componentDidMount() {
    this.mounted = true;
    const {
      client: { id: userId },
    } = this.props;

    this.getSurveysIndex();
    this.getUserSurveys(userId);
    const programs = await this.getPrograms(userId);
    // eslint-disable-next-line camelcase
    this.getTreatmentPlan(programs[0]?.treatment_plan);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.type && this.state.activeView !== nextProps.type) {
      this.setState({
        activeView: nextProps.type,
      });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getSurveysIndex = () => {
    axios
      .get('surveys/')
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        this.setState({
          fetchingIndex: false,
          surveys: res.results,
        });
      })
      .catch(() => {
        this.setState({
          fetchingIndex: false,
        });
      });
  };

  getUserSurveys = (userId) => {
    axios
      .get('surveys/responses/', {
        params: { user: userId, 'limit': LIMIT },
      })
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        this.setUserSurveys(res.results);
      })
      .catch(() => {
        this.setState({
          fetchingUserSurveys: false,
        });
      });
  };

  getPrograms = async (userId) => {
    try {
      const programsResponse = await axios.get('programs/acne/', {
        params: { user: userId },
      });
      const programs = programsResponse.data.results;
      this.setUserProgram(programs);
      return programs;
    } catch (error) {
      console.log('error getting program data: ', error); // eslint-disable-line no-console
      return [];
    }
  };

  getTreatmentPlan = async (treatmentPlanUrl) => {
    if (treatmentPlanUrl) {
      const { data: treatmentPlan } = await axios.get(treatmentPlanUrl);
      this.setState({
        treatmentPlan,
      });
    }
  };

  getUserProgramStages = (userId) => {
    axios
      .get('programs/acne/stages/', {
        params: { acne_program__user: userId, 'limit': LIMIT },
      })
      .then((response) => {
        return response.data;
      })
      .then((res) => {
        this.setUserProgramStages(res.results);
      })
      .catch(() => {
        this.setState({
          fetchingUserProgramStages: false,
        });
      });
  };

  getSurveyLink = (survey, userSurvey) => {
    const { coachView, client } = this.props;
    if (
      resolveService(userSurvey.collector) === Constant.services.surveySparrow
    ) {
      return `/client-details/${client.id}/surveys/hq`;
    }
    if (coachView) {
      return `/client-details/${client.id}/surveys/${survey.id}/${userSurvey.id}`;
    }
    if (userSurvey.state === 'completed') {
      return `/client-details/${client.id}/surveys/${survey.id}/${userSurvey.id}`;
    }
    return `/surveys/${survey.id}`;
  };

  setUserSurveys = (userSurveys) => {
    const { id } = this.props.client;

    this.setState(
      {
        userSurveys,
        fetchingUserSurveys: false,
      },
      this.getUserProgramStages(id),
    );
  };

  setUserProgramStages = (userProgramStages) => {
    const responses = this.state.userSurveys;

    const stageResponses = [];
    if (userProgramStages.length) {
      for (let s = 0; s < responses.length; s++) {
        const response = responses[s];

        for (let p = 0; p < userProgramStages.length; p++) {
          const stage = userProgramStages[p];

          // eslint-disable-next-line max-depth
          if (stage.check_in && response.id === getIdFromUrl(stage.check_in)) {
            stageResponses.push({
              ...response,
              program_view_index: stage.program_view_index,
            });

            break;
          }
        }
      }
    }

    if (stageResponses.length) {
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i];

        for (let s = 0; s < stageResponses.length; s++) {
          const stage = stageResponses[s];

          // eslint-disable-next-line max-depth
          if (stage.id === response.id) {
            response[i] = stage;
          }
        }
      }
    }

    const sortedUserProgramStages = userProgramStages.sort(
      (a, b) => b.program_index - a.program_index,
    );

    this.setState({
      userProgramStages: sortedUserProgramStages,
      userSurveys: responses,
      fetchingUserProgramStages: false,
    });

    if (
      !this.props.coachView &&
      sortedUserProgramStages.length &&
      this.props.client.startedTreatment
    ) {
      this.setState({
        activeView: 'regimens',
      });
    }
  };

  setUserProgram = (res) => {
    if (this.mounted) {
      this.setState({
        userProgram: res,
      });
      if (res.length && res[0].started) {
        this.props.setUserStartedProgram(true);
      } else {
        this.props.setUserStartedProgram(false);
      }
    }
  };

  setActiveView = (activeView) => {
    this.setState({
      activeView,
    });
  };

  mounted = false;

  // eslint-disable-next-line complexity
  render({ coachView, client, skipTreatmentPlan } = this.props) {
    const {
      surveys,
      activeView,
      userProgramStages,
      userProgram,
      treatmentPlan,
    } = this.state;
    let { userSurveys } = this.state;

    if (coachView) {
      userSurveys = userSurveys.filter((s) => s.state === 'completed');
    }

    userSurveys = userSurveys.sort(
      (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime(),
    );

    const questionnaire = userSurveys.filter(
      (s) => s.collector !== collectorIds.checkin,
    );
    const checkins = userSurveys.filter(
      (s) => s.collector === collectorIds.checkin,
    );

    if (coachView) {
      if (activeView === 'treatment' && !userSurveys.length) {
        return (
          <CoachMessage message="This client hasn't completed any surveys" />
        );
      } else if (activeView === 'questionnaire' && !questionnaire.length) {
        return (
          <CoachMessage message="This client hasn't completed their questionnaire" />
        );
      } else if (activeView === 'checkins' && !checkins.length) {
        return (
          <CoachMessage message="This client hasn't completed any checkins" />
        );
      } else if (activeView === 'regimens' && !userProgramStages.length) {
        return <CoachMessage message="This client doesn't have any regimen." />;
      } else if (
        activeView === 'consentForm' &&
        !userProgram.some((p) => !!p.consent_form)
      ) {
        return (
          <CoachMessage message="This client hasn't signed any consent form." />
        );
      }
    }

    return (
      !this.state.fetchingIndex &&
      !this.state.fetchingUserSurveys &&
      !this.state.fetchingUserProgramStages && (
        <div className={theme.root}>
          {!coachView && (
            <header>
              <div
                id={clientDashboardTour.regimens.id}
                onClick={() => this.setActiveView('regimens')}
                className={activeView === 'regimens' ? theme.active : null}
              >
                Regimens
              </div>
              <div
                id={clientDashboardTour.hq.id}
                onClick={() => this.setActiveView('questionnaire')}
                className={activeView === 'questionnaire' ? theme.active : null}
              >
                Questionnaire
              </div>
              <div
                id={clientDashboardTour.checkins.id}
                onClick={() => this.setActiveView('checkins')}
                className={activeView === 'checkins' ? theme.active : null}
              >
                Check-ins
              </div>
              {!skipTreatmentPlan && (
                <div
                  id={clientDashboardTour.treatmentPlan.id}
                  onClick={() => this.setActiveView('treatment')}
                  className={activeView === 'treatment' ? theme.active : null}
                >
                  Treatment Plan
                </div>
              )}
              <div
                onClick={() => this.setActiveView('photos')}
                className={activeView === 'photos' ? theme.active : null}
              >
                Photos
              </div>
              <div
                onClick={() => this.setActiveView('consentForm')}
                className={activeView === 'consentForm' ? theme.active : null}
              >
                Consent Form
              </div>
            </header>
          )}
          {activeView === 'questionnaire' && ( // eslint-disable-line no-nested-ternary
            <Fragment>
              <div className={theme.listHeader}>
                <div className={theme.itemHeader}>Survey</div>
                <div className={theme.completedHeader}>Completed</div>
              </div>
              <ul className={theme.itemList}>
                {questionnaire.length ? (
                  questionnaire.map((survey, i) => {
                    const surveyInfo = surveys.find(
                      ({ collector }) =>
                        getIdFromUrl(collector) === survey.collector,
                    );

                    if (
                      surveyInfo.title.toLowerCase() === 'checkin' &&
                      survey.state !== 'completed'
                    ) {
                      return null;
                    }

                    const link = this.getSurveyLink(surveyInfo, survey);

                    return (
                      <SurveyItem
                        key={i}
                        link={link}
                        surveyInfo={surveyInfo}
                        userSurvey={survey}
                      />
                    );
                  })
                ) : (
                  <li className={theme.itemNotAvailable}>
                    You have not completed your questionnaire.
                  </li>
                )}
              </ul>
            </Fragment>
          )}
          {activeView === 'checkins' && ( // eslint-disable-line no-nested-ternary
            <Fragment>
              <div className={theme.listHeader}>
                <div className={theme.itemHeader}>Survey</div>
                <div className={theme.completedHeader}>Completed</div>
              </div>
              <ul className={theme.itemList}>
                {checkins.length ? (
                  checkins.map((survey, i) => {
                    const surveyInfo = surveys.find(
                      ({ collector }) =>
                        getIdFromUrl(collector) === survey.collector,
                    );

                    if (
                      surveyInfo.title.toLowerCase() === 'checkin' &&
                      survey.state !== 'completed'
                    ) {
                      return null;
                    }

                    const link = this.getSurveyLink(surveyInfo, survey);

                    return (
                      <SurveyItem
                        key={i}
                        link={link}
                        surveyInfo={surveyInfo}
                        userSurvey={survey}
                      />
                    );
                  })
                ) : (
                  <li className={theme.itemNotAvailable}>
                    You have not completed any checkins.
                  </li>
                )}
              </ul>
            </Fragment>
          )}
          {activeView === 'treatment' && ( // eslint-disable-line no-nested-ternary
            <Fragment>
              <div className={theme.listHeader}>
                <div className={theme.itemHeader}>Treatment Plan</div>
                <div className={theme.completedHeader}>Completed</div>
              </div>
              <ul className={theme.itemList}>
                {isEmpty(treatmentPlan) ||
                isTreatmentPlanDraft(treatmentPlan) ? (
                  <li className={theme.itemNotAvailable}>
                    Your Clear Skin Specialist has not completed your treatment
                    plan.
                  </li>
                ) : (
                  userProgram.map((p, i) => (
                    <li key={i}>
                      <Link
                        className={theme.Link}
                        to={`/programs/acne/treatment-plans/${treatmentPlan.id}`}
                      >
                        <div className={theme.title}>Treatment plan</div>
                        <div className={theme.completed}>
                          {p.state === 'started' &&
                            format(new Date(p.started), 'MM/dd/yyyy hh:mm a')}
                        </div>
                      </Link>
                    </li>
                  ))
                )}
              </ul>
            </Fragment>
          )}
          {activeView === 'regimens' && <RegimenList userId={client.id} />}
          {activeView === 'photos' && (
            <>
              <HQPhotosWidget clientId={client.id} />
              <CheckinPhotosWidget clientId={client.id} />
            </>
          )}
          {activeView === 'consentForm' && ( // eslint-disable-line no-nested-ternary
            <>
              <div className={theme.listHeader}>
                <div className={theme.itemHeader}>Consent Form</div>
                <div className={theme.completedHeader}>Signed</div>
              </div>
              <ul className={theme.itemList}>
                {!userProgram.some((p) => !!p.consent_form) ? ( // eslint-disable-line no-negated-condition
                  <li className={theme.itemNotAvailable}>
                    You have not signed your consent form.
                  </li>
                ) : (
                  userProgram.map((p, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        window.open(p.consent_form);
                      }}
                    >
                      <div className={theme.title}>Consent Form</div>
                      <div className={theme.completed}>
                        {p.consented &&
                          format(new Date(p.consented), 'MM/dd/yyyy')}
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </>
          )}
        </div>
      )
    );
  }
}

ClientOverview.propTypes = {
  user: PropTypes.object.isRequired,
  program: PropTypes.object,
  coachView: PropTypes.bool,
  skipTreatmentPlan: PropTypes.bool,
  client: PropTypes.object,
  viewClientResponse: PropTypes.func,
  type: PropTypes.string,
  setUserStartedProgram: PropTypes.func,
};

SurveyItem.propTypes = {
  link: PropTypes.string.isRequired,
  surveyInfo: PropTypes.object,
  userSurvey: PropTypes.object,
};

ProgramStageItem.propTypes = {
  program: PropTypes.object,
  programStage: PropTypes.object,
  programStageTitle: PropTypes.string,
  clientId: PropTypes.number,
  canEdit: PropTypes.bool,
};

const mapStateToProps = ({ user }, ownProps) => ({
  user: {
    ...ownProps.user,
    userType: user.userType,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserStartedProgram: (val) => {
      dispatch({
        type: 'SET_USER_STARTED_PROGRAM',
        payload: val,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientOverview);
