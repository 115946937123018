import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { usePrevious } from 'hooks';

import theme from './ListHeader.scss';

function ListHeader({ title, currentQuery, activeFilter, onSubmit }) {
  const prevActiveFilter = usePrevious(activeFilter);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    // coming to this page from dashboard with a query already defined
    if (currentQuery?.length) {
      setValue(currentQuery);
    }
  }, [currentQuery]);

  React.useEffect(() => {
    if (prevActiveFilter && prevActiveFilter.id !== activeFilter.id) {
      setValue('');
    }
  }, [prevActiveFilter, activeFilter]);

  function clearSearch() {
    setValue('');
    onSubmit('');
  }

  function renderClearButton() {
    if (!value.length) {
      return null;
    }

    return (
      <i
        className={classNames('material-icons', 'clear-icon')}
        onClick={() => clearSearch()}
      >
        close
      </i>
    );
  }

  return (
    <div className={theme.listHeaderContainer}>
      <h2>{title}</h2>
      <div
        className={classNames(
          'search-input-container',
          theme.searchInputContainer,
        )}
      >
        <input
          type="text"
          placeholder={activeFilter.title}
          value={value}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSubmit(value);
            }

            if (e.key === 'Escape') {
              clearSearch();
            }
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <i
          className={classNames('material-icons', 'search-icon')}
          onClick={() => onSubmit(value)}
        >
          search
        </i>
        {renderClearButton()}
      </div>
    </div>
  );
}

ListHeader.propTypes = {
  title: PropTypes.string,
  currentQuery: PropTypes.string,
  activeFilter: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ListHeader;
