import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import { getErrorMessage } from 'helpers/errorHandling';
import Feedback from 'components/Feedback';
import Button from 'components/Button';

function ErrorFallback({ error, additionalData = {}, resetErrorBoundary }) {
  const errorMessage = getErrorMessage(error);
  console.error(error, additionalData);
  return (
    <Feedback
      type="error"
      message={
        <>
          <div>
            {errorMessage}
            <br />
            Please try refreshing the page or contact support.
            <br />
            <Button size="small" onClick={resetErrorBoundary}>
              Try Again
            </Button>
          </div>
          <details>
            <summary>Error Details (for developers)</summary>
            <pre>{JSON.stringify(additionalData)}</pre>
            <pre>{error.stack}</pre>
          </details>
        </>
      }
    />
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  additionalData: PropTypes.object,
  resetErrorBoundary: PropTypes.func.isRequired,
};

function ErrorBoundary({ children, additionalData }) {
  return (
    <_ErrorBoundary
      FallbackComponent={(props) => (
        <ErrorFallback {...props} additionalData={additionalData} />
      )}
      onError={(error, info) => {
        Sentry.captureException(error, {
          extra: { ...info, ...additionalData },
        });
      }}
    >
      {children}
    </_ErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  additionalData: PropTypes.object,
};

export default ErrorBoundary;
