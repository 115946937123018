/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import axios from 'config/axios';
import { getErrorMessage } from 'helpers/errorHandling';
import { hasParticipant } from 'helpers/thread';
import { isPracticeManager } from 'helpers/user';
import { TYPES } from 'helpers/message';
import { isMaintenance } from 'helpers/program';
import { updateUserProgramMaintenancePlanBalance } from 'actions/userActions';
import * as inboxActions from 'containers/InboxWidget/UserInboxWidget/UserInboxWidget.ducks';

// Actions
const INITIALIZE = 'cc/conversation/INITIALIZE';
const INITIALIZE_SUCCESS = 'cc/conversation/INITIALIZE_SUCCESS';
const INITIALIZE_FAIL = 'cc/conversation/INITIALIZE_FAIL';
const FETCH_THREAD = 'cc/conversation/FETCH_THREAD';
const FETCH_THREAD_SUCCESS = 'cc/conversation/FETCH_THREAD_SUCCESS';
const FETCH_THREAD_FAIL = 'cc/conversation/FETCH_THREAD_FAIL';
const SEND_MESSAGE = 'cc/conversation/SEND_MESSAGE';
const SEND_MESSAGE_SUCCESS = 'cc/conversation/SEND_MESSAGE_SUCCESS';
const SEND_MESSAGE_FAIL = 'cc/conversation/SEND_MESSAGE_FAIL';

const initialState = {
  loading: false,
  thread: {},
};

// Reducer
// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state, loading: true };
    case INITIALIZE_SUCCESS:
    case INITIALIZE_FAIL:
      return { ...state, loading: false };
    case FETCH_THREAD_SUCCESS: {
      const { thread } = action.payload;
      return { ...state, thread };
    }
    default:
      return state;
  }
};

// Action Creators

const initialize = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  const { threadId, user } = payload;
  try {
    const thread = await dispatch(fetchThread({ threadId }));
    if (!thread) {
      throw new Error(`This conversation ${threadId} no longer exists`);
    }
    if (!hasParticipant(thread, user) && !isPracticeManager(user)) {
      throw new Error(`You cannot view the conversation ${threadId}`);
    }
    dispatch(initializeSuccess());
    return true;
  } catch (error) {
    dispatch(initializeFail({ error }));
    return false;
  }
};

const initializeSuccess = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCESS, payload });
};

const initializeFail = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAIL, payload });
  const { error } = payload;
  toast.error(getErrorMessage(error, 'Failed to load the conversation'));
};

const fetchThread = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_THREAD, payload });
  const { threadId } = payload;
  try {
    const { data: thread } = await axios.get(
      `messages/threads/${threadId}/details/`,
    );
    dispatch(fetchThreadSuccess({ thread }));
    return thread;
  } catch (error) {
    dispatch(fetchThreadFail({ error }));
    return null;
  }
};

const fetchThreadSuccess = (payload) => (dispatch) => {
  dispatch({ type: FETCH_THREAD_SUCCESS, payload });
};

const fetchThreadFail = (payload) => (dispatch) => {
  dispatch({ type: FETCH_THREAD_FAIL, payload });
};

const sendMessage = (payload) => async (dispatch) => {
  dispatch({ type: SEND_MESSAGE, payload });

  const { thread, body, type, recipient, attachment } = payload;

  const data = new FormData();
  data.append('type', type);
  data.append('thread', thread);
  data.append('body', body);
  data.append('recipient', recipient);
  if (attachment) {
    data.append('attachment', attachment);
  }

  try {
    const { data: message } = await axios.post('messages/', data);
    dispatch(sendMessageSuccess({ message }));
    return message;
  } catch (error) {
    dispatch(sendMessageFail({ error }));
    return null;
  }
};

const sendMessageSuccess = (payload) => (dispatch, getState) => {
  const { user } = getState();
  const { message } = payload;
  dispatch({ type: SEND_MESSAGE_SUCCESS, payload });
  if (message.type === TYPES.shipping) {
    toast.success('Your shipping question was sent!');
  } else {
    if (
      isMaintenance(user.program) &&
      user.program.maintenance_plan_balance.balance.available_messages > 0
    ) {
      dispatch(
        updateUserProgramMaintenancePlanBalance({
          ...user.program.maintenance_plan_balance,
          balance: {
            ...user.program.maintenance_plan_balance.balance,
            available_messages: message.available_messages,
          },
        }),
      );
    }
    dispatch({ type: SEND_MESSAGE_SUCCESS, payload });
  }
  const thread = { id: message.thread };
  dispatch(inboxActions.readThread({ user: user.id, thread }));
};

const sendMessageFail = (payload) => (dispatch) => {
  dispatch({ type: SEND_MESSAGE_FAIL, payload });
  const { error } = payload;
  toast.error(getErrorMessage(error, 'Something went wrong'));
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_SUCCESS,
  INITIALIZE_FAIL,
  FETCH_THREAD,
  FETCH_THREAD_SUCCESS,
  FETCH_THREAD_FAIL,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  // Action Creators
  initialize,
  initializeSuccess,
  initializeFail,
  fetchThread,
  fetchThreadSuccess,
  fetchThreadFail,
  sendMessage,
  sendMessageSuccess,
  sendMessageFail,
};
