import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isClient } from 'helpers/user';
import { mapModalDispatchToProps } from 'helpers/redux';
import { userAware } from 'components/UserProvider';
import Modal from 'components/Modal';

import Welcome from './Welcome';
import ConfirmSelections from './ConfirmSelections';
import QuestionsForm from './QuestionsForm';
import SunscreenForm from './SunscreenForm';
import TreatmentAreasForm from './TreatmentAreasForm';
import PaymentPlanForm from './PaymentPlanForm';

const AcceptTreatmentPlanModal = ({
  user,
  modalId,
  sunscreenOptions,
  onSubmit,
  setOpenModalId,
  program,
}) => {
  const REGULAR_STEPS = {
    COMMUNICATE_QUESTIONS: 0,
    SELECT_SUNSCREEN: 1,
    SELECT_TREATMENT_AREAS: 2,
    SELECT_PAYMENT_PLAN: 3,
    CONFIRM_SELECTIONS: 4,
    WELCOME: 5,
  };

  const SUBS_STEPS = {
    COMMUNICATE_QUESTIONS: 0,
    CONFIRM_SELECTIONS: 1,
    WELCOME: 2,
  };

  const STEPS = program.plan?.id ? SUBS_STEPS : REGULAR_STEPS;

  const SUBMIT_TYPES = {
    previous: 'previous',
    next: 'next',
    cancel: 'cancel',
    submit: 'submit',
    done: 'done',
  };

  const [activeStep, setActiveStep] = useState(STEPS.COMMUNICATE_QUESTIONS);
  const [communicationMethod, setCommunicationMethod] = useState();
  const [sunscreen, setSunscreen] = useState();
  const [treatmentAreas, setTreatmentAreas] = useState();
  const [paymentPlan, setPaymentPlan] = useState();
  const [questions, setQuestions] = useState('');

  useEffect(() => {
    if (program.plan?.id) {
      // eslint-disable-next-line camelcase
      setCommunicationMethod(program.plan?.communication_method);
      // eslint-disable-next-line camelcase
      setTreatmentAreas(program.plan?.treatment_area);
    }
  }, [program]);

  const processSubmitAction = async (submitAction) => {
    switch (submitAction) {
      case SUBMIT_TYPES.previous:
        setActiveStep(activeStep - 1);
        break;
      case SUBMIT_TYPES.next:
        setActiveStep(activeStep + 1);
        break;
      case SUBMIT_TYPES.cancel:
        setOpenModalId(null);
        setActiveStep(STEPS.COMMUNICATE_QUESTIONS);
        break;
      case SUBMIT_TYPES.submit: {
        const succeeded = await onSubmit({
          communicationMethod,
          paymentPlan,
          treatmentAreas: Object.values(treatmentAreas).filter(
            (treatmentArea) => treatmentArea,
          ),
          sunscreen,
          questions,
        });
        if (succeeded) {
          setActiveStep(STEPS.WELCOME);
        }
        break;
      }
      case SUBMIT_TYPES.done:
        setOpenModalId(null);
        break;
      default:
        throw new Error(`Submit action ${submitAction} not supported`);
    }
  };

  const getActiveComponent = () => {
    switch (activeStep) {
      case STEPS.COMMUNICATE_QUESTIONS:
        if (!isClient(user)) {
          setActiveStep(activeStep + 1);
          return null;
        }
        return (
          <QuestionsForm
            defaultValues={{
              questions,
            }}
            onSubmit={(values) => {
              setQuestions(values.questions);
              processSubmitAction(values.submitAction);
            }}
          />
        );
      case STEPS.SELECT_SUNSCREEN:
        return (
          <SunscreenForm
            defaultValues={{
              sunscreen,
            }}
            sunscreenOptions={sunscreenOptions}
            onSubmit={(values) => {
              setSunscreen(values.sunscreen);
              processSubmitAction(values.submitAction);
            }}
          />
        );
      case STEPS.SELECT_TREATMENT_AREAS:
        return (
          <TreatmentAreasForm
            defaultValues={{
              ...treatmentAreas,
            }}
            onSubmit={({ submitAction, ...values }) => {
              setTreatmentAreas(values);
              processSubmitAction(submitAction);
            }}
          />
        );
      case STEPS.SELECT_PAYMENT_PLAN:
        return (
          <PaymentPlanForm
            defaultValues={{
              paymentPlan,
            }}
            onSubmit={(values) => {
              setPaymentPlan(values.paymentPlan);
              processSubmitAction(values.submitAction);
            }}
          />
        );
      case STEPS.CONFIRM_SELECTIONS:
        return (
          <ConfirmSelections
            sunscreen={
              sunscreen
                ? sunscreenOptions.find(
                    (option) => option.id === parseInt(sunscreen, 10),
                  ).name
                : 'NA'
            }
            treatmentAreas={treatmentAreas}
            paymentPlan={paymentPlan}
            onPrevious={() => {
              processSubmitAction(SUBMIT_TYPES.previous);
            }}
            onConfirm={() => {
              processSubmitAction(SUBMIT_TYPES.submit);
            }}
          />
        );
      case STEPS.WELCOME:
        return (
          <Welcome
            onDone={() => {
              processSubmitAction(SUBMIT_TYPES.done);
            }}
          />
        );
      default:
        throw new Error(`Step ${activeStep} not supported`);
    }
  };

  return (
    <Modal
      id={modalId}
      onClose={() => {
        processSubmitAction(SUBMIT_TYPES.cancel);
      }}
    >
      {getActiveComponent()}
    </Modal>
  );
};

AcceptTreatmentPlanModal.propTypes = {
  user: PropTypes.object.isRequired,
  modalId: PropTypes.string.isRequired,
  sunscreenOptions: PropTypes.array,
  program: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ reviewTreatmentPlan }) => ({
  ...reviewTreatmentPlan,
});

export default connect(
  mapStateToProps,
  mapModalDispatchToProps,
)(userAware(AcceptTreatmentPlanModal));
