import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { mapModalDispatchToProps } from 'helpers/redux';
import { isPracticeManager, isCoach, isClient } from 'helpers/user';
import { useLoggedInUser } from 'hooks';
import { useBranch } from 'components/BranchProvider';

import Button from 'components/Button';
import InviteClientWidget from 'containers/InviteClientWidget';
import InviteMemberWidget from 'containers/InviteMemberWidget';

import DashboardTour from './DashboardTour';
import ClientDashboard from './ClientDashboard';
import CoachDashboard from './CoachDashboard';
import ManagerDashboard from './ManagerDashboard';

import theme from './Dashboard.sss';

const INVITE_CLIENT_MODAL_ID = 'AdminDashboard/InviteClient';
const INVITE_MEMBER_MODAL_ID = 'AdminDashboard/InviteMember';

// eslint-disable-next-line react/prefer-stateless-function
function Dashboard({ setOpenModalId }) {
  const [branch] = useBranch();
  const user = useLoggedInUser();
  function renderDashboard() {
    if (user.email === 'eric.garcia+admin@grillotech.com') {
      return (
        <div className={theme.AdminWrapper}>
          {branch.invite_client_enabled && (
            <Button
              size="big"
              onClick={() => {
                setOpenModalId(INVITE_CLIENT_MODAL_ID);
              }}
            >
              Invite Client
            </Button>
          )}
          {branch.invite_member_enabled && (
            <Button
              size="big"
              onClick={() => {
                setOpenModalId(INVITE_MEMBER_MODAL_ID);
              }}
            >
              Invite Member
            </Button>
          )}
          {branch.invite_client_enabled && (
            <InviteClientWidget modalId={INVITE_CLIENT_MODAL_ID} />
          )}
          {branch.invite_member_enabled && (
            <InviteMemberWidget modalId={INVITE_MEMBER_MODAL_ID} />
          )}
        </div>
      );
    }

    if (isClient(user)) {
      return <ClientDashboard />;
    }

    if (isCoach(user)) {
      return <CoachDashboard />;
    }

    if (isPracticeManager(user)) {
      return <ManagerDashboard />;
    }
    toast.error('Something went wrong. Please refresh your page.');
    return null;
  }

  return (
    <div className={theme.Dashboard}>
      {renderDashboard()}
      <DashboardTour />
    </div>
  );
}

Dashboard.propTypes = {
  setOpenModalId: PropTypes.func.isRequired,
};

export default connect(null, mapModalDispatchToProps)(Dashboard);
