/* eslint max-depth: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

import axios from 'config/axios';
import Constant from 'utils/constants';
import { getErrorMessage } from 'helpers/errorHandling';
import { TYPES } from 'helpers/note';
import { url } from 'helpers/user';
import { apiBaseUrl, userTypeIds } from 'config/config';
import { userAware } from 'components/UserProvider';
import Loader from 'components/Loader';
import * as userInboxActions from 'containers/InboxWidget/UserInboxWidget/UserInboxWidget.ducks';

import { Header, Body } from './components';

import * as actions from './ClientDetails.ducks';

const collectors = {
  healthQuestionnaire: `${apiBaseUrl}surveys/collectors/1/`,
  checkin: `${apiBaseUrl}surveys/collectors/2/`,
};

class ClientDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: {},
      notes: {},
      filter: TYPES.general,
      noteAuthorMap: [],
      surveys: [],
      clientProgram: {},
      programStages: [],
      isLoading: true,
      lastCheckinDate: null,
      nextCheckinDate: null,
      isPracticeManager: false,
      isCoachUser: false,
    };

    this.headers = {
      Authorization: `Bearer ${props.user.jwt}`,
      'Content-Type': 'application/json',
    };

    this.messageThreadsUpdated = false;
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.getClientDetails(id);

    const { user } = this.props;
    const { userType } = user;
    const isPracticeManager =
      userType && userType.id && userType.id === userTypeIds.practice_manager;
    const isCoachUser =
      userType && userType.id && userType.id === userTypeIds.coach;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      isPracticeManager,
      isCoachUser,
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.details.billing_address &&
      this.state.details.billing_address
    ) {
      this.getAddresses();
    }
  }

  getAddresses = async () => {
    try {
      /* eslint-disable-next-line */
      const { billing_address, shipping_address } = this.state.details;
      const promises = [
        axios.get(`addresses/${billing_address}/`), // eslint-disable-line camelcase
        axios.get(`addresses/${shipping_address}/`), // eslint-disable-line camelcase
      ];

      const addresses = await Promise.all(promises);
      this.setState((prevState) => ({
        ...prevState,
        details: {
          ...prevState.details,
          billingAddress: addresses[0].data,
          shippingAddress: addresses[1].data,
        },
      }));
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  getClientDetails = async (id) => {
    const { fetchProgram, match } = this.props;
    this.setState({ isLoading: true });
    try {
      const promises = [
        this.getProfile(id),
        this.getUser(id),
        this.getClientNotes(id, TYPES.general),
        this.getClientResponses(id),
        this.getSurveys(),
        this.getProgram(),
      ];

      const results = await Promise.all(promises);
      results[2].results.forEach((note) =>
        this.getNoteAuthor(url(note.author)),
      );

      let programStages = false;
      if (results[5].results && results[5].results.length) {
        const stages = await Promise.resolve(
          this.getProgramStages(results[5].results[0].id),
        );
        const startedStage = await this.getActiveProgramStage(
          results[5].results[0].id,
        );
        if (stages && stages.length) {
          programStages = stages;
          if (startedStage) {
            this.setState({
              nextCheckinDate: moment(startedStage.check_in_scheduled).format(
                'MMMM D, YYYY',
              ),
            });
          }
        }
      }

      if (results[3].results && results[3].results.length) {
        const lastCompletedCheckin = results[3].results
          .filter(
            (response) =>
              response.collector === collectors.checkin &&
              response.state === Constant.stage.state.completed,
          )
          .sort((a, b) => b.id - a.id)[0];

        if (lastCompletedCheckin) {
          this.setState({
            lastCheckinDate: moment(lastCompletedCheckin.updated).format(
              'MMMM D, YYYY',
            ),
          });
        }
      }
      this.setState({
        details: {
          ...results[0],
          client: results[1].url,
          user_id: results[1].id,
          email: results[1].email,
        },
        notes: results[2],
        responses: results[3].results,
        surveys: results[4].results,
        clientProgram:
          results[5].results && results[5].results.length
            ? results[5].results[0]
            : {},
        programStages,
      });
      fetchProgram({ userId: match.params.id });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  getProgram = () =>
    axios
      .get(`programs/acne/?user=${this.props.match.params.id}`)
      .then((response) => response.data);

  getProgramStages = (programId) =>
    axios
      .get(`programs/acne/${programId}/stages/`)
      .then((response) => response.data);

  getActiveProgramStage = async (programId) => {
    const response = await axios.get('stages/', {
      params: {
        acne_program: programId,
        state: [
          Constant.stage.state.started,
          Constant.stage.state.checkinMissed,
          Constant.stage.state.upcomingCheckinSent,
          Constant.stage.state.checkinSent,
          Constant.stage.state.upcomingCheckinSent,
        ],
      },
    });
    return response.data.results[0];
  };

  getClientNotes = (id, type) => {
    if (type === TYPES.task) {
      return axios.get(`users/clients/${id}/linked-tasks/`).then((response) => {
        const taskNotes = response.data
          .filter((task) => {
            return task.note;
          })
          .map((task) => {
            return { ...task.note, action: task.action };
          });
        return { results: taskNotes };
      });
    }
    return axios
      .get('notes/', {
        params: {
          subject: id,
          type: [type],
        },
      })
      .then((response) => response.data);
  };

  getClientResponses = (id) =>
    axios
      .get(`surveys/responses/?user=${id}`)
      .then((response) => response.data);

  getNoteAuthor = async (authorUrl) => {
    const { noteAuthorMap } = this.state;
    const mapping = noteAuthorMap.filter((map) => map.authorUrl === authorUrl);
    if (mapping.length) {
      // author exists in the map
      return mapping[0].authorName;
    }
    // must fetch the data
    const newMapping = await axios
      .get(`${authorUrl}profile/`)
      .then((response) => response.data)
      .then((res) => {
        return {
          authorUrl,
          authorName: res.full_name,
        };
      });
    this.setState((prevState) => ({
      ...prevState,
      noteAuthorMap: [...prevState.noteAuthorMap, newMapping],
    }));
    return newMapping.author;
  };

  getProfile = (id) =>
    axios.get(`users/${id}/profile/`).then((response) => response.data);

  getSurveys = () => axios.get('surveys/').then((response) => response.data);

  getUser = (id) => axios.get(`users/${id}/`).then((response) => response.data);

  setNoteAuthor = (authorUrl) => {
    try {
      return this.state.noteAuthorMap.filter(
        (mapping) => mapping.authorUrl === authorUrl,
      )[0].authorName;
    } catch (error) {
      return '';
    }
  };

  updateCheckin = (date) => {
    const { program } = this.props;
    const { programStages } = this.state;
    const lastStage = programStages.slice(-1)[0];
    try {
      axios.put(`stages/${lastStage.id}/`, {
        acne_program: program.id,
        check_in_scheduled: date,
      });
      this.setState({
        nextCheckinDate: moment(date).format('MMMM D, YYYY'),
      });
      toast.success('The checkin date was updated successfully');
    } catch (error) {
      toast.error(
        getErrorMessage(error, 'Could not update the checkin date right now'),
      );
    }
  };

  updateTreatmentArea = async (treatmentAreas) => {
    const { clientProgram } = this.state;
    const { data: newProgram } = await axios.patch(
      `programs/acne/${clientProgram.id}/`,
      {
        treatment_area: treatmentAreas,
      },
    );
    this.setState({
      clientProgram: newProgram,
    });
  };

  updateMaintenancePlan = async (maintenancePlan) => {
    const { clientProgram } = this.state;
    const { data: newProgram } = await axios.patch(
      `programs/acne/${clientProgram.id}/`,
      {
        maintenance_plan: parseInt(maintenancePlan.id, 10),
      },
    );
    this.setState({
      clientProgram: newProgram,
    });
  };

  submitNote = async (note) => {
    try {
      const { user } = this.props;
      const newNote = await axios
        .post('notes/', JSON.stringify(note))
        .then((response) => response.data);
      if (note.type === this.state.filter) {
        this.setState((prevState) => ({
          ...prevState,
          notes: {
            ...prevState.notes,
            results: [
              ...prevState.notes.results,
              {
                ...newNote,
                authorName: `${user.profile.first_name} ${user.profile.last_name}`,
              },
            ],
          },
        }));
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  submitEditNote = async (note) => {
    try {
      const { data: newNote } = await axios.patch(`notes/${note.id}/`, {
        ...note,
      });
      this.setState((prevState) => ({
        ...prevState,
        notes: {
          ...prevState.notes,
          results: prevState.notes.results.map((prevNote) => {
            return prevNote.id === newNote.id ? newNote : prevNote;
          }),
        },
      }));
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  createThread = async () => {
    const { createThread } = this.props;
    const userId = this.props.match.params.id;

    const participants = [`${apiBaseUrl}users/${userId}/`];
    const newThread = await createThread({ participants });
    this.props.history.push(`/messages/${newThread.id}`);
  };

  render() {
    const { match, user, activateProgram, updateProgramWeek, program } =
      this.props;
    const {
      details,
      notes,
      responses,
      surveys,
      clientProgram,
      lastCheckinDate,
      nextCheckinDate,
    } = this.state;

    try {
      if (notes.results) {
        notes.results = notes.results.map((note) => {
          return {
            ...note,
            authorName: this.setNoteAuthor(note.author),
          };
        });
      } else {
        notes.results = [];
      }
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }

    if (this.state.isLoading) {
      return <Loader loading={this.state.isLoading} />;
    }

    return (
      <>
        <Header
          details={details}
          clientProgram={clientProgram}
          programIndex={program.program_index}
          user={user}
          lastCheckinDate={lastCheckinDate}
          nextCheckinDate={nextCheckinDate}
          updateCheckin={this.updateCheckin}
          updateProgramWeek={updateProgramWeek}
          onTreatmentAreaChange={this.updateTreatmentArea}
          onMaintenancePlanChange={this.updateMaintenancePlan}
          createThread={this.createThread}
          activateProgram={async (programId, isStarted) => {
            await activateProgram({ programId, isStarted });
          }}
        />
        <Body
          user={user}
          details={details}
          notes={notes}
          submit={this.submitNote}
          submitEditNote={this.submitEditNote}
          onFilterChange={async (filter) => {
            const newNotes = await this.getClientNotes(match.params.id, filter);
            this.setState({ notes: newNotes });
            this.setState({ filter });
          }}
          surveys={surveys}
          responses={responses}
          clientProgram={clientProgram}
        />
      </>
    );
  }
}

ClientDetails.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  program: PropTypes.object.isRequired,
  fetchProgram: PropTypes.func.isRequired,
  activateProgram: PropTypes.func.isRequired,
  updateProgramWeek: PropTypes.func.isRequired,
  createThread: PropTypes.func.isRequired,
};

const mapStateToProps = ({ clientDetails }) => ({
  ...clientDetails,
});

export default withRouter(
  connect(mapStateToProps, {
    createThread: userInboxActions.createThread,
    ...actions,
  })(userAware(ClientDetails)),
);
