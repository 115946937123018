import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from 'components/Loader';
import { useBranch } from 'components/BranchProvider';

import * as actions from './ViewRegimen.ducks';
import ViewRegimen from './components/ViewRegimen';

function ViewRegimenPage({
  initialize,
  match,
  loading,
  stage,
  regimens,
  download,
}) {
  const [branch] = useBranch();
  const { stageId } = match.params;
  React.useEffect(() => {
    initialize({ stageId, branch });
  }, [branch, initialize, stageId]);

  if (loading) {
    return <Loader />;
  }
  return (
    <ViewRegimen
      stage={stage}
      regimens={regimens}
      onDownloadAsPDFClick={download}
    />
  );
}

ViewRegimenPage.propTypes = {
  match: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  stage: PropTypes.object.isRequired,
  regimens: PropTypes.array.isRequired,
  initialize: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
};

const mapStateToProps = ({ viewRegimen }) => ({
  ...viewRegimen,
});

export default withRouter(connect(mapStateToProps, actions)(ViewRegimenPage));
