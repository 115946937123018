import { useReducer } from 'reinspect';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions
export const fetchDataInitialized = createAction(
  'usePaginator/FETCH_INITIALIZED',
);
export const fetchDataSuceeded = createAction(
  'usePaginator/FETCH_DATA_SUCEEDED',
);
export const fetchDataFailed = createAction('usePaginator/FETCH_DATA_FAILED');
export const setData = createAction('cc/usePaginator/SET_DATA');
export const reset = createAction('cc/usePaginator/RESET');

const initialState = {
  isLoading: false,
  didReset: false,
  currentPage: 0,
  data: [],
  total: 0,
  next: '',
};

// Reducer

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchDataInitialized, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchDataSuceeded, (state, action) => {
      state.data = [...state.data, ...action.payload.data];
      state.total = action.payload.total;
      state.next = action.payload.next;
      state.isLoading = false;
      state.didReset = false;
      state.currentPage++;
    })
    .addCase(fetchDataFailed, (state) => {
      state.isLoading = false;
    })
    .addCase(setData, (state, action) => {
      state.data = action.payload;
    })
    .addCase(reset, () => ({ ...initialState, didReset: true }));
});

const usePaginatorReducer = (id) =>
  useReducer(reducer, initialState, (state) => state, id);

export default usePaginatorReducer;
