import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { SingleChoice } from 'components/Form';

import Question from 'pages/NewSurvey/CompleteSurvey/Question';

import theme from './SingleMatrix.sss';

const SingleMatrix = forwardRef(
  ({ defaultValue, error, rows, choices, ...others }, ref) => {
    const [selectedChoices, setSelectedChoices] = useState([]);
    useEffect(() => {
      others.onChange?.(selectedChoices);
    }, [selectedChoices]);
    return (
      <ul className={theme.SingleMatrix} {...others}>
        {rows.map(({ id, text }, i) => (
          <li className={theme.Row} key={i}>
            <label dangerouslySetInnerHTML={{ __html: text }}></label>
            <SingleChoice
              ref={ref}
              error={JSON.parse(error).find(({ row }) => row === id)?.error}
              defaultValue={
                defaultValue
                  ?.find(({ row }) => row === id)
                  ?.defaultValue?.toString() ?? null
              }
              orientation="horizontal"
              choices={choices.map((choice) => ({
                title: choice.text,
                value: choice.id.toString(),
                followupQuestions: choice.followup_questions.map(
                  (followUpQuestion) => (
                    <Question
                      key={`question-${followUpQuestion.id}`}
                      question={followUpQuestion}
                    />
                  ),
                ),
              }))}
              onChange={(value) => {
                setSelectedChoices([
                  {
                    row: id,
                    choice: Number(value),
                  },
                ]);
              }}
            />
          </li>
        ))}
      </ul>
    );
  },
);

SingleMatrix.propTypes = {
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number.isRequired,
      defaultValue: PropTypes.number.isRequired,
    }),
  ),
  error: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

SingleMatrix.defaultProps = {
  error: '[]',
};

export default SingleMatrix;
