import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './BadgeButton.sss';

const BadgeButton = ({
  active,
  count,
  classes,
  children,
  ...buttonProps
}) => (
  <button
    className={classNames(theme.BadgeButton, {
      [theme.BadgeButtonActive] : active,
      ...classes,
    })}
    type="button"
    {...buttonProps}
  >
    <span className={classNames(theme.Badge, {[theme.BadgeActive] : active,})}>{count}</span>
    <span className={classNames(theme.BadgeText, {[theme.BadgeTextActive] : active,})}>{children}</span>
  </button>
);

BadgeButton.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number.isRequired,
  classes: PropTypes.array,
  selected: PropTypes.bool,
  active: PropTypes.bool,
};

BadgeButton.defaultProps = {
  classes: [],
  selected: false,
};

export default BadgeButton;
