import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Filter from 'components/Filter';
import Loader from 'components/Loader';

import theme from './NotesModal.sss';
import Note from './Note';

const NotesModal = ({
  clientName,
  loading,
  notes,
  modalId,
  types,
  defaultFilter,
  changeFilter,
}) => {
  let content;
  if (loading) {
    content = <Loader />;
  } else if (notes.length) {
    content = (
      <ul className={theme.Notes}>
        {notes.map((note, key) => (
          <Note key={key} note={note} />
        ))}
      </ul>
    );
  } else {
    content = <h1>No notes found</h1>;
  }
  return (
    <Modal
      className={theme.NotesModal}
      id={modalId}
      size="medium"
      title={`${clientName} Notes`}
      autoClose
    >
      <div className={theme.NotesModalContent}>
        <Filter
          filters={types}
          defaultValue={defaultFilter}
          onChange={(filter) => {
            changeFilter(filter);
          }}
        />
        {content}
      </div>
    </Modal>
  );
};

NotesModal.propTypes = {
  notes: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  clientName: PropTypes.string,
  modalId: PropTypes.string.isRequired,
  defaultFilter: PropTypes.string,
  types: PropTypes.array.isRequired,
  changeFilter: PropTypes.func.isRequired,
};

NotesModal.defaultProps = {
  notes: [],
  clientName: '',
};

export default NotesModal;
