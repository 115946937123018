import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import ThreadService from 'services/thread.service';

import { useLoggedInUser } from 'hooks';
import { useBranch } from 'components/BranchProvider';
import { TR, TD } from 'components/Table';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { TDNote } from 'components/Table';

import { NOTES_MODAL_ID, ADD_NOTE_MODAL_ID } from './ProspectsTable';

import theme from './ProspectRow.sss';

const STRINGS_BY_STATUS = {
  awaiting_hq: {
    profileLink: ({ id: clientId }) => `/client-details/${clientId}`,
    status: 'Awaiting health questionnaire',
    action: 'Message Client',
  },
  awaiting_to_response_treatment: {
    profileLink: ({ id: clientId }) => `/client-details/${clientId}`,
    status: 'Awaiting treatment plan response',
    action: 'Message Client',
  },
  awaiting_to_consent: {
    profileLink: ({ id: clientId }) => `/client-details/${clientId}`,
    status: 'Awaiting consent form',
    action: 'Message Client',
  },
  awaiting_to_start: {
    profileLink: ({ id: clientId }) => `/client-details/${clientId}`,
    status: 'Awaiting for client to start the program',
    action: 'Message Client',
  },
};

const ProspectRow = ({ prospect, onActionClick }) => {
  const user = useLoggedInUser();
  const [branch] = useBranch();

  const [thread, setThread] = useState();
  const [loading, setLoading] = useState(false);
  const isShowNextAppoinmentEnable = branch.show_next_appointment_enabled;
  const date = prospect.next_appointment?.appointment_services?.[0]?.start_time;

  useEffect(() => {
    setLoading(true);
    ThreadService.getByOnlyParticipants([user, prospect]).then((userThread) => {
      setThread(userThread);
      setLoading(false);
    });
  }, [user, prospect]);

  const strings = STRINGS_BY_STATUS[prospect.prospect_status];

  function renderMessageClient() {
    if (loading) {
      return <Loader size="small" />;
    }
    if (thread) {
      return (
        <Link className={[theme.Link]} to={`/messages/${thread.id}`}>
          {strings.action}
        </Link>
      );
    }
    return (
      <Button
        classes={[theme.ActionButton]}
        color="sensitive"
        onClick={() => {
          onActionClick(prospect);
        }}
      >
        {strings.action}
      </Button>
    );
  }

  return (
    <TR>
      <TD>
        <Link className={theme.Link} to={strings.profileLink(prospect)}>
          {`${prospect.profile.first_name} ${prospect.profile.last_name}`}
        </Link>
      </TD>
      <TD>{strings.status}</TD>
      {isShowNextAppoinmentEnable && (
        <TD>{date && format(new Date(date), 'MM/dd/yyyy hh:mm a')}</TD>
      )}
      <TD>{branch.messaging_enabled && renderMessageClient()}</TD>
      <TDNote
        clientID={prospect.id}
        note={prospect.latest_note}
        viewNotesModalID={NOTES_MODAL_ID}
        addNoteModalID={ADD_NOTE_MODAL_ID}
      />
    </TR>
  );
};

ProspectRow.propTypes = {
  thread: PropTypes.object,
  prospect: PropTypes.object.isRequired,
  onActionClick: PropTypes.func.isRequired,
};

export default ProspectRow;
