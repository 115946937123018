/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import axios from 'config/axios';
import Constant from 'utils/constants';
import { pageIds, questionIds } from 'config/config';
import { getErrorMessage } from 'helpers/errorHandling';
import { isFileUpload } from 'helpers/question';
import { isHQ } from 'helpers/survey';

import * as surveyActions from 'pages/NewSurvey/Survey.ducks';
import * as userActions from 'actions/userActions';

// Actions
const INITIALIZE = 'cc/CompleteSurvey/INITIALIZE';
const INITIALIZE_SUCCEESS = 'cc/CompleteSurvey/INITIALIZE_SUCCEESS';
const INITIALIZE_FAILED = 'cc/CompleteSurvey/INITIALIZE_FAILED';
const SEND = 'cc/CompleteSurvey/SEND';
const SEND_SUCCEESS = 'cc/CompleteSurvey/SEND_SUCCEESS';
const SEND_FAILED = 'cc/CompleteSurvey/SEND_FAILED';
const SAVE_AS_DRAFT = 'cc/CompleteSurvey/SAVE_AS_DRAFT';
const SAVE_AS_DRAFT_SUCCEESS = 'cc/CompleteSurvey/SAVE_AS_DRAFT_SUCCEESS';
const SAVE_AS_DRAFT_FAILED = 'cc/CompleteSurvey/SAVE_AS_DRAFT_FAILED';

const initialState = {
  loading: false,
  response: {},
  submitting: false,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state, loading: true };
    case INITIALIZE_SUCCEESS: {
      const { response } = action.payload;
      return { ...state, response, loading: false };
    }
    case INITIALIZE_FAILED:
      return { ...state, loading: false };
    case SEND:
    case SAVE_AS_DRAFT:
      return { ...state, submitting: true };
    case SEND_SUCCEESS:
    case SEND_FAILED:
    case SAVE_AS_DRAFT_SUCCEESS:
    case SAVE_AS_DRAFT_FAILED:
      return { ...state, submitting: false };
    default:
      return state;
  }
};

// Helpers

const formatImageAnswerBody = async ({ context, image }) => {
  if (typeof image === 'number') {
    return {
      context,
      image,
    };
  }
  const data = new FormData();
  data.append('upload', image);
  const { data: newImage } = await axios.post('images/', data);
  return {
    context,
    image: newImage.id,
  };
};

const buildAnswers = async (answers, questions) => {
  return await Promise.all(
    answers.map(async (answer) => {
      const question = questions.find(
        ({ id }) => id === Number(answer.question),
      );
      if (question && isFileUpload(question)) {
        return {
          ...answer,
          image_group_answer: {
            image_answers: await Promise.all(
              answer.image_group_answer.image_answers
                .filter(({ image }) => image)
                .map((imageAnswer) => formatImageAnswerBody(imageAnswer)),
            ),
          },
        };
      }
      return answer;
    }),
  );
};

const initialize = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  const { responseId } = payload;
  try {
    let response = {};
    if (responseId) {
      const { data } = await axios.get(
        `surveys/responses/${responseId}/details/`,
      );
      response = data;
    }
    dispatch(initializeSuccess({ response }));
  } catch (error) {
    dispatch(initializeFailed(error));
  }
};

const initializeSuccess = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCEESS, payload });
};

const initializeFailed = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE_FAILED, payload });
};

const send = (payload) => async (dispatch, getState) => {
  dispatch({ type: SEND, payload });
  const { response } = getState().completeSurvey;
  const { survey, userId, collectorId, stageId, page, answers, questions } =
    payload;
  try {
    if (response.id) {
      await axios.patch(`surveys/responses/${response.id}/`, {
        page,
        state: Constant.response.state.completed,
        answers: await buildAnswers(answers, questions),
      });
    } else {
      await axios.post('surveys/responses/', {
        user: userId,
        collector: collectorId,
        page,
        stage: stageId,
        state: Constant.response.state.completed,
        answers: await buildAnswers(answers, questions),
      });
    }
    dispatch(sendSuccess({ survey }));
    return true;
  } catch (error) {
    dispatch(sendFailed(error));
    return false;
  }
};

const sendSuccess = (payload) => async (dispatch) => {
  dispatch({ type: SEND_SUCCEESS, payload });
  const { survey } = payload;
  if (isHQ(survey)) {
    dispatch(userActions.updateProfile({ has_hq_completed: true }));
  }
};

const sendFailed = (payload) => async (dispatch) => {
  dispatch({ type: SEND_FAILED, payload });
  toast.error(
    getErrorMessage(payload, 'There was an error while sending your survey'),
  );
};

const saveAsDraft = (payload) => async (dispatch, getState) => {
  dispatch({ type: SAVE_AS_DRAFT, payload });
  const { response } = getState().completeSurvey;
  const { survey, collectorId, stageId, userId, page, answers, questions } =
    payload;
  try {
    if (response.id) {
      await axios.patch(`surveys/responses/${response.id}/`, {
        answers: await buildAnswers(answers, questions),
        page,
      });
    } else {
      await axios.post('surveys/responses/', {
        user: userId,
        collector: collectorId,
        stage: stageId,
        answers: await buildAnswers(answers, questions),
        page,
      });
    }

    // NOTE: Fetch survey again in case the user gender has been updated
    const hasGender =
      page === pageIds.medicalHistory &&
      answers.some(
        ({ question }) => question === questionIds.healthQuestionnaire.gender,
      );
    if (hasGender) {
      dispatch(
        surveyActions.fetchSurveyDetails({
          userId,
          surveyId: survey.id,
        }),
      );
    }

    dispatch(saveAsDraftSuccess());
    return true;
  } catch (error) {
    dispatch(saveAsDraftFailed(error));
    return false;
  }
};

const saveAsDraftSuccess = (payload) => async (dispatch) => {
  dispatch({ type: SAVE_AS_DRAFT_SUCCEESS, payload });
  toast.success('Your survey was saved');
};

const saveAsDraftFailed = (payload) => async (dispatch) => {
  dispatch({ type: SAVE_AS_DRAFT_FAILED, payload });
  toast.error(
    getErrorMessage(payload, 'There was an error while saving your survey'),
  );
};

export default reducer;
export {
  // Actions
  initialize,
  initializeSuccess,
  initializeFailed,
  send,
  sendSuccess,
  sendFailed,
  saveAsDraft,
  saveAsDraftSuccess,
  saveAsDraftFailed,
  // Action Creators
  INITIALIZE,
  INITIALIZE_SUCCEESS,
  INITIALIZE_FAILED,
  SEND,
  SEND_SUCCEESS,
  SEND_FAILED,
  SAVE_AS_DRAFT,
  SAVE_AS_DRAFT_SUCCEESS,
  SAVE_AS_DRAFT_FAILED,
};
