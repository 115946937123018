import Constant from 'utils/constants';

export function getUnreadNotificationCount(notifications) {
  let count = 0;
  if (notifications && notifications.length) {
    notifications.forEach((notification) => {
      if (notification.state === 'unread') {
        count++;
      }
    });
  }
  return count;
}

export const isRead = ({state}) => state === Constant.notification.state.read;
export const isUnread = ({state}) => state === Constant.notification.state.unread;
