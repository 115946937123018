/* eslint-disable max-len */

import React from 'react';

const FilterIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 10 2 C 8.6983906 2 7.5978609 2.8387486 7.1835938 4 L 2 4 L 2 6 L 7.1835938 6 C 7.5978609 7.1612514 8.6983906 8 10 8 C 11.654 8 13 6.654 13 5 C 13 3.346 11.654 2 10 2 z M 15 4 L 15 6 L 22 6 L 22 4 L 15 4 z M 15 9 C 13.698391 9 12.597861 9.8387486 12.183594 11 L 2 11 L 2 13 L 12.183594 13 C 12.597861 14.161251 13.698391 15 15 15 C 16.654 15 18 13.654 18 12 C 18 10.346 16.654 9 15 9 z M 20 11 L 20 13 L 22 13 L 22 11 L 20 11 z M 7 16 C 5.6983906 16 4.5978609 16.838749 4.1835938 18 L 2 18 L 2 20 L 4.1835938 20 C 4.5978609 21.161251 5.6983906 22 7 22 C 8.654 22 10 20.654 10 19 C 10 17.346 8.654 16 7 16 z M 12 18 L 12 20 L 22 20 L 22 18 L 12 18 z" fill="#000000" />
  </svg>
);

FilterIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default FilterIcon;
