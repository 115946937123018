/* eslint no-use-before-define: 0 */
import axios from 'config/axios';
import { toast } from 'react-toastify';

import Constant from 'utils/constants';

// Actions
const FETCH_PROGRAM = 'cc/ClientDetails/FETCH_PROGRAM';
const FETCH_PROGRAM_FAIL = 'cc/ClientDetails/FETCH_PROGRAM_FAIL';
const ACTIVATE_PROGRAM = 'cc/ClientDetails/ACTIVATE_PROGRAM';
const UPDATE_PROGRAM_SUCCESS = 'cc/ClientDetails/UPDATE_PROGRAM';
const UPDATE_PROGRAM_WEEK = 'cc/ClientDetails/UPDATE_PROGRAM_WEEK';
const ACTIVATE_PROGRAM_FAIL = 'cc/ClientDetails/ACTIVATE_PROGRAM_FAIL';

const initialState = {
  tasks: [],
  program: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROGRAM_SUCCESS: {
      const { program } = action.payload;
      return {
        ...state,
        program,
      };
    }
    default:
      return state;
  }
};

// Action Creators

const updateProgramSuccess = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_PROGRAM_SUCCESS, payload });
};

const updateProgramWeek = (payload) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_PROGRAM_WEEK, payload });
  const { program } = getState().clientDetails;
  try {
    const { data: updatedProgram } = await axios.patch(
      `programs/acne/${program.id}/`,
      {
        program_index: Number(payload),
      },
    );
    dispatch(updateProgramSuccess({ program: updatedProgram }));
  } catch (error) {
    toast.error('Your program week cannot be edited');
  }
};

const activateProgram = (payload) => async (dispatch, getState) => {
  dispatch({ type: ACTIVATE_PROGRAM, payload });
  const { jwt } = getState().user;
  const { programId, isStarted } = payload;
  try {
    const { data: program } = await axios.patch(
      `programs/acne/${programId}/`,
      {
        state: isStarted
          ? Constant.program.state.started
          : Constant.program.state.new,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      },
    );
    dispatch(updateProgramSuccess({ program }));
    window.location.reload();
  } catch (error) {
    dispatch(activateProgramFail(error));
  }
};

const activateProgramFail = (payload) => (dispatch) => {
  dispatch({ type: ACTIVATE_PROGRAM_FAIL, payload });
};

const fetchProgram = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_PROGRAM, payload });
  const { userId } = payload;
  try {
    const response = await axios.get(`programs/acne/?user=${userId}`);
    if (response.data.results.length > 0) {
      dispatch(updateProgramSuccess({ program: response.data.results[0] }));
    }
  } catch (error) {
    dispatch(fetchProgramFail(error));
  }
};

const fetchProgramFail = (payload) => (dispatch) => {
  dispatch({ type: FETCH_PROGRAM_FAIL, payload });
};

export default reducer;
export {
  // Actions
  FETCH_PROGRAM,
  FETCH_PROGRAM_FAIL,
  ACTIVATE_PROGRAM,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_WEEK,
  ACTIVATE_PROGRAM_FAIL,
  // Action Creators
  fetchProgram,
  fetchProgramFail,
  activateProgram,
  updateProgramSuccess,
  updateProgramWeek,
  activateProgramFail,
};
