import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import history from 'global-history';
import Constant from 'utils/constants';
import { userAware } from 'components/UserProvider';
import { isClient, isCoach } from 'helpers/user';
import { isUnread } from 'helpers/notification';

import * as actions from './NotificationListWidget.ducks';
import theme from './NotificationListWidget.sss';
import NotificationList from './components';

export class NotificationListWidget extends Component {

  componentDidMount() {
    const { user, fetchNotifications } = this.props;
    fetchNotifications();
    document.addEventListener('click', this.handleClickOutside);
    if (isClient(user)) {
      this.fetchNotificationsInterval = setInterval(() => {
        fetchNotifications();
      }, 60000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    clearInterval(this.fetchNotificationsInterval);
  }

  containerNode = React.createRef();

  handleClickOutside = (ev) => {
    const { open, setOpen } = this.props;
    const component = ev.target.getAttribute('target');
    if (component === NotificationListWidget.displayName || !this.containerNode.current) {
      return;
    }

    if (!this.containerNode.current.contains(ev.target) && open) {
      setOpen(false);
    }
  }

  handleNotificationClick = (notification) => {
    const { setOpen, readNotification } = this.props;

    if (isUnread(notification)) {
      readNotification(notification);
    }

    setOpen(false);
    this.handleNavigation(notification);
  }

  handleNavigation = (notification) => {
    const { user } = this.props;
    const { trigger, context } = notification;
    const {
      user_id: userId,
      survey_id: surveyId,
      response_id: responseId,
      stage_id: stageId,
    } = context;

    switch(trigger) {
      case Constant.notification.trigger.surveyResponseCompleted: {
        history.push(`/client-details/${userId}/surveys/${surveyId}/${responseId}/`);
        break;
      }
      case Constant.notification.trigger.checkInMissed: {
        if (isClient(user)) {
          history.push('/');
        } else if (isCoach(user)) {
          history.push(`/client-details/${userId}/`);
        }
        break;
      }
      case Constant.notification.trigger.stagePublished: {
        history.push(`/view-regimen/${stageId}`);
        break;
      }
      case Constant.notification.trigger.treatmentPlanAccepted: {
        history.push(`/client-details/${userId}`);
        break;
      }
      case Constant.notification.trigger.treatmentPlanRejected: {
        history.push(`/client-details/${userId}`);
        break;
      }
      default: {
        throw new Error(`Notification trigger ${trigger} not supported`);
      }
    }
  }

  render() {
    const {
      open,
      notifications,
      removeNotification,
      readNotification,
      unreadNotification,
    } = this.props;

    let content;
    if (notifications.length) {
      content = (
        <>
          <header>Notifications</header>
          <NotificationList
            open={open}
            notifications={notifications}
            onClearNotification={(notification) => { removeNotification(notification); }}
            onReadNotification={(notification) => { readNotification(notification); }}
            onUnreadNotification={(notification) => { unreadNotification(notification); }}
            onNotificationClick={this.handleNotificationClick}
          />
        </>
      );
    } else {
      content = <p className={theme.Empty}>You have no notifications</p>;
    }
    return (
      <div
        className={classNames([theme.NotificationListWidget, {
          [theme.Open]: open,
        }])}
        ref={this.containerNode}
      >
        {content}
      </div>
    );
  }
}

NotificationListWidget.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  unreadNotification: PropTypes.func.isRequired,
};

NotificationListWidget.displayName = 'NotificationListWidget';

const mapStateToProps = ({ notificationList }) => ({
  ...notificationList,
});

export default connect(mapStateToProps, actions)(
  userAware(NotificationListWidget)
);
