import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Constant from 'utils/constants';
import { getErrorMessage } from 'helpers/errorHandling';
import { useGetProfile, useUpdateUser } from 'features/users/user.query';

import ErrorBoundary from 'components/ErrorBoundary';
import * as modalActions from 'components/Modal/Modal.ducks';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import Form from 'components/Form';

function RemoveClientModal({ modalId, clientId, onClientRemoved }) {
  const dispatch = useDispatch();

  const { data: profile, isLoading } = useGetProfile(clientId);
  const { mutate: updateUser, isLoading: isUpdating } = useUpdateUser();

  if (isLoading) {
    return (
      <Modal id={modalId} size="medium" title="Remove client" autoClose>
        <Loader />
      </Modal>
    );
  }

  return (
    <ErrorBoundary>
      <Modal id={modalId} size="medium" title="Remove client" autoClose>
        <Form
          title={`Are you sure you want to remove ${profile.full_name}?`}
          description="If you remove this client, you will no longer be able to recover
          it"
          onSubmit={() => {
            updateUser(
              {
                userId: clientId,
                payload: {
                  state: Constant.user.state.inactive,
                },
              },
              {
                onSuccess: () => {
                  toast.success('The client was removed successfully');
                  onClientRemoved?.();
                  dispatch(modalActions.setOpenModalId(null));
                },
                onError: (error) => {
                  toast.error(getErrorMessage(error));
                },
              },
            );
          }}
        >
          {() => (
            <Form.Actions>
              <Button disabled={isUpdating} type="submit" size="big">
                Remove client
              </Button>
            </Form.Actions>
          )}
        </Form>
      </Modal>
    </ErrorBoundary>
  );
}

RemoveClientModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  onClientRemoved: PropTypes.func,
};

export default RemoveClientModal;
