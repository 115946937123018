import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { PDFIcon } from 'components/Icon';

import theme from './ViewRegimen.scss';
import Header from './Header';
import PersonalMessage from './PersonalMessage';
import Regimen from './Regimen';

const ViewRegimen = ({
  stage,
  regimens,
  onDownloadAsPDFClick,
}) => {
  const weekPeriod = `${(stage.program_view_index * 2) + 1} - ${(stage.program_view_index * 2) + 2}`;
  return (
    <div>
      <Header weekPeriod={weekPeriod} />
      <div className={theme.body}>
        <Button color="sensitive" size="big" classes={[theme.printButton]} onClick={onDownloadAsPDFClick}>
          <PDFIcon width="32" height="32" />
          <span className={theme.buttonText}>PRINT REGIMEN</span>
        </Button>
        {stage.text &&
          <div className={theme.personalMessage}>
            <PersonalMessage message={stage.text} />
          </div>
        }
        <div>
          {regimens.filter(
            (regimen) => regimen.regimens.length > 0
          ).map((regimen, i) => (
            <Regimen weekPeriod={weekPeriod} key={i} regimen={regimen} />
          ))}
        </div>
      </div>
    </div>
  );
};

ViewRegimen.propTypes = {
  stage: PropTypes.object.isRequired,
  regimens: PropTypes.array.isRequired,
  onDownloadAsPDFClick: PropTypes.func.isRequired,
};

export default ViewRegimen;
