/* eslint-disable max-len */

import React from 'react';

const CommentIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 4.0019531 3 C 2.9088903 3 2.0019531 3.9069372 2.0019531 5 L 2.0019531 17 C 2.0019531 18.093063 2.9088903 19 4.0019531 19 L 18.001953 19 L 22.001953 23 L 21.990234 4.9980469 C 21.990235 3.9049841 21.082089 3 19.990234 3 L 4.0019531 3 z M 4.0019531 5 L 19.990234 5 L 19.998047 18.167969 L 18.830078 17 L 4.0019531 17 L 4.0019531 5 z M 7 8 L 7 10 L 17 10 L 17 8 L 7 8 z M 7 12 L 7 14 L 14 14 L 14 12 L 7 12 z" />
  </svg>
);

CommentIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default CommentIcon;
