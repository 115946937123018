/* eslint-disable max-len */

import React from 'react';

const CameraIcon = (props) => (
  <svg viewBox="0 0 12 16" {...props}>
    <path d="M6.02040816,0.127714286 C5.88456653,-0.0425714286 5.6122449,-0.0425714286 5.47640816,0.127714286 C4.31952653,1.62453061 0,7.47465306 0,10.2297551 C0,13.3930204 2.58481633,15.9778367 5.74808163,15.9778367 C8.91134694,15.9778367 11.4961633,13.3930204 11.4961633,10.2297551 C11.4961633,7.47465306 7.17665306,1.62453061 6.02040816,0.127714286 Z M7.27869388,13.018658 C9.28571429,12.2023314 9.11542857,9.75335184 9.08163265,9.71955592 C9,9.65196408 10,9.65196408 10.1020408,9.65196408 C10.2378824,10.7399804 9.82971429,13.0869029 7.68685714,13.9714743 C7.61861714,14.0052702 7.27869388,13 7.27869388,13.018658 Z" />
  </svg>
);

CameraIcon.defaultProps = {
  height: 12,
  width: 16,
  fill: 'black',
};

export default CameraIcon;
