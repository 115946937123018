import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Wysiwyg from 'components/Wysiwyg';
import Form from 'components/Form';
import Button from 'components/Button';
import Loader from 'components/Loader';

import theme from './CreateTreatmentPlanForm.sss';

const CreateTreatmentPlanForm = ({
  history,
  profile,
  treatmentPlan,
  submitting,
  save,
  send,
}) => (
  <Form
    defaultValues={{
      body: treatmentPlan?.body || '',
    }}
    submitting={submitting}
    description={
      <p className={theme.CreateTreatmentPlanTitle}>
        Create {profile.first_name}&apos;s Treatment Plan
      </p>
    }
  >
    {({ handleSubmit }) => (
      <>
        <Form.Row>
          <Form.Item
            name="body"
            onChange={(value) => value}
            input={
              <Wysiwyg defaultValue={treatmentPlan?.body || ''} size="large" />
            }
            registerOptions={{
              validate: (value) =>
                (value?.length &&
                  value !== '' &&
                  value.trim() !== '' &&
                  value !== '<br>' &&
                  value !== '<p><br></p>') ||
                'Please enter the Treatment Plan',
            }}
          />
        </Form.Row>
        <Form.Actions>
          <Form.Actions.Row>
            {submitting && <Loader />}
            <Button
              disabled={submitting}
              color="secondary"
              size="big"
              type="submit"
              onClick={handleSubmit((values) => {
                save({ ...values });
                history.push('/');
              })}
            >
              Save As Draft
            </Button>
            <Button
              disabled={submitting}
              size="big"
              type="submit"
              onClick={handleSubmit((values) => {
                send({ ...values });
              })}
            >
              {`Send to ${profile.first_name}`}
            </Button>
          </Form.Actions.Row>
          <Form.Actions.Row>
            <Button
              disabled={submitting}
              size="big"
              color="sensitive"
              onClick={() => history.push('/')}
            >
              Cancel
            </Button>
          </Form.Actions.Row>
        </Form.Actions>
      </>
    )}
  </Form>
);

CreateTreatmentPlanForm.propTypes = {
  history: PropTypes.object.isRequired,
  treatmentPlan: PropTypes.object,
  programDocuments: PropTypes.array,
  profile: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  save: PropTypes.func.isRequired,
  send: PropTypes.func.isRequired,
};

export default withRouter(CreateTreatmentPlanForm);
