import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { mapModalDispatchToProps } from 'helpers/redux';
import { getErrorMessage } from 'helpers/errorHandling';
import { useBranch } from 'components/BranchProvider';
import { useGetCoaches, useAddUser } from 'features/users/user.query';
import Modal from 'components/Modal';
import Loader from 'components/Loader';

import InviteClientForm from './components';

function InviteClientWidget({ modalIsOpen, modalId, setOpenModalId }) {
  const [branch] = useBranch();
  const { data: coaches, isLoading } = useGetCoaches({
    enabled: modalIsOpen && branch.client_coach_assignment_enabled,
  });
  const { mutate: inviteClient, isLoading: isSubmitting } = useAddUser();

  if (isLoading) {
    return (
      <Modal id={modalId} size="small" title="Invite a client" autoClose>
        <Loader />
      </Modal>
    );
  }
  return (
    <Modal id={modalId} size="small" title="Invite a client" autoClose>
      <InviteClientForm
        submitting={isSubmitting}
        coaches={coaches}
        onSubmit={(values) => {
          inviteClient(values, {
            onSuccess: () => {
              setOpenModalId(null);
              toast.success(
                'New client added! The invitation email will be send shortly',
              );
            },
            onError: (error) => {
              toast.error(getErrorMessage(error, 'Something went wrong.'));
            },
          });
        }}
      />
    </Modal>
  );
}

InviteClientWidget.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal }, { modalId }) => ({
  modalIsOpen: modal.openModalId === modalId,
});

export default connect(
  mapStateToProps,
  mapModalDispatchToProps,
)(InviteClientWidget);
