import { useQuery } from 'react-query';

import ResponseService from 'services/response.service';

export function useGetHqResponseMerge(params, options) {
  return useQuery(
    ['response', 'hq', 'merge', params],
    () => ResponseService.getHqMerge(params),
    options,
  );
}
