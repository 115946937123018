import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './IconButton.sss';

const IconButton = forwardRef(({ classes, icon, ...others }, ref) => (
  <button
    ref={ref}
    type="button"
    {...others}
    className={classNames(theme.IconButton, ...classes)}
  >
    {icon}
  </button>
));

IconButton.propTypes = {
  classes: PropTypes.array,
  icon: PropTypes.node.isRequired,
};

IconButton.defaultProps = {
  classes: [],
};

export default IconButton;
