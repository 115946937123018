import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { TYPES } from 'helpers/note';
import Filter from 'components/Filter';
import { userAware } from 'components/UserProvider';
import Constant from 'utils/constants';

import Button from 'components/Button';
import { EditIcon } from 'components/Icon';

import { orange01 } from 'styles/colors.sss';

import NotesForm from './NotesForm';
import theme from './Notes.scss';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.noteForm = React.createRef();
  }

  state = {
    subject: {
      error: '',
      value: '',
    },
    message: {
      error: '',
      value: '',
    },
    namesMap: [],
    isAddingNote: false,
    editedNote: {},
    isEdditingNote: false,
  };

  onChange = (name, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
      },
    }));
  };

  saveNote = ({ title, body, type }) => {
    const { clientId, onSubmit } = this.props;
    onSubmit({
      title: title || moment().format('MMM D, h:mma'),
      body,
      type,
      subject: clientId,
    });
    this.toggleIsAddingNote();
  };

  toggleIsAddingNote = () => {
    this.setState((prevState) => ({
      isAddingNote: !prevState.isAddingNote,
    }));
  };

  saveEditNote = ({ title, body }) => {
    const { clientId, onSubmitEdit } = this.props;
    onSubmitEdit({
      title: title || moment().format('MMM D, h:mma'),
      body,
      id: this.state.editedNote.id,
      subject: clientId,
    });
    this.toggleIsEditingNote();
  };

  toggleIsAddingNote = () => {
    this.setState((prevState) => ({
      isAddingNote: !prevState.isAddingNote,
    }));
  };

  toggleIsEditingNote = (note) => {
    this.setState((prevState) => ({
      editedNote: note,
      isEdditingNote: !prevState.isEdditingNote,
    }));
  };

  isNoteAuthor = (note, user) => note.author?.id === user.id;

  renderNotes = () => {
    const { notes, user } = this.props;

    if (!notes?.results?.length) {
      return 'No notes found';
    }

    if (notes && notes.results) {
      return notes.results.map((note, i) => {
        return (
          <li key={i}>
            <div className={theme.noteHeader}>
              <div className={theme.authorName}>
                {/* eslint-disable-next-line camelcase */}
                {note?.author?.profile?.full_name}{' '}
                {Constant.task.actionLabels[note.action]}
              </div>
              <span className={theme.noteDate}>
                {moment(note.created).format('MMM D, h:mma')}
              </span>
            </div>
            <div className={theme.noteBody}>
              <div className={theme.noteText}>
                <div className={theme.noteTitle}>{note.title}</div>
                <p
                  className={theme.noteTextCompose}
                  dangerouslySetInnerHTML={{ __html: note.body }}
                />
              </div>
              {/* eslint-disable-next-line prettier/prettier */}
              {note.type && this.isNoteAuthor(note, user) &&
                !this.state.isEdditingNote &&
                // eslint-disable-next-line prettier/prettier
                !this.state.isAddingNote && (
                  <div className={theme.noteActions}>
                    <Button
                      color="sensitive"
                      classes={[theme.EditCheckin]}
                      onClick={() => {
                        this.toggleIsEditingNote(note);
                        this.noteForm.current.scrollIntoView({
                          behavior: 'auto',
                        });
                      }}
                    >
                      <EditIcon width={18} height={18} fill={orange01} />
                    </Button>
                  </div>
                )}
            </div>
          </li>
        );
      });
    }
    return null;
  };

  render() {
    const { onFilterChange } = this.props;
    const { isAddingNote, isEdditingNote } = this.state;
    return (
      <div className={theme.root}>
        <header>
          <h1 ref={this.noteForm}>Notes</h1>
          {isEdditingNote ? (
            <Button
              color="secondary"
              onClick={() => this.toggleIsEditingNote(null)}
            >
              Cancel
            </Button>
          ) : (
            <Button color="secondary" onClick={this.toggleIsAddingNote}>
              {isAddingNote ? 'Cancel' : 'Add Note'}
            </Button>
          )}
        </header>
        {isEdditingNote && (
          <NotesForm
            note={this.state.editedNote}
            onSubmit={this.saveEditNote}
          />
        )}
        {isAddingNote && <NotesForm onSubmit={this.saveNote} />}
        <Filter
          filters={[TYPES.general, TYPES.consult, TYPES.task]}
          onChange={onFilterChange}
        />
        <ul className={theme.notesList}>{this.renderNotes()}</ul>
      </div>
    );
  }
}

Notes.propTypes = {
  user: PropTypes.object.isRequired,
  notes: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onSubmitEdit: PropTypes.func,
  clientId: PropTypes.number,
};

export default userAware(Notes);
