import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';

const SUBCATEGORIES = {
  dateOnly: 'date_only',
  both: 'both',
  timeOnly: 'time_only',
};

import DateOnly from './DateOnly';

const Datetime = ({ question, answers }) => {
  const getDefaultValue = () => {
    if (!answers.length) {
      return undefined;
    }
    return answers.map(({ row, text }) => ({
      row,
      defaultValue: new Date(text),
    }));
  };
  const renderInput = () => {
    switch (question.subcategory) {
      case SUBCATEGORIES.dateOnly:
        return (
          <Form.Item
            name={question.id.toString()}
            onChange={(dates) => {
              if (!dates.length) {
                return undefined;
              }
              return dates.map((date) => ({
                ...date,
                question: question.id,
              }));
            }}
            label={<label>{question.text}</label>}
            input={
              <DateOnly rows={question.rows} defaultValue={getDefaultValue()} />
            }
            registerOptions={{
              required: question.is_required && 'Please select a date',
            }}
          />
        );
      case SUBCATEGORIES.both:
      case SUBCATEGORIES.timeOnly:
      default:
        throw new Error(`subcategory ${question.subcategory} not supported`);
    }
  };
  return renderInput();
};

Datetime.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array,
};

Datetime.defaultProps = {
  answers: [],
};

export default Datetime;
