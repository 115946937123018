/* eslint-disable max-len */

import React from 'react';

const VerticalDragIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 12 2 L 9 5 L 15 5 L 12 2 z M 3 7 L 3 9 L 21 9 L 21 7 L 3 7 z M 3 11 L 3 13 L 21 13 L 21 11 L 3 11 z M 3 15 L 3 17 L 21 17 L 21 15 L 3 15 z M 9 19 L 12 22 L 15 19 L 9 19 z" />
  </svg>

);

VerticalDragIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default VerticalDragIcon;
