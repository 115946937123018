import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { useBranch } from 'components/BranchProvider';
import { isDraft, isEditable } from 'helpers/stage';
import { getNextProgramIndex, getWeekPeriod } from 'helpers/program';
import { getIdFromUrl } from 'helpers/formatHelpers';
import * as modalActions from 'components/Modal/Modal.ducks';

import {
  ProgramWeek,
  Preferences,
  ClientOrderedProducts,
  PersonalMessage,
} from './components/Details';
import IconButton from 'components/IconButton';
import NotesModalWidget from 'containers/NotesModalWidget';
import { NoteIcon } from 'components/Icon';
import { TYPES } from 'helpers/note';

import { Regimen } from './components/Regimen';

import theme from './PrepareRegimen.sss';

const PrepareRegimen = ({
  stage,
  program,
  profile,
  clientOrderedProducts,
  regimens,
  steps,
  editingProgramWeek,
  editingPublishedStage,
  onChangeProgramWeek,
  onChangePersonalMessage,
  onChangeStep,
  setOpenModalId,
}) => {
  const notesModalId = 'NotesWidget/PrepareRegimen';
  const shouldRenderRegimen = (regimen) => {
    const name = regimen.name;

    // always render herbal regimen
    if (name.includes('HERBAL')) {
      return true;
    }

    return program.treatment_area.some((treatmentArea) =>
      name.includes(treatmentArea.toUpperCase()),
    );
  };

  const getRegimenComponent = (regimen) => {
    const regimenName = regimen.name;
    switch (regimenName) {
      case 'DIET RECOMMENDATIONS':
        return null;
      default: {
        if (shouldRenderRegimen(regimen)) {
          return Regimen;
        }
        return null;
      }
    }
  };

  let programWeek = isDraft(stage)
    ? stage.program_index
    : getNextProgramIndex(program);
  if (editingProgramWeek) {
    programWeek = program.program_index;
  } else if (editingPublishedStage) {
    programWeek--;
  }
  const programStageWeeks = getWeekPeriod(programWeek);
  const [branch] = useBranch();

  return (
    <div>
      <header
        className={classNames(
          theme.RegimenHeader,
          { [theme.PublishedHeader]: isEditable(stage) },
          { [theme.DraftedHeader]: isDraft(stage) },
        )}
      >
        {editingPublishedStage && isEditable(stage) && (
          <h1>PUBLISHED REGIMEN</h1>
        )}
        {isDraft(stage) && <h1>DRAFTED REGIMEN</h1>}
        <h2>Create Regimen for Weeks {programStageWeeks}</h2>
      </header>
      <div className={theme.RegimenContainer}>
        <IconButton
          classes={[theme.ViewNotesButton]}
          icon={
            <>
              <NoteIcon width="32" height="32" />
              <span className={theme.ViewNotesText}>VIEW NOTES</span>
            </>
          }
          onClick={() => {
            setOpenModalId(notesModalId, {
              clientId: getIdFromUrl(program.user),
            });
          }}
        />
        <ProgramWeek
          firstName={profile.first_name}
          lastName={profile.last_name}
          range={programStageWeeks}
          allowEdit={programWeek <= branch.program_config.duration}
          selectedWeek={programWeek}
          onChangeWeek={onChangeProgramWeek}
        />
        <Preferences
          firstName={profile.first_name}
          lastName={profile.last_name}
          communicationMethod={program.communication_method}
          treatmentAreas={program.treatment_area}
          products={program.initial_products}
        />
        <NotesModalWidget
          modalId={notesModalId}
          clientId={profile.id}
          types={[TYPES.general]}
        />
        {clientOrderedProducts.length > 0 && (
          <ClientOrderedProducts
            firstName={profile.first_name}
            lastName={profile.last_name}
            products={clientOrderedProducts}
          />
        )}
        <PersonalMessage
          firstName={profile.first_name}
          lastName={profile.last_name}
          personalMessage={stage.text}
          onChange={onChangePersonalMessage}
        />
        {regimens.map((regimen, index) => {
          const RegimenComponent = getRegimenComponent(regimen);
          if (RegimenComponent) {
            const regimenSteps = steps.filter(
              (step) => step.regimen === regimen.id,
            );
            return (
              <RegimenComponent
                key={index}
                name={regimen.name}
                steps={regimenSteps}
                stepLimit={program.plan?.order_item_limit} // eslint-disable-line camelcase
                onChangeStep={onChangeStep}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

PrepareRegimen.propTypes = {
  stage: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  regimens: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  program: PropTypes.object.isRequired,
  clientOrderedProducts: PropTypes.array.isRequired,
  editingProgramWeek: PropTypes.bool.isRequired,
  editingPublishedStage: PropTypes.bool.isRequired,
  onChangeProgramWeek: PropTypes.func.isRequired,
  onChangePersonalMessage: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

export default connect(null, {
  setOpenModalId: modalActions.setOpenModalId,
})(PrepareRegimen);
