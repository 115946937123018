import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { isClient } from 'helpers/user';
import { isMaintenance } from 'helpers/program';
import { useLoggedInUser } from 'hooks';
import Avatar from 'components/Avatar';
import { MaintenancePlanIcon } from 'components/Icon';

import theme from './Details.sss';

const Details = ({ thread }) => {
  const user = useLoggedInUser();
  const recipient = thread.participants.find(
    (participant) => participant.id !== user.id,
  );
  function renderHelperText() {
    if (isClient(user)) {
      return (
        <p className={theme.HelperText}>
          You are chatting with your Clear Skin Specialist:
        </p>
      );
    }
    return <p className={theme.HelperText}>You are chatting with a member:</p>;
  }
  function renderBalance() {
    const availableMessages =
      user.program?.maintenance_plan_balance?.balance.available_messages;
    if (availableMessages >= 0) {
      return (
        <div className={theme.BalanceInfo}>
          <span className={theme.MessagesNumber}>{availableMessages}</span>
          Messages left in plan
        </div>
      );
    }
    return null;
  }
  return (
    <div className={theme.Details}>
      <Avatar className={theme.Avatar} style="icon" user={recipient} />
      <header className={theme.RecipientInfo}>
        {renderHelperText()}
        <div className={theme.Recipient}>
          <p className={theme.RecipientName}>{recipient.profile.full_name}</p>
          {recipient.current_maintenance_plan && (
            <div className={theme.Circle}>
              <MaintenancePlanIcon />
              <p className={theme.PlanName}>
                {recipient.current_maintenance_plan}
              </p>
            </div>
          )}
        </div>
        {isClient(recipient) && (
          <Link to={`/client-details/${recipient.id}`}>
            View medical record
          </Link>
        )}
      </header>
      {isClient(user) && isMaintenance(user.program) && renderBalance()}
    </div>
  );
};

Details.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default Details;
