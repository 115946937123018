import { useQuery, useMutation } from 'react-query';

import ProgramService from 'services/program.service';

export function useGetProgram(programId, options) {
  return useQuery(
    ['programs', programId],
    () => ProgramService.get(programId),
    options,
  );
}

export function useGetUserProgram(userId, options) {
  return useQuery(
    [`${userId}`, 'program'],
    () => ProgramService.getByUser(userId),
    options,
  );
}

// NOTE: NO LONGER USED
export function useGetOrderedProducts(programId, options) {
  return useQuery(
    ['programs', programId, 'orderedProducts'],
    () => ProgramService.getOrderedProducts(programId),
    options,
  );
}

export function useGetProgramProducts(programId, options) {
  return useQuery(
    ['programs', programId, 'programProducts'],
    () => ProgramService.getProgramProducts(programId),
    options,
  );
}

export function useUpdateProgram(options) {
  return useMutation(
    ({ programId, payload }) => ProgramService.update(programId, payload),
    options,
  );
}
