import axios from 'config/axios';

export default class ProgramOrderService {
  static async get(params = {}) {
    const { data } = await axios.get('programs/orders/', {
      params,
    });
    return data.results[0];
  }
}
