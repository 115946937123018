/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';
import localStorage from 'local-storage';
import jwtDecode from 'jwt-decode';

import axios from 'config/axios';

// Actions
const TOGGLE_RESET_PASSWORD_MODAL = 'cc/login/TOGGLE_RESET_PASSWORD_MODAL';
const RESET_PASSWORD = 'cc/login/RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'cc/login/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'cc/login/RESET_PASSWORD_FAIL';
const LOGIN = 'cc/login/LOGIN';
const LOGIN_SUCCESS = 'cc/login/LOGIN_SUCCESS';
const LOGIN_FAIL = 'cc/login/LOGIN_FAIL';
const RESET_LOGIN = 'cc/login/RESET_LOGIN';

const initialState = {
  showResetPasswordModal: false,
  submitting: false,
  error: '',
  userId: undefined,
};

// Reducer
// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_RESET_PASSWORD_MODAL: {
      const { showResetPasswordModal } = action.payload;
      return {
        ...state,
        showResetPasswordModal,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        showResetPasswordModal: false,
      };
    }
    case LOGIN:
      return {
        ...state,
        submitting: true,
        error: '',
      };
    case LOGIN_SUCCESS: {
      const { userId } = action.payload;
      return {
        ...state,
        submitting: false,
        userId,
      };
    }
    case LOGIN_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        submitting: false,
      };
    }
    case RESET_LOGIN: {
      return initialState;
    }
    default:
      return state;
  }
};

// Action Creators

const toggleResetPasswordModal = (payload) => ({
  type: TOGGLE_RESET_PASSWORD_MODAL,
  payload,
});

const resetPassword = (payload) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD, payload });
  const { email } = payload;

  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(
      'users/reset_password/',
      {
        email: email.toLowerCase(),
      },
      headers,
    );
    toast.success(
      `Success! An email has been sent to ${email} to reset your password.`,
    );
    dispatch(resetPasswordSuccess({}));
  } catch (error) {
    toast.error(error.message);
    dispatch(resetPasswordFail({ error }));
  }
};

const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

const resetPasswordFail = (payload) => ({
  type: RESET_PASSWORD_FAIL,
  payload,
});

const resetLogin = (payload) => ({
  type: RESET_LOGIN,
  payload,
});

const login = (payload) => async (dispatch) => {
  dispatch({ type: LOGIN, payload });
  const { loginEmail: email, loginPassword: password } = payload;

  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const { data: result } = await axios.post(
      'token-auth/',
      {
        email: email.toLowerCase(),
        password,
      },
      headers,
    );
    const user = {
      jwt: result.access,
      refresh: result.refresh,
      userId: jwtDecode(result.access).user_id,
    };
    await dispatch(loginSuccess({ ...user }));
  } catch {
    const error = 'You have entered an invalid email/password combination.';
    dispatch(loginFail({ error }));
  }
};

const loginSuccess = (payload) => async (dispatch) => {
  dispatch({ type: LOGIN_SUCCESS, payload });
  const { jwt, refresh, userId } = payload;
  localStorage.set('token', jwt);
  localStorage.set('refreshToken', refresh);
  localStorage.set('user', userId);
};

const loginFail = (payload) => ({
  type: LOGIN_FAIL,
  payload,
});

export default reducer;
export {
  // Actions
  TOGGLE_RESET_PASSWORD_MODAL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_LOGIN,
  // Action Creators
  toggleResetPasswordModal,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
  login,
  loginSuccess,
  loginFail,
  resetLogin,
};
