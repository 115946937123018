/* eslint-disable max-len */

import React from 'react';

const CameraIcon = (props) => (
  <svg viewBox="0 0 14 16" {...props}>
    <path d="M12.9590451,11.974291 C12.0078256,11.5268763 11.5245865,11.4377056 11.4910646,11.4194032 C11.4621007,11.4041593 9.86987434,10.9857056 9.13886946,10.5878519 C8.96737483,10.4780958 8.85914263,10.3957778 8.85914263,10.3957778 L8.85914263,10.2844978 L8.85838049,10.2844978 L8.85838049,9.60537584 C8.85838049,9.60537584 11.003161,9.67092511 12.020722,8.63204901 C12.020722,8.63204901 10.5397854,8.21284901 10.8057756,5.51692706 C11.0717854,2.82112218 10.4742244,0.454488035 8.30723902,0.654097791 C8.30723902,0.654097791 7.36821463,-0.476243673 5.49845854,0.234897791 C4.85668293,0.479561205 3.13494634,1.0961856 3.2225561,4.83099535 C3.31020878,8.56660511 1.89480976,8.58865389 1.89480976,8.58865389 C1.89480976,8.58865389 2.62423415,9.65039047 5.10066341,9.62828316 L5.10066341,10.3874246 C5.10066341,10.3874246 4.99319415,10.4697427 4.82093659,10.5794988 C4.08999024,10.976611 2.49781463,11.39505 2.46874146,11.41105 C2.4352039,11.4293427 1.95121951,11.5185193 1.00076098,11.9659378 C0.0495414634,12.4133524 0,13.3851378 0,13.3851378 L0,14.2441232 C2.09912195,15.2769036 4.46946341,15.8584451 6.98009756,15.8584451 C9.49073171,15.8584451 11.8612683,15.2768841 13.9596098,14.2433622 L13.9596098,13.3935085 C13.9596098,13.3935085 13.9100663,12.4217036 12.9588488,11.9743085 L12.9590451,11.974291 Z" />
  </svg>
);

CameraIcon.defaultProps = {
  height: 14,
  width: 16,
  fill: 'black',
};

export default CameraIcon;
