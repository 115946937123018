import { useEffect, useRef, useState } from 'react';

const TOP = 'top';
const BOTTOM = 'bottom';

export default function useInfiniteScroll({
  loading,
  hasNextPage,
  onLoadMore,
  threshold = 60,
  direction = BOTTOM,
}) {
  const [containerNode, setContainerNode] = useState(null);
  const [loaderNode, setLoaderNode] = useState(null);
  const onLoadMoreRef = useRef();
  const loadingRef = useRef(null);

  useEffect(() => {
    onLoadMoreRef.current = onLoadMore;
  }, [onLoadMore]);

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  useEffect(() => {
    if (!loaderNode || !containerNode || !onLoadMoreRef.current) {
      return;
    }
    function getRootMargin() {
      switch (direction) {
        case BOTTOM:
          return `0px 0px ${threshold}px 0px`;
        case TOP:
          return `${threshold}px 0px 0px 0px`;
        default:
          throw new Error(`${direction} not supported`);
      }
    }
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        if (isIntersecting && !loadingRef.current) {
          onLoadMoreRef.current?.(); // eslint-disable-line @babel/no-unused-expressions
        }
      },
      {
        root: containerNode,
        rootMargin: getRootMargin(),
      },
    );

    if (hasNextPage) {
      observer.observe(loaderNode);
    } else {
      loaderNode.style.display = 'none';
      observer.disconnect();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [direction, threshold, hasNextPage, loaderNode, containerNode]);

  return [setContainerNode, setLoaderNode];
}
