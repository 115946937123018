import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
  answers: {},
  errors: {},
  gender: '',
  isFetching: false,
  questions: [], // questions to be submitted on page navigation
  response: null,
  surveyQuestions: [], // flat list of all possible survey questions
  types: [],
};

function updateAnswers(previousAnswers, nextAnswer) {
  // Takes care of some weird behavior on the part of multiple choice questions
  // when they have optional text answers at the end.
  if (
    !previousAnswers ||
    (nextAnswer.every((a) => a.category !== 'multiple_choice') &&
      previousAnswers.every((a) => a.category !== 'multiple_choice'))
  ) {
    if (nextAnswer.some((ans) => ans.row) && previousAnswers) {
      return previousAnswers.map((ans) => {
        return nextAnswer.find((next) => next.row === ans.row) || ans;
      });
    }
    return nextAnswer;
  } else if (nextAnswer.length === 1) {
    return previousAnswers;
  }

  const textAnswer = previousAnswers.find((a) => a.text_answer);
  return textAnswer ? [...nextAnswer, textAnswer] : nextAnswer;
}

// eslint-disable-next-line complexity
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_CHOICE:
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              choices: [...question.choices, action.choice.url],
            };
          }
          return question;
        }),
      };
    case types.ADD_QUESTION:
      return {
        ...state,
        questions: [...state.questions, action.question],
      };
    case types.CLEAR_REDUX_SURVEY:
      return INITIAL_STATE;
    case types.GET_SURVEY_ANSWERS:
      return {
        ...state,
        answers: {},
        isFetching: true,
      };
    case types.GET_SURVEY_ANSWERS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case types.GET_SURVEY_TYPES:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_SURVEY_TYPES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case types.REMOVE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter((q) => q.id !== action.questionId),
      };
    case types.REGISTER_PAGE:
      return {
        ...state,
        questions: [],
      };
    case types.SET_GENDER:
      return {
        ...state,
        gender: action.gender,
      };
    case types.SET_SURVEY_ANSWERS:
      return {
        ...state,
        answers: action.answers,
        isFetching: false,
      };
    case types.SET_SURVEY_QUESTIONS:
      return {
        ...state,
        surveyQuestions: action.questions,
      };
    case types.SET_SURVEY_RESPONSE:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_SURVEY_RESPONSE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case types.SET_SURVEY_RESPONSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        response: action.response,
      };
    case types.SET_SURVEY_TYPES:
      return {
        ...state,
        isFetching: false,
        types: action.types,
      };
    case types.SET_SURVEY_VALIDATION_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case types.TOGGLE_MULTIPLE_CHOICE:
      return {
        ...state,
        surveyQuestions: state.surveyQuestions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              answers: action.answers,
            };
          }
          return question;
        }),
      };
    case types.TOGGLE_SINGLE_CHOICE:
      return {
        ...state,
        surveyQuestions: state.surveyQuestions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              answers: action.choice
                ? [
                    {
                      category: question.category,
                      choice: action.choice,
                      question: question.id,
                    },
                  ]
                : [],
            };
          }
          return question;
        }),
      };
    case types.UPDATE_OPEN_ENDED:
      return {
        ...state,
        surveyQuestions: state.surveyQuestions.map((question) => {
          if (question.id === action.questionId) {
            const answers = action.answers ? [action.answers] : [];
            return {
              ...question,
              answers,
            };
          }
          return question;
        }),
      };
    case types.UPDATE_ROWS:
      return {
        ...state,
        surveyQuestions: state.surveyQuestions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              answers: action.rows,
            };
          }
          return question;
        }),
      };
    case types.UPDATE_MATRIX_ROWS:
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              rows: action.rows,
            };
          }
          return question;
        }),
      };
    case types.UPDATE_TEXT_ANSWER:
      return {
        ...state,
        surveyQuestions: state.surveyQuestions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              answers: [
                ...question.answers.filter(
                  (answer) => answer.category !== 'text_answer',
                ),
                action.textAnswer,
              ],
            };
          }
          return question;
        }),
      };
    case types.UPDATE_QUESTION_TEXT:
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question.id === action.questionId) {
            return {
              ...question,
              text: action.text,
            };
          }
          return question;
        }),
      };
    case types.UPDATE_SURVEY_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionUrl]: updateAnswers(
            state.answers[action.questionUrl],
            action.answers,
          ),
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
