import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

import { isMobile } from 'helpers/browser';

import Checkbox from './Checkbox';
import TextInput from './TextInput';
import TextAreaInput from './TextAreaInput';

import theme from './MultipleChoice.sss';

const ORIENTATION = {
  vertical: 'vertical',
  verticalTwoCol: 'vertical_two_col',
};

const OTHER_TYPES = {
  choice: 'choice',
  field: 'field',
};

const OTHER_INPUT_TYPE_COMPONENT_MAPPING = {
  text: TextInput,
  textArea: TextAreaInput,
};

const MultipleChoice = forwardRef(
  (
    { defaultValue, orientation, choices, otherChoice, error, ...others },
    ref,
  ) => {
    const buildInitialSelectedChoices = () => {
      if (defaultValue) {
        return defaultValue
          .filter((value) => !isObject(value))
          .map((value) => value);
      }
      return [];
    };
    const buildInitialOtherChoice = () => {
      const otherChoiceDefaultValue = defaultValue?.find(isObject);
      if (otherChoiceDefaultValue) {
        const [key, value] = Object.entries(otherChoiceDefaultValue)[0];
        return {
          [key]: value,
        };
      }
      if (otherChoice?.value) {
        return {
          [Number(otherChoice.value)]: '',
        };
      }
      return {};
    };
    const [selectedChoices, setSelectedChoices] = useState(
      buildInitialSelectedChoices(),
    );
    const [otherChoiceSelected, setOtherChoiceSelected] = useState(
      !!defaultValue?.find(isObject),
    );
    const [otherChoiceValue, setOtherChoiceValue] = useState(
      buildInitialOtherChoice(),
    );
    useEffect(() => {
      if (selectedChoices) {
        const values = [...selectedChoices];
        if (!isEmpty(otherChoiceValue) && otherChoiceSelected) {
          values.push(otherChoiceValue);
        }
        others.onChange?.(values);
      }
    }, [selectedChoices, otherChoiceSelected, otherChoiceValue]);
    const renderOtherChoice = () => {
      const OtherInputComponent =
        OTHER_INPUT_TYPE_COMPONENT_MAPPING[otherChoice.inputType];
      switch (otherChoice.type) {
        case OTHER_TYPES.choice:
          return (
            <>
              <div className={theme.Choice}>
                <Checkbox
                  {...others}
                  id={`other-${otherChoice.value}`}
                  value={otherChoice.value}
                  size="small"
                  checked={otherChoiceSelected}
                  onChange={({ target: { checked } }) => {
                    setOtherChoiceSelected(checked);
                  }}
                />
                <label htmlFor={`other-${otherChoice.value}`}>
                  {otherChoice.title}
                </label>
              </div>
              <OtherInputComponent
                {...others}
                disabled={!otherChoiceSelected}
                defaultValue={otherChoiceValue[Number(otherChoice.value)]}
                onChange={(ev) => {
                  setOtherChoiceValue({
                    [Number(otherChoice.value)]: ev.target.value,
                  });
                }}
              />
            </>
          );
        case OTHER_TYPES.field:
          return <OtherInputComponent />;
        default:
          throw new Error(`The type ${otherChoice.type} is not supported`);
      }
    };

    const getChoicesFromOrientation = () => {
      switch (orientation) {
        case ORIENTATION.vertical:
          return choices;
        case ORIENTATION.verticalTwoCol: {
          if (isMobile()) {
            return choices;
          }
          const halfIndex = Math.ceil(choices.length / 2);
          const orderedChoices = [];
          for (let i = 0; i < halfIndex; i++) {
            orderedChoices[i * 2] = choices[i];
            if (i + halfIndex < choices.length) {
              orderedChoices[i * 2 + 1] = choices[i + halfIndex];
            }
          }
          return orderedChoices;
        }
        default:
          throw new Error(`Orientation ${orientation} not supported`);
      }
    };

    const renderChoices = (layoutChoices) => {
      return (
        <ul>
          {layoutChoices.map(({ title, value, followupQuestions }, index) => (
            <li key={index}>
              <div className={theme.Choice}>
                <Checkbox
                  size="small"
                  {...others}
                  ref={ref}
                  id={`choice-${value}`}
                  value={value}
                  checked={selectedChoices.includes(value)}
                  onChange={({ target: { checked } }) => {
                    let newSelectedChoices;
                    if (checked) {
                      newSelectedChoices = [...selectedChoices, value];
                      setSelectedChoices(newSelectedChoices);
                    } else {
                      newSelectedChoices = selectedChoices.filter(
                        (selectedChoice) => selectedChoice !== value,
                      );
                      setSelectedChoices(newSelectedChoices);
                    }
                    others.onChange?.(newSelectedChoices);
                  }}
                />
                <label htmlFor={`choice-${value}`}>{title}</label>
              </div>
              {selectedChoices.includes(value) && followupQuestions}
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div
        className={classNames(theme.MultipleChoice, {
          [theme.InputError]: error,
          [theme.VerticalTwoCol]: orientation === ORIENTATION.verticalTwoCol,
        })}
      >
        {renderChoices(getChoicesFromOrientation())}
        {otherChoice && renderOtherChoice()}
        {error && <span className={theme.ErrorText}>{error}</span>}
      </div>
    );
  },
);

MultipleChoice.propTypes = {
  defaultValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      followupQuestions: PropTypes.array,
    }),
  ).isRequired,
  otherChoice: PropTypes.shape({
    title: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(OTHER_TYPES)).isRequired,
    inputType: PropTypes.oneOf(Object.keys(OTHER_INPUT_TYPE_COMPONENT_MAPPING))
      .isRequired,
  }),
  orientation: PropTypes.oneOf(Object.values(ORIENTATION)),
  error: PropTypes.string,
};

MultipleChoice.defaultProps = {
  orientation: 'vertical',
};

export default MultipleChoice;
