import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { getContext } from 'helpers/context';

const UserContext = getContext('ClearConnection.Client.UserContext');

const UserProvider = ({ user, children }) => (
  <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
);

UserProvider.propTypes = {
  user: PropTypes.object,
  children: PropTypes.object,
};

/*
 Use this HOC for components that need to receive user props.

 Usage example:

 const MyComponent = ({prop}) => { ... }

 export default userAware(MyComponent);

 */
const userAware = (WrappedComponent) => {
  const UserAware = forwardRef((props, ref) => (
    <UserContext.Consumer>
      {({ user }) => <WrappedComponent {...props} ref={ref} user={user} />}
    </UserContext.Consumer>
  ));
  return UserAware;
};

export { UserContext, userAware };
export default UserProvider;
