/* eslint-disable @babel/no-unused-expressions */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { turquoise01 } from 'styles/colors.sss';
import { StarEmptyIcon, StarFilledIcon } from 'components/Icon';

import theme from './Rating.sss';

const Rating = forwardRef(
  ({ defaultValue, choices, error, ...others }, ref) => {
    const buildInitialValue = () => {
      if (defaultValue) {
        const initialChoice = choices.find(
          ({ value }) => value === defaultValue,
        );
        return choices.indexOf(initialChoice);
      }
      return undefined;
    };
    const [selectedRateIndex, setSelectedRateIndex] = useState(
      buildInitialValue(),
    );
    return (
      <div className={theme.Rating}>
        <ul
          className={classNames({
            [theme.InputError]: error,
          })}
        >
          {choices.map(({ text, value }, index) => (
            <label className={theme.Rate} htmlFor={value} key={index}>
              {index <= selectedRateIndex ? (
                <StarFilledIcon width="32" height="32" fill={turquoise01} />
              ) : (
                <StarEmptyIcon width="32" height="32" fill={turquoise01} />
              )}
              {text}
              <input
                {...others}
                type="radio"
                id={value}
                value={value}
                ref={ref}
                onClick={() => {
                  setSelectedRateIndex(index);
                  others.onChange?.(value);
                }}
                // NOTE: Prevent onChange to be called from "others" props
                onChange={() => undefined}
              />
            </label>
          ))}
        </ul>
        {error && <span className={theme.ErrorText}>{error}</span>}
      </div>
    );
  },
);

Rating.propTypes = {
  defaultValue: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  error: PropTypes.string,
};

export default Rating;
