import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import Constant from 'utils/constants';
import { useGetUserProgram } from 'features/programs/program.query';
import { useGetProgramOrders } from 'features/programs/programOrder.query';
import Loader from 'components/Loader';

const STRINGS_BY_COMMUNICATION_METHOD = {
  [Constant.treatmentPlan.communicationMethod.email]: {
    title: 'Standard Coaching',
  },
  [Constant.treatmentPlan.communicationMethod.onlineVideo]: {
    title: 'Video Coaching',
  },
};

function CommunicationMethod({ userId }) {
  const { data: program, isLoading: isProgramLoading } =
    useGetUserProgram(userId);
  const { data: programOrder, isLoading: isProgramOrdersLoading } =
    useGetProgramOrders(
      {
        program: program?.id,
        is_paid: true,
      },
      {
        enabled: !isProgramLoading,
      },
    );

  function renderLastPaymentDate() {
    if (programOrder) {
      return format(new Date(programOrder?.last_payment), 'MM/dd/yyyy');
    }
    return 'N/A';
  }

  const isLoading = isProgramLoading || isProgramOrdersLoading;

  if (isLoading) {
    return <Loader type="absolute" />;
  }

  return (
    <>
      <p>
        Communication Method:&nbsp;
        <em>
          {STRINGS_BY_COMMUNICATION_METHOD[program.communication_method].title}
        </em>
      </p>
      <p>
        Last Payment: <em>{renderLastPaymentDate()}</em>
      </p>
    </>
  );
}

CommunicationMethod.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default CommunicationMethod;
