import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';
import Loader from 'components/Loader';

import Constant from 'utils/constants';
import { getIdFromUrl } from 'helpers/formatHelpers';
import { isNew } from 'helpers/program';
import { isClient } from 'helpers/user';
import {
  useGetClientProgramPlanPaid,
  useGetNextAcnePeelAppointment,
} from 'features/users/user.query';
import { useGetProgram } from 'features/programs/program.query';
import { useGetStages } from 'features/programs/stage.query';
import { useLoggedInUser } from 'hooks';
import { useBranch } from 'components/BranchProvider';
import Task from 'components/Task';

import theme from './PublishStageWidget.sss';

function PublishStageWidget({ task }) {
  const loggedInUser = useLoggedInUser();
  const queryClient = useQueryClient();
  const [branch] = useBranch();
  const { program_id: programId, client_name: clientName } = task.context;
  const userId = getIdFromUrl(task.linked_user);

  const { data: nextAcnePeelAppt, isLoading: isNextAcnePeelApptLoading } =
    useGetNextAcnePeelAppointment(userId, {
      enabled:
        !isClient(loggedInUser) && !!branch.show_next_acne_peel_appointment,
      refetchOnWindowFocus: false,
    });
  const { data: program, isLoading: isProgramLoading } =
    useGetProgram(programId);
  const { data: draftedStages, isLoading: isStageLoading } = useGetStages(
    {
      acne_program: program?.id,
      state: Constant.stage.state.drafted,
    },
    { enabled: !!program },
  );
  const { data: programPlanPaid, isLoading: isProgramPlanPaidLoading } =
    useGetClientProgramPlanPaid(userId, {
      enabled: !!program && isNew(program) && !!program.plan,
    });
  const isEnabled = programPlanPaid || true;
  const isLoading =
    isProgramPlanPaidLoading ||
    isStageLoading ||
    isProgramLoading ||
    isNextAcnePeelApptLoading;
  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  const draftedStage = draftedStages[0];
  const action = draftedStage ? 'View Draft' : 'Create Regimen';
  const weekPeriod = draftedStage?.week_period ?? program.next_week_period;

  function renderNextAppt() {
    if (!nextAcnePeelAppt.start) {
      return 'None';
    }
    return format(new Date(nextAcnePeelAppt.start), 'MM/dd/yyyy');
  }

  return (
    <Task
      task={task}
      title={
        <p>
          <Link to={`/client-details/${userId}`}>{clientName}</Link>{' '}
          {`is ready for their week ${weekPeriod} regimen.`}
        </p>
      }
      subtitle={
        <>
          <span>
            Completed: {formatDistance(new Date(task.created), new Date())}
          </span>
          {branch.show_next_acne_peel_appointment && (
            <span className={theme.NextApptDate}>
              Next Appt Date: {renderNextAppt()}
            </span>
          )}
        </>
      }
      action={action}
      disableToolTip={isEnabled ? null : 'The client hasn’t paid'}
      link={isEnabled ? `/create-regimen/${userId}/${programId}` : null}
      extraDataCalls={{
        user: `users/${userId}/profile/`,
        stages: `programs/acne/${programId}/stages/`,
      }}
      allowNote
      onSaveNote={() => {
        queryClient.invalidateQueries(['tasks']);
      }}
    />
  );
}

PublishStageWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

export default PublishStageWidget;
