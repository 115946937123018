import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';

import theme from './Form.sss';

const Form = ({
  className,
  title,
  description,
  children,
  defaultValues,
  onSubmit,
}) => {
  const methods = useForm({
    defaultValues,
  });
  return (
    <FormProvider {...methods} defaultValues={defaultValues}>
      <form
        className={classNames(className, theme.Form)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <header>
          {title && <h2 className={theme.Title}>{title}</h2>}
          {description && <h2 className={theme.Description}>{description}</h2>}
        </header>
        {children(methods)}
      </form>
      <DevTool control={methods.control} />
    </FormProvider>
  );
};

Form.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  defaultValues: {},
};

export default Form;
