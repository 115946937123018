/* eslint-disable max-len */

import React from 'react';

const CloudUploadIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M18.705,8.004C17.839,5.056,15.15,3,12,3C9.708,3,7.56,4.138,6.258,6.005C2.786,6.133,0,8.997,0,12.5 C0,16.084,2.916,19,6.5,19H9h2v-6H8l4-4l4,4h-3v6h2h3.5c3.033,0,5.5-2.468,5.5-5.5C24,10.536,21.643,8.111,18.705,8.004z" />
  </svg>

);

CloudUploadIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default CloudUploadIcon;
