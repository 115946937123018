import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

import theme from './StartProgramModal.scss';

const StartProgramModal = ({ open, onClose, onStartClicked }) => {
  const [isValid, setIsValid] = useState(false);
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    const isDateValid = moment(startDate, 'M/D/YYYY', true).isValid();
    setIsValid(isDateValid);
  }, [startDate]);

  if (!open) {
    return null;
  }
  return (
    <div className={theme.modal}>
      <div className={theme.modalContainer}>
        <header>
          <h4>
            We are so excited to begin this clear skin journey with you! Please
            choose your start date:
          </h4>
          <button className={theme.closeModal} onClick={onClose}>
            <i className={'material-icons'}>close</i>
          </button>
        </header>
        <div className={theme.modalInput}>
          <label htmlFor="program-start-date">Start Date:</label>
          <DatePicker
            disableFuture
            format="M/d/yyyy"
            value={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <footer>
          <button
            disabled={!isValid}
            onClick={() => {
              onStartClicked(startDate);
            }}
          >
            Done
          </button>
        </footer>
      </div>
    </div>
  );
};

StartProgramModal.propTypes = {
  open: PropTypes.bool,
  onStartClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

StartProgramModal.defaultProps = {
  open: false,
};

export default StartProgramModal;
