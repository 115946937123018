import Form from './Form';
import FormRow from './FormRow';
import FormItem from './FormItem';
import FormActions from './FormActions';

Form.Row = FormRow;
Form.Item = FormItem;
Form.Actions = FormActions;

export { default as TextInput } from './components/TextInput';
export { default as TextAreaInput } from './components/TextAreaInput';
export { default as PasswordInput } from './components/PasswordInput';
export { default as NumberInput } from './components/NumberInput';
export { default as CountrySelector } from './components/CountrySelector';
export { default as RegionSelector } from './components/RegionSelector';
export { default as Checkbox } from './components/Checkbox';
export { default as GenderSelector } from './components/GenderSelector';
export { default as Select } from './components/Select';
export { default as DatePicker } from './components/DatePicker';
export { default as PhoneNumberInput } from './components/PhoneNumberInput';
export { default as FileInput } from './components/FileInput';
export { default as SearchInput } from './components/SearchInput';
export { default as Radio } from './components/Radio';
export { default as Autocomplete } from './components/Autocomplete';
export { default as SingleChoice } from './components/SingleChoice';
export { default as MultipleChoice } from './components/MultipleChoice';
export { default as Ranking } from './components/Ranking';
export { default as Rating } from './components/Rating';
export { default as Slider } from './components/Slider';
export { default as BranchSelect } from './components/BranchSelect';
export { default as GroupSelect } from './components/GroupSelect';
export { default as QuantityControl } from './components/QuantityControl';

export default Form;
