/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';

import history from 'global-history';
import axios from 'config/axios';
import { resolveBranch } from 'config/config';

// Actions
const CHECK_TOKEN = 'cc/createAccount/CHECK_TOKEN';
const CHECK_TOKEN_SUCCESS = 'cc/createAccount/CHECK_TOKEN_SUCCESS';
const CHECK_TOKEN_FAIL = 'cc/createAccount/CHECK_TOKEN_FAIL';
const RESEND_REGISTRATION_EMAIL = 'cc/createAccount/RESEND_REGISTRATION_EMAIL';
const RESEND_REGISTRATION_EMAIL_SUCCESS =
  'cc/createAccount/RESEND_REGISTRATION_EMAIL_SUCCESS';
const RESEND_REGISTRATION_EMAIL_FAIL =
  'cc/createAccount/RESEND_REGISTRATION_EMAIL_FAIL';
const ACTIVATE_ACCOUNT = 'cc/createAccount/ACTIVATE_ACCOUNT';
const ACTIVATE_ACCOUNT_SUCCESS = 'cc/createAccount/ACTIVATE_ACCOUNT_SUCCESS';
const ACTIVATE_ACCOUNT_FAIL = 'cc/createAccount/ACTIVATE_ACCOUNT_FAIL';

const initialState = {
  loading: false,
  submitting: false,
  isTokenValid: false,
};

// Reducer
// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_TOKEN:
      return {
        ...state,
        loading: true,
      };
    case CHECK_TOKEN_SUCCESS: {
      return {
        ...state,
        isTokenValid: true,
        loading: false,
      };
    }
    case CHECK_TOKEN_FAIL: {
      return {
        ...state,
        isTokenValid: false,
        loading: false,
      };
    }
    case RESEND_REGISTRATION_EMAIL:
      return {
        ...state,
        submitting: true,
      };
    case RESEND_REGISTRATION_EMAIL_SUCCESS: {
      return {
        ...state,
        submitting: false,
      };
    }
    case RESEND_REGISTRATION_EMAIL_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        submitting: false,
      };
    }
    case ACTIVATE_ACCOUNT: {
      return {
        ...state,
        submitting: true,
      };
    }
    case ACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        submitting: false,
      };
    }
    default:
      return state;
  }
};

// Action Creators

const checkToken = (payload) => async (dispatch) => {
  dispatch({ type: CHECK_TOKEN, payload });

  const { userId, token, branchId } = payload;
  const branchName = resolveBranch(branchId, userId);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (branchName) {
    config.headers.TenantId = branchName;
  }

  try {
    await axios.post(
      `users/${userId}/activation-token/verify/`,
      {
        token,
      },
      config,
    );
    dispatch(checkTokenSuccess());
  } catch (error) {
    toast.error('Your token is no longer valid');
    dispatch(checkTokenFail({ error }));
  }
};

const checkTokenSuccess = () => ({
  type: CHECK_TOKEN_SUCCESS,
});

const checkTokenFail = (payload) => ({
  type: CHECK_TOKEN_FAIL,
  payload,
});

const resendRegistrationEmail = (payload) => async (dispatch) => {
  dispatch({ type: RESEND_REGISTRATION_EMAIL });
  const { userId, branchId } = payload;

  const branchName = resolveBranch(branchId, userId);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (branchName) {
    config.headers.TenantId = branchName;
  }

  try {
    await axios.post(
      `users/${userId}/activation-token/refresh/`,
      undefined,
      config,
    );
    toast.success('Activation email resent!');
    dispatch(resendRegistrationEmailSuccess());
  } catch (error) {
    toast.error(
      "Error sending activation email. This user either doesn't exist or is already verified",
    );
    dispatch(resendRegistrationEmailFail({ error }));
  }
};

const resendRegistrationEmailSuccess = () => ({
  type: RESEND_REGISTRATION_EMAIL_SUCCESS,
});

const resendRegistrationEmailFail = (payload) => ({
  type: RESEND_REGISTRATION_EMAIL_FAIL,
  payload,
});

const activateAccount = (payload) => async (dispatch) => {
  dispatch({ type: ACTIVATE_ACCOUNT, payload });

  const { userId, token, branchId, password, confirmPassword } = payload;
  const branchName = resolveBranch(branchId, userId);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (branchName) {
    config.headers.TenantId = branchName;
  }

  try {
    const { data: response } = await axios.post(
      `users/${userId}/activate/`,
      {
        token,
        password,
        password_confirm: confirmPassword,
      },
      config,
    );

    config.headers.Authorization = `Bearer ${response.token}`;

    toast.success('Your account is now active! Please login');
    history.replace('/login');
  } catch (error) {
    toast.error(error.message);
    dispatch(activateAccountFail({ error }));
  }
};

const activateAccountSuccess = () => ({
  type: ACTIVATE_ACCOUNT_SUCCESS,
});

const activateAccountFail = (payload) => ({
  type: ACTIVATE_ACCOUNT_FAIL,
  payload,
});

export default reducer;
export {
  // Actions
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_FAIL,
  RESEND_REGISTRATION_EMAIL,
  RESEND_REGISTRATION_EMAIL_SUCCESS,
  RESEND_REGISTRATION_EMAIL_FAIL,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAIL,
  // Action Creators
  checkToken,
  checkTokenFail,
  checkTokenSuccess,
  resendRegistrationEmail,
  resendRegistrationEmailFail,
  resendRegistrationEmailSuccess,
  activateAccount,
  activateAccountSuccess,
  activateAccountFail,
};
