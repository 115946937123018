/**
 * No longer used, we were requested to remove it and always use email as the communication method
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Constant from 'utils/constants';
import { isClient } from 'helpers/user';
import { userAware } from 'components/UserProvider';
import Button from 'components/Button';

import theme from './CommunicationMethodForm.sss';

export const STRINGS_BY_COMMUNICATION_METHOD = {
  [Constant.treatmentPlan.communicationMethod.email]: {
    message: 'Standard Coaching ($65/month)',
  },
  [Constant.treatmentPlan.communicationMethod.onlineVideo]: {
    message: 'Video Coaching ($125/month)',
  },
};

const CommunicationMethodForm = ({ user, defaultValues, onSubmit }) => {
  const { register, watch, handleSubmit } = useForm({
    defaultValues,
  });
  const communicationMethod = watch('communicationMethod');
  return (
    <form>
      <h4 className={theme.Title}>Which consultation works best for you?</h4>
      <div className={theme.CommunicationMethod}>
        <input
          id="email"
          type="radio"
          name="communicationMethod"
          value="email"
          ref={register}
        />
        <label htmlFor="email">
          {STRINGS_BY_COMMUNICATION_METHOD.email.message}
        </label>
      </div>
      <div className={theme.CommunicationMethod}>
        <input
          id="onlineVideo"
          type="radio"
          name="communicationMethod"
          value="online_video"
          ref={register}
        />
        <label htmlFor="onlineVideo">
          {STRINGS_BY_COMMUNICATION_METHOD.online_video.message}
        </label>
      </div>
      <footer className={theme.Actions}>
        <div className={theme.MainActions}>
          {isClient(user) && (
            <Button
              classes={[theme.Action]}
              size="big"
              color="secondary"
              onClick={handleSubmit((values) => {
                onSubmit({
                  ...values,
                  submitAction: 'previous',
                });
              })}
            >
              Previous
            </Button>
          )}
          <Button
            classes={[theme.Action]}
            size="big"
            disabled={!!!communicationMethod}
            onClick={handleSubmit((values) => {
              onSubmit({
                ...values,
                submitAction: 'next',
              });
            })}
          >
            Next
          </Button>
        </div>
      </footer>
    </form>
  );
};

CommunicationMethodForm.propTypes = {
  user: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default userAware(CommunicationMethodForm);
