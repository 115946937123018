import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './Select.sss';

const Select = forwardRef(({ choices, error, ...others }, ref) => (
  <div
    className={classNames(theme.Select, {
      [theme.InputError]: error,
    })}
  >
    <select ref={ref} {...others}>
      {choices.map(({ value, title }) => (
        <option key={value} id={value} size="small" value={value}>
          {title}
        </option>
      ))}
    </select>
    {error && <span className={theme.ErrorText}>{error}</span>}
  </div>
));

Select.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  error: PropTypes.string,
};

export default Select;
