import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';
import Button from 'components/Button';

import theme from './NoteModal.sss';

const NoteModal = ({ open, onClose, onSaveClicked, note }) => {
  if (!open) {
    return null;
  }
  return (
    <div className={theme.modal}>
      <div className={theme.modalContainer}>
        <Form
          onSubmit={(values) => {
            onSaveClicked(values.note);
            onClose();
          }}
        >
          {({ formState: { errors } }) => (
            <>
              <Form.Row>
                <Form.Item
                  name="note"
                  label={<label>Please add your note:</label>}
                  input={
                    <textarea className={theme.textarea} defaultValue={note} />
                  }
                  registerOptions={{
                    required: 'please type a note',
                    maxLength: {
                      value: 280,
                      message: 'Max Lenght exceeded',
                    },
                  }}
                />
              </Form.Row>
              {errors.note && (
                <span className={theme.ErrorText}>{errors.note.message}</span>
              )}
              <Form.Actions>
                <Button
                  size="big"
                  color="sensitive"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button size="big" type="submit">
                  Save
                </Button>
              </Form.Actions>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

NoteModal.propTypes = {
  open: PropTypes.bool,
  onSaveClicked: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  note: PropTypes.string,
};

NoteModal.defaultProps = {
  open: false,
  note: '',
};

export default NoteModal;
