import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

import { PAGE_SIZE } from 'config/config';
import { useLoggedInUser } from 'hooks';
import { useGetMissedCheckIn } from 'features/users/client.query';
import ErrorBoundary from 'components/ErrorBoundary';
import { useBranch } from 'components/BranchProvider';

import MissedCheckInTable from './components';

import theme from './MissedCheckInsWidget.sss';

function MissedCheckInsWidget() {
  const [branch] = useBranch();
  const loggedInUser = useLoggedInUser();

  const [page, setPage] = React.useState(1);
  const [query, setQuery] = React.useState('');

  const apiParams = React.useMemo(() => {
    const params = {
      offset: (page - 1) * PAGE_SIZE,
    };
    if (branch.client_coach_assignment_enabled) {
      params.coach = loggedInUser.id;
    }
    if (query) {
      params.query = query;
    }
    return params;
  }, [branch, loggedInUser, page, query]);

  const { data, isLoading } = useGetMissedCheckIn(apiParams);

  return (
    <div className={theme.MissedCheckInsWidget}>
      <ErrorBoundary>
        <MissedCheckInTable
          loading={isLoading}
          clients={data?.results}
          onSearch={(newQuery) => {
            if (newQuery) {
              setPage(1);
              setQuery(newQuery);
            } else {
              setPage(1);
            }
          }}
        />
        <Pagination
          count={Math.ceil(data?.total ?? 0 / PAGE_SIZE)}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
        />
      </ErrorBoundary>
    </div>
  );
}

export default MissedCheckInsWidget;
