import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { isCoach, isPracticeManager } from 'helpers/user';
import { useLoggedInUser } from 'hooks';
import InviteClientWidget from 'containers/InviteClientWidget';
import ListFilters from 'components/ListFilters';
import { useBranch } from 'components/BranchProvider';
import Button from 'components/Button';
import * as modalActions from 'components/Modal/Modal.ducks';

import AllClients from './AllClients';
import ActiveProgramClients from './ActiveProgramClients';
import StartedProgramClients from './StartedProgramClients';
import NoConsultClients from './NoConsultClients';
import GraduatedClients from './GraduatedClients';
import MaintenanceClients from './MaintenanceClients';
import DeclinedClients from './DeclinedClients';
import QuitClients from './QuitClients';
import NewClients from './NewClients';
import NotesModalWidget from 'containers/NotesModalWidget';
import { TYPES } from 'helpers/note';

import theme from './Clients.sss';

export const NOTES_MODAL_ID = 'NotesWidget/Clients';

const FILTERS_IDS = {
  all: 1,
  activeProgram: 2,
  startedProgram: 3,
  graduated: 4,
  maintenance: 5,
  declined: 6,
  quit: 7,
  noConsult: 8, // NOTE: Not used
  new: 9,
};

const COACH_FILTERS = [
  { id: FILTERS_IDS.activeProgram, title: 'My Current Clients' },
  { id: FILTERS_IDS.startedProgram, title: 'In-Program Clients' },
  { id: FILTERS_IDS.graduated, title: 'Graduated Clients' },
  { id: FILTERS_IDS.maintenance, title: 'Maintenance Clients' },
  { id: FILTERS_IDS.declined, title: 'Declined Program Clients' },
  { id: FILTERS_IDS.quit, title: 'Quit Program Clients' },
];

const PRACTICE_MANAGER_FILTERS = [
  { id: FILTERS_IDS.all, title: 'All Clients' },
  { id: FILTERS_IDS.activeProgram, title: 'Current Clients' },
  { id: FILTERS_IDS.startedProgram, title: 'In-Program Clients' },
  { id: FILTERS_IDS.graduated, title: 'Graduated Clients' },
  { id: FILTERS_IDS.maintenance, title: 'Maintenance Clients' },
  { id: FILTERS_IDS.declined, title: 'Declined Program Clients' },
  { id: FILTERS_IDS.quit, title: 'Quit Program Clients' },
  { id: FILTERS_IDS.new, title: 'New Clients' },
];

const INVITE_CLIENT_MODAL_ID = 'ManagerDashboard/InviteClient';

function Clients() {
  const [branch] = useBranch();
  const user = useLoggedInUser();
  const dispatch = useDispatch();

  const filters = React.useMemo(() => {
    if (isCoach(user)) {
      if (!branch.client_coach_assignment_enabled) {
        const elements = [
          ...[...COACH_FILTERS].slice(0, 1),
          {
            id: FILTERS_IDS.all,
            title: 'All Clients',
          },
          ...[...COACH_FILTERS].slice(1),
        ];
        elements[0].title = 'Current Clients';
        return elements;
      }
      return COACH_FILTERS;
    } else if (isPracticeManager(user)) {
      return PRACTICE_MANAGER_FILTERS;
    }
    throw new Error(`Clients Page is not available to the user ${user.id}`);
  }, [user, branch.client_coach_assignment_enabled]);

  const [activeFilterId, setActiveFilterId] = React.useState(filters[0].id);

  const renderClients = () => {
    let coachId;
    if (isCoach(user) && branch.client_coach_assignment_enabled) {
      coachId = user.id;
    }
    switch (activeFilterId) {
      case FILTERS_IDS.all:
        return <AllClients />;
      case FILTERS_IDS.activeProgram:
        return <ActiveProgramClients coachId={coachId} />;
      case FILTERS_IDS.startedProgram:
        return <StartedProgramClients coachId={coachId} />;
      case FILTERS_IDS.noConsult:
        return <NoConsultClients coachId={coachId} />;
      case FILTERS_IDS.graduated:
        return <GraduatedClients coachId={coachId} />;
      case FILTERS_IDS.maintenance:
        return <MaintenanceClients coachId={coachId} />;
      case FILTERS_IDS.declined:
        return <DeclinedClients coachId={coachId} />;
      case FILTERS_IDS.quit:
        return <QuitClients coachId={coachId} />;
      case FILTERS_IDS.new:
        return <NewClients coachId={coachId} />;
      default:
        throw new Error(`Filter ID ${activeFilterId} not supported`);
    }
  };

  return (
    <div className={theme.Clients}>
      <div className="list-page-columns">
        <div className="list-page-sidebar">
          <ListFilters
            filters={filters}
            activeFilterId={activeFilterId}
            setActiveFilter={(id) => setActiveFilterId(id)}
          />
        </div>
        <div className={classNames(theme.ClientList, 'list-page-content')}>
          {branch.invite_client_enabled && (
            <InviteClientWidget modalId={INVITE_CLIENT_MODAL_ID} />
          )}
          <NotesModalWidget
            modalId={NOTES_MODAL_ID}
            types={[TYPES.general, TYPES.consult]}
          />
          {branch.invite_client_enabled && isPracticeManager(user) && (
            <Button
              size="big"
              onClick={() => {
                dispatch(modalActions.setOpenModalId(INVITE_CLIENT_MODAL_ID));
              }}
            >
              Invite Client
            </Button>
          )}
          {renderClients()}
        </div>
      </div>
    </div>
  );
}

export default Clients;
