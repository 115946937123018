import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { userTypeIds } from 'config/config';

import Loader from 'components/Loader';

// pages
import requirePersonalInformation from 'components/requirePersonalInformation';
import requireAuth from 'components/requireAuth';
import requireMaintenancePlan from 'components/requireMaintenancePlan';
import App from 'pages/App/App';
import ClientDetails from 'pages/ClientDetails';
import Clients from 'pages/Clients';
import CreateTreatmentPlan from 'pages/CreateTreatmentPlan';
import CreateRegimen from 'pages/CreateRegimen';
import Dashboard from 'pages/Dashboard';
import Documents from 'pages/Documents';
const EndUserLicenseAgreement = React.lazy(() =>
  import('pages/EndUserLicenseAgreement'),
);
import ResetPassword from 'pages/ResetPassword';
import ConversationPage from 'pages/Conversation';
import ConversationHistoryPage from 'pages/ConversationHistory';
import Login from 'pages/Login';
import TreatmentPlanPage from 'pages/TreatmentPlanPage';
import SurveysOverview from 'pages/SurveysOverview';
const MaintenancePlan = React.lazy(() => import('pages/MaintenancePlan'));
import AddPersonalInformation from 'pages/AddPersonalInformation';
import AddTimeZone from 'pages/AddTimeZone';
import SurveyPage from 'pages/Survey';
import SurveyRemotePage from 'pages/SurveyRemote';
const NewSurvey = React.lazy(() => import('pages/NewSurvey'));

// Tasks pages
import ReviewTreatmentPlan from './containers/TaskWidget/ReviewTreatmentPlanWidget/ReviewTreatmentPlan';

import SurveyOverview from 'pages/SurveyOverview';
import ViewRegimen from 'pages/ViewRegimen';
import ClientOrderProducts from 'pages/ClientOrderProducts';
import ProgramConsent from 'pages/ProgramConsent';
import CreateAccount from 'pages/CreateAccount/CreateAccount';

export default (location) => (
  <App>
    <React.Suspense fallback={<Loader type="fixed" loading size="large" />}>
      <Switch location={location}>
        {/* App */}
        <Route exact path="/login" component={() => <Login />} />
        <Route
          exact
          path="/account-create/:userId"
          component={() => <CreateAccount />}
        />
        <Route exact path="/password-reset/:userId" component={ResetPassword} />

        {/* Dashboard */}
        <Route
          exact
          path="/"
          component={requireAuth(
            requirePersonalInformation(requireMaintenancePlan(Dashboard)),
          )}
        />

        {/* Clients */}
        <Route exact path="/clients" component={requireAuth(Clients)} />
        <Route
          exact
          path="/client/:clientId/messages"
          component={requireAuth(ConversationHistoryPage)}
        />
        <Route
          exact
          path="/client-details/:id"
          component={requireAuth(ClientDetails)}
        />
        <Route
          exact
          path="/client-details/:clientId/surveys/:surveyId/:responseId"
          component={requireMaintenancePlan(SurveyPage)}
        />
        <Route
          exact
          path="/client-details/:clientId/surveys/hq"
          component={requireMaintenancePlan(SurveyRemotePage)}
        />

        {/* Documents */}
        <Route path="/documents" component={requireAuth(Documents)} />

        {/* EULA */}
        <Route exact path="/eula" component={EndUserLicenseAgreement} />

        {/* Chat */}
        <Route
          path="/messages/:threadId"
          component={requireAuth(requireMaintenancePlan(ConversationPage))}
        />

        {/* HQ & Check-In */}
        <Route exact path="/surveys" component={requireAuth(SurveysOverview)} />
        <Route
          path="/surveys/:surveyId/:pageId?"
          component={requireAuth(NewSurvey)}
        />
        <Route
          path="/checkin/:surveyId/:pageId?"
          component={requireAuth(NewSurvey)}
        />
        <Route
          path="/survey-overview/:surveyId/:pageId?"
          component={requireAuth(SurveyOverview, [
            userTypeIds.coach,
            userTypeIds.practice_manager,
          ])}
        />
        <Route
          path="/order-products/:clientId?/:programId?/:stageId?"
          component={requireAuth(ClientOrderProducts)}
        />
        <Route
          exact
          path="/add-personal-information"
          component={requireAuth(AddPersonalInformation)}
        />
        <Route
          exact
          path="/add-time-zone"
          component={requireAuth(AddTimeZone)}
        />

        {/* Treatment Plan */}
        <Route
          path="/create-treatment-plan/:clientId"
          component={requireAuth(CreateTreatmentPlan)}
        />
        <Route
          path="/programs/acne/treatment-plans/:treatmentPlanId"
          component={requireAuth(requireMaintenancePlan(TreatmentPlanPage))}
        />

        {/* Regimen */}
        <Route
          path="/create-regimen/:clientId?/:programId?"
          component={requireAuth(CreateRegimen)}
        />
        <Route
          path="/view-regimen/:stageId"
          component={requireAuth(requireMaintenancePlan(ViewRegimen))}
        />
        <Route
          path="/regimen-consent/:programId"
          component={requireAuth(ProgramConsent)}
        />

        {/* MaintenancePlan */}
        <Route
          path="/maintenance-plans"
          component={requireAuth(MaintenancePlan)}
        />

        {/* New abstraction layer for containers/TaskWidget/XXXTask */}
        <Route
          exact
          path="/review-treatment-plan/:treatmentPlanId/:taskId"
          component={requireAuth(ReviewTreatmentPlan)}
        />
      </Switch>
    </React.Suspense>
  </App>
);
