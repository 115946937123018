import React from 'react';
import PropTypes from 'prop-types';

import theme from './PersonalMessage.sss';

const PersonalMessage = ({
  message,
}) => (
  <div className={theme.PersonalMessage} dangerouslySetInnerHTML={{ __html: message }} />
);

PersonalMessage.propTypes = {
  message: PropTypes.string,
};

PersonalMessage.defaultProps = {
  message: '',
};

export default PersonalMessage;
