import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { isCheckIn } from 'helpers/survey';

import SurveyOverviewNav from './SurveyOverviewNav';
import SurveyOverviewContent from './SurveyOverviewContent';

import theme from './SurveyOverview.scss';

const SurveyOverview = ({ history, survey, pageId }) => {
  const page = survey.pages.find(({ id }) => id === pageId);

  useEffect(() => {
    if (!page) {
      history.push(`/survey-overview/${survey.id}/${survey.pages[0].id}`);
    }
  }, []);

  if (!page) {
    return null;
  }

  return (
    <div>
      <div className={theme.surveyOverviewContainer}>
        <h1>{survey.title} Overview</h1>
        <div className={theme.surveyOverviewContentContainer}>
          {!isCheckIn(survey) && (
            <SurveyOverviewNav
              theme={theme}
              pages={survey.pages}
              surveyId={survey.id}
            />
          )}
          <SurveyOverviewContent page={page} theme={theme} />
        </div>
      </div>
    </div>
  );
};

SurveyOverview.propTypes = {
  history: PropTypes.object,
  survey: PropTypes.object,
  pageId: PropTypes.number,
};

export default withRouter(SurveyOverview);
