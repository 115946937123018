/* eslint-disable no-use-before-define */
import axios from 'config/axios';
import { toast } from 'react-toastify';


// Actions
const FETCH_PHOTOS = 'cc/CheckinPhotosWidget/FETCH_PHOTOS';
const FETCH_PHOTOS_FAILED = 'cc/CheckinPhotosWidget/FETCH_PHOTOS_FAILED';
const FETCH_PHOTOS_SUCCEEDED = 'cc/CheckinPhotosWidget/FETCH_PHOTOS_SUCCEEDED';

// Per-taskList initial state
const initialState = {
  loading: false,
  photos: [],
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PHOTOS: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_PHOTOS_SUCCEEDED: {
      const { photos } = action.payload;
      return {
        ...state,
        photos,
        loading: false,
      };
    }
    case FETCH_PHOTOS_FAILED:
      return {
        ...state,
        loading: false,
      };
    default: return state;
  }
};

const fetchPhotos = (payload) =>
  async (dispatch) => {
    dispatch({ type: FETCH_PHOTOS, payload });
    const { clientId } = payload;

    try {
      const {data: photos} = await axios.get(`/users/clients/${clientId}/checkin-images/`);
      dispatch(fetchPhotosSuceeded({ photos }));
    } catch(error) {
      dispatch(fetchPhotosFailed({ error }));
    }
  };

const fetchPhotosSuceeded = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_PHOTOS_SUCCEEDED, payload });
  };

const fetchPhotosFailed = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_PHOTOS_FAILED, payload });
    const { error } = payload;
    toast.error(error.response?.data?.message || error.message || 'Could not save your personal information');
  };

export default reducer;
export {
  // Actions
  FETCH_PHOTOS,
  FETCH_PHOTOS_FAILED,
  FETCH_PHOTOS_SUCCEEDED,

  // Action Creators
  fetchPhotos,
  fetchPhotosSuceeded,
  fetchPhotosFailed,
};
