import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import theme from './Banner.sss';

const Banner = ({ onViewPlansClick, isOldCC }) => {
  const title = isOldCC ? (
    <>
      GETTING <span className={theme.Green}>CLEAR</span> IS THE FIRST STEP…
    </>
  ) : (
    <>
      YOU’VE <span className={theme.Green}>COMPLETED</span>
      <br /> YOUR ONLINE ACNE PROGRAM!
    </>
  );
  return (
    <div className={theme.Banner}>
      <h1 className={theme.Title}>{title}</h1>
      {!isOldCC && (
        <Button classes={[theme.ViewPlansButton]} onClick={onViewPlansClick}>
          VIEW PLANS
        </Button>
      )}
    </div>
  );
};

Banner.propTypes = {
  onViewPlansClick: PropTypes.func.isRequired,
  isOldCC: PropTypes.bool,
};

export default Banner;
