import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { TextInput } from 'components/Form';

import theme from './MultipleTextInput.sss';

const MultipleTextInput = forwardRef(
  ({ defaultValue, rows, ...others }, ref) => {
    const [textEntries, setTextEntries] = useState(defaultValue ?? []);
    useEffect(() => {
      others.onChange?.(textEntries);
    }, [textEntries]);
    return (
      <ul className={theme.MultipleTextInput}>
        {rows.map(({ id, text }, index) => (
          <li key={index}>
            <label htmlFor={id}>{text}</label>
            <TextInput
              id={id}
              value={textEntries.find((entry) => entry.row === id)?.text ?? ''}
              ref={ref}
              onChange={({ target: { value } }) => {
                setTextEntries(
                  [
                    ...textEntries.filter(({ row }) => row !== id),
                    {
                      row: id,
                      text: value,
                    },
                  ].filter((entry) => entry.text),
                );
              }}
            />
          </li>
        ))}
      </ul>
    );
  },
);

MultipleTextInput.propTypes = {
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MultipleTextInput;
