import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';

import { useGetTasks } from './task.query';
import TaskList from './components';

import theme from './TaskListWidget.sss';

const LIMIT = 50;
function TaskListWidget({ actions, linkedUser, truncateSize, title }) {
  const params = React.useMemo(() => {
    const obj = {
      limit: LIMIT,
    };
    if (actions) {
      obj.action = actions;
    }
    if (linkedUser) {
      obj.linked_user = linkedUser;
    }
    return obj;
  }, [actions, linkedUser]);
  const { isLoading, data: tasks } = useGetTasks(params);

  if (isLoading) {
    return (
      <div className={theme.TaskListWidget}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={theme.TaskListWidget}>
      <TaskList tasks={tasks} title={title} truncateSize={truncateSize} />
    </div>
  );
}

TaskListWidget.propTypes = {
  linkedUser: PropTypes.number,
  actions: PropTypes.array,
  title: PropTypes.string,
  truncateSize: PropTypes.number,
};

export default TaskListWidget;
