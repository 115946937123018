/* eslint no-use-before-define: 0 */
import { saveAs } from 'file-saver';

import axios from 'config/axios';
import { userTypeIds } from 'config/config';
import { getErrorMessage } from 'helpers/errorHandling';

import history from 'global-history';

import { toast } from 'react-toastify';

// Actions
const INITIALIZE = 'cc/viewRegimen/INITIALIZE';
const INITIALIZE_SUCCESS = 'cc/viewRegimen/INITIALIZE_SUCCESS';
const INITIALIZE_FAIL = 'cc/viewRegimen/INITIALIZE_FAIL';
const DOWNLOAD = 'cc/viewRegimen/DOWNLOAD';
const DOWNLOAD_SUCCESS = 'cc/viewRegimen/DOWNLOAD_SUCCESS';
const DOWNLOAD_FAIL = 'cc/viewRegimen/DOWNLOAD_FAIL';

const initialState = {
  loading: false,
  stage: {},
  regimens: [],
};

// Reducer
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        loading: true,
      };
    case INITIALIZE_SUCCESS: {
      const { stage, regimens } = action.payload;
      return {
        ...state,
        stage,
        regimens,
        loading: false,
      };
    }
    default:
      return state;
  }
};

// Action Creators

const initialize = (payload) => async (dispatch, getState) => {
  dispatch({ type: INITIALIZE, payload });
  const { stageId, branch } = payload;
  const { jwt, id: userId, userType } = getState().user;
  const { stageConfigs } = getState().app;

  const headers = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };

  const isClient = userType?.id === userTypeIds.client;

  try {
    if (isClient) {
      const { data: acneProgramData } = await axios.get(
        `programs/acne/?user=${userId}`,
        headers,
      );
      const acneProgram = acneProgramData?.results[0];

      if (!acneProgram) {
        history.push('/');
        return;
      }

      if (!acneProgram.consented) {
        history.push(`/regimen-consent/${acneProgram.id}`);
        return;
      }
    }

    const { data: stage } = await axios.get(`stages/${stageId}/`, headers);

    if (stage?.acne_program === '') {
      // eslint-disable-line camelcase
      history.push('/');
      return;
    }

    const { regimens } = stage;
    let stageIndex = stage.program_view_index;
    if (stage.program_view_index > branch.program_config.duration) {
      stageIndex = branch.program_config.duration;
    }

    const stageConfig = stageConfigs[stageIndex];

    // group regimen by treatment area, herbal, or diet recommendations, etc
    const groupedRegimens = [
      {
        code: 'HERBAL',
        label: 'Your Herbal Regimen',
        regimens: regimens.filter(({ code }) => code.includes('HERBAL')),
      },
      {
        code: 'FACE',
        label: 'Your Face Product Regimen',
        regimens: regimens
          .filter(({ code }) => code.includes('FACE'))
          .sort((a, b) => {
            if (a.code < b.code) {
              return 1;
            }
            if (b.code < a.code) {
              return -1;
            }
            return 0;
          }),
      },
      {
        code: 'CHEST',
        label: 'Your Chest Product Regimen',
        regimens: regimens
          .filter(({ code }) => code.includes('CHEST'))
          .sort((a, b) => {
            if (a.code < b.code) {
              return 1;
            }
            if (b.code < a.code) {
              return -1;
            }
            return 0;
          }),
      },
      {
        code: 'BACK',
        label: 'Your Back Product Regimen',
        regimens: regimens
          .filter(({ code }) => code.includes('BACK'))
          .sort((a, b) => {
            if (a.code < b.code) {
              return 1;
            }
            if (b.code < a.code) {
              return -1;
            }
            return 0;
          }),
      },
      {
        code: 'DIET',
        label: 'Your Diet Recommendations',
        regimens: [
          {
            code: 'DIET RECOMMENDATIONS',
            id: 'diet-recommendations',
            steps: stageConfig.regimens
              .find((regimen) => regimen.name === 'DIET RECOMMENDATIONS')
              .steps.map((step) => ({
                instructions: step.options[0].instructions,
                text: step.options[0].item.name,
                regimen_id: 'diet-recommendations',
              })),
          },
        ],
      },
    ];

    dispatch(initializeSuccess({ stage, regimens: groupedRegimens }));
  } catch (error) {
    dispatch(initializeFail({ error }));
  }
};

const initializeSuccess = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCESS, payload });
};

const initializeFail = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAIL, payload });
  const { error } = payload;
  toast.error(getErrorMessage(error, 'Could not initialize View Regimen'));
};

const download = (payload) => async (dispatch, getState) => {
  dispatch({ type: DOWNLOAD, payload });
  const { stage } = getState().viewRegimen;
  const { jwt } = getState().user;

  const options = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    responseType: 'blob',
  };

  try {
    const { data: pdf } = await axios.get(
      `stages/${stage.id}/download/`,
      options,
    );
    saveAs(pdf, 'Clear_Connection_Regimen');
  } catch (error) {
    dispatch(downloadFail({ error }));
  }
};

const downloadSuccess = (payload) => (dispatch) => {
  dispatch({ type: DOWNLOAD_SUCCESS, payload });
};

const downloadFail = (payload) => (dispatch) => {
  dispatch({ type: DOWNLOAD_FAIL, payload });
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_SUCCESS,
  INITIALIZE_FAIL,
  DOWNLOAD,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAIL,
  // Action Creators
  initialize,
  initializeFail,
  initializeSuccess,
  download,
  downloadFail,
  downloadSuccess,
};
