/* eslint-disable max-len */

import React from 'react';

const SendIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props} >
    <path d="M 2 3 L 2 10.5 L 17 12 L 2 13.5 L 2 21 L 22 12 L 2 3 z" />
  </svg>
);

SendIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default SendIcon;
