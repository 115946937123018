/* eslint no-use-before-define:0 */
import localStorage from 'local-storage';

import axios from 'config/axios';

import * as types from './actionTypes';

// eslint-disable-next-line id-length
const UPDATE_USER_PROGRAM_MAINTENANCE_PLAN_BALANCE =
  'cc/user/UPDATE_USER_PROGRAM_MAINTENANCE_PLAN_BALANCE';

export function clearFeedback() {
  return {
    type: types.CLEAR_FEEDBACK,
  };
}

const defineUserType = (payload) => async (dispatch) => {
  dispatch({ type: types.DEFINE_USER_TYPE, payload });
  const { group } = payload;

  try {
    const { data: userType } = await axios.get(group);
    dispatch(defineUserTypeSuccess({ userType }));
  } catch (error) {
    dispatch(defineUserTypeFailed({ error }));
    throw error;
  }
};

const defineUserTypeSuccess = (payload) => (dispatch) => {
  dispatch({ type: types.DEFINE_USER_TYPE_SUCCESS, payload });
};

const defineUserTypeFailed = (payload) => (dispatch) => {
  dispatch({ type: types.DEFINE_USER_TYPE_ERROR, payload });
};

const expiredToken = () => (dispatch) => {
  localStorage.set('token', null);
  localStorage.set('refreshToken', null);
  dispatch({ type: types.EXPIRED_TOKEN_LOGOUT });
};

const refreshToken = (token) => async (dispatch) => {
  dispatch({ type: types.REFRESH_TOKEN, token });
  try {
    const result = await axios.post('token-auth/refresh/', { refresh: token });
    dispatch(refreshTokenSuccess(result.data.access, result.data.refresh));
  } catch (error) {
    refreshTokenFailed({ error });
    throw error;
  }
};

const refreshTokenSuccess = (token, _refreshToken) => (dispatch) => {
  localStorage.set('token', token);
  localStorage.set('refreshToken', _refreshToken);
  dispatch({ type: types.REFRESH_TOKEN_SUCCESS, jwt: token });
};

const refreshTokenFailed = (payload) => (dispatch) => {
  dispatch({ type: types.REFRESH_TOKEN_FAILED, payload });
};

const setTokenFromStorage = (token) => (dispatch) => {
  dispatch({ type: types.SET_TOKEN_FROM_STORAGE, token });
};

const updateProfile = (payload) => (dispatch) => {
  dispatch({ type: types.UPDATE_PROFILE, payload });
};

// Right now, universe call is a series of api calls that return what should be
// all the relevant information a user needs to see upon login.
// Eventually, it would be cool to have a single endpoint that returns everything
// this method sets out to retrieve
const universeCall = (userId) => async (dispatch) => {
  dispatch({ type: types.UNIVERSE_CALL });

  try {
    const results = await Promise.all([
      axios.get(`users/${userId}/`),
      axios.get(`/users/${userId}/profile/`),
      axios.get(`programs/acne/?user=${userId}`),
    ]);
    const { id, email, migrated, groups } = results[0].data;
    const profile = results[1].data;
    const program = results[2].data.results[0] || {};
    const treatmentPlan = program.treatment_plan
      ? program.treatment_plan.split('v1')[1]
      : '';

    dispatch(
      universeCallSuccess({
        email,
        groups,
        id,
        migrated,
        profile,
        treatmentPlan,
        program,
      }),
    );

    if (groups.length) {
      await dispatch(defineUserType({ group: groups[0] }));
    }
  } catch (error) {
    dispatch({ type: types.UNIVERSE_CALL_ERROR });
    throw error;
  }
};

const universeCallSuccess = (payload) => (dispatch) => {
  dispatch({ type: types.UNIVERSE_CALL_SUCCESS, payload });
};

export function updateUserGender(gender) {
  return {
    type: types.UPDATE_USER_GENDER,
    gender,
  };
}

const updateUserProgram = (program) => async (dispatch) => {
  await dispatch({ type: types.UPDATE_USER_PROGRAM, program });
};

const updateUserProgramMaintenancePlanBalance =
  (maintenancePlanBalance) => (dispatch) => {
    dispatch({
      type: UPDATE_USER_PROGRAM_MAINTENANCE_PLAN_BALANCE,
      maintenancePlanBalance,
    });
  };

export {
  UPDATE_USER_PROGRAM_MAINTENANCE_PLAN_BALANCE,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenFailed,
  universeCall,
  universeCallSuccess,
  setTokenFromStorage,
  expiredToken,
  updateProfile,
  defineUserType,
  updateUserProgram,
  updateUserProgramMaintenancePlanBalance,
};
