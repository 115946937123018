import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { Checkbox } from 'components/Form';
import Button from 'components/Button';

import theme from './ReasonsForm.sss';

const ReasonsForm = ({ defaultValues, onSubmit }) => {
  const { register, watch, handleSubmit } = useForm({
    defaultValues,
  });
  const reasons = watch([
    'APTPR001',
    'APTPR002',
    'APTPR003',
    'APTPR004',
    'APTPR005',
    'APTPR006',
    'APTPR007',
    'APTPR008',
  ]);
  const hasSelected = Object.values(reasons).some((reason) => !!reason);
  return (
    <form>
      <div>
        <h4 className={theme.Title}>
          We are always trying to improve our program as well as how we
          communicate it to our clients.
        </h4>
        <p>
          We would greatly appreciate if you shared your reason(s) for not
          joining the program. Please check all that apply.
        </p>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR001"
            name="APTPR001"
            value="APTPR001"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR001">
            I am not confident it will get me clear
          </label>
        </div>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR002"
            name="APTPR002"
            value="APTPR002"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR002">
            I am not willing to change my products
          </label>
        </div>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR003"
            name="APTPR003"
            value="APTPR003"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR003">
            I did not have a good experience with my Clear Skin Specialist on
            the initial consult
          </label>
        </div>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR004"
            name="APTPR004"
            value="APTPR004"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR004">Too expensive for my budget</label>
        </div>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR005"
            name="APTPR005"
            value="APTPR005"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR005">The Program is too long</label>
        </div>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR006"
            name="APTPR006"
            value="APTPR006"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR006">I am not willing to change my diet</label>
        </div>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR007"
            name="APTPR007"
            value="APTPR007"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR007">
            I do not understand the program enough to try it
          </label>
        </div>
        <div className={theme.Reason}>
          <Checkbox
            id="APTPR008"
            name="APTPR008"
            value="APTPR008"
            size="small"
            ref={register}
          />
          <label htmlFor="APTPR008">
            I think there are easier ways to get clear
          </label>
        </div>
      </div>
      <footer className={theme.Actions}>
        <Button
          classes={[theme.Action]}
          disabled={!hasSelected}
          size="big"
          onClick={handleSubmit((values) => {
            onSubmit({
              ...values,
              submitAction: 'next',
            });
          })}
        >
          Next
        </Button>
      </footer>
    </form>
  );
};

ReasonsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ReasonsForm;
