import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';

import TextInput from './TextInput';
import theme from './Autocomplete.sss';

const Autocomplete = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ error, icon, options, getOptionLabel, ...others }, ref) => {
    return (
      <>
        <div
          className={classNames(theme.Autocomplete, {
            [theme.InputError]: error,
          })}
        >
          {icon && icon}
          <MUIAutocomplete
            {...others}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => {
              return (
                <div ref={params.InputProps.ref}>
                  <TextInput {...params.inputProps} />
                </div>
              );
            }}
          />
        </div>
        {error && <span className={theme.ErrorText}>{error}</span>}
      </>
    );
  },
);

Autocomplete.propTypes = {
  error: PropTypes.string,
  icon: PropTypes.node,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func.isRequired,
};

Autocomplete.defaultProps = {
  options: [],
};

export default Autocomplete;
