import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import Constant from 'utils/constants';
import { useLoggedInUser } from 'hooks';
import { findByName, buildByOrderedProducts } from 'helpers/productCategory';
import { sortByAlphabeticalOrder } from 'helpers/product';
import { useBranch } from 'components/BranchProvider';
import { useGetProgramProducts } from 'features/programs/program.query';
import { useGetOrderMoreProducts } from 'features/commerce/product.query';
import { useGetProductCategories } from 'features/commerce/productCategory.query';

import Button from 'components/Button';
import Loader from 'components/Loader';

import * as messages from './messages';
import theme from './OrderProducts.scss';
import OrderProduct from './OrderProduct';
import NextAppointment from '../NextAppointment';

const OrderProducts = ({ onExitClick, onOrderProductsClick }) => {
  const user = useLoggedInUser();
  const [branch] = useBranch();
  const { control, watch, handleSubmit } = useForm();
  const { data: productCategories, isLoading: isCategoriesLoading } =
    useGetProductCategories({ limit: 100 });
  const { data: programProducts, isLoading: isOrderedProductsLoading } =
    useGetProgramProducts(user.program.id);
  const { data: orderMoreProducts, isLoading: isOrderMoreProducts } =
    useGetOrderMoreProducts();
  const hasOrderedProducts = Object.values(watch()).some((value) => !!value);

  const isLoading =
    isCategoriesLoading || isOrderedProductsLoading || isOrderMoreProducts;

  if (isLoading) {
    return <Loader />;
  }

  function buildProductCategories() {
    const orderedProductCategories = buildByOrderedProducts(
      programProducts,
      productCategories,
      !!user.program.plan,
    );
    const enhanceYourProgramCategory = findByName(
      productCategories,
      Constant.category.enhanceYourProgram,
    );
    let finalOrderMoreProducts = orderMoreProducts;
    if (isEmpty(finalOrderMoreProducts)) {
      finalOrderMoreProducts = [
        {
          ...enhanceYourProgramCategory,
          products: sortByAlphabeticalOrder(
            enhanceYourProgramCategory.products,
          ),
        },
      ];
    }
    return [...orderedProductCategories, ...finalOrderMoreProducts];
  }

  return (
    <form
      className={theme.ClientOrderProducts}
      onSubmit={handleSubmit((values) => {
        onOrderProductsClick(
          Object.entries(values)
            .filter(([, value]) => !!parseInt(value, 10))
            .reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]: parseInt(value, 10),
              }),
              {},
            ),
        );
      })}
    >
      <div>
        <h4>{messages.HEADER_ORDER_PRODUCTS}</h4>
        <p>{messages.ORDER_MORE}</p>
      </div>
      <div className={theme.productsContainer}>
        <ul className={theme.categoriesList}>
          {buildProductCategories().map((category, i) => (
            <li key={i}>
              <h3>{category.name}</h3>
              <div className={theme.productGrid}>
                {category.products.map((product, p) => (
                  <Controller
                    key={p}
                    as={OrderProduct}
                    defaultValue={0}
                    control={control}
                    name={`${product.id}`}
                    product={product}
                  />
                ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <footer>
        {branch.show_next_appointment_enabled && (
          <NextAppointment className={theme.NextAppointment} userId={user.id} />
        )}
        <Button
          classes={[theme.Action]}
          color="secondary"
          size="big"
          onClick={onExitClick}
        >
          {messages.EXIT}
        </Button>
        <Button
          classes={[theme.Action]}
          size="big"
          type="submit"
          disabled={!hasOrderedProducts}
        >
          {messages.HEADER_ORDER_PRODUCTS}
        </Button>
      </footer>
    </form>
  );
};

OrderProducts.propTypes = {
  onExitClick: PropTypes.func.isRequired,
  onOrderProductsClick: PropTypes.func.isRequired,
};

OrderProducts.defaultProps = {};

export default OrderProducts;
