/* eslint-disable max-len */

import React from 'react';

const StarEmptyIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M23.04,9.021L14.77,8.796L12,1L9.23,8.796L0.96,9.021l6.559,5.043L5.177,22L12,17.321L18.823,22l-2.342-7.935L23.04,9.021z M12,14.896l-3.312,2.271l1.137-3.851l-3.183-2.448l4.014-0.109L12,6.974l1.344,3.784l4.014,0.109l-3.183,2.448l1.137,3.851 L12,14.896z" />
  </svg>
);

StarEmptyIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default StarEmptyIcon;
