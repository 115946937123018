import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import theme from './Regimen.scss';

const DietRecommendationSteps = ({
  title,
  steps,
}) => (
  <div className={theme.DietRecommendationSteps}>
    <h4>{title}</h4>
    <ul className={theme.steps}>
      {steps.map((step, s) => (
        <li key={s} className={theme.step}>{step.text}</li>
      ))}
    </ul>
  </div>
);

DietRecommendationSteps.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
};

const RegimenSteps = ({
  steps,
}) => (
  <ul className={theme.RegimenSteps}>
    {steps.map((step, i) => {
      const stepText = step.product?.name || step.text || 'No product selected';
      return (
        <div key={i} className={theme.step}>
          <h4>{`Step ${i+1}: ${stepText}`}</h4>
          <div
            className={[theme.stepInstructions, 'wysiwyg-content'].join(' ')}
            dangerouslySetInnerHTML={{ __html: step.instructions }}
          />
        </div>
      );
    })}
  </ul>
);

RegimenSteps.propTypes = {
  steps: PropTypes.array.isRequired,
};

const Regimen = ({
  weekPeriod,
  regimen,
}) => {

  const renderDietRecommendations = (regimenSteps) => {
    const eliminate = regimenSteps.filter(
      ({ instructions }) => instructions === 'eliminate'
    );
    const oneTwoServings = regimenSteps.filter(
      ({ instructions }) => instructions === 'one_two_servings_per_week'
    );
    const twoThreeServings = regimenSteps.filter(
      ({ instructions }) => instructions === 'two_three_servings_per_week'
    );

    return (
      <>
        {eliminate.length > 0 &&
          <DietRecommendationSteps title="Eliminate" steps={eliminate} />
        }
        {oneTwoServings.length > 0 &&
          <DietRecommendationSteps title="Limit 1-2 servings per week" steps={oneTwoServings} />
        }
        {twoThreeServings.length > 0 &&
          <DietRecommendationSteps title="Limit 3-4 servings per week" steps={twoThreeServings} />
        }
      </>
    );

  };

  return (
    <div className={theme.Regimen}>
      <h2>
        <span>{regimen.label}</span>
      </h2>
      <ul>
        {regimen.regimens?.map((childRegimen, i) => (
          <li key={i}>
            <div className={theme.listTitle}>
              <h3>{`${startCase(childRegimen.code.toLowerCase())} for Weeks ${weekPeriod}`}</h3>
              {regimen.code === 'DIET' ? renderDietRecommendations(childRegimen.steps)
                : (
                  <RegimenSteps steps={childRegimen.steps} />
                )
              }
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

Regimen.propTypes = {
  weekPeriod: PropTypes.string,
  regimen: PropTypes.object,
};

Regimen.defaultProps = {
  weekPeriod: '',
  regimen: {},
};

export default Regimen;
