/* eslint-disable max-len */

import React from 'react';

const SaveIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M17,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V7L17,3z M12,19c-1.66,0-3-1.34-3-3s1.34-3,3-3 s3,1.34,3,3S13.66,19,12,19z M15,9H5V5h10V9z" />
  </svg>
);

SaveIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default SaveIcon;
