import React from 'react';
import PropTypes from 'prop-types';
import theme from './ListFilters.scss';

const ListFilters = (props) => (
  <ul className={theme.filtersContainer}>
    {props.filters.map((filter, f) => (
      <li
        key={f}
        className={props.activeFilterId === filter.id ? theme.activeFilter : ''}
        onClick={() => props.setActiveFilter(filter.id)}
      >
        <span>{filter.title}</span>
        {filter.count && <span>{filter.count}</span>}
      </li>
    ))}
  </ul>
);

ListFilters.propTypes = {
  filters: PropTypes.array,
  activeFilterId: PropTypes.number,
  setActiveFilter: PropTypes.func,
};

export default ListFilters;
