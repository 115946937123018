import React from 'react';
import PropTypes from 'prop-types';

import theme from './FormActionRow.sss';

const FormActionRow = ({ children }) => (
  <div className={theme.FormActionRow}>{children}</div>
);

FormActionRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormActionRow;
