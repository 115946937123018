import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';

import { isClient } from 'helpers/user';
import { isEditable } from 'helpers/stage';
import useLoggedInUser from 'hooks/useLoggedInUser';

import List from 'components/List';
import Loader from 'components/Loader';
import IconButton from 'components/IconButton';
import { EditIcon } from 'components/Icon';
import Constant from 'utils/constants';

import { useGetStages } from 'features/programs/stage.query';
import { useGetClient } from 'features/users/user.query';

export const LIMIT = 50;

function RegimenList({ userId }) {
  const history = useHistory();
  const user = useLoggedInUser();

  const { data: client, isLoading: isClientLoading } = useGetClient(userId);
  const { data: regimens, isLoading: isRegimensLoading } = useGetStages({
    acne_program__user: userId,
    state: [
      Constant.stage.state.published,
      Constant.stage.state.started,
      Constant.stage.state.completed,
      Constant.stage.state.checkinMissed,
      Constant.stage.state.checkinSent,
      Constant.stage.state.upcomingCheckinSent,
    ],
    limit: LIMIT,
  });

  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        label: 'Name',
        value: (regimen) => `Week ${regimen.week_period} Regimen`,
      },
      {
        label: 'Started',
        value: (regimen) => {
          if (!regimen.started) {
            return 'Not started';
          }
          return format(new Date(regimen.started), 'MM/dd/yyyy hh:mm a');
        },
      },
    ];
    if (isClient(user)) {
      return [...baseColumns];
    }
    return [
      ...baseColumns,
      {
        label: 'Published By',
        value: (regimen) => {
          return regimen.published_by_details?.profile?.full_name;
        },
      },
      {
        label: 'Action',
        value: (regimen) => {
          if (!isEditable(regimen)) {
            return null;
          }
          // TODO: Get the user program id
          return (
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                history.push(
                  `/create-regimen/${userId}/${client.program.id}?editingPublishedStage=true`,
                );
              }}
              icon={<EditIcon />}
            />
          );
        },
      },
    ];
  }, [history, user, userId, client]);

  if (isClientLoading || isRegimensLoading) {
    return (
      <div style={{ minHeight: '180px', position: 'relative' }}>
        <Loader />
      </div>
    );
  }

  if (isClient(user) && !client.program?.started) {
    if (!client.program) {
      return <p>Your Clear Skin Specialist has not created any regimen yet.</p>;
    }
    return (
      <p>You must receive your products before you can view your regimen.</p>
    );
  }

  if (!regimens.length) {
    const emptyMessage = isClient(user)
      ? 'Your Clear Skin Specialist has not created any regimen yet.'
      : "This client doesn't have any regimen.";
    return <p>{emptyMessage}</p>;
  }

  return (
    <List
      columns={columns}
      items={regimens}
      itemLink={(regimen) => `/view-regimen/${regimen.id}`}
    />
  );
}

RegimenList.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default RegimenList;
