import Constant from 'utils/constants';

export const TYPES = {
  conversation: 'conversation',
  shipping: 'shipping',
  feedback: 'feedback',
};

export const STATUS = {
  read: 'read',
  unread: 'unread',
};

export const isConversation = ({ type }) => type === TYPES.conversation;
export const isShipping = ({ type }) => type === TYPES.shipping;
export const isFeedback = ({ type }) => type === TYPES.feedback;

export const isRead = ({ status }) => status === Constant.message.status.read;
export const isUnread = ({ status }) =>
  status === Constant.message.status.unread;

export const isReadByUser = (message, user) =>
  message.status === Constant.message.status.read &&
  message.recipient.id === user.id;

export const isUnreadByUser = (message, user) =>
  message.status === Constant.message.status.unread &&
  message.recipient.id === user.id;

export function statusForUser(message, user) {
  if (isUnreadByUser(message, user)) {
    return STATUS.unread;
  }
  return STATUS.read;
}
