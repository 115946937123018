import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { useQueryClient } from 'react-query';

import {
  useGetLatestHACAppointment,
  useGetProfile,
} from 'features/users/user.query';
import { getIdFromUrl } from 'helpers/formatHelpers';
import { isClient } from 'helpers/user';
import { surveyIds } from 'config/config';
import { useLoggedInUser } from 'hooks';
import { useBranch } from 'components/BranchProvider';
import Task from 'components/Task';
import Loader from 'components/Loader';

function CompleteSurveyWidget(props) {
  const { survey_id: surveyId } = props.task.context;
  switch (surveyId) {
    case surveyIds.healthQuestionnaire:
      return <CompleteHQSurveyWidget {...props} />;
    case surveyIds.checkin:
      return <CompleteCheckInSurveyWidget {...props} />;
    default:
      throw new Error(`Task ${props.task.action} not supported`);
  }
}

function CompleteCheckInSurveyWidget({ task, ...rest }) {
  const { survey_id: surveyId, stage_id: stageId } = task.context;
  const userId = getIdFromUrl(task.linked_user);
  return (
    <Task
      task={task}
      title="It's time for your check-in"
      action="Complete Check-in"
      link={`/checkin/${surveyId}/?stageId=${stageId}&userId=${userId}`}
      {...rest}
    />
  );
}

function CompleteHQSurveyWidget({ task, ...rest }) {
  const { survey_id: surveyId } = task.context;
  const userId = getIdFromUrl(task.linked_user);

  const loggedInUser = useLoggedInUser();
  const [branch] = useBranch();
  const queryClient = useQueryClient();
  const { data: userProfile, isLoading: isUserProfileLoading } = useGetProfile(
    userId,
    {
      enabled: !isClient(loggedInUser) && !!branch.show_latest_hac_appointment,
    },
  );
  const { data: latestHACAppt, isLoading: isLatestHACApptLoading } =
    useGetLatestHACAppointment(userId, {
      enabled: !isClient(loggedInUser) && !!branch.show_latest_hac_appointment,
      refetchOnWindowFocus: false,
    });

  function renderTitle() {
    if (isClient(loggedInUser)) {
      return 'Complete your health questionnaire';
    }
    if (isUserProfileLoading) {
      return <Loader size="small" />;
    }
    return (
      <p>
        <Link to={`/client-details/${userId}`}>{userProfile.full_name}</Link>{' '}
        has NOT completed their Health Questionnaire
      </p>
    );
  }

  function renderNextAppt() {
    if (isLatestHACApptLoading) {
      return <Loader size="small" />;
    }
    if (!latestHACAppt.start) {
      return 'None';
    }
    return format(new Date(latestHACAppt.start), 'MM/dd/yyyy');
  }

  return (
    <Task
      task={task}
      title={renderTitle()}
      subtitle={
        !isClient(loggedInUser) &&
        branch.show_latest_hac_appointment && (
          <span>Last Appt Date: {renderNextAppt()}</span>
        )
      }
      action="Complete Health Questionnaire"
      link={`/surveys/${surveyId}/?userId=${userId}`}
      allowNote={!isClient(loggedInUser)}
      onSaveNote={() => {
        queryClient.invalidateQueries(['all-clients-tasks']);
      }}
      {...rest}
    />
  );
}

CompleteSurveyWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

CompleteHQSurveyWidget.propTypes = CompleteSurveyWidget.propTypes;
CompleteCheckInSurveyWidget.propTypes = CompleteSurveyWidget.propTypes;

export default CompleteSurveyWidget;
