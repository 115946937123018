import React from 'react';
import PropTypes from 'prop-types';

import jessiFace from 'assets/images/Jessica_Gremley.png';
import Button from 'components/Button';

import theme from './Highlights.sss';

const Highlights = ({ onSubscribeClick, isOldCC }) => {
  const leftContent = isOldCC ? (
    <>
      <h1 className={theme.Title}>
        <span className={theme.Green}>STAYING</span> CLEAR IS WHERE YOU FIND
        TRUE FREEDOM!
      </h1>
      <div className={theme.Content}>
        <div className={theme.Description}>
          <strong>
            The #1 fear Online Acne Program graduates have once they’ve achieved
            clear skin is… <br />
            Not Being Able To Stay Clear For Life…
          </strong>
          And this is where our Clear Connection Maintenance Plans come in!
          <br />
          <br />
          <br />
          Here are just a few of the benefits you will receive with your
          subscription:
          <ul className={theme.Checked}>
            <li>
              Continued access to your online profile so you can remind yourself
              of the regimen tweaks your Clear Skin Specialist made helping your
              skin get to the next level.
            </li>
            <li>
              Visibility into the recorded messages with your clear skin
              specialist so you can revisit tips and tricks you received along
              the way.
            </li>
            <li>
              If your skin starts to respond in an unexpected way, you will have
              access to your Clear Skin Specialist a minimum of 10 times per
              year depending on the plan you choose.
            </li>
            <li>
              Having the comfort of knowing you can chat with your clear skin
              specialist and get the information you need at your fingertips
              anytime you need it!
            </li>
          </ul>
        </div>
      </div>
    </>
  ) : (
    <>
      <h1>Congratulations on making it this far!</h1>
      <p>
        On behalf of your Clear Skin Specialist and the entire staff at Natural
        Acne Clinic, WELL DONE! <br />
        <br />
        Choosing to embark on this journey took a lot of courage and you should
        be super proud of yourself. <br />
        <br />
        When you get a chance, look at some old pictures and reflect on how far{' '}
        {"you've"} come. <br />
        <br />
        Tears of joy may fall down your cheeks and {"that's okay. I'm"} sure
        this has been an emotional journey that brought you a great amount of
        unexpected joy!
        <br />
        <br />
        Now that {"you're clear, let's"} make sure you stay clear. <br />
        <br />
        We have created three digital Clear Connection Maintenance Plan
        subscriptions for you to choose from so you can continue your journey to
        a lifetime of clear skin. <br />
        <br />
        Just scroll down a bit to learn more!
        <br />
        <br />
        Again… <br />
        <br />
        We Are So Happy For You!
      </p>
      <Button classes={[theme.StayClearButton]} onClick={onSubscribeClick}>
        VIEW CLEAR CONNECTION MAINTENANCE PLANS
      </Button>
    </>
  );
  return (
    <div className={theme.Highlights}>
      <div className={theme.HighlightsContent}>
        <div className={theme.Left}>{leftContent}</div>
        <div className={theme.Right}>
          <img src={jessiFace} />
          <div className={theme.CEO}>
            <span className={theme.Name}>Jessica Gremley</span>
            {'Founder & CEO'}
            <br />
            Natural Acne Clinic
          </div>
        </div>
      </div>
    </div>
  );
};

Highlights.propTypes = {
  onSubscribeClick: PropTypes.func.isRequired,
  isOldCC: PropTypes.bool,
};

export default Highlights;
