import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import theme from './ConfirmSendTreatmentPlan.sss';

const ConfirmSendTreatmentPlan = ({
  profile,
  selectedPlan,
  onPrevious,
  onConfirm,
}) => (
  <form>
    <div className={theme.ConfirmSendTreatmentPlan}>
      <h2 className={theme.Title}>Send Treatment Plan</h2>
      <h4>
        {`Are you sure you are ready to send
        ${profile.first_name}
        ${profile.last_name}
        their treatment plan?`}
      </h4>
      <p>
        You will not be able to update the treatment plan once it has been sent.
      </p>
      <div className={theme.Selection}>
        <p>{`Selected plan: ${selectedPlan}`}</p>
      </div>
    </div>
    <footer className={theme.Actions}>
      <div className={theme.MainActions}>
        <Button
          classes={[theme.Action]}
          size="big"
          color="secondary"
          onClick={onPrevious}
        >
          Previous
        </Button>
        <Button classes={[theme.Action]} size="big" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </footer>
  </form>
);

ConfirmSendTreatmentPlan.propTypes = {
  profile: PropTypes.object.isRequired,
  communicationMethod: PropTypes.string,
  selectedPlan: PropTypes.string.isRequired,
  treatmentAreas: PropTypes.object,
  onPrevious: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmSendTreatmentPlan;
