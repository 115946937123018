import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
  clients: [],
  error: '',
  success: '',
  isFetching: false,
};

const admin = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CLEAR_FEEDBACK:
      return {
        ...state,
        error: '',
        success: '',
      };
    case types.SEND_CLIENT_INVITE:
    case types.SEND_COACH_INVITE:
      return {
        ...state,
        error: '',
        success: '',
        isFetching: true,
      };
    case types.SEND_CLIENT_INVITE_ERROR:
    case types.SEND_COACH_INVITE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case types.SEND_CLIENT_INVITE_SUCCESS:
    case types.SEND_COACH_INVITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: action.success,
      };
    default:
      return {
        ...state,
      };
  }
};

export default admin;
