import { useMutation } from 'react-query';

import TaskNoteService from 'services/taskNote.service';

export function useAddTaskNote(options) {
  return useMutation((payload) => TaskNoteService.add(payload), options);
}

export function useUpdateTaskNote(options) {
  return useMutation(
    ({ taskNoteId, payload }) => TaskNoteService.update(taskNoteId, payload),
    options,
  );
}
