import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import theme from './Success.sss';

const Success = ({
  onDone,
}) => (
  <div>
    <div>
      <h4 className={theme.Title}>We have received your message.</h4>
      <p>Please expect a response in 1-2 business days.</p>
    </div>
    <footer className={theme.Actions}>
      <Button size="big" onClick={() => { onDone(); }}>
        Done
      </Button>
    </footer>
  </div>
);

Success.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default Success;
