import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import startCase from 'lodash/startCase';

import { mapModalDispatchToProps } from 'helpers/redux';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { EditIcon } from 'components/Icon';
import Modal from 'components/Modal';
import Form, { MultipleChoice } from 'components/Form';

import theme from './TreatmentArea.sss';
import { orange01 } from 'styles/colors.sss';

export const TREAMENT_AREA_MODAL_ID = 'cc/TreatmentArea';

const TREATMENT_AREAS = [
  {
    title: 'Face',
    value: 'face',
  },
  {
    title: 'Back',
    value: 'back',
  },
  {
    title: 'Chest',
    value: 'chest',
  },
];

const TreatmentArea = ({
  editable,
  treatmentAreas,
  onChange,
  setOpenModalId,
}) => {
  const [editing, setEditing] = useState(false);
  return (
    <>
      <p>
        Treatment Area:&nbsp;
        <em>
          {treatmentAreas.length
            ? treatmentAreas
                .map((treatmentArea) => startCase(treatmentArea))
                .join(', ')
            : 'None'}
        </em>
        {editable && !editing && (
          <IconButton
            classes={[theme.Edit]}
            icon={<EditIcon width={18} height={18} fill={orange01} />}
            onClick={() => {
              setEditing(true);
              setOpenModalId(TREAMENT_AREA_MODAL_ID);
            }}
          />
        )}
      </p>
      <Modal
        id={TREAMENT_AREA_MODAL_ID}
        size="small"
        title="Select the treatment area"
        autoClose
        onClose={() => {
          setEditing(false);
        }}
      >
        <Form
          onSubmit={(values) => {
            onChange(values.treatmentAreas);
            setOpenModalId(null);
            setEditing(false);
          }}
        >
          {() => (
            <>
              <Form.Row>
                <Form.Item
                  name="treatmentAreas"
                  label={<label>Treatment Areas:</label>}
                  input={<MultipleChoice choices={TREATMENT_AREAS} />}
                  registerOptions={{
                    required: 'Please select one or more treatment areas',
                  }}
                />
              </Form.Row>
              <Form.Actions>
                <Button size="big" type="submit">
                  Save
                </Button>
              </Form.Actions>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

TreatmentArea.propTypes = {
  editable: PropTypes.bool,
  treatmentAreas: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

TreatmentArea.defaultProps = {
  editable: true,
  treatmentAreas: [],
};

export default connect(null, mapModalDispatchToProps)(TreatmentArea);
