import React from 'react';

import iconFacebook from 'assets/images/icon-Facebook-circle.png';
import iconInstagram from 'assets/images/icon-Instagram-cirlce.png';
import IconPinterest from 'assets/images/icon-Pinterest-circle.png';

import theme from './Footer.sss';

const Footer = () => (
  <div className={theme.Footer}>
    <ul className={theme.SocialMedia}>
      <li>
        <a target="_blank" href="https://www.facebook.com/NaturalAcneClinic">
          <img src={iconFacebook} alt="NAC Facebook" />
        </a>
      </li>
      <li>
        <a target="_blank" href="https://www.instagram.com/naturalacneclinic/">
          <img src={iconInstagram} alt="NAC Instagram" />
        </a>
      </li>
      <li>
        <a target="_blank" href="https://www.pinterest.com/naturalacneclinic/">
          <img src={IconPinterest} alt="NAC Pinterest" />
        </a>
      </li>
    </ul>

    <ul className={theme.Links}>
      <li>
        <a
          target="_blank"
          href="https://www.naturalacneclinic.com/privacy-policy"
        >
          PRIVACY POLICY
        </a>
      </li>
      <li>
        <a
          target="_blank"
          href="https://www.naturalacneclinic.com/terms-conditions/"
        >
          TERMS & CONDITIONS
        </a>
      </li>
    </ul>

    <div className={theme.Copyright}>
      &copy; Copyright 2021 by ClearSkinCentral.com & Natural Acne Clinic <br />
      4325 Harlan St., Wheat Ridge, CO 80033 <br />
      Ph. 720-340-2263
    </div>
    <div className={theme.Disclaimer}>
      Disclaimer: These statements have not been evaluated by the Food and Drug
      Administration. This product is not intended to diagnose, treat, cure, or
      prevent any disease.
    </div>
  </div>
);

export default Footer;
