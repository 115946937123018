/* eslint-disable max-len */

import React from 'react';

const DocumentIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M 2 4 L 2 20 C 2 21.101563 2.898438 22 4 22 L 20 22 C 21.101563 22 22 21.101563 22 20 L 22 4 C 22 2.898438 21.101563 2 20 2 L 4 2 C 2.898438 2 2 2.898438 2 4 Z M 17 9 L 7 9 L 7 7 L 17 7 Z M 17 13 L 7 13 L 7 11 L 17 11 Z M 14 17 L 7 17 L 7 15 L 14 15 Z" fill="#000000" />
  </svg>
);

DocumentIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default DocumentIcon;
