const locale = (() => {
  let loc = require('browser-locale')(); // eslint-disable-line global-require
  // Once there are other languages to translate strings into,
  // define logic to properly assign locale here.
  // In the meantime, only English is possible.
  loc = 'en-US';
  return loc;
})();

const strings = {
  'en-US': {
    greet: (name) => {
      return `Hi ${name}, here are today's updates...`;
    },
    cancel: 'Cancel',
    client: 'Client',
    client_details: 'Client Details',
    client_reports: 'Client Reports',
    coach: 'Coach',
    complete_coach_profile: 'Complete Your Coach Profile',
    complete_your_profile: 'Complete Your Profile',
    confirmPassword: 'Confirm Password',
    confirm_password: 'Confirm Password',
    dashboard: 'Dashboard',
    details: 'Details',
    email: 'Email',
    EULA: 'EULA',
    firstName: 'First Name',
    first_name: 'First Name',
    forgot_password: 'Forgot Password',
    forgot_password_question: 'Forgot Password?',
    HIPAA: 'HIPAA',
    _is_required: ' Is Required',
    lastName: 'Last Name',
    last_name: 'Last Name',
    login: 'Login',
    logout: 'Logout',
    manager: 'Manager',
    messages: 'Messages',
    oops: 'Oops',
    password: 'Password',
    profile: 'Profile',
    resend: 'Resend',
    resend_link_email_question: 'It looks like your link has expired. Resend?',
    reset_password: 'Reset Password',
    reset_password_prompt:
      "Enter your email below and we'll send you a link to reset your password",
    same_as_billing_address: 'Same as billing address',
    save: 'Save',
    signIn: 'Sign In',
    sign_in: 'Sign In',
    tasks: 'My Tasks',
    upcomingConsultationTasks: 'Upcoming Consultation Tasks',
    terms_and_conditions: 'Terms and Conditions',
    user_name: 'User Name',
    APTPR001: 'I am not confident it will get me clear',
    APTPR002: 'I am not willing to change my products',
    APTPR003:
      'I did not have a good experience with my Clear Skin Specialist on the initial consult',
    APTPR004: 'Too expensive for my budget',
    APTPR005: 'The Program is too long',
    APTPR006: 'I am not willing to change my diet',
    APTPR007: 'I do not understand the program enough to try it',
    APTPR008: 'I think there are easier ways to get clear',
  },
};

export default new Proxy(strings, {
  get: (target, name) => {
    return target[locale][name];
  },
});

// NOTE: Not used yet, commenting for increasing coverage
// export const upperCase = new Proxy(strings, {
//   get: (target, name) => {
//     return target[locale][name].toUpperCase();
//   },
// });

export const lowerCase = new Proxy(strings, {
  get: (target, name) => {
    return target[locale][name].toLowerCase();
  },
});
