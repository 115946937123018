import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Button from 'components/Button';

import theme from './Plan.sss';

const Plan = ({ plan, styleClass, title, price, benefits, onClick }) => (
  <div className={classNames(theme.Plan, theme[styleClass])}>
    <h1>{title}</h1>
    <label>{price}</label>
    <ul className={theme.Benefits}>
      {benefits.map((benefit, key) => (
        <li key={key} className={theme.Benefit}>
          {benefit}
        </li>
      ))}
    </ul>
    <Button
      classes={[theme.ViewPlansButton]}
      onClick={() => {
        onClick(plan);
      }}
    >
      Select this Plan
    </Button>
  </div>
);

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  styleClass: PropTypes.string.isRequired,
  benefits: PropTypes.arrayOf(PropTypes.node),
  onClick: PropTypes.func.isRequired,
};

Plan.defaultProps = {
  benefits: [],
};

const mapStateToProps = ({ app }, { title }) => ({
  plan: app.maintenancePlans.find(({ name }) => name === title),
});

export default connect(mapStateToProps)(Plan);
