import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './FormRow.sss';

const FormRow = ({
  className,
  children,
}) => {
  return (
    <div className={classNames(className, theme.FormRow)}>
      {children}
    </div>
  );
};

FormRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FormRow;
