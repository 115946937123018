/* eslint-disable max-len */

import React from 'react';

const PlusIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 11 3 L 11 11 L 3 11 L 3 13 L 11 13 L 11 21 L 13 21 L 13 13 L 21 13 L 21 11 L 13 11 L 13 3 L 11 3 z" />
  </svg>
);

PlusIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default PlusIcon;
