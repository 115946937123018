import React from 'react';
import PropTypes from 'prop-types';

import ccLogo from 'assets/images/cc_logo.png';
import Button from 'components/Button';
import Loader from 'components/Loader';

import theme from './RequestNewToken.sss';

const RequestNewToken = ({
  submitting,
  onRequestNewToken,
}) => (
  <div className={theme.RequestNewToken}>
    <div className={theme.LeftPane}>
      <img src={ccLogo} />
    </div>
    <div className={theme.RightPane}>
      <div className={theme.RightPaneFill}>
        <div className={theme.ResendContainer}>
          <h1 className={theme.Title}>Oops</h1>
          <p>It looks like your link has expired. Resend?</p>
          <Button
            style={{ position: 'relative' }}
            size="big"
            disabled={submitting}
            onClick={onRequestNewToken}
          >
            Request New Token
            {submitting &&
              <Loader size="small" loading />
            }
          </Button>
        </div>
      </div>
    </div>
  </div>
);

RequestNewToken.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onRequestNewToken: PropTypes.func.isRequired,
};

RequestNewToken.defaultProps = {
  submitting: false,
};

export default RequestNewToken;
