import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { questionIds } from 'config/config';
import { formatUpdateAnswer } from 'helpers/answer';

import Form, { TextInput, TextAreaInput, Slider } from 'components/Form';
import PhotoUploadArea from './PhotoUploadArea';
import MultipleTextInput from './MultipleTextInput';

import { isFileUpload, isSlider } from 'helpers/question';

import theme from './OpenEnded.sss';

const SUBCATEGORIES = {
  single: 'single',
  essay: 'essay',
  multi: 'multi',
  numerical: 'numerical',
};

export const QUESTION_PHOTO_UPLOAD_PROPS = {
  [questionIds.checkin.uploadPhotosFace]: {
    area: 'face',
    positions: ['left', 'front', 'right'],
  },
  [questionIds.checkin.uploadPhotosChest]: {
    area: 'chest',
    positions: ['chest'],
  },
  [questionIds.checkin.uploadPhotoBack]: {
    area: 'back',
    positions: ['back'],
  },
  [questionIds.healthQuestionnaire.uploadPhotosFace]: {
    area: 'face',
    positions: ['left', 'front', 'right'],
  },
  [questionIds.healthQuestionnaire.uploadPhotosChest]: {
    area: 'chest',
    positions: ['chest'],
  },
  [questionIds.healthQuestionnaire.uploadPhotoBack]: {
    area: 'back',
    positions: ['back'],
  },
};

const OpenEnded = ({ question, answers, ...others }) => {
  const getFileUploadDefaultValue = () => {
    const { positions } = QUESTION_PHOTO_UPLOAD_PROPS[question.id];
    const answer = answers[0];
    if (!answer) {
      const defaultValue = positions.reduce(
        (acc, position) => ({ ...acc, [position]: '' }),
        {},
      );
      return {
        images: defaultValue,
        thumbnails: defaultValue,
      };
    }
    return answer.image_group_answer.image_answers.reduce(
      (previous, { context, image }) => ({
        ...previous,
        images: {
          ...previous.images,
          [context]: image.upload,
        },
        thumbnails: {
          ...previous.thumbnails,
          [context]: image.thumbnail,
        },
      }),
      positions.reduce(
        (previous, position) => ({
          ...previous,
          images: {
            ...previous.images,
            [position]: '',
          },
          thumbnails: {
            ...previous.thumbnails,
            [position]: '',
          },
        }),
        {},
      ),
    );
  };
  const getSliderInputDefaultValue = () => {
    const answer = answers[0];
    if (!answer) {
      return 50;
    }
    return Number(answer.text);
  };
  const getMultipleTextInputDefaultValue = () => {
    if (!answers.length) {
      return undefined;
    }
    return answers.map(({ row, text }) => ({
      row,
      text,
    }));
  };
  const renderInput = () => {
    switch (question.subcategory) {
      case SUBCATEGORIES.single: {
        if (isFileUpload(question)) {
          const photoUploadProps = QUESTION_PHOTO_UPLOAD_PROPS[question.id];
          return (
            <>
              <Form.Item
                name={question.id.toString()}
                onChange={(answer) => {
                  if (
                    !answer.image_group_answer.image_answers.some(
                      ({ image }) => image,
                    )
                  ) {
                    return undefined;
                  }
                  return [
                    {
                      ...answer,
                      image_group_answer: {
                        image_answers: answer.image_group_answer.image_answers.map(
                          (imageAnswer) => {
                            if (
                              typeof imageAnswer.image === 'string' &&
                              imageAnswer.image.startsWith('http')
                            ) {
                              const imageId = answers[0].image_group_answer.image_answers.find(
                                ({ image }) =>
                                  image.upload === imageAnswer.image,
                              ).image.id;
                              return {
                                context: imageAnswer.context,
                                image: imageId,
                              };
                            }
                            return imageAnswer;
                          },
                        ),
                      },
                      question: question.id,
                    },
                  ];
                }}
                label={
                  <label dangerouslySetInnerHTML={{ __html: question.text }} />
                }
                input={
                  <PhotoUploadArea
                    className={theme.PhotoUpload}
                    id={question.id.toString()}
                    defaultValue={getFileUploadDefaultValue()}
                    {...photoUploadProps}
                    {...others}
                  />
                }
                registerOptions={{
                  required:
                    question.is_required &&
                    JSON.stringify(
                      photoUploadProps.positions.reduce(
                        (acc, position) => ({
                          ...acc,
                          [position]: `Please upload the ${position} photo`,
                        }),
                        {},
                      ),
                    ),
                  validate: (values) => {
                    if (question.is_required) {
                      const errors = values[0].image_group_answer.image_answers.reduce(
                        (acc, { context, image }) => {
                          if (!image) {
                            acc[context] = `Please upload the ${context} photo`;
                          }
                          return acc;
                        },
                        {},
                      );
                      if (!isEmpty(errors)) {
                        return JSON.stringify(errors);
                      }
                    }
                    return true;
                  },
                }}
              />
            </>
          );
        }
        if (isSlider(question)) {
          return (
            <Form.Item
              name={question.id.toString()}
              defaultValue={
                answers[0] ? [formatUpdateAnswer(answers[0])] : undefined
              }
              onChange={(_, data) => [
                {
                  question: question.id,
                  text: data,
                },
              ]}
              label={
                <label htmlFor={question.id.toString()}>{question.text}</label>
              }
              input={
                <Slider
                  id={question.id.toString()}
                  defaultValue={getSliderInputDefaultValue()}
                  marks
                  step={5}
                  valueLabelDisplay="auto"
                />
              }
              registerOptions={{
                required:
                  question.is_required &&
                  'Please select a number in the slider',
              }}
            />
          );
        }
        return (
          <Form.Item
            name={question.id.toString()}
            defaultValue={
              answers[0] ? [formatUpdateAnswer(answers[0])] : undefined
            }
            onChange={(ev) => {
              if (!ev.target.value) {
                return undefined;
              }
              return [
                {
                  question: question.id,
                  text: ev.target.value,
                },
              ];
            }}
            label={
              <label htmlFor={question.id.toString()}>{question.text}</label>
            }
            input={
              <TextInput
                id={question.id.toString()}
                defaultValue={answers[0]?.text ?? ''}
              />
            }
            registerOptions={{
              required: question.is_required && 'Please fill out this field',
            }}
          />
        );
      }
      case SUBCATEGORIES.essay:
        return (
          <Form.Item
            name={question.id.toString()}
            defaultValue={
              answers[0] ? [formatUpdateAnswer(answers[0])] : undefined
            }
            onChange={(ev) => {
              if (!ev.target.value) {
                return undefined;
              }
              return [
                {
                  question: question.id,
                  text: ev.target.value,
                },
              ];
            }}
            label={
              <label htmlFor={question.id.toString()}>{question.text}</label>
            }
            input={
              <TextAreaInput
                name={question.id.toString()}
                id={question.id.toString()}
                defaultValue={answers[0]?.text ?? ''}
              />
            }
            registerOptions={{
              required: question.is_required && 'Please fill out this field',
            }}
          />
        );
      case SUBCATEGORIES.multi:
        return (
          <>
            <Form.Item
              name={question.id.toString()}
              onChange={(values) => {
                if (!values.length) {
                  return undefined;
                }
                return values.map(({ row, text }) => ({
                  question: question.id,
                  row,
                  text,
                }));
              }}
              fill={false}
              label={<label>{question.text}</label>}
              input={
                <MultipleTextInput
                  rows={question.rows}
                  defaultValue={getMultipleTextInputDefaultValue()}
                />
              }
              registerOptions={{
                required:
                  question.is_required && 'Please fill out all the fields',
              }}
            />
          </>
        );
      case SUBCATEGORIES.numerical:
      default:
        throw new Error(`subcategory ${question.subcategory} not supported`);
    }
  };
  return renderInput();
};

OpenEnded.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array,
};

OpenEnded.defaultProps = {
  answers: [],
};

export default OpenEnded;
