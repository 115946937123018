/* eslint no-use-before-define: 0 */
import axios from '../config/axios';

export function cardItemFormatter(label, value) {
  const labelName = label.toLowerCase().replace(/(\([a-zA-Z0-9 ]*\))/g, '');
  if (!value) {
    /* regex takes out anything inside parentheses for labels */
    return `No ${labelName} provided`;
  }
  switch (labelName) {
    case 'alt. phone ':
    case 'phone number':
      return formatPhoneNumber(value);
    default:
      return value;
  }
}

export function digitsOnly(phoneNumber) {
  return phoneNumber.replace(/[^0-9]+/g, '');
}

export function enhanceFollowup(question, answers) {
  return {
    ...question,
    answers: answers[question.url],
  };
}

export function properNounCase(string) {
  return string.toLowerCase().replace(/\b[a-z]/g, (c) => c.toUpperCase());
}

/* eslint-disable camelcase */
export function formatAddress({
  city,
  state,
  country,
  postal_code,
  street1,
  street2,
}) {
  const address = street2
    ? `${properNounCase(street1)} ${properNounCase(
        street2,
      )}, ${city} ${state}, ${postal_code}`
    : `${properNounCase(street1)}, ${city} ${state}, ${postal_code}`;
  return {
    address,
    country,
  };
}
/* eslint-enable camcelcase */

export function formatPhoneNumber(number) {
  try {
    return `(${number.substring(0, 3)})-${number.substring(
      3,
      6,
    )}-${number.substring(6)}`;
  } catch {
    return '';
  }
}

// eslint-disable-next-line no-unused-vars
export function formatDeleteForChoice(answer, token) {
  return axios.delete(`surveys/answers/${answer}/`);
}

export function formatPatchForChoice(answer, token, body) {
  return axios.patch(`surveys/answers/${answer}/`, JSON.stringify(body));
}

export function formatPatchForDatetime(answer, token, body) {
  return axios.patch(`surveys/answers/${answer}/`, JSON.stringify(body));
}

export function formatPatchForOpenEnded(answer, token, body) {
  return axios.patch(`surveys/answers/${answer}/`, JSON.stringify(body));
}

// eslint-disable-next-line no-unused-vars
export function formatPostForChoice(choice, response, token) {
  if (choice.category === 'text_answer') {
    return axios.post(
      'surveys/answers/',
      JSON.stringify({
        question: choice.question,
        text: choice.text,
        text_answer: choice.text_answer,
        response,
      }),
    );
  }
  return axios.post(
    'surveys/answers/',
    JSON.stringify({
      question: choice.question,
      choice: choice.choice,
      response,
    }),
  );
}

export function formatPostForDatetime(token, body) {
  return axios.post('surveys/answers/', JSON.stringify(body));
}

export function formatPostForOpenEnded(token, body) {
  return axios.post('surveys/answers/', JSON.stringify(body));
}

export function getIdFromUrl(url) {
  if (!url) {
    return url;
  }
  return Number(url.match(/\/[0-9]+\/$/)[0].match(/[0-9]+/)[0]);
}
