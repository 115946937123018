import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import history from 'global-history';
import { getTimeZones } from 'helpers/getTimeZones';

import Form from 'components/Form';
import { userAware } from 'components/UserProvider';
import Loader from 'components/Loader';
import { Autocomplete } from 'components/Form';
import Button from 'components/Button';

import * as actions from './AddTimeZone.ducks';
import theme from './AddTimeZone.sss';

const AddTimeZone = ({ user, loading, save }) => {
  const timeZones = getTimeZones();
  useEffect(() => {
    if (user.profile.time_zone) {
      history.replace('/');
    }
  }, [user]);
  if (loading) {
    return <Loader size="large" loading />;
  }
  return (
    <Form
      className={theme.AddTimeZone}
      onSubmit={(values) => {
        save(values);
      }}
      description={
        <p className={theme.FormTitle}>
          {/* eslint-disable-next-line max-len */}
          {`Hi ${user.profile.full_name}, before you keep using Clear Connection, help us to improve by providing us your timezone to send your notifications in time.`}
        </p>
      }
    >
      {() => (
        <>
          <Form.Row>
            <Form.Item
              name="timeZone"
              onChange={(_, value) => value}
              label={<label htmlFor="timeZone">Time Zone</label>}
              input={
                <Autocomplete
                  id="timeZone"
                  getOptionLabel={(tz) => tz?.name}
                  renderOption={(tz) => `(GMT ${tz?.utcOffsetStr}) ${tz?.name}`}
                  options={timeZones}
                  disabled={loading}
                />
              }
              registerOptions={{
                required: 'Please select your Time zone',
              }}
            />
          </Form.Row>
          <Form.Actions>
            <Button classes={[theme.Submit]} type="submit">
              {'Save Time Zone'}
            </Button>
          </Form.Actions>
        </>
      )}
    </Form>
  );
};

AddTimeZone.propTypes = {
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
};

const mapStateToProps = ({ addTimeZone }) => ({
  ...addTimeZone,
});

export default connect(mapStateToProps, actions)(userAware(AddTimeZone));
