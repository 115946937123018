import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Avatar from 'components/Avatar';
import Loader from 'components/Loader';

import { useGetClient } from 'features/users/user.query';

import theme from './Details.sss';

const Details = ({ clientId }) => {
  const { data: client, isLoading } = useGetClient(clientId);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={theme.Details}>
      <Avatar className={theme.Avatar} style="icon" user={client} />
      <header className={theme.RecipientInfo}>
        <p className={theme.HelperText}>You are chatting with a member:</p>
        <div className={theme.Recipient}>
          <p className={theme.RecipientName}>{client.profile.full_name}</p>
        </div>
        <Link to={`/client-details/${client.id}`}>View medical record</Link>
      </header>
    </div>
  );
};

Details.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default Details;
