import React from 'react';
import PropTypes from 'prop-types';
import Viewer from 'react-viewer';
import moment from 'moment';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import axios from 'config/axios';
import { userAware } from 'components/UserProvider';
import Loader from 'components/Loader';
import theme from 'components/Surveys/SurveyResults/SurveyResults.scss';

class PhotoViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewerVisible: false,
      imageIndex: 0,
      viewerImages: false,
      loadingOtherImages: false,
      otherImages: [],
      selectedOtherWeek: false,
      showOtherImages: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { existingCheckins, questionId } = nextProps;

    if (
      !this.state.loadingOtherImages &&
      existingCheckins &&
      existingCheckins.length
    ) {
      this.setState({
        loadingOtherImages: true,
      });
      this.getExistingResponseImages(existingCheckins, questionId);
    }
  };

  getExistingResponseImages = async (checkins, questionId) => {
    const results = await Promise.all(
      checkins.map(({ check_in: checkin }) =>
        axios.get(`surveys/responses/${checkin}/details/`),
      ),
    );

    this.setState({
      loadingOtherImages: false,
      otherImages: results.map(({ data: response }) => ({
        completed: response.stage.completed,
        images: response.answers
          .find((answer) => answer.question.id === questionId)
          .image_group_answer.image_answers.reduce(
            (acc, { context, image }) => ({
              ...acc,
              [context]: {
                image: image.upload,
                thumbnail: image.thumbnail,
              },
            }),
            {},
          ),
        programIndex: response.stage.program_index,
      })),
    });
  };

  openViewer = (images, index) => {
    this.setState({
      viewerVisible: true,
      viewerImages: Object.keys(images).map((key) => ({
        src: images[key].image,
        alt: key,
      })),
      imageIndex: index,
    });
  };

  handleOtherWeekClicked = (selectedOtherWeek) => {
    const { selectedOtherWeek: previousWeekSelected, showOtherImages } =
      this.state;
    this.setState({
      showOtherImages:
        previousWeekSelected !== selectedOtherWeek || !showOtherImages,
      selectedOtherWeek,
    });
  };

  render({ images } = this.props) {
    const {
      otherImages,
      loadingOtherImages,
      showOtherImages,
      selectedOtherWeek,
    } = this.state;

    const selectedOtherImages =
      otherImages.find(({ programIndex }) => programIndex === selectedOtherWeek)
        ?.images || {};

    return (
      <div>
        <ul className={theme.checkinImages}>
          {Object.keys(images).map((key, i) => {
            return (
              <li key={i} onClick={() => this.openViewer(images, i)}>
                <img src={images[key].thumbnail} />
                <span>{key}</span>
              </li>
            );
          })}
        </ul>
        <Viewer
          visible={this.state.viewerVisible}
          onClose={() => {
            this.setState({ viewerVisible: false });
          }}
          images={this.state.viewerImages}
          activeIndex={this.state.imageIndex}
        />
        {loadingOtherImages && (
          <Loader
            className={theme.otherImagesLoading}
            loading={loadingOtherImages}
          />
        )}
        {otherImages.length > 0 && (
          <div className={theme.otherPhotos}>
            <h4>Other Checkin Photos</h4>
            <ul>
              {otherImages.map((imageObj, i) => {
                const programStageWeeks = `${imageObj.programIndex * 2 + 3}-${
                  imageObj.programIndex * 2 + 4
                }`;
                const completed = moment(imageObj.completed).format(
                  'MM/DD/YYYY',
                );

                return (
                  <li key={i}>
                    <button
                      className={classNames({
                        [theme.SelectedButton]:
                          showOtherImages &&
                          imageObj.programIndex === selectedOtherWeek,
                      })}
                      onClick={() => {
                        this.handleOtherWeekClicked(imageObj.programIndex);
                      }}
                    >
                      <h4>{`Week ${programStageWeeks} Checkin`}</h4>
                      <p>{completed}</p>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {showOtherImages && !isEmpty(selectedOtherImages) && (
          <ul className={theme.checkinImages}>
            {Object.keys(this.props.images).map((key, i) => (
              <li
                key={i}
                onClick={() => this.openViewer(selectedOtherImages, i)}
              >
                <img src={selectedOtherImages[key].thumbnail} />
                <span>{key}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

PhotoViewer.propTypes = {
  images: PropTypes.object,
  existingCheckins: PropTypes.array,
  user: PropTypes.object,
};

export default userAware(PhotoViewer);
