import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';

import Button from 'components/Button';

import theme from './Welcome.sss';

const Welcome = ({ onDone }) => {
  useEffect(() => {
    ReactPixel.pageView();
    ReactPixel.trackCustom('accepted treatment plan');
  }, []);
  return (
    <div>
      <div>
        <h4 className={theme.Title}>
          Welcome to the program! If you have any questions, feel free to reach
          out to your Clear Skin Specialist through your messages.
        </h4>
      </div>
      <footer className={theme.Actions}>
        <Button
          size="big"
          onClick={() => {
            onDone();
          }}
        >
          Done
        </Button>
      </footer>
    </div>
  );
};

Welcome.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default Welcome;
