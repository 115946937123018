import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { QuantityControl } from 'components/Form';

import theme from './OrderProducts.scss';

const OrderProduct = forwardRef(({ product, ...others }, ref) => (
  <div className={theme.ClientOrderProduct}>
    <span>
      {product.name} (${product.price})
    </span>
    <span>
      <QuantityControl ref={ref} small {...others} />
    </span>
  </div>
));

OrderProduct.propTypes = {
  product: PropTypes.object,
};

export default OrderProduct;
