/* eslint-disable max-len */

import React from 'react';

const CameraIcon = (props) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path d="M15.2954667,5.19380513 L10.7867487,5.19380513 L10.7867487,0.685087179 C10.7867487,0.306871795 10.4798564,0 10.1016615,0 L5.87889231,0 C5.50067692,0 5.19380513,0.306892308 5.19380513,0.685087179 L5.19380513,5.19380513 L0.685087179,5.19380513 C0.306071795,5.19380513 0,5.50069744 0,5.87889231 L0,10.1024821 C0,10.4806974 0.306092308,10.7875692 0.685087179,10.7875692 L5.19380513,10.7875692 L5.19380513,15.2962872 C5.19380513,15.6745026 5.50069744,15.9813744 5.87889231,15.9813744 L10.1016615,15.9805731 C10.4798769,15.9805731 10.7867487,15.6736808 10.7867487,15.2954859 L10.7867487,10.786768 L15.2954667,10.786768 C15.6736821,10.786768 15.9805538,10.4798757 15.9805538,10.1016808 L15.9805538,5.87809108 C15.9805538,5.49987569 15.6736615,5.1938039 15.2954667,5.1938039 L15.2954667,5.19380513 Z" />
  </svg>
);

CameraIcon.defaultProps = {
  height: 16,
  width: 16,
  fill: 'black',
};

export default CameraIcon;
