import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';

import TruncatedText from 'components/TruncatedText';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { PlusIcon } from 'components/Icon';
import * as modalActions from 'components/Modal/Modal.ducks';

import theme from './TDNote.sss';

function TDNote({ clientID, note, viewNotesModalID, addNoteModalID }) {
  const dispatch = useDispatch();
  let content = '0 notes';
  if (note) {
    content = (
      <>
        <span className={theme.NoteDate}>
          {format(new Date(note.created), 'MM/dd/yyyy hh:mm a')}
        </span>
        <TruncatedText text={note.body} truncateSize={507} />
        <Button
          color="sensitive"
          onClick={() => {
            dispatch(
              modalActions.setOpenModalId(viewNotesModalID, {
                clientId: clientID,
              }),
            );
          }}
        >
          View More
        </Button>
      </>
    );
  }
  return (
    <div className={theme.TDNote}>
      {content}
      <IconButton
        data-testid={`TDNote/${clientID}/add-note`}
        icon={<PlusIcon />}
        onClick={() => {
          dispatch(
            modalActions.setOpenModalId(addNoteModalID, {
              clientId: clientID,
            }),
          );
        }}
      />
    </div>
  );
}

TDNote.propTypes = {
  clientID: PropTypes.number.isRequired,
  note: PropTypes.object,
  viewNotesModalID: PropTypes.string.isRequired,
  addNoteModalID: PropTypes.string.isRequired,
};

export default TDNote;
