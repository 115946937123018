/* eslint no-use-before-define: 0 */

import axios from 'config/axios';
import { toast } from 'react-toastify';

import { getErrorMessage } from 'helpers/errorHandling';

// Actions
const FETCH_UNREAD_COUNT = 'cc/UserInboxWidget/FETCH_UNREAD_COUNT';
const FETCH_UNREAD_COUNT_SUCCEEDED =
  'cc/UserInboxWidget/FETCH_UNREAD_COUNT_SUCCEEDED';
const FETCH_UNREAD_COUNT_FAILED =
  'cc/UserInboxWidget/FETCH_UNREAD_COUNT_FAILED';
const CREATE_THREAD = 'cc/UserInboxWidget/CREATE_THREAD';
const CREATE_THREAD_FAILED = 'cc/UserInboxWidget/CREATE_THREAD_FAILED';
const CREATE_THREAD_SUCCEEDED = 'cc/UserInboxWidget/CREATE_THREAD_SUCCEEDED';
const READ_THREAD = 'cc/UserInboxWidget/READ_THREAD';
const READ_THREAD_SUCCEEDED = 'cc/UserInboxWidget/READ_THREAD_SUCCEEDED';
const READ_THREAD_FAILED = 'cc/UserInboxWidget/READ_THREAD_FAILED';

const initialState = {
  unreadCount: 0,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNREAD_COUNT_SUCCEEDED: {
      const { unreadCount } = action.payload;
      return { ...state, unreadCount };
    }
    default:
      return state;
  }
};

// Actions

const fetchUnreadCount = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_UNREAD_COUNT, payload });
  try {
    const { data } = await axios.get('messages/inbox/', {
      params: { filter: 'unread' },
    });
    dispatch(fetchUnreadCountSucceeded({ unreadCount: data.count }));
  } catch (error) {
    fetchUnreadCountFailed(error);
  }
};

const fetchUnreadCountSucceeded = (payload) => ({
  type: FETCH_UNREAD_COUNT_SUCCEEDED,
  payload,
});

const fetchUnreadCountFailed = (error) => async (dispatch) => {
  dispatch({ type: FETCH_UNREAD_COUNT_FAILED, error });
  toast.error(getErrorMessage(error));
};

const createThread = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_THREAD, payload });
  const { participants } = payload;
  try {
    const { data: thread } = await axios.post('messages/threads/', {
      participants,
    });
    await dispatch(createThreadSucceeded({ thread }));
    return thread;
  } catch (error) {
    createThreadFailed({ error });
    return null;
  }
};

const createThreadSucceeded = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_THREAD_SUCCEEDED, payload });
};

const createThreadFailed = (payload) => (dispatch) => {
  dispatch({ type: CREATE_THREAD_FAILED, payload });
};

const readThread = (payload) => async (dispatch) => {
  dispatch({ type: READ_THREAD, payload });
  const { user, thread } = payload;
  try {
    const response = await axios.post(`messages/threads/${thread.id}/read/`, {
      user: user.id,
    });
    dispatch(readThreadSucceeded(response.data));
  } catch (error) {
    readThreadFailed({ error });
  }
};

const readThreadSucceeded = (thread) => (dispatch) => {
  dispatch({ type: READ_THREAD_SUCCEEDED, thread });
  dispatch(fetchUnreadCount());
};

const readThreadFailed = (payload) => (dispatch) => {
  dispatch({ type: READ_THREAD_FAILED, payload });
};

export default reducer;
export {
  // Actions
  FETCH_UNREAD_COUNT,
  FETCH_UNREAD_COUNT_FAILED,
  FETCH_UNREAD_COUNT_SUCCEEDED,
  CREATE_THREAD,
  CREATE_THREAD_FAILED,
  CREATE_THREAD_SUCCEEDED,
  READ_THREAD,
  READ_THREAD_SUCCEEDED,
  READ_THREAD_FAILED,
  // Action Creators
  fetchUnreadCount,
  fetchUnreadCountSucceeded,
  fetchUnreadCountFailed,
  createThread,
  createThreadSucceeded,
  createThreadFailed,
  readThread,
  readThreadSucceeded,
  readThreadFailed,
};
