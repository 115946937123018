import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Button from 'components/Button';

import messages from './messages';
import theme from './SuccessModal.sss';

const SuccessModal = ({
  modalId,
  onExit
}) => (
  <Modal id={modalId} showTopActions={false}>
    <div className={theme.SuccessModal}>
      <h3 className={theme.Title}>{messages.description}</h3>
      <footer className={theme.Footer}>
        <Button classes={[theme.Action]} size="big" onClick={onExit}>
          {messages.submit}
        </Button>
      </footer>
    </div>
  </Modal>
);

SuccessModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default SuccessModal;
