import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { turquoise01 } from 'styles/colors.sss';
import { TYPES } from 'helpers/note';
import { useLoggedInUser } from 'hooks';
import { TextInput } from 'components/Form';
import { PeopleIcon, SearchIcon } from 'components/Icon';
import { useBranch } from 'components/BranchProvider';
import { TABLE, THEAD, TBODY, TH, TR } from 'components/Table';

import ProspectRow from './ProspectRow';
import NotesModalWidget from 'containers/NotesModalWidget';
import AddNoteModalWidget from 'containers/AddNoteModalWidget';

import theme from './ProspectsTable.sss';

export const NOTES_MODAL_ID = 'ProspectTable/NotesWidget';
export const ADD_NOTE_MODAL_ID = 'ProspectTable/AddNoteModalWidget';

const ProspectsTable = ({ prospects, onActionClick }) => {
  const queryClient = useQueryClient();
  const user = useLoggedInUser();

  const [searchResults, setSearchResults] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [branch] = useBranch();
  const isShowNextAppoinmentEnable = branch.show_next_appointment_enabled;
  const filterResult = searchResults || prospects;
  const total = filterResult.length;

  const searchCompare = (prospect, clientName) =>
    `${prospect.profile.first_name} ${prospect.profile.last_name}`
      .toLowerCase()
      .includes(clientName.toLowerCase());

  return (
    <div className={theme.ProspectsTable}>
      <div className={theme.TableHeader}>
        <div className={theme.Title}>
          <PeopleIcon fill={turquoise01} />
          <h1>Prospects</h1>
        </div>
        <TextInput
          placeholder="Search a client"
          icon={<SearchIcon fill={turquoise01} />}
          onChange={(ev) => {
            const clientName = ev.target.value;
            if (typingTimeout) {
              clearTimeout(typingTimeout);
            }
            setTypingTimeout(
              setTimeout(() => {
                if (clientName) {
                  setSearchResults(
                    prospects.filter((prospect) =>
                      searchCompare(prospect, clientName),
                    ),
                  );
                } else {
                  setSearchResults(null);
                }
              }, 1000),
            );
          }}
        />
      </div>
      <div className={theme.ResultsCount}>{`${total} clients`}</div>
      <NotesModalWidget modalId={NOTES_MODAL_ID} types={[TYPES.general]} />
      <AddNoteModalWidget
        modalId={ADD_NOTE_MODAL_ID}
        onNoteAdded={() => {
          queryClient.invalidateQueries(['coaches', user.id, 'prospects']);
        }}
      />
      <TABLE>
        <THEAD>
          <TR>
            <TH>Name</TH>
            <TH>Status</TH>
            {isShowNextAppoinmentEnable && <TH>Appt Date</TH>}
            <TH>Action</TH>
            <TH>Notes</TH>
          </TR>
        </THEAD>
        <TBODY>
          {filterResult.map((prospect, key) => (
            <ProspectRow
              key={key}
              prospect={prospect}
              onActionClick={onActionClick}
            />
          ))}
        </TBODY>
      </TABLE>
    </div>
  );
};

ProspectsTable.propTypes = {
  prospects: PropTypes.array,
  onActionClick: PropTypes.func.isRequired,
};

ProspectsTable.defaultProps = {
  prospects: [],
};

export default ProspectsTable;
