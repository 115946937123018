/* eslint-disable quote-props */
export const facebookPixelId = process.env.FACEBOOK_PIXEL_ID;
export const baseUrl = process.env.BASEURL;
export const apiBaseUrl = process.env.API_BASE_URL;
export const sentryDsn = process.env.SENTRY_DSN;
export const sentryEnvironment = process.env.SENTRY_ENVIRONMENT;
export const maintenanceMode = !!Number(process.env.MAINTENANCE_MODE);

// NOTE: For unit tests, we use 25
export const PAGE_SIZE =
  process.env.NODE_ENV === 'test' ? 25 : Number(process.env.PAGE_SIZE);

export const userTypeIds = {
  client: 1,
  coach: 2,
  practice_manager: 3,
};

export const surveyIds = {
  healthQuestionnaire: 1,
  checkin: 2,
};

export const collectorIds = {
  questionnaire: 1,
  checkin: 2,
};

export const choiceIds = {
  healthQuestionnaire: {
    maleGender: 1,
  },
  checkin: {},
};

export const questionIds = {
  healthQuestionnaire: {
    gender: 2,
    facialHair: 5,
    uploadPhotosFace: 80,
    uploadPhotosChest: 81,
    uploadPhotoBack: 82,
    adhereToCriteria: 83,
  },
  checkin: {
    uploadPhotosFace: 85,
    uploadPhotosChest: 86,
    uploadPhotoBack: 87,
    adhereToCriteria: 88,
    selectASR: 89,
    IUD: 102,
    coachReview: 112,
    feedback: 113,
    prenantOrNursing: 114,
  },
};

export const checkinFemaleQuestions = [
  questionIds.checkin.IUD,
  questionIds.checkin.prenantOrNursing,
];

export const pageIds = {
  medicalHistory: 1,
};

export const userTypeUrls = {
  client: `${apiBaseUrl}groups/${userTypeIds.client}`,
  coach: `${apiBaseUrl}groups/${userTypeIds.coach}`,
  practice_manager: `${apiBaseUrl}groups/${userTypeIds.practice_manager}`,
};

export const googleDocuments = [
  {
    name: 'Treatment Plan Template',
    url: 'https://docs.google.com/document/d/1eetm_GqDG1e9SGAHgk-gUR7C_wHMpZqMWb8CGi1WHB0/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Responding To A Client Checkin Beg To End Inflamed',
    url: 'https://docs.google.com/document/d/1OJ6IkCkydrCFU2olFEpe4uj5gvOf7vgk4PGPDFc-iYs/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Responding To A Client Checkin Beg To End Non-Inflamed',
    url: 'https://docs.google.com/document/d/1sUfXefrevv6YwDzeO7gFx7BHPAIYPUzLM6fcBk6yHF0/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Responding To Client Checkin Back',
    url: 'https://docs.google.com/document/d/11HTHCk-aZ2PCZvvPyUi-f0eVspLR5vEu018xWYB_Tmo/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Responding To Client Checkin Chest',
    url: 'https://docs.google.com/document/d/1_yhQKDZky65afy8r_rbCD74lLc-CPFiwPGGRu_wmQTI/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Maintenance Regimen Inflamed',
    url: 'https://docs.google.com/document/d/10p-bW3UwlKrJCkM7QQFSVj6rKERy6CubWSQ4wa8h_k0/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Maintenance Regimen Non-Inflamed',
    url: 'https://docs.google.com/document/d/1jz4LKYausz6a-6DbBnhVWmhZDlMprcp9Ey-iNwMHZlU/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Maintenance Regimen Back And Chest',
    url: 'https://docs.google.com/document/d/1oAJZIs_xTAaYRlUniQjsMq-D6fpvqRLB13vN1JdZsiw/edit?usp=sharing',
    type: 'document',
  },
  {
    name: 'Testimonial Request',
    url: 'https://docs.google.com/document/d/1xrCIna-1adUSaEsyG7NcyHzIQ7v4NRsgOyu5zefRZP8/edit?usp=sharing',
    type: 'document',
  },
];

export const asrTypes = [
  { asr_a: 'ASR A' },
  { asr_b: 'ASR B' },
  { asr_c: 'ASR C' },
  { asr_d: 'ASR D' },
  { asr_e: 'ASR E' },
];

export const branches = {
  1: 'online', // eslint-disable-line quote-props
  2: 'nscc', // eslint-disable-line quote-props
};

// NOTE: Only used for the following ticket
// https://naturalacneclinic.atlassian.net/browse/SNAC-876
export function resolveBranch(branchId, userId) {
  // NOTE: Hardcoded to avoid sending new emails to users
  // https://naturalacneclinic.atlassian.net/browse/SNAC-876
  const NSCC_USERS = [2600, 2591, 2590, 2588];
  if (!branchId) {
    if (NSCC_USERS.includes(userId)) {
      return 'nscc';
    }
    return 'online';
  }
  return branches[branchId];
}

export function resolveService(collector) {
  switch (collector) {
    case 1:
    case 2:
      return 'survey_monkey';
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return 'survey_sparrow';
    default:
      return 'collector_not_supported';
  }
}
