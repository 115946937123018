/**
 * Return a mapping of all products with their quantities
 * @param {Array} steps steps to retrieve the quantities
 * @returns {Object} productQtyMap object made of product key and value quantity
 */
export const getAllProductsQtys = (steps) =>
  steps.filter((step) => step.includeStep && step.stepQty > 0).reduce((acc, step) => ({
    ...acc,
    [step.product]: (acc[step.product] || 0) + step.stepQty,
  }), {});

/**
 * Return the total quantity found in all steps matching a given product
 * @param {string} product product to calculate the sum of the quantities
 */
export const getProductQty = (steps, product) => {
  const productSteps = steps.filter((step) => step.includeStep && step.product === product && step.stepQty > 0);
  return productSteps.reduce((acc, { stepQty }) => acc + stepQty, 0);
};

export const parseCreate = (step) => {
  const stepObj = {
    instructions: step.instructions,
    name: step.name,
  };

  if (step.product) {
    stepObj.product = step.product;
    stepObj.quantity = step.stepQty;
  } else if (step.text) {
    stepObj.text = step.text;
  }

  return stepObj;
};

export const buildReduxObj = ({
  stepId,
  regimenIndex,
  existingStep,
  stepConfig,
  isNewRegimen,
}) => {
  const noneOption = { product: { id: -1, name: 'None' } };
  const isHerbalStep = stepConfig.name === 'HERBAL';
  const options = isHerbalStep ? stepConfig.options : [noneOption, ...stepConfig.options];
  const selectedProduct = options.find(
    (option) => option.product.id === existingStep?.product?.id
  );
  let instructions;
  if (isNewRegimen) {
    instructions = selectedProduct ? selectedProduct.instructions : options[0].instructions;
  } else {
    instructions = existingStep?.instructions || options[0].instructions;
  }

  return {
    ...existingStep,
    includeStep: (isNewRegimen && stepConfig.enabled) || !!existingStep,
    id: stepId,
    regimen: regimenIndex,
    name: stepConfig.name,
    options,
    product: selectedProduct ? existingStep?.product?.id : options[0].product.id,
    stepQty: isNewRegimen ? 0 : existingStep?.quantity || 0,
    helperText: stepConfig.instructions,
    instructions,
  };
};
