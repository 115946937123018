import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import { useBranch } from 'components/BranchProvider';
import Button from 'components/Button';
import { TextAreaInput } from 'components/Form';

import theme from './QuestionsForm.sss';

const QuestionsForm = ({ defaultValues, onSubmit }) => {
  const [branch] = useBranch();
  const { control, handleSubmit } = useForm({
    defaultValues,
  });
  return (
    <form>
      <h4 className={theme.Title}>
        Congratulations! You have made a great decision.
      </h4>
      {branch.messaging_enabled && (
        <p className={theme.Description}>
          Do you have any questions for us at this point?
        </p>
      )}
      {branch.messaging_enabled && (
        <div>
          <Controller
            as={TextAreaInput}
            name="questions"
            size="small"
            control={control}
          />
        </div>
      )}
      <footer className={theme.Actions}>
        <Button
          classes={[theme.Action]}
          size="big"
          onClick={handleSubmit((values) => {
            onSubmit({
              ...values,
              submitAction: 'next',
            });
          })}
        >
          Next
        </Button>
      </footer>
    </form>
  );
};

QuestionsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default QuestionsForm;
