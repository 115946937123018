import React from 'react';

const BranchContext = React.createContext();
BranchContext.displayName = 'BranchContext';

// eslint-disable-next-line react/prop-types
function BranchProvider({ branch: initialBranch, children }) {
  const [branch, setBranch] = React.useState(initialBranch);
  const value = [branch, setBranch];
  return (
    <BranchContext.Provider value={value}>{children}</BranchContext.Provider>
  );
}

function useBranch() {
  const context = React.useContext(BranchContext);
  if (context === undefined) {
    throw new Error('useBranch must be used within a BranchProvider');
  }
  return context;
}

export { BranchContext, useBranch };
export default BranchProvider;
