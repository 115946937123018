import { useQuery } from 'react-query';

import CoachService from 'services/coach.service';

export function useGetProspects(coachId, params, options) {
  return useQuery(
    ['coaches', coachId, 'prospects', params],
    () => CoachService.getProspects(coachId, params),
    options,
  );
}
