/* eslint no-use-before-define: 0 */

import axios from 'config/axios';
import { toast } from 'react-toastify';

import history from 'global-history';
import { collectorIds } from 'config/config';
import { isCompleted } from 'helpers/stage';
import { isCheckIn, isHQ } from 'helpers/survey';
import { getErrorMessage } from 'helpers/errorHandling';

// Actions
const INITIALIZE = 'cc/newSurvey/INITIALIZE';
const INITIALIZE_FAILED = 'cc/newSurvey/INITIALIZE_FAILED';
const INITIALIZE_SUCCEEDED = 'cc/newSurvey/INITIALIZE_SUCCEEDED';
const FETCH_SURVEY_DETAILS = 'cc/newSurvey/FETCH_SURVEY_DETAILS';
const FETCH_SURVEY_DETAILS_FAILED = 'cc/newSurvey/FETCH_SURVEY_DETAILS_FAILED';
const FETCH_SURVEY_DETAILS_SUCCEEDED =
  'cc/newSurvey/FETCH_SURVEY_DETAILS_SUCCEEDED';
const SET_ACTIVE_STEP = 'cc/newSurvey/SET_ACTIVE_STEP';
const RESET = 'cc/newSurvey/RESET';

const initialState = {
  loading: true,
  activeStep: 0,
  survey: {},
  stage: {},
  responseId: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state, loading: true };
    case INITIALIZE_SUCCEEDED: {
      const { survey, stage, responseId } = action.payload;
      return {
        ...state,
        survey,
        stage,
        responseId,
        loading: false,
      };
    }
    case FETCH_SURVEY_DETAILS_SUCCEEDED: {
      const { survey } = action.payload;
      return {
        ...state,
        survey,
      };
    }
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.step,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

const initialize = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  const { userId, surveyId, stageId } = payload;

  try {
    const { data: surveyDetail } = await axios.get(
      `surveys/${surveyId}/details/`,
      {
        params: { user: userId },
      },
    );

    let stage = {};
    let responseId;
    if (isCheckIn(surveyDetail)) {
      const { data } = await axios.get(`stages/${stageId}/`);
      stage = data;
      if (!stage || isCompleted(stage)) {
        history.replace('/');
        return;
      }
      responseId = stage?.check_in; // eslint-disable-line camelcase
    } else if (isHQ(surveyDetail)) {
      const {
        data: { results: responses },
      } = await axios.get('surveys/responses/', {
        params: {
          user: userId,
          collector: collectorIds.questionnaire,
        },
      });
      let hqResponse = responses[0];
      if (!hqResponse) {
        const { data } = await axios.post('surveys/responses/', {
          collector: collectorIds.questionnaire,
          user: userId,
        });
        hqResponse = data;
      }
      if (isCompleted(hqResponse)) {
        history.replace('/');
        return;
      }
      responseId = hqResponse?.id;
    } else {
      throw new Error(`Survey ${surveyDetail.id} not supported`);
    }

    dispatch(
      initializeSuceeded({
        survey: surveyDetail,
        stage,
        responseId,
      }),
    );
  } catch (error) {
    dispatch(initializeFailed(error));
  }
};

const initializeSuceeded = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCEEDED, payload });
};

const initializeFailed = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAILED, payload });
  toast.error(getErrorMessage(payload));
};

const fetchSurveyDetails = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_SURVEY_DETAILS, payload });
  const { userId, surveyId } = payload;

  try {
    const { data: survey } = await axios.get(`surveys/${surveyId}/details/`, {
      params: { user: userId },
    });

    dispatch(
      fetchSurveyDetailsSuceeded({
        survey,
      }),
    );
  } catch (error) {
    dispatch(fetchSurveyDetailsFailed(error));
  }
};

const fetchSurveyDetailsSuceeded = (payload) => (dispatch) => {
  dispatch({ type: FETCH_SURVEY_DETAILS_SUCCEEDED, payload });
};

const fetchSurveyDetailsFailed = (payload) => (dispatch) => {
  dispatch({ type: FETCH_SURVEY_DETAILS_FAILED, payload });
  toast.error(getErrorMessage(payload));
};

const setActiveStep = (step) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_STEP, step });
};

const reset = (payload) => (dispatch) => {
  dispatch({ type: RESET, payload });
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_FAILED,
  INITIALIZE_SUCCEEDED,
  FETCH_SURVEY_DETAILS,
  FETCH_SURVEY_DETAILS_FAILED,
  FETCH_SURVEY_DETAILS_SUCCEEDED,
  SET_ACTIVE_STEP,
  RESET,
  // Action Creators
  initialize,
  initializeSuceeded,
  initializeFailed,
  fetchSurveyDetails,
  fetchSurveyDetailsSuceeded,
  fetchSurveyDetailsFailed,
  setActiveStep,
  reset,
};
