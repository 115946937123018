import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import classNames from 'classnames';

import theme from './PhoneNumberInput.sss';

const PhoneNumberInput = forwardRef(
  ({ classes, disabled, error, onChange, ...others }, ref) => (
    <>
      <PhoneInput
        className={classNames(
          theme.PhoneNumberInput,
          { [theme.InputError]: error },
          ...classes,
        )}
        defaultCountry="US"
        disabled={disabled}
        displayInitialValueAsLocalNumber={disabled}
        onChange={onChange}
        ref={ref}
        {...others}
      />
      {error && <span className={theme.ErrorText}>{error}</span>}
    </>
  ),
);

PhoneNumberInput.propTypes = {
  classes: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

PhoneNumberInput.defaultProps = {
  disabled: false,
  classes: [],
  onChange: () => undefined,
};

export default PhoneNumberInput;
