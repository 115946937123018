import React from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';

import { isVerticalTwoCol } from 'helpers/question';

import Form, { MultipleChoice as MultipleChoiceInput } from 'components/Form';

import Question from '../../Question';

const SUBCATEGORIES = {
  vertical: 'vertical',
  verticalTwoCol: 'vertical_two_col',
};

const MultipleChoice = ({ question, answers }) => {
  const getDefaultValue = () => {
    if (!answers.length) {
      return [];
    }
    return answers.map(({ choice, text, text_answer: textAnswer }) => {
      if (choice) {
        return choice.id.toString();
      }
      return {
        [textAnswer]: text,
      };
    });
  };
  const getOtherChoice = () => {
    if (!question.text_answer) {
      return undefined;
    }
    return {
      title: question.text_answer.text,
      value: question.text_answer.id.toString(),
      type: question.text_answer.is_answer_choice ? 'choice' : 'field',
      inputType: 'text',
    };
  };
  const renderInput = () => {
    switch (question.subcategory) {
      case SUBCATEGORIES.vertical:
      case SUBCATEGORIES.verticalTwoCol:
        return (
          <Form.Item
            name={question.id.toString()}
            onChange={(choices) => {
              if (!choices.length) {
                return undefined;
              }
              return choices.map((choiceValue) => {
                let text;
                let textAnswer;
                let choice = Number(choiceValue);
                if (isObject(choiceValue)) {
                  text = choiceValue[question.text_answer.id];
                  textAnswer = Number(Object.keys(choiceValue)[0]);
                  choice = undefined;
                }
                return {
                  question: question.id,
                  choice,
                  text_answer: textAnswer,
                  text,
                };
              });
            }}
            label={<label>{question.text}</label>}
            input={
              <MultipleChoiceInput
                id={question.id.toString()}
                defaultValue={getDefaultValue()}
                choices={question.choices.map(
                  ({ id, text, followup_questions: followupQuestions }) => ({
                    title: text,
                    value: id.toString(),
                    followupQuestions: followupQuestions.map(
                      (followUpQuestion) => (
                        <Question
                          key={`question-${followUpQuestion.id}`}
                          question={followUpQuestion}
                        />
                      ),
                    ),
                  }),
                )}
                otherChoice={getOtherChoice()}
                orientation={
                  isVerticalTwoCol(question) ? 'vertical_two_col' : 'vertical'
                }
              />
            }
            registerOptions={{
              required:
                question.is_required && 'Please select at least one choice',
              validate: (values) => {
                if (question.is_required) {
                  const otherChoiceAnswer = values.find(
                    ({ text_answer: textAnswer }) => textAnswer,
                  );
                  if (otherChoiceAnswer && !otherChoiceAnswer.text) {
                    return 'Please specify an answer';
                  }
                }
                return true;
              },
            }}
          />
        );
      default:
        throw new Error(`subcategory ${question.subcategory} not supported`);
    }
  };
  return renderInput();
};

MultipleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array,
};

MultipleChoice.defaultProps = {
  answers: [],
};

export default MultipleChoice;
