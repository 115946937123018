/* eslint-disable max-len */

import React from 'react';

const HouseIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 9 21 L 9 14 L 15 14 L 15 21 L 20 21 L 20 12 L 23 12 L 19 8.4003906 L 19 4 L 17 4 L 17 6.5996094 L 12 2.0996094 z" />
  </svg>
);

HouseIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default HouseIcon;
