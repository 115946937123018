import React from 'react';
import { useHistory } from 'react-router-dom';

import Constant from 'utils/constants';
import { useLoggedInUser } from 'hooks';
import { isClient } from 'helpers/user';
import { useBranch } from 'components/BranchProvider';

const requirePersonalInformation = (WrappedComponent) => {
  function RequirePersonalInformation(props) {
    const user = useLoggedInUser();
    const history = useHistory();
    const [branch] = useBranch();
    React.useEffect(() => {
      if (!branch.skip_request_more_information) {
        if (
          !user.migrated &&
          user.profile.state === Constant.user.profile.state.partial &&
          isClient(user) &&
          user.profile.has_hq_completed
        ) {
          history.replace('/add-personal-information');
          return;
        } else if (
          !user.migrated &&
          isClient(user) &&
          user.profile.has_hq_completed &&
          !user.profile.time_zone
        ) {
          history.replace('/add-time-zone');
          return;
        }
      }
    }, [user, history, branch]);
    return <WrappedComponent {...props} />;
  }
  return RequirePersonalInformation;
};

export default requirePersonalInformation;
