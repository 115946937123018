import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { coachDashboardTour } from 'config/tour';
import strings from 'assets/strings';
import Constant from 'utils/constants';
import { surveyIds } from 'config/config';
import history from 'global-history';
import { url } from 'helpers/user';
import { useLoggedInUser } from 'hooks';
import { useGetProspects } from 'features/users/coach.query';

import TaskListWidget from 'containers/TaskListWidget';
import AllClientsTaskListWidget from 'containers/AllClientsTaskListWidget';
import MissedCheckInsWidget from 'containers/MissedCheckInsWidget';
import * as inboxActions from 'containers/InboxWidget/UserInboxWidget/UserInboxWidget.ducks';

import { useBranch } from 'components/BranchProvider';
import Loader from 'components/Loader';

import { Calendar, ProspectsTable } from './components';

import theme from './CoachDashboard.scss';

function CoachDashboard() {
  const user = useLoggedInUser();
  const [branch] = useBranch();
  const { data: prospects, isLoading } = useGetProspects(user.id, undefined, {
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Loader size="medium" loading />;
  }

  return (
    <div className={theme.dashboardRoot}>
      <div className={theme.dashboardContentContainer}>
        <div className={theme.dashboardGreeting}>
          {strings.greet(user.profile.first_name || user.email)}
        </div>
        <div id={coachDashboardTour.tasks.id} className={theme.dashboardRow}>
          <TaskListWidget
            actions={Object.values(Constant.task.action).filter(
              (action) =>
                ![
                  Constant.task.action.acceptRejectProgram,
                  Constant.task.action.publishTreatmentPlan,
                  Constant.task.action.completeAcneProgram,
                  Constant.task.action.noop,
                ].includes(action),
            )}
            title={branch.main_task_list_title}
          />
        </div>
        <div
          id={coachDashboardTour.upcomingConsultationTasks.id}
          className={theme.dashboardRow}
        >
          <TaskListWidget
            id="CoachDashboard-UpcomingConsultationTasks"
            actions={[
              Constant.task.action.publishTreatmentPlan,
              Constant.task.action.acceptRejectProgram,
            ]}
            title={branch.secondary_task_list_title}
          />
        </div>
        {!branch.client_coach_assignment_enabled && (
          <div className={theme.dashboardRow}>
            <AllClientsTaskListWidget
              id="CoachDashboard-CompleteHealthQuestionnaireTasks"
              actions={[Constant.task.action.completeSurveyResponse]}
              context={{
                survey_id: surveyIds.healthQuestionnaire,
              }}
              title="Incomplete Health Questionnaires"
              readOnly
            />
          </div>
        )}
        <div
          id={coachDashboardTour.prospects.id}
          className={theme.dashboardRow}
        >
          {branch.show_prospects && (
            <ProspectsTable
              prospects={prospects}
              onActionClick={async (prospect) => {
                const { createThread } = this.props;
                const newThread = await createThread({
                  participants: [url(prospect)],
                });
                history.push(`/messages/${newThread.id}`);
              }}
            />
          )}
        </div>
        <div className={theme.dashboardRow}>
          <MissedCheckInsWidget />
        </div>
      </div>
      {branch.show_calendar && <Calendar user={user} />}
    </div>
  );
}

CoachDashboard.propTypes = {
  createThread: PropTypes.func.isRequired,
};

export default connect(null, {
  createThread: inboxActions.createThread,
})(CoachDashboard);
