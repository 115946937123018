/* eslint-disable max-len */

import React from 'react';

const RestaurantIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 3 2 L 3 9 C 3 11.209 4.791 13 7 13 L 7 22 L 9 22 L 9 13 C 11.209 13 13 11.209 13 9 L 13 2 L 11 2 L 11 9 L 9 9 L 9 2 L 7 2 L 7 9 L 5 9 L 5 2 L 3 2 z M 21 2 C 17.533 2.144 16 4.707 16 6 L 16 14 L 19 14 L 19 22 L 21 22 L 21 14 L 21 13 L 21 2 z" />
  </svg>
);

RestaurantIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default RestaurantIcon;
