import React from 'react';
import PropTypes from 'prop-types';
import { useLoggedInUser, useCountdownTimer } from 'hooks';

function CountdownTimer({ timeUp }) {
  const user = useLoggedInUser();

  const timeLeft = useCountdownTimer({
    endTime: user?.program?.grace_period_end,
  });
  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{' '}
      </span>,
    );
  });
  if (!timerComponents.length) {
    timeUp();
    return null;
  }
  return <p>{timerComponents}</p>;
}

CountdownTimer.propTypes = {
  timeUp: PropTypes.func,
};

export default CountdownTimer;
