/* eslint no-use-before-define: 0 */

import Constant from 'utils/constants';
import history from 'global-history';
import axios from 'config/axios';
import moment from 'moment';
import { toast } from 'react-toastify';

// Actions
const INITIALIZE = 'cc/StartProgramWidget/INITIALIZE';
const INITIALIZE_SUCCEEDED = 'cc/StartProgramWidget/INITIALIZE_SUCCEEDED';
const INITIALIZE_FAILED = 'cc/StartProgramWidget/INITIALIZE_FAILED';
const START_PROGRAM = 'cc/StartProgramWidget/START_PROGRAM';
const START_PROGRAM_SUCCEEDED = 'cc/StartProgramWidget/START_PROGRAM_SUCCEEDED';
const START_PROGRAM_FAILED = 'cc/StartProgramWidget/START_PROGRAM_FAILED';

const initialState = {
  stage: {},
  submitting: false,
};

// Reducer
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INITIALIZE: {
      const { task } = action.payload;
      const taskState = state[task.id] ? state[task.id] : initialState;
      return {
        ...state,
        [task.id]: { ...taskState, submitting: false },
      };
    }
    case INITIALIZE_SUCCEEDED: {
      const { stage, task } = action.payload;
      const taskState = state[task.id];
      return { ...state, [task.id]: { ...taskState, stage } };
    }
    case START_PROGRAM: {
      const { task } = action.payload;
      const taskState = state[task.id];
      return {
        ...state,
        [task.id]: { ...taskState, submitting: true },
      };
    }
    case START_PROGRAM_SUCCEEDED:
    case START_PROGRAM_FAILED: {
      const { task } = action.payload;
      const taskState = state[task.id];
      return {
        ...state,
        [task.id]: { ...taskState, submitting: false },
      };
    }
    default:
      return state;
  }
};

const initialize = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  const { task } = payload;
  const { program_id: programId } = task.context;

  try {
    const response = await axios.get(
      `stages/?acne_program=${programId}&state=${Constant.stage.state.published}`,
    );
    const stage = response.data.results[0];
    dispatch(initializeSuceeded({ stage, task }));
  } catch (error) {
    dispatch(initializeFailed({ error, task }));
  }
};

const initializeSuceeded = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCEEDED, payload });
};

const initializeFailed = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAILED, payload });
  const { error } = payload;
  toast.error(
    error.response?.data?.message ||
      error.message ||
      'Could not initialize the Start Program task',
  );
};

const startProgram = (payload) => async (dispatch) => {
  dispatch({ type: START_PROGRAM, payload });
  const { task, stage, startProgramDate } = payload;
  const { program_id: programId } = task.context;

  try {
    await axios.patch(`programs/acne/${programId}/`, {
      started: moment(startProgramDate, 'M/D/YYYY').format('YYYY-MM-DD'),
    });
    dispatch(startProgramSuceeded({ stageId: stage.id, task }));
  } catch (error) {
    dispatch(startProgramFailed({ error, task }));
  }
};

const startProgramSuceeded = (payload) => (dispatch) => {
  dispatch({ type: START_PROGRAM_SUCCEEDED, payload });
  const { stageId } = payload;
  // TODO: Check if this is still needed
  // dispatch(taskListWidgetActions.removeTask(task.id));
  dispatch({ type: 'SET_USER_STARTED_PROGRAM', payload: true });
  history.replace(`/view-regimen/${stageId}`);
};

const startProgramFailed = (payload) => (dispatch) => {
  dispatch({ type: START_PROGRAM_FAILED, payload });
  const { error } = payload;
  toast.error(
    error.response?.data?.message ||
      error.message ||
      'Could not start the program',
  );
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_SUCCEEDED,
  INITIALIZE_FAILED,
  START_PROGRAM,
  START_PROGRAM_SUCCEEDED,
  START_PROGRAM_FAILED,
  // Action Creators
  initialize,
  initializeSuceeded,
  initializeFailed,
  startProgram,
  startProgramSuceeded,
  startProgramFailed,
};
