import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from 'config/axios';
import { questionIds, choiceIds, checkinFemaleQuestions } from 'config/config';
import { userAware } from 'components/UserProvider';
import { followupQuestions } from 'config/surveys';

import ResultsSection from '../SurveyResults/ResultsSection';
import theme from '../SurveyResults/SurveyResults.scss';

class ExistingCheckinResults extends Component {
  state = {
    enhancedSurvey: null,
    programStageWeeks: false,
  };

  componentDidMount = () => {
    this.enhanceSurvey(this.props.survey);

    if (this.props.survey.title.toLowerCase() === 'checkin') {
      this.getCheckinData();
    }
  };

  getFollowups = async (question) => {
    const possibleFollowups = followupQuestions[question.id];
    const followups = question.answers.map((a) => {
      return possibleFollowups[a.choice.id];
    });
    if (followups.some((fu) => !!fu)) {
      const promises = followups[0].map((id) => {
        return axios.get(`surveys/questions/${id}`).then((res) => res.data);
      });

      const enhancedQ = await Promise.all(promises).then((results) => {
        const qfus = results.map((qfu) => this.enhanceQuestion(qfu));
        return {
          ...question,
          followups: qfus,
        };
      });
      return enhancedQ;
    }
    return question;
  };

  getUserProgram = async () => {
    const { data } = await axios.get(
      `programs/acne/?user=${this.props.clientId}`,
    );
    return data;
  };

  getStagesByProgram = async (programId) => {
    const { data } = await axios.get(`programs/acne/${programId}/stages/`);
    return data;
  };

  getCheckinData = async () => {
    const userProgram = await Promise.resolve(this.getUserProgram());

    if (userProgram && userProgram.results && userProgram.results.length) {
      const program = userProgram.results[0];
      const stages = await Promise.resolve(this.getStagesByProgram(program.id));

      // set current checkin weeks
      const currentCheckin = stages.find(
        (stage) => stage.check_in === parseInt(this.props.responseId, 10),
      );

      // eslint-disable-next-line max-len
      const programStageWeeks = `${
        currentCheckin.program_view_index * 2 + 3
      } - ${currentCheckin.program_view_index * 2 + 4}`;
      this.setState({
        programStageWeeks,
      });
    }
  };

  enhanceSurvey = (survey) => {
    const enhancedSurvey = {
      ...survey,
      pages: survey.pages.map((page) => this.enhancePage(page)),
    };
    this.setState({
      enhancedSurvey,
    });
  };

  enhancePage = (page) => {
    const mapAnswersToQuestion = (question) => {
      const enhancedQuestion = this.enhanceQuestion(question);
      // check if this question may have followup question
      if (
        enhancedQuestion.answers.length &&
        followupQuestions[enhancedQuestion.id]
      ) {
        // if so, fetch it and enhance the question with followup property
        Promise.resolve(this.getFollowups(enhancedQuestion)).then((enQ) => {
          this.setState((prevState) => ({
            enhancedSurvey: {
              ...prevState.enhancedSurvey,
              pages: prevState.enhancedSurvey.pages.map((pg) => {
                if (page.id !== pg.id) {
                  return pg;
                }
                return {
                  ...pg,
                  questions: pg.questions.map((q) => {
                    if (q.id !== enQ.id) {
                      return q;
                    }
                    return enQ;
                  }),
                };
              }),
            },
          }));
        });
      }
      return enhancedQuestion;
    };

    if (page.id === 1) {
      if (
        this.props.answers.some(
          (answer) =>
            answer.question.id === questionIds.healthQuestionnaire.gender &&
            answer.choice.id === choiceIds.healthQuestionnaire.maleGender,
        )
      ) {
        this.setState({ gender: 'male' });
      } else {
        this.setState({ gender: 'female' });
      }
    } else if (page.id === 13) {
      if (
        this.props.answers.some((answer) =>
          checkinFemaleQuestions.includes(answer.question.id),
        )
      ) {
        this.setState({ gender: 'female' });
      } else {
        this.setState({ gender: 'male' });
      }
    }

    return {
      ...page,
      questions: page.questions.map((question) =>
        mapAnswersToQuestion(question),
      ),
    };
  };

  enhanceQuestion = (question) => {
    const { answers } = this.props;
    return {
      ...question,
      answers: answers.filter((ans) => ans.question.id === question.id),
    };
  };

  render({ clientName, survey } = this.props) {
    const { enhancedSurvey, gender, programStageWeeks } = this.state;
    if (!enhancedSurvey) {
      return null;
    }

    const surveyType = survey.title.toLowerCase();

    return (
      <div
        className={[
          theme.root,
          theme[surveyType],
          survey.pages.length > 1 ? theme.grid : '',
        ].join(' ')}
      >
        <header>
          <h1>
            <span className={theme.clientName}>{`${clientName}'s`}</span>
            {programStageWeeks
              ? `Week ${programStageWeeks} ${survey.title}`
              : survey.title}
          </h1>
        </header>
        <div className={theme.surveyResults}>
          <div className={theme.resultPages}>
            {enhancedSurvey.pages.map((page, i) => {
              return (
                <ResultsSection
                  gender={gender}
                  key={i}
                  page={page}
                  survey={survey}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

ExistingCheckinResults.propTypes = {
  user: PropTypes.object.isRequired, // TODO: HOC gets removed when fetch logic migrates to ducks pattern
  responseId: PropTypes.string,
  answers: PropTypes.array,
  clientName: PropTypes.string,
  survey: PropTypes.object,
  clientId: PropTypes.string,
};

const mapStateToProps = ({ survey }) => ({
  questionsFlat: survey.surveyQuestions,
});

export default connect(mapStateToProps)(
  userAware(ExistingCheckinResults), // TODO: HOC gets removed when fetch logic migrates to ducks pattern
);
