import { sortByAlphabeticalOrder } from './product';
import Constant from 'utils/constants';

/**
 * Returns the productCategory with the products
 * @param {Object} productCategory the product category to alter
 * @param {Array} products
 */
export const setProducts = (productCategory, products) => {
  const categoryProducts = products.filter(
    (product) => product.category === productCategory.url,
  );
  return {
    ...productCategory,
    products: categoryProducts.map((product) => product.id),
  };
};

/**
 *
 * @param {Array} categories - all the categories recognized in the system
 * @param {*} name - the name to find
 * @returns {Array} the category matching the name
 */
export function findByName(categories, name) {
  return categories.find(
    (category) => category.name.toLowerCase() === name.toLowerCase(),
  );
}

/**
 *
 * @param {Array} categories all the categories recognized in the system
 * @returns {Array} only categories with products
 */
export function onlyWithProducts(categories) {
  return categories.filter(
    (category) =>
      !!category.products.length &&
      category.name.toLowerCase() !==
        Constant.category.enhanceYourProgram.toLowerCase(),
  );
}

export function excludeByName(categories, name) {
  return categories.filter(
    (category) => category.name.toLowerCase() !== name.toLowerCase(),
  );
}

/**
 *
 * @param {Array} orderedProducts - the products previously ordered during the whole program
 * @param {Array} productCategories - all the categories recognized in the system
 * @param {Boolean} hasProgramPlan - whether the client has a program plan
 * @returns
 */
export function buildByOrderedProducts(
  orderedProducts,
  productCategories,
  hasProgramPlan,
) {
  const ORDERED_PRODUCT_IDS = orderedProducts.map(({ id }) => id);
  if (hasProgramPlan) {
    return [];
  }
  const categoriesWithOrderedProducts = productCategories.map((category) => ({
    ...category,
    products: sortByAlphabeticalOrder(
      category.products.filter((product) =>
        ORDERED_PRODUCT_IDS.includes(product.id),
      ),
    ),
  }));

  return onlyWithProducts(
    excludeByName(
      categoriesWithOrderedProducts,
      Constant.category.enhanceYourProgram,
    ),
  );
}
