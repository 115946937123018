/* eslint id-length: 0 */

// redux middleware constants
export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK';
export const SUCCESS = 'RSAA/SUCCESS';
export const FAILURE = 'RSAA/FAILURE';
export const MIDDLEWARE_FAILURE = {
  type: FAILURE,
  meta: (action, state, res) => {
    if (res) {
      return {
        tokenExpired: res.status === 401,
        status: res.status,
        statusText: res.statusText,
      };
    }
    return {
      status: 'Network request failed',
    };

  },
};
export const REQUEST = 'RSAA/REQUEST';
export const MIDDLEWARE_TYPES = [REQUEST, SUCCESS, MIDDLEWARE_FAILURE];
export const NO_ACTION = 'NO_ACTION';

// Admin actions

export const SEND_CLIENT_INVITE = 'SEND_CLIENT_INVITE';
export const SEND_CLIENT_INVITE_ERROR = 'SEND_CLIENT_INVITE_ERROR';
export const SEND_CLIENT_INVITE_SUCCESS = 'SEND_CLIENT_INVITE_SUCCESS';

export const SEND_COACH_INVITE = 'SEND_COACH_INVITE';
export const SEND_COACH_INVITE_ERROR = 'SEND_COACH_INVITE_ERROR';
export const SEND_COACH_INVITE_SUCCESS = 'SEND_COACH_INVITE_SUCCESS';

// User actions

export const EXPIRED_TOKEN_LOGOUT = 'EXPIRED_TOKEN_LOGOUT';

export const UNIVERSE_CALL = 'UNIVERSE_CALL';
export const UNIVERSE_CALL_ERROR = 'UNIVERSE_CALL_ERROR';
export const UNIVERSE_CALL_SUCCESS = 'UNIVERSE_CALL_SUCCESS';

export const DEFINE_USER_TYPE = 'DEFINE_USER_TYPE';
export const DEFINE_USER_TYPE_ERROR = 'DEFINE_USER_TYPE_ERROR';
export const DEFINE_USER_TYPE_SUCCESS = 'DEFINE_USER_TYPE_SUCCESS';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';

export const SET_TOKEN_FROM_STORAGE = 'SET_TOKEN_FROM_STORAGE';

export const UPDATE_USER_GENDER = 'UPDATE_USER_GENDER';
export const UPDATE_USER_TREATMENT_PLAN = 'UPDATE_USER_TREATMENT_PLAN';
export const UPDATE_USER_PROGRAM = 'UPDATE_USER_PROGRAM';

// Profile actions

export const GET_FORMATTED_ADDRESSES = 'GET_FORMATTED_ADDRESSES';
export const GET_FORMATTED_ADDRESSES_ERROR = 'GET_FORMATTED_ADDRESSES_ERROR';
export const GET_FORMATTED_ADDRESSES_SUCCESS = 'GET_FORMATTED_ADDRESSES_SUCCESS';

export const UPDATE_PROFILE = 'cc/user/UPDATE_PROFILE';

// Message actions
export const GET_THREADS = 'GET_THREADS';
export const GET_THREADS_ERROR = 'GET_THREADS_ERROR';
export const GET_THREADS_SUCCESS = 'GET_THREADS_SUCCESS';

export const GET_UNREAD_MESSAGE_COUNT = 'GET_UNREAD_MESSAGE_COUNT';
export const ADD_MESSAGE_TO_THREAD = 'ADD_MESSAGE_TO_THREAD';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';

// Appointment actions
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENTS_ERROR = 'GET_APPOINTMENTS_ERROR';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';

// Survey actions
export const GET_SURVEY_ANSWERS = 'GET_SURVEY_ANSWERS';
export const GET_SURVEY_ANSWERS_ERROR = 'GET_SURVEY_ANSWERS_ERROR';
export const SET_SURVEY_ANSWERS = 'SET_SURVEY_ANSWERS';

export const GET_SURVEY_TYPES = 'GET_SURVEY_TYPES';
export const GET_SURVEY_TYPES_ERROR = 'GET_SURVEY_TYPES_ERROR';
export const SET_SURVEY_TYPES = 'SET_SURVEY_TYPES';

export const SET_GENDER = 'SET_GENDER';

export const UPDATE_SURVEY_ANSWER = 'UPDATE_SURVEY_ANSWER';

export const SET_SURVEY_QUESTIONS = 'SET_SURVEY_QUESTIONS';

export const SET_SURVEY_RESPONSE = 'SET_SURVEY_RESPONSE';
export const SET_SURVEY_RESPONSE_ERROR = 'SET_SURVEY_RESPONSE_ERROR';
export const SET_SURVEY_RESPONSE_SUCCESS = 'SET_SURVEY_RESPONSE_SUCCESS';

export const ADD_CHOICE = 'ADD_CHOICE';
export const TOGGLE_MULTIPLE_CHOICE = 'TOGGLE_MULTIPLE_CHOICE';
export const TOGGLE_SINGLE_CHOICE = 'TOGGLE_SINGLE_CHOICE';

export const ADD_QUESTION = 'ADD_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';

export const UPDATE_ANSWERS_IN_REDUX = 'UPDATE_ANSWERS_IN_REDUX';
export const UPDATE_OPEN_ENDED = 'UPDATE_OPEN_ENDED';
export const UPDATE_ROWS = 'UPDATE_ROWS';
export const UPDATE_MATRIX_ROWS = 'UPDATE_MATRIX_ROWS';
export const UPDATE_TEXT_ANSWER = 'UPDATE_TEXT_ANSWER';
export const UPDATE_QUESTION_TEXT = 'UPDATE_QUESTION_TEXT';

export const REGISTER_PAGE = 'REGISTER_PAGE';
export const SUBMIT_PAGE = 'SUBMIT_PAGE';

export const SET_SURVEY_VALIDATION_ERRORS = 'SET_SURVEY_VALIDATION_ERRORS';
export const CLEAR_REDUX_SURVEY = 'CLEAR_REDUX_SURVEY';

// Notification Actions
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const SET_NOTIFICATION_LIST_ACTIVE = 'SET_NOTIFICATION_LIST_ACTIVE';
export const TOGGLE_NOTIFICATION_LIST_ACTIVE = 'TOGGLE_NOTIFICATION_LIST_ACTIVE';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

// Treatment Plan Actions
export const UPDATE_TREATMENT_PLAN = 'UPDATE_TREATMENT_PLAN';
export const SET_TREATMENT_PLAN_ERROR = 'SET_TREATMENT_PLAN_ERROR';
