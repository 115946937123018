import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { CountryDropdown } from 'react-country-region-selector';
import classNames from 'classnames';

import theme from './CountrySelector.sss';

// eslint-disable-next-line no-unused-vars
const CountrySelector = forwardRef(({ error, ...others }, ref) => {
  return (
    <>
      <CountryDropdown
        classes={classNames(theme.CountrySelector, {
          [theme.InputError]: error,
        })}
        valueType="short"
        {...others}
      />
      {error && <span className={theme.ErrorText}>{error}</span>}
    </>
  );
});

CountrySelector.propTypes = {
  error: PropTypes.string,
};

export default CountrySelector;
