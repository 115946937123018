import React from 'react';
import PropTypes from 'prop-types';

import theme from './Header.scss';

const Header = ({
  weekPeriod,
}) => (
  <header className={theme.Header}>
    <div>
      <h1>{`Week ${weekPeriod} Regimen`}</h1>
    </div>
  </header>
);

Header.propTypes = {
  weekPeriod: PropTypes.string,
};

Header.defaultProps = {
  weekPeriod: '0-0',
};

export default Header;
