/* eslint no-use-before-define: 0 */
import { toast } from 'react-toastify';

import axios from 'config/axios';

import { updateUserProgram } from 'actions/userActions';

const SAVE_PLAN = 'cc/MaintenancePlan/SAVE_PLAN';
const SAVE_PLAN_SUCCEEDED = 'cc/MaintenancePlan/SAVE_PLAN_SUCCEEDED';
const SAVE_PLAN_FAILED = 'cc/MaintenancePlan/SAVE_PLAN_FAILED';

// Actions

const initialState = {
  submitting: false,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PLAN: return { ...state, submitting: true };
    case SAVE_PLAN_FAILED:
    case SAVE_PLAN_SUCCEEDED: return { ...state, submitting: false };
    default: return state;
  }
};

const savePlan = (payload) =>
  async (dispatch) => {
    dispatch({ type: SAVE_PLAN, payload });
    const { plan, program } = payload;
    try {
      const { data: newProgram } = await axios.patch(`programs/acne/${program.id}/`, {
        maintenance_plan: plan.id,
      });
      await dispatch(savePlanSucceeded({ program: newProgram }));
    } catch(error) {
      dispatch(savePlanFailed({ error }));
    }
  };

const savePlanSucceeded = (payload) =>
  async (dispatch) => {
    const { program } = payload;
    dispatch({ type: SAVE_PLAN_SUCCEEDED, payload });
    await dispatch(updateUserProgram(program));
  };

const savePlanFailed = (payload) =>
  async (dispatch) => {
    dispatch({ type: SAVE_PLAN_FAILED, payload });
    const { error } = payload;
    toast.error(error.response?.data?.message || error.message || 'Cannot apply for a plan');
  };

export default reducer;
export {
  // Actions
  SAVE_PLAN,
  SAVE_PLAN_SUCCEEDED,
  SAVE_PLAN_FAILED,

  // Action Creators
  savePlan,
  savePlanSucceeded,
  savePlanFailed,
};
