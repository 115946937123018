import React from 'react';
import PropTypes from 'prop-types';

import Wysiwyg from 'components/Wysiwyg';

import theme from './PersonalMessage.scss';

const PersonalMessage = ({
  firstName,
  lastName,
  personalMessage,
  onChange,
}) => (
  <div className={theme.personalMessage}>
    <p>
      <strong>{`Personal Message to ${firstName} ${lastName}:`}</strong>
    </p>
    <Wysiwyg value={personalMessage} onChange={onChange} />
  </div>
);

PersonalMessage.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  personalMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PersonalMessage.defaultProps = {
  firstName: 'John',
  lastName: 'Doe',
  personalMessage: '',
};

export default PersonalMessage;
