import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';

import Constant from 'utils/constants';
import { getErrorMessage } from 'helpers/errorHandling';
import { getIdFromUrl } from 'helpers/formatHelpers';
import { isClient } from 'helpers/user';
import { useGetLatestHACAppointment } from 'features/users/user.query';
import {
  useGetTreatmentPlan,
  useUpdateTreatmentPlan,
} from 'features/programs/treatmentPlan.query';
import { useUpdateProgram } from 'features/programs/program.query';
import { useLoggedInUser } from 'hooks';
// eslint-disable-next-line max-len
import * as reviewTPActions from 'containers/TaskWidget/ReviewTreatmentPlanWidget/ReviewTreatmentPlan/ReviewTreatmentPlan.ducks.js';
// eslint-disable-next-line max-len
import { RejectTreatmentPlanModal } from 'containers/TaskWidget/ReviewTreatmentPlanWidget/ReviewTreatmentPlan/components';
import { useBranch } from 'components/BranchProvider';
import * as modalActions from 'components/Modal/Modal.ducks';
import Loader from 'components/Loader';
import Task from 'components/Task';

import AcceptProgramModal from './components/AcceptProgramModal';
import theme from './AcceptRejectProgramWidget.sss';

function AcceptRejectProgramWidget({ task }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [branch] = useBranch();
  const loggedInUser = useLoggedInUser();

  const { client_name: clientName, treatment_plan_id: treatmentPlanId } =
    task.context;
  const userId = getIdFromUrl(task.linked_user);
  const REJECT_PROGRAM_MODAL_ID = `cc/RejectProgram/${task.id}`;
  const ACCEPT_PROGRAM_MODAL_ID = `cc/AcceptProgram/${task.id}`;

  const { data: latestHACAppt, isLoading: isLatestHACApptLoading } =
    useGetLatestHACAppointment(userId, {
      enabled: !isClient(loggedInUser) && !!branch.show_latest_hac_appointment,
      refetchOnWindowFocus: false,
    });
  const { data: treatmentPlan, isLoading: isTreatmentPlanLoading } =
    useGetTreatmentPlan(treatmentPlanId);
  const { mutateAsync: updateProgram } = useUpdateProgram();
  const { mutateAsync: updateTreatmentPlan } = useUpdateTreatmentPlan();

  const title = (
    <p>
      {/* eslint-disable-next-line camelcase */}
      <Link to={`/client-details/${userId}`}>{clientName}</Link>
      &nbsp;has completed the HQ
    </p>
  );

  function renderNextAppt() {
    if (!latestHACAppt.start) {
      return 'None';
    }
    return format(new Date(latestHACAppt.start), 'MM/dd/yyyy');
  }

  return (
    <>
      <Task
        task={task}
        title={title}
        subtitle={
          isLatestHACApptLoading ? (
            <Loader size="small" />
          ) : (
            <>
              <span>
                Completed: {formatDistance(new Date(task.created), new Date())}
              </span>
              {branch.show_latest_hac_appointment && (
                <span className={theme.NextApptDate}>
                  Last Appt Date: {renderNextAppt()}
                </span>
              )}
            </>
          )
        }
        action="Accept Program"
        allowNote
        onClick={() => {
          dispatch(modalActions.setOpenModalId(ACCEPT_PROGRAM_MODAL_ID));
        }}
        additionalAction="Reject Program"
        onClickAdditional={() => {
          dispatch(modalActions.setOpenModalId(REJECT_PROGRAM_MODAL_ID));
        }}
        onSaveNote={() => {
          queryClient.invalidateQueries(['tasks']);
        }}
      />
      {isTreatmentPlanLoading ? (
        <Loader />
      ) : (
        <>
          <AcceptProgramModal
            modalId={ACCEPT_PROGRAM_MODAL_ID}
            onSubmit={async (treatmentAreas) => {
              try {
                const programId = getIdFromUrl(treatmentPlan.acne_program);
                await Promise.all([
                  updateProgram({
                    programId,
                    payload: {
                      treatment_area: treatmentAreas,
                    },
                  }),
                  updateTreatmentPlan({
                    treatmentPlanId,
                    payload: { state: Constant.treatmentPlan.state.accepted },
                  }),
                ]);
                toast.success('The program was saved successfully');
                history.replace('/');
              } catch (error) {
                toast.error(getErrorMessage(error));
              }
            }}
          />
          <RejectTreatmentPlanModal
            modalId={REJECT_PROGRAM_MODAL_ID}
            onSubmit={async (values) => {
              const succeeded = await dispatch(
                reviewTPActions.rejectTreatmentPlan({
                  ...values,
                  treatmentPlanId: treatmentPlan.id,
                }),
              );
              return succeeded;
            }}
          />
        </>
      )}
    </>
  );
}

AcceptRejectProgramWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

export default AcceptRejectProgramWidget;
