/* eslint no-use-before-define: 0 */

import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import axios from 'config/axios';
import Constant from 'utils/constants';
import {
  shouldIncludeRegimenTreatmentArea,
  regimenHasSteps,
} from 'helpers/regimen';
import { buildStageData, getStages, isDraft, isEditable } from 'helpers/stage';
import { parseCreate, getAllProductsQtys, getProductQty } from 'helpers/step';
import { getNextProgramIndex } from 'helpers/program';

import { setOpenModalId } from 'components/Modal/Modal.ducks';

import history from 'global-history';

// Actions
const INITIALIZE = 'cc/createRegimen/INITIALIZE';
const INITIALIZE_SUCCESS = 'cc/createRegimen/INITIALIZE_SUCCESS';
const INITIALIZE_FAIL = 'cc/createRegimen/INITIALIZE_FAIL';
const SET_ACTIVE_STEP = 'cc/createRegimen/SET_ACTIVE_STEP';
const UPDATE_STAGE = 'cc/createRegimen/UPDATE_STAGE';
const UPDATE_CLIENT_ORDERED_PRODUCTS =
  'cc/createRegimen/UPDATE_CLIENT_ORDERED_PRODUCTS';
const UPDATE_STEP = 'cc/createRegimen/UPDATE_STEP';
const UPDATE_ORDER_PRODUCT = 'cc/createRegimen/UPDATE_ORDER_PRODUCT';
const CHANGE_PROGRAM_WEEK = 'cc/createRegimen/CHANGE_PROGRAM_WEEK';
const CHANGE_PROGRAM_WEEK_SUCCESS =
  'cc/createRegimen/CHANGE_PROGRAM_WEEK_SUCCESS';
const REMOVE_STAGE = 'cc/createRegimen/REMOVE_STAGE';
const REMOVE_STAGE_SUCCESS = 'cc/createRegimen/REMOVE_STAGE_SUCCESS';
const REMOVE_STAGE_FAIL = 'cc/createRegimen/REMOVE_STAGE_FAIL';
const SAVE_STAGE_AS_DRAFT = 'cc/createRegimen/SAVE_STAGE_AS_DRAFT';
const SAVE_STAGE_AS_DRAFT_SUCCESS =
  'cc/createRegimen/SAVE_STAGE_AS_DRAFT_SUCCESS';
const SAVE_STAGE_AS_DRAFT_FAIL = 'cc/createRegimen/SAVE_STAGE_AS_DRAFT_FAIL';
const SEND_REGIMEN = 'cc/createRegimen/SEND_REGIMEN';
const SEND_REGIMEN_SUCCESS = 'cc/createRegimen/SEND_REGIMEN_SUCCESS';
const SEND_REGIMEN_FAIL = 'cc/createRegimen/SEND_REGIMEN_FAIL';
const RESTORE = 'cc/createRegimen/RESTORE';

const initialState = {
  loading: false,
  profile: {},
  program: {},
  stage: {},
  previousStages: [],
  regimens: [],
  steps: [],
  productCategories: [],
  clientOrderedProducts: [],
  editingProgramWeek: false,
  activeStep: 0,
};

// Reducer
// eslint-disable-next-line complexity
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        loading: true,
      };
    case INITIALIZE_SUCCESS: {
      const {
        profile,
        program,
        previousStages,
        regimens,
        steps,
        productCategories,
      } = action.payload;
      const sortedCategories = productCategories.map((category) => {
        if (
          category.name.toLowerCase() ===
          Constant.category.enhanceYourProgram.toLowerCase()
        ) {
          category.products.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }
        return category;
      });
      return {
        ...state,
        profile,
        program,
        previousStages,
        regimens,
        steps,
        productCategories: sortedCategories,
        editingProgramWeek: false,
        loading: false,
      };
    }
    case SET_ACTIVE_STEP: {
      const { activeStep } = action.payload;
      return {
        ...state,
        activeStep,
      };
    }
    case UPDATE_STAGE: {
      const { stage = state.stage, stageBody } = action.payload;
      return {
        ...state,
        stage: {
          ...stage,
          ...stageBody,
        },
      };
    }
    case UPDATE_CLIENT_ORDERED_PRODUCTS: {
      const { clientOrderedProducts } = action.payload;
      return {
        ...state,
        clientOrderedProducts,
      };
    }
    case UPDATE_STEP: {
      const { stepId, stepBody } = action.payload;
      return {
        ...state,
        steps: state.steps.map((step) => {
          if (step.id === stepId) {
            return {
              ...step,
              ...stepBody,
            };
          }
          return step;
        }),
      };
    }
    case UPDATE_ORDER_PRODUCT: {
      const { productId, productBody } = action.payload;
      return {
        ...state,
        productCategories: state.productCategories.map((category) => ({
          ...category,
          products: category.products.map((product) => {
            if (product.id === productId) {
              return {
                ...product,
                ...productBody,
              };
            }
            return product;
          }),
        })),
      };
    }
    case CHANGE_PROGRAM_WEEK:
      return {
        ...state,
        loading: true,
        editingProgramWeek: true,
      };
    case CHANGE_PROGRAM_WEEK_SUCCESS: {
      const { programWeek, regimens, steps } = action.payload;
      return {
        ...state,
        loading: false,
        program: {
          ...state.program,
          program_index: programWeek,
        },
        regimens,
        steps,
      };
    }
    case SAVE_STAGE_AS_DRAFT:
      return {
        ...state,
        loading: true,
      };
    case SAVE_STAGE_AS_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_STAGE_SUCCESS:
      return {
        ...state,
        stage: {},
      };
    case SEND_REGIMEN:
      return {
        ...state,
        loading: true,
      };
    case SEND_REGIMEN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESTORE:
      return {
        ...initialState,
      };
    case SAVE_STAGE_AS_DRAFT_FAIL:
    case SEND_REGIMEN_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// Helpers

const getSelectedRegimens = (getState) => {
  const { program, regimens, steps } = getState().createRegimen;

  const includedRegimens = regimens.filter(
    (regimen) =>
      shouldIncludeRegimenTreatmentArea(regimen, program.treatment_area) &&
      regimenHasSteps(regimen, steps),
  );
  return includedRegimens.map((regimen) => {
    const regimenSteps = steps.filter(
      (step) => regimen.id === step.regimen && step.includeStep,
    );
    const hasInvalidStep = regimenSteps.some(({ product }) => product === -1);
    if (hasInvalidStep) {
      throw new Error(
        'You cannot leave any None value. Review all your steps.',
      );
    }
    return {
      code: regimen.name,
      steps: regimenSteps.map((step) => parseCreate(step)),
    };
  });
};

// Action Creators

const initialize = (payload) => async (dispatch, getState) => {
  dispatch({ type: INITIALIZE, payload });

  const { clientId, programId, editingPublishedStage, duration } = payload;
  const { stageConfigs, productCategories } = getState().app;

  try {
    const { data: profile } = await axios.get(`users/${clientId}/profile/`);
    const { data: program } = await axios.get(`programs/acne/${programId}/`);
    if (program.initial_products) {
      const initialProducts = await Promise.all(
        program.initial_products.map((productUrl) => axios.get(productUrl)),
      );
      program.initial_products = initialProducts.map(
        (product) => product.data.name,
      );
    }

    const response = await axios.get('stages/', {
      params: {
        acne_program: programId,
      },
    });
    const previousStages = response.data.results;
    const { drafted, completed, editable } = getStages(previousStages);
    const stage = drafted || editable || completed || {};
    dispatch(updateStage({ stage }));

    if (completed) {
      const orderResponse = await axios.get(
        `programs/acne/stages/orders/?stage=${completed.id}&placed_by=${clientId}`,
      );
      if (orderResponse.data.results.length) {
        const clientOrder = orderResponse.data.results[0];
        const orderItemsResponse = await axios.get(
          `programs/acne/stages/orders/items/?order=${clientOrder.id}`,
        );
        const productsResponses = await Promise.all(
          orderItemsResponse.data.results.map((orderItem) =>
            axios.get(orderItem.product),
          ),
        );
        const clientOrderedProducts = orderItemsResponse.data.results.map(
          (orderItem) => {
            const { data: product } = productsResponses.find(
              ({ data: { url } }) => url === orderItem.product,
            );
            return {
              name: product.name,
              quantity: orderItem.quantity,
            };
          },
        );
        dispatch(updateClientOrderedProducts({ clientOrderedProducts }));
      }
    }

    let nextProgramIndex;
    if (editingPublishedStage) {
      nextProgramIndex = program.program_index;
    } else if (isDraft(stage)) {
      nextProgramIndex = stage.program_index;
    } else {
      nextProgramIndex = getNextProgramIndex(program);
    }

    if (nextProgramIndex > duration) {
      nextProgramIndex = duration;
    }

    const regimensConfig = stageConfigs[nextProgramIndex]?.regimens;

    const { regimens, steps } = buildStageData(
      stage,
      previousStages,
      regimensConfig,
    );

    let orderedProductCategories = productCategories;
    const enhanceIndex = productCategories.findIndex(
      (category) =>
        category.name.toLowerCase() ===
        Constant.category.enhanceYourProgram.toLowerCase(),
    );
    if (enhanceIndex > -1) {
      orderedProductCategories[enhanceIndex].products =
        orderedProductCategories[enhanceIndex].products.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
    }

    const productQtyMap = getAllProductsQtys(steps);
    if (!isEmpty(productQtyMap)) {
      orderedProductCategories = productCategories.map((category) => ({
        ...category,
        products: category.products
          .map((product) => ({
            ...product,
            quantity: productQtyMap[product.id] || 0,
          }))
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => b.quantity - a.quantity),
      }));
    }

    dispatch(
      initializeSuccess({
        profile,
        program,
        regimens,
        steps,
        previousStages,
        productCategories: orderedProductCategories,
      }),
    );
  } catch (error) {
    dispatch(initializeFail({ error }));
  }
};

const initializeSuccess = (payload) => ({
  type: INITIALIZE_SUCCESS,
  payload,
});

const initializeFail = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAIL, payload });
  const { error } = payload;
  toast.error(error.message || 'Could not load your regimen', {
    autoClose: false,
  });
  toast.error('You were redirected to your dashboard', { autoClose: false });
  history.replace('/');
};

const setActiveStep = (payload) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_STEP, payload });
};

const updateStage = (payload) => ({
  type: UPDATE_STAGE,
  payload,
});

const updateClientOrderedProducts = (payload) => ({
  type: UPDATE_CLIENT_ORDERED_PRODUCTS,
  payload,
});

const updateStep = (payload) => async (dispatch, getState) => {
  const {
    stepBody: { product: selectedProduct },
  } = payload;
  dispatch({ type: UPDATE_STEP, payload });
  if (selectedProduct) {
    const { steps } = getState().createRegimen;
    const newProductQty = getProductQty(steps, selectedProduct);
    dispatch(
      updateOrderProduct({
        productId: selectedProduct,
        productBody: {
          quantity: newProductQty,
        },
      }),
    );
  }
};

const updateOrderProduct = (payload) => ({
  type: UPDATE_ORDER_PRODUCT,
  payload,
});

const changeProgramWeek = (payload) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_PROGRAM_WEEK, payload });
  const { programWeek } = payload;
  const { stage, previousStages } = getState().createRegimen;
  const { stageConfigs } = getState().app;
  const regimensConfig = stageConfigs[programWeek].regimens;

  const { regimens, steps } = buildStageData(
    stage,
    previousStages,
    regimensConfig,
  );

  dispatch(
    changeProgramWeekSuccess({
      programWeek,
      regimens,
      steps,
    }),
  );
};

const changeProgramWeekSuccess = (payload) => ({
  type: CHANGE_PROGRAM_WEEK_SUCCESS,
  payload,
});

const removeStage = () => async (dispatch, getState) => {
  dispatch({ type: REMOVE_STAGE });
  const { stage } = getState().createRegimen;

  try {
    await axios.delete(`programs/acne/stages/${stage.id}/`);
    dispatch(removeStageSuccess());
    return true;
  } catch (error) {
    toast.error('Could not remove the stage before saving a draft', {
      autoClose: false,
    });
    dispatch(removeStageFail({ error }));
    return false;
  }
};

const removeStageSuccess = () => ({
  type: REMOVE_STAGE_SUCCESS,
});

const removeStageFail = (payload) => ({
  type: REMOVE_STAGE_FAIL,
  payload,
});

const saveAsDraft = (payload) => async (dispatch, getState) => {
  dispatch({ type: SAVE_STAGE_AS_DRAFT });
  const { clientId } = payload;
  const { stage, program, editingProgramWeek } = getState().createRegimen;

  try {
    const regimens = getSelectedRegimens(getState);

    if (isDraft(stage)) {
      await dispatch(removeStage());
    }

    let programIndex;
    if (editingProgramWeek) {
      programIndex = program.program_index;
    } else if (isDraft(stage)) {
      programIndex = stage.program_index;
    } else {
      programIndex = getNextProgramIndex(program);
    }

    await axios.post('stages/', {
      acne_program: program.id,
      text: stage.text,
      program_index: programIndex,
      state: 'drafted',
      regimens,
    });
    toast.success('New regimen draft saved! Redirecting to client detail...');
    setTimeout(() => {
      dispatch(saveAsDraftSuccess());
      dispatch(restore());
      history.replace(`/client-details/${clientId}`);
    }, 3000);
  } catch (error) {
    dispatch(saveAsDraftFail({ error }));
  }
};

const saveAsDraftSuccess = () => ({
  type: SAVE_STAGE_AS_DRAFT_SUCCESS,
});

const saveAsDraftFail = (payload) => (dispatch) => {
  dispatch({ type: SAVE_STAGE_AS_DRAFT_FAIL, payload });
  const { error } = payload;
  toast.error(error.message || 'Could not save a new draft', {
    autoClose: false,
  });
};

const sendRegimen = (payload) => async (dispatch, getState) => {
  dispatch({ type: SEND_REGIMEN, payload });
  const { program, stage, editingProgramWeek } = getState().createRegimen;
  const { clientId, products, editingPublishedStage } = payload;

  try {
    const regimens = getSelectedRegimens(getState);

    if (isDraft(stage)) {
      await dispatch(removeStage());
    }

    const service = program.plan
      ? Constant.services.qboss
      : Constant.services.infusionsoft;

    let programIndex;
    if (editingPublishedStage || editingProgramWeek) {
      programIndex = program.program_index;
    } else if (isDraft(stage)) {
      programIndex = stage.program_index;
    } else {
      programIndex = getNextProgramIndex(program);
    }
    const orders = products.length
      ? [
          {
            items: products.map(({ id: product, quantity }) => ({
              product,
              quantity,
            })),
          },
        ]
      : undefined;

    if (!editingPublishedStage) {
      const { data: newStage } = await axios.post('stages/', {
        acne_program: program.id,
        text: stage.text,
        program_index: programIndex,
        regimens,
        orders,
      });
      await axios.patch(`stages/${newStage.id}/`, {
        service,
        state: Constant.stage.state.published,
      });
    } else if (isEditable(stage)) {
      await axios.patch(`stages/${stage.id}/`, {
        text: stage.text,
        program_index: programIndex,
        service,
        regimens,
        orders,
      });
    }

    toast.success('Your regimen is saved. Redirecting to client detail...');
    setTimeout(() => {
      dispatch(sendRegimenSuccess());
      dispatch(restore());
      history.replace(`/client-details/${clientId}`);
    }, 3000);
  } catch (error) {
    dispatch(sendRegimenFail({ error }));
  }
};

const sendRegimenSuccess = () => ({
  type: SEND_REGIMEN_SUCCESS,
});

const sendRegimenFail = (payload) => (dispatch) => {
  dispatch({ type: SEND_REGIMEN_FAIL, payload });
  dispatch(setOpenModalId(null));
  const { error } = payload;
  toast.error(error.message || 'Could not publish the regimen', {
    autoClose: false,
  });
};

const restore = () => ({
  type: RESTORE,
});

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_SUCCESS,
  INITIALIZE_FAIL,
  SET_ACTIVE_STEP,
  UPDATE_STAGE,
  UPDATE_CLIENT_ORDERED_PRODUCTS,
  UPDATE_STEP,
  UPDATE_ORDER_PRODUCT,
  CHANGE_PROGRAM_WEEK,
  CHANGE_PROGRAM_WEEK_SUCCESS,
  REMOVE_STAGE,
  REMOVE_STAGE_SUCCESS,
  REMOVE_STAGE_FAIL,
  SAVE_STAGE_AS_DRAFT,
  SAVE_STAGE_AS_DRAFT_SUCCESS,
  SAVE_STAGE_AS_DRAFT_FAIL,
  SEND_REGIMEN,
  SEND_REGIMEN_SUCCESS,
  SEND_REGIMEN_FAIL,
  RESTORE,
  // Action Creators
  initialize,
  initializeFail,
  initializeSuccess,
  setActiveStep,
  updateStage,
  updateClientOrderedProducts,
  updateStep,
  updateOrderProduct,
  changeProgramWeek,
  changeProgramWeekSuccess,
  removeStage,
  removeStageSuccess,
  removeStageFail,
  saveAsDraft,
  saveAsDraftSuccess,
  saveAsDraftFail,
  sendRegimen,
  sendRegimenSuccess,
  sendRegimenFail,
  restore,
};
