import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './Radio.sss';

const Radio = forwardRef(({ error, ...others }, ref) => (
  <div className={classNames(theme.Radio, { [theme.InputError]: error })}>
    <input type="radio" {...others} ref={ref} />
    {error && <span className={theme.ErrorText}>{error}</span>}
  </div>
));

Radio.propTypes = {
  error: PropTypes.string,
};

export default Radio;
