import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { useQueryParams } from 'hooks/';
import Loader from 'components/Loader/Loader';

import * as actions from './Survey.ducks';
import SurveyIntro from './SurveyIntro';
import CompleteSurvey from './CompleteSurvey';
import CompletedSurvey from './CompletedSurvey';

import theme from './Survey.sss';

const STEPS = {
  INTRO: 0,
  COMPLETE_SURVEY: 1,
  SURVEY_COMPLETED: 2,
};

function SurveyPage({
  loading,
  stage,
  responseId,
  activeStep,
  survey,
  initialize,
  setActiveStep,
  reset,
}) {
  const history = useHistory();
  const { surveyId } = useParams();
  const { userId, stageId } = useQueryParams();

  useEffect(() => {
    initialize({
      userId,
      surveyId,
      stageId,
    });
    return () => {
      reset();
    };
  }, [stageId, userId, surveyId, initialize, reset]);

  function renderActiveStep() {
    switch (activeStep) {
      case STEPS.INTRO: {
        return (
          <SurveyIntro
            survey={survey}
            onStart={() => {
              setActiveStep(activeStep + 1);
            }}
          />
        );
      }
      case STEPS.COMPLETE_SURVEY: {
        return (
          <CompleteSurvey
            survey={survey}
            stage={stage}
            responseId={responseId}
            onComplete={() => {
              toast.success('Your survey was sent');
              setActiveStep(activeStep + 1);
            }}
            onSaveAndExit={() => {
              history.replace('/');
            }}
          />
        );
      }
      case STEPS.SURVEY_COMPLETED: {
        return <CompletedSurvey survey={survey} />;
      }
      default:
        throw new Error(`Step ${activeStep} not supported`);
    }
  }

  if (loading) {
    return <Loader type="fixed" size="large" />;
  }
  return (
    <div className={theme.Survey}>
      <div className={theme.ActiveStep}>{renderActiveStep()}</div>
    </div>
  );
}

SurveyPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  survey: PropTypes.object.isRequired,
  stage: PropTypes.object.isRequired,
  responseId: PropTypes.number,
  activeStep: PropTypes.number.isRequired,
  initialize: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = ({ newSurvey }) => ({
  ...newSurvey,
});

export default connect(mapStateToProps, actions)(SurveyPage);
