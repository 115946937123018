import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { mapModalDispatchToProps } from 'helpers/redux';
import { userAware } from 'components/UserProvider';
import Modal from 'components/Modal';

import ReasonsForm from './ReasonsForm';
import OtherReasonsForm from './OtherReasonsForm';
import Success from './Success';

const RejectTreatmentPlanModal = ({ modalId, onSubmit, setOpenModalId }) => {
  const STEPS = {
    SELECT_REASONS: 0,
    COMMUNICATE_OTHER_REASONS: 1,
    SUCCESS_MESSAGE: 2,
  };

  const SUBMIT_TYPES = {
    previous: 'previous',
    next: 'next',
    cancel: 'cancel',
    submit: 'submit',
    done: 'done',
  };

  const [activeStep, setActiveStep] = useState(STEPS.SELECT_REASONS);
  const [rejectionReasons, setRejectionReasons] = useState();
  const [otherReasons, setOtherReasons] = useState('');

  const processSubmitAction = async (submitAction, values) => {
    switch (submitAction) {
      case SUBMIT_TYPES.previous:
        setActiveStep(activeStep - 1);
        break;
      case SUBMIT_TYPES.next:
        setActiveStep(activeStep + 1);
        break;
      case SUBMIT_TYPES.cancel:
        setOpenModalId(null);
        setActiveStep(STEPS.SELECT_REASONS);
        break;
      case SUBMIT_TYPES.submit: {
        const succeeded = await onSubmit({
          rejectionReasons: Object.values(rejectionReasons).filter(
            (reason) => reason,
          ),
          otherReasons,
          ...values,
        });
        if (succeeded) {
          setActiveStep(STEPS.SUCCESS_MESSAGE);
        }
        break;
      }
      case SUBMIT_TYPES.done:
        setOpenModalId(null);
        break;
      default:
        throw new Error(`Submit action ${submitAction} not supported`);
    }
  };

  const getActiveComponent = () => {
    switch (activeStep) {
      case STEPS.SELECT_REASONS:
        return (
          <ReasonsForm
            defaultValues={{ ...rejectionReasons }}
            onSubmit={({ submitAction, ...values }) => {
              setRejectionReasons(values);
              processSubmitAction(submitAction);
            }}
          />
        );
      case STEPS.COMMUNICATE_OTHER_REASONS:
        return (
          <OtherReasonsForm
            defaultValues={{ otherReasons }}
            onSubmit={({ submitAction, ...values }) => {
              setOtherReasons(values.otherReasons);
              processSubmitAction(submitAction, values);
            }}
          />
        );
      case STEPS.SUCCESS_MESSAGE:
        return (
          <Success
            onDone={() => {
              processSubmitAction(SUBMIT_TYPES.done);
            }}
          />
        );
      default:
        throw new Error(`Step ${activeStep} not supported`);
    }
  };

  return (
    <Modal
      id={modalId}
      onClose={() => {
        processSubmitAction(SUBMIT_TYPES.cancel);
      }}
    >
      {getActiveComponent()}
    </Modal>
  );
};

RejectTreatmentPlanModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  setOpenModalId: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapModalDispatchToProps,
)(userAware(RejectTreatmentPlanModal));
