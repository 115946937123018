import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import theme from './SurveyRouter.scss';

import SurveyIntro from '../SurveyIntro/SurveyIntro';
import SurveyComplete from 'components/Surveys/SurveyComplete';

class SurveyRouter extends React.Component {
  componentWillReceiveProps(props) {
    // scroll to top on page change
    if (props.pageId !== this.props.pageId) {
      this.containerNode.current.scrollTop = 0;
    }
  }

  containerNode = React.createRef();

  render() {
    return (
      <div className={theme.surveyRouterContainer} ref={this.containerNode}>
        <Switch>
          <Route
            path="/surveys/:surveyId"
            exact
            render={(routeProps) => (
              <SurveyIntro {...this.props} {...routeProps} />
            )}
          />
          <Route
            path="/surveys/:surveyId/:pageId/complete"
            exact
            render={(routeProps) => (
              <SurveyComplete {...this.props} {...routeProps} />
            )}
          />
          <Route
            path="/checkin/:surveyId/:stageId"
            exact
            render={(routeProps) => (
              <SurveyIntro {...this.props} {...routeProps} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

SurveyRouter.propTypes = {
  pageId: PropTypes.number,
};

export default SurveyRouter;
