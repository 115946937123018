/* eslint-disable max-len */

import React from 'react';

const StarFilledIcon = (props) => (
  <svg  viewBox="0 0 24 24" width="24px" height="24px" {...props}>
    <path d="M12,17.877L18.831,22l-1.813-7.77l6.035-5.228l-7.947-0.674L12,1L8.894,8.328L0.947,9.002l6.035,5.228L5.169,22L12,17.877z" />
  </svg>
);

StarFilledIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default StarFilledIcon;
