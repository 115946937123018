import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';

import { mapModalDispatchToProps } from 'helpers/redux';
import Modal from 'components/Modal';
import Button from 'components/Button';

import { ProductList } from './components';

import theme from './ConfirmOrderModal.sss';

const ConfirmOrderModal = ({
  sendEnable,
  modalId,
  products,
  setOpenModalId,
  onSendRegimenClicked,
}) => (
  <Modal id={modalId}>
    <div className={theme.ConfirmOrderModal}>
      <h2 className={theme.Title}>Order Summary</h2>
      {/* eslint-disable-next-line max-len */}
      <p className={theme.TitleText}>Please make sure the products below are correct according to your previous selection</p>
      <h4 className={theme.Subtitle}>PRODUCTS</h4>
      <hr />
      <p className={theme.SubtitleText}>You are about to place order for the following products:</p>
      <ProductList products={products} />
      <footer className={theme.Footer}>
        <Button color="secondary" onClick={() => { setOpenModalId(null); }}>Go Back</Button>
        {
          sendEnable ?
            (<Button onClick={() => { onSendRegimenClicked(products); }}>Send Regimen</Button>)
            :
            (<Tooltip
              title={'You have exceeded the limit order item for one or more products'}
            >
              <span>
                <Button disabled >Send Regimen</Button>
              </span>
            </Tooltip>)
        }
      </footer>
    </div>
  </Modal>
);

ConfirmOrderModal.propTypes = {
  sendEnable: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  products: PropTypes.array,
  onSendRegimenClicked: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

ConfirmOrderModal.defaultProps = {
  products: [],
};

export default connect(null, mapModalDispatchToProps)(ConfirmOrderModal);
