import ComposeMessage, { useComposeMessage } from './ComposeMessage';
import MessageTypeButton from './MessageTypeButton';
import AttachmentPreview from './AttachmentPreview';
import TextArea from './TextArea';
import Bottombar from './Bottombar';

ComposeMessage.MessageTypeButton = MessageTypeButton;
ComposeMessage.AttachmentPreview = AttachmentPreview;
ComposeMessage.TextArea = TextArea;
ComposeMessage.Bottombar = Bottombar;

export { useComposeMessage };

export default ComposeMessage;
