import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import {
  TextInput,
  Checkbox,
  CountrySelector,
  RegionSelector,
} from 'components/Form';
import Button from 'components/Button';

import theme from './AddressInformationForm.sss';

const AddressInformationForm = ({
  onSubmit,
  onGoBackClick,
}) => {
  const { register, control, setValue, watch, handleSubmit, errors } = useForm();
  const [useSameAddress, setUseSameAddress] = useState(false);

  const handleUseSameAddress = (ev) => {
    const checked = ev.target.checked;
    if (!checked) {
      setValue('shippingAddressLine1', '');
      setValue('shippingAddressCity', '');
      setValue('shippingAddressState', '');
      setValue('shippingAddressZipCode', '');
      setValue('shippingAddressCountry', '');
    }
    setUseSameAddress(checked);
  };

  const getFinalValues = (values) => {
    if (useSameAddress) {
      return {
        ...values,
        shippingAddressLine1: values.billingAddressLine1,
        shippingAddressCity: values.billingAddressCity,
        shippingAddressState: values.billingAddressState,
        shippingAddressZipCode: values.billingAddressZipCode,
        shippingAddressCountry: values.billingAddressCountry,
      };
    }
    return values;
  };

  const billingAddressCountry = watch('billingAddressCountry');
  const shippingAddressCountry = watch('shippingAddressCountry');

  return (
    <form
      className={theme.AddressInformationForm}
      onSubmit={handleSubmit((values) => {
        onSubmit(getFinalValues(values));
      })}
    >
      <h2 className={theme.SectionTitle}>Where can we send your orders?</h2>
      <h2 className={theme.FormTitle}>BILLING ADDRESS</h2>
      <div className={theme.VerticalGroup}>
        <div className={theme.InputGroup}>
          <label htmlFor="billingAddressLine1" className={theme.Label}>Address</label>
          <TextInput
            name="billingAddressLine1"
            id="billingAddressLine1"
            placeholder="Street Address, P. O. box"
            register={register({ required: 'Please fill out this field' })}
            error={errors.billingAddressLine1?.message}
          />
        </div>
      </div>
      <div className={theme.HorizontalGroup}>
        <div className={theme.InputGroup}>
          <label htmlFor="billingAddressCity" className={theme.Label}>City</label>
          <TextInput
            name="billingAddressCity"
            id="billingAddressCity"
            placeholder="City"
            register={register({ required: 'Please fill out this field' })}
            error={errors.billingAddressCity?.message}
          />
        </div>
        <div className={theme.InputGroup}>
          <label htmlFor="billingAddressZipCode" className={theme.Label}>Zip Code</label>
          <TextInput
            name="billingAddressZipCode"
            id="billingAddressZipCode"
            placeholder="*****"
            register={register({ required: 'Please fill out this field' })}
            error={errors.billingAddressZipCode?.message}
          />
        </div>
      </div>
      <div className={theme.HorizontalGroup}>
        <div className={theme.InputGroup}>
          <label htmlFor="billingAddressCountry" className={theme.Label}>Country</label>
          <Controller
            as={CountrySelector}
            name="billingAddressCountry"
            id="billingAddressCountry"
            control={control}
            rules={{ required: 'Select your country' }}
            error={errors.billingAddressCountry?.message}
            defaultValue=""
          />
        </div>
        <div className={theme.InputGroup}>
          <label htmlFor="billingAddressState" className={theme.Label}>State</label>
          <Controller
            as={RegionSelector}
            name="billingAddressState"
            id="billingAddressState"
            country={billingAddressCountry}
            control={control}
            rules={{ required: 'Select your state' }}
            error={errors.billingAddressState?.message}
            defaultValue=""
          />
        </div>
      </div>
      <div className={theme.UseSameAddress}>
        <Checkbox
          name="useBillingAddress"
          id="useBillingAddress"
          onChange={handleUseSameAddress}
          value={useSameAddress}
        />
        <label
          className={theme.CheckboxLabel}
          htmlFor="useBillingAddress"
        >
          Shipping address is same as billing address
        </label>
      </div>
      {!useSameAddress &&
        <div className={theme.ShippingAddress}>
          <h2 className={theme.FormTitle}>SHIPPING ADDRESS</h2>
          <div className={theme.VerticalGroup}>
            <div className={theme.InputGroup}>
              <label htmlFor="shippingAddressLine1" className={theme.Label}>Address</label>
              <TextInput
                name="shippingAddressLine1"
                id="shippingAddressLine1"
                placeholder="Street Address, P. O. box"
                register={register({ required: 'Please fill out this field' })}
                error={errors.shippingAddressLine1?.message}
              />
            </div>
          </div>
          <div className={theme.HorizontalGroup}>
            <div className={theme.InputGroup}>
              <label htmlFor="shippingAddressCity" className={theme.Label}>City</label>
              <TextInput
                name="shippingAddressCity"
                id="shippingAddressCity"
                placeholder="City"
                register={register({ required: 'Please fill out this field' })}
                error={errors.shippingAddressCity?.message}
              />
            </div>
            <div className={theme.InputGroup}>
              <label htmlFor="shippingAddressZipCode" className={theme.Label}>Zip Code</label>
              <TextInput
                name="shippingAddressZipCode"
                id="shippingAddressZipCode"
                placeholder="*****"
                register={register({ required: 'Please fill out this field' })}
                error={errors.shippingAddressZipCode?.message}
              />
            </div>
          </div>
          <div className={theme.HorizontalGroup}>
            <div className={theme.InputGroup}>
              <label htmlFor="shippingAddressCountry" className={theme.Label}>Country</label>
              <Controller
                as={CountrySelector}
                name="shippingAddressCountry"
                id="shippingAddressCountry"
                control={control}
                rules={{ required: 'Select your country' }}
                error={errors.shippingAddressCountry?.message}
                defaultValue=""
              />
            </div>
            <div className={theme.InputGroup}>
              <label htmlFor="shippingAddressState" className={theme.Label}>State</label>
              <Controller
                as={RegionSelector}
                name="shippingAddressState"
                id="shippingAddressState"
                country={shippingAddressCountry}
                control={control}
                rules={{ required: 'Select your state' }}
                error={errors.shippingAddressState?.message}
                defaultValue=""
              />
            </div>
          </div>
        </div>
      }
      <footer className={theme.FormFooter}>
        <Button size="big" color="secondary" onClick={onGoBackClick}>Go Back</Button>
        <Button size="big" type="submit">Save My Information</Button>
      </footer>
    </form>
  );
};

AddressInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
};

export default AddressInformationForm;
