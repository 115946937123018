import isUndefined from 'lodash/isUndefined';
import Constant from 'utils/constants';

export const isNew = ({ state }) => state === Constant.program.state.new;
export const isStarted = ({ state }) =>
  state === Constant.program.state.started;
export const isCompleted = ({ state }) =>
  state === Constant.program.state.completed;
export const isMaintenance = ({ state }) =>
  state === Constant.program.state.maintenance;
export const isMaintenanceDue = ({ state }) =>
  state === Constant.program.state.maintenanceDue;
export const isQuitted = ({ state }) => state === Constant.program.state.quit;
export const isConsented = ({ state }) =>
  state === Constant.program.state.consented;
export const isDeclined = ({ state }) =>
  state === Constant.program.state.declined;

export const hasTreatmentPlan = ({ treatment_plan }) => !!treatment_plan;

export const getNextProgramIndex = (program) => {
  return isUndefined(program.program_index) ? 0 : program.program_index + 1;
};

export const getWeekPeriod = (programIndex) => {
  return `${programIndex * 2 + 1}-${programIndex * 2 + 2}`;
};

export const getWeekPeriods = (maxIndex) => {
  const weekPeriods = [];
  for (let i = 0; i <= maxIndex; i++) {
    weekPeriods.push(`${i * 2 + 1}/${i * 2 + 2}`);
  }
  return weekPeriods;
};
