import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import * as actions from './TaskData.ducks';

class TaskData extends Component {
  componentDidMount() {
    const { taskId, fetchTask, extraDataCalls } = this.props;
    fetchTask(parseInt(taskId, 10), extraDataCalls);
  }

  render() {
    return null;
  }
}

TaskData.propTypes = {
  taskId: PropTypes.string.isRequired,
  extraDataCalls: PropTypes.object,
  fetchTask: PropTypes.func.isRequired,
};

TaskData.defaultProps = {
  extraDataCalls: {},
};

export default connect(null, actions)(TaskData);
