import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import * as Sentry from '@sentry/react';

import apiAuthInjector from 'helpers/apiAuthInjector';
import rootReducer from 'reducers';
import * as loginActions from 'pages/Login/Login.ducks';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    switch (action) {
      case loginActions.LOGIN:
        return {
          ...action,
          payload: {
            ...action.payload,
            loginPassword: null,
          },
        };
      default:
        return action;
    }
  },
});

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, apiAuthInjector, apiMiddleware),
    sentryReduxEnhancer,
  ),
);
