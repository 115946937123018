import { useQuery, useMutation, useQueryClient } from 'react-query';

import UserService from 'services/user.service';

export function useGetUser(userId, options) {
  return useQuery(['users', userId], () => UserService.get(userId), options);
}

export function useGetActiveClients(params, options) {
  return useQuery(
    ['clients', 'active', params],
    () => UserService.getActiveClients(params),
    options,
  );
}

export function useGetActiveProgramClients(params, options) {
  return useQuery(
    ['clients', 'active-program', params],
    () => UserService.getActiveProgramClients(params),
    options,
  );
}

export function useGetStartedProgramClients(params, options) {
  return useQuery(
    ['clients', 'started-program', params],
    () => UserService.getStartedProgramClients(params),
    options,
  );
}

export function useGetDeclinedClients(params, options) {
  return useQuery(
    ['clients', 'declined', params],
    () => UserService.getDeclinedClients(params),
    options,
  );
}

export function useGetGraduatedClients(params, options) {
  return useQuery(
    ['clients', 'graduated', params],
    () => UserService.getGraduatedClients(params),
    options,
  );
}

export function useGetMaintenanceClients(params, options) {
  return useQuery(
    ['clients', 'maintenance', params],
    () => UserService.getMaintenanceClients(params),
    options,
  );
}

export function useGetQuitClients(params, options) {
  return useQuery(
    ['clients', 'quit', params],
    () => UserService.getQuitClients(params),
    options,
  );
}

export function useGetNewClients(params, options) {
  return useQuery(
    ['clients', 'new', params],
    () => UserService.getNewClients(params),
    options,
  );
}

export function useGetNoConsultClients(params, options) {
  return useQuery(
    ['clients', 'no-consult', params],
    () => UserService.getNoConsultClients(params),
    options,
  );
}

export function useGetCoaches(options) {
  return useQuery(['coaches'], () => UserService.getCoaches(), options);
}

export function useAddUser(options) {
  const queryClient = useQueryClient();
  return useMutation((body) => UserService.add(body), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(['coaches']);
    },
  });
}

export function useGetClient(userId, options) {
  return useQuery(
    ['clients', userId],
    () => UserService.getClient(userId),
    options,
  );
}

export function useGetClientProgramPlanPaid(userId, options) {
  return useQuery(
    ['clients', userId, 'program-plan-paid'],
    () => UserService.getClientProgramPlanPaid(userId),
    options,
  );
}

export function useGetProfile(userId, options) {
  return useQuery(
    ['users', userId, 'profile'],
    () => UserService.getProfile(userId),
    options,
  );
}

export function useGetNextAppointment(userId, options) {
  return useQuery(
    ['users', userId, 'next-appointment'],
    () => UserService.getNextAppointment(userId),
    options,
  );
}

export function useGetLatestHACAppointment(userId, options) {
  return useQuery(
    ['users', userId, 'latest-hac-appointment'],
    () => UserService.getLatestHACAppointment(userId),
    options,
  );
}

export function useGetNextAcnePeelAppointment(userId, options) {
  return useQuery(
    ['users', userId, 'next-acne-peel-appointment'],
    () => UserService.getNextAcnePeelAppointment(userId),
    options,
  );
}

export function useGetBranches(userId, options) {
  return useQuery(
    ['users', userId, 'branches'],
    () => UserService.getBranches(userId),
    options,
  );
}

export function useUpdateUser(options) {
  return useMutation(
    ({ userId, payload }) => UserService.update(userId, payload),
    options,
  );
}
