import React from 'react';
import PropTypes from 'prop-types';

import theme from './Regimen.scss';
import RegimenStep from './RegimenStep';

const Regimen = ({
  name,
  steps,
  stepLimit,
  onChangeStep,
}) => (
  <div className={theme.regimen}>
    <h3>{name}</h3>
    <ul>
      {steps.map((step, i) => (
        <RegimenStep
          key={i}
          index={i}
          step={step}
          name={name}
          limit={stepLimit}
          showStepNumber={name !== 'HERBAL REGIMEN'}
          onChangeStep={onChangeStep}
        />
      ))}
    </ul>
  </div>
);

Regimen.propTypes = {
  name: PropTypes.string.isRequired,
  steps: PropTypes.array,
  stepLimit: PropTypes.number,
  onChangeStep: PropTypes.func.isRequired,
};

Regimen.defaultProps = {
  steps: [],
};

export default Regimen;
