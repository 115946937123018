import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { STATUS } from 'helpers/message';

import Thread from './Thread';

import theme from './ThreadList.sss';

const ThreadList = forwardRef(
  ({ className, threads, emptyMessage, onThreadClick, onReadThread }, ref) => {
    if (!threads.length) {
      return <p className={theme.Empty}>{emptyMessage}</p>;
    }
    return (
      <ul className={classNames(theme.ThreadList, className)} ref={ref}>
        {threads.map((thread) => (
          <li key={`thread-${thread.id}`} className={theme.Thread}>
            <Thread
              {...thread}
              onClick={onThreadClick}
              onMarkAsReadClick={onReadThread}
            />
          </li>
        ))}
      </ul>
    );
  },
);

ThreadList.propTypes = {
  className: PropTypes.string,
  threads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
      date: PropTypes.string.isRequired,
      status: PropTypes.oneOf(Object.values(STATUS)),
    }),
  ).isRequired,
  emptyMessage: PropTypes.string,
  onThreadClick: PropTypes.func.isRequired,
  onReadThread: PropTypes.func,
};

ThreadList.defaultProps = {
  emptyMessage: 'You have no messages 👍',
};

export default ThreadList;
