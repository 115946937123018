/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';

const ChatIcon = ({
  count,
  ...others
}) => (
  <Badge
    invisible={!!!count}
    badgeContent={count}
    color="primary"
  >
    <svg viewBox="0 0 24 24" {...others}>
      <path d="M 9 3 C 5.134 3 2 5.686 2 9 C 2 10.127 2.369 11.177125 3 12.078125 L 3 17 L 6.2890625 14.533203 C 7.1230625 14.833203 8.039 15 9 15 C 12.866 15 16 12.314 16 9 C 16 5.686 12.866 3 9 3 z M 17.976562 8.5742188 C 17.984563 8.7162188 18 8.857 18 9 C 18 13.411 13.963 17 9 17 C 8.98 17 8.9604063 16.996094 8.9414062 16.996094 C 10.151406 18.789094 12.409 20 15 20 C 15.961 20 16.876938 19.833203 17.710938 19.533203 L 21 22 L 21 17.078125 C 21.631 16.177125 22 15.127 22 14 C 22 11.6 20.352563 9.5342188 17.976562 8.5742188 z" fill="#000000" />
    </svg>
  </Badge>
);

ChatIcon.propTypes = {
  count: PropTypes.number,
};

ChatIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
  count: 0,
};

export default ChatIcon;
