import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

const MessagesLimitModal = ({ modalId }) => (
  <Modal id={modalId} size="small" title="Message Limit Exceeded" autoClose>
    <div>
      Sorry. You’ve sent the maximum number of messages for your subscription
      plan.
    </div>
  </Modal>
);

MessagesLimitModal.propTypes = {
  modalId: PropTypes.string.isRequired,
};

export default MessagesLimitModal;
