/* eslint-disable quote-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NACIcon } from 'components/Icon';
import IconButton from 'components/IconButton';

import { PAGE_ICON_MAPPING } from 'helpers/survey';

import { turquoise01, bluePrimary } from 'styles/colors.sss';

import theme from './Navbar.sss';

const Navbar = ({ pages, onPageClick }) => (
  <div className={theme.Navbar}>
    <NACIcon width={114} height={64} fill={turquoise01} />
    <ul>
      {pages.map(({ id, selected }, index) => {
        const PageIcon = PAGE_ICON_MAPPING[id.toString()];
        if (!PageIcon) {
          return null;
        }
        return (
          <li
            key={index}
            className={classNames({
              [theme.ActivePage]: selected,
            })}
            onClick={() => {
              onPageClick(index);
            }}
          >
            <IconButton
              icon={
                <PageIcon
                  width={24}
                  height={24}
                  fill={selected ? turquoise01 : bluePrimary}
                />
              }
            />
          </li>
        );
      })}
    </ul>
  </div>
);

Navbar.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ),
  onPageClick: PropTypes.func.isRequired,
};

export default Navbar;
