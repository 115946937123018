import { combineReducers } from 'redux';

// old pattern, deprecated and not scalable
import admin from './adminReducer';
import user from './userReducer';
import appointments from './appointmentsReducer';
import survey from './surveyReducer';

// pages ducks pattern
import app, * as appActions from 'pages/App/App.ducks';
import { reducer as login } from 'pages/Login';
import { reducer as createAccount } from 'pages/CreateAccount';
import { reducer as resetPassword } from 'pages/ResetPassword';
import { reducer as conversation } from 'pages/Conversation';
import { reducer as surveyDucks } from 'pages/Survey';
import { reducer as newSurvey } from 'pages/NewSurvey';
import { reducer as completeSurvey } from 'pages/NewSurvey/CompleteSurvey';
import { reducer as surveyOverview } from 'pages/SurveyOverview';
import { reducer as addPersonalInformation } from 'pages/AddPersonalInformation';
import { reducer as addTimeZone } from 'pages/AddTimeZone';
import { reducer as createRegimen } from 'pages/CreateRegimen';
import { reducer as createTreatmentPlan } from 'pages/CreateTreatmentPlan';
import { reducer as clientDetails } from 'pages/ClientDetails';
import { reducer as viewRegimen } from 'pages/ViewRegimen';
import { reducer as clientOrderProducts } from 'pages/ClientOrderProducts';
import { reducer as maintenancePlan } from 'pages/MaintenancePlan';

// containers ducks pattern
import { reducer as taskData } from 'containers/TaskData';
import { reducer as hqPhotos } from 'containers/PhotosWidget/HQPhotosWidget';
import { reducer as checkinPhotos } from 'containers/PhotosWidget/CheckinPhotosWidget';
import { reducer as startProgram } from 'containers/TaskWidget/StartProgramWidget';
import { reducer as reviewTreatmentPlan } from 'containers/TaskWidget/ReviewTreatmentPlanWidget/ReviewTreatmentPlan';
import { reducer as modal } from 'components/Modal';
import { reducer as inbox } from 'containers/InboxWidget';
import { reducer as userInbox } from 'containers/InboxWidget/UserInboxWidget';
import { reducer as coachesInbox } from 'containers/InboxWidget/CoachesInboxWidget';
import { reducer as notificationList } from 'containers/NotificationListWidget';
import { reducer as checkinFollowup } from 'containers/CheckInFollowupWidget';
import { reducer as upcomingCheckin } from 'containers/UpcomingCheckInWidget';
import { reducer as notes } from 'containers/NotesModalWidget';

export const appReducer = combineReducers({
  // old pattern, deprecated and not scalable
  admin,
  user,
  appointments,
  survey,

  // Modal
  modal,

  // Pages
  app,
  login,
  createAccount,
  surveyDucks,
  newSurvey,
  completeSurvey,
  resetPassword,
  conversation,
  surveyOverview,
  addPersonalInformation,
  addTimeZone,
  createRegimen,
  createTreatmentPlan,
  clientDetails,
  viewRegimen,
  clientOrderProducts,
  maintenancePlan,

  // widgets
  taskData,
  checkinPhotos,
  hqPhotos,
  startProgram,
  reviewTreatmentPlan,
  inbox,
  userInbox,
  coachesInbox,
  notificationList,
  checkinFollowup,
  upcomingCheckin,
  notes,
});

const rootReducer = (state, action) => {
  if (action.type === appActions.LOGOUT) {
    state = undefined; // eslint-disable-line no-param-reassign
  }
  return appReducer(state, action);
};

export default rootReducer;
