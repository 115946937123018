import React from 'react';
import PropTypes from 'prop-types';

import Constant from 'utils/constants';
import Button from 'components/Button';
import Form, { SingleChoice } from 'components/Form';

export const STRINGS_BY_PAYMENT_PLAN = {
  [Constant.program.paymentPlan.payAsYouGo]: {
    message: 'Pay-as-you-go (pay every 2-weeks when you submit your checkin)',
  },
  [Constant.program.paymentPlan.payUpfront]: {
    message:
      'Pay upfront (save up to $120 in shipping costs and pay for all products upfront)',
  },
};

const PAYMENT_PLAN_OPTIONS = [
  {
    title:
      STRINGS_BY_PAYMENT_PLAN[Constant.program.paymentPlan.payAsYouGo].message,
    value: Constant.program.paymentPlan.payAsYouGo,
  },
  {
    title:
      STRINGS_BY_PAYMENT_PLAN[Constant.program.paymentPlan.payUpfront].message,
    value: Constant.program.paymentPlan.payUpfront,
  },
];

const PaymentPlanForm = ({ defaultValues, onSubmit }) => {
  return (
    <Form defaultValues={defaultValues} title="Select Treatment Plan">
      {({ handleSubmit, watch }) => {
        const paymentPlan = watch('paymentPlan');
        return (
          <>
            <Form.Row>
              <Form.Item
                name="paymentPlan"
                label={<label>Payment Plan:</label>}
                input={<SingleChoice choices={PAYMENT_PLAN_OPTIONS} />}
              />
            </Form.Row>
            <Form.Actions>
              <Form.Actions.Row>
                <Button
                  size="big"
                  color="secondary"
                  onClick={handleSubmit((values) => {
                    onSubmit({
                      ...values,
                      submitAction: 'previous',
                    });
                  })}
                >
                  Previous
                </Button>
                <Button
                  disabled={!paymentPlan}
                  size="big"
                  onClick={handleSubmit((values) => {
                    onSubmit({
                      ...values,
                      submitAction: 'next',
                    });
                  })}
                >
                  Next
                </Button>
              </Form.Actions.Row>
            </Form.Actions>
          </>
        );
      }}
    </Form>
  );
};

PaymentPlanForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default PaymentPlanForm;
