import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Form, { Radio } from 'components/Form';
import theme from './SelectPlan.sss';

// eslint-disable-next-line no-unused-vars
const SelectPlan = ({ onSubmit, planOptions, defaultValues }) => {
  return (
    <Form
      defaultValues={defaultValues}
      description={
        <>
          <p className={theme.Title}>Select Plan</p>
          <p>Which plan would you like?</p>
        </>
      }
    >
      {({ handleSubmit }) => (
        <>
          <Form.Row key={0}>
            <Form.Item
              name="plan"
              fill={false}
              inlineLabel
              reverseLabel
              input={<Radio id="planOption-0" value={0} />}
              label={<label htmlFor={'planOption-0'}>Regular Plan</label>}
            />
          </Form.Row>
          {/* TODO: Enable this once we need to test Subscriptions Plans */}
          {/* {planOptions.map((option, i) => (
            <Form.Row key={i}>
              <Form.Item
                name="plan"
                fill={false}
                inlineLabel
                reverseLabel
                input={
                  <Radio id={`planOption-${option.id}`} value={option.id} />
                }
                label={
                  <label htmlFor={`planOption-${option.id}`}>
                    {option.name}
                  </label>
                }
              />
            </Form.Row>
          ))} */}
          <footer className={theme.Actions}>
            <Form.Actions>
              <div className={theme.MainActions}>
                <Button
                  classes={[theme.Action]}
                  size="big"
                  onClick={handleSubmit((values) => {
                    onSubmit({
                      ...values,
                      submitAction: 'next',
                    });
                  })}
                >
                  Next
                </Button>
              </div>
            </Form.Actions>
          </footer>
        </>
      )}
    </Form>
  );
};

SelectPlan.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  planOptions: PropTypes.array.isRequired,
};

export default SelectPlan;
