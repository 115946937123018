import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { passwordRegex } from 'helpers/validators';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { PasswordInput } from 'components/Form';

import theme from './CreatePasswordForm.sss';

const CreatePasswordForm = ({ submitting, onSubmit }) => {
  const { register, getValues, handleSubmit, errors } = useForm();
  return (
    <div className={theme.CreatePasswordForm}>
      <h2 className={theme.Title}>ACTIVATE YOUR ACCOUNT</h2>
      <h2 className={theme.SectionTitle}>
        Set a password for getting access to Clear Connection
      </h2>
      <h2 className={theme.SectionTitle}>
        After creating your password, please login and complete your required
        Health Questionnaire and upload photos of your skin.
      </h2>
      <h4 className={theme.SectionTitle}>
        ( Your password must be at least 8 characters, contain one uppercase,
        one lowercase, and one numeric character )
      </h4>
      <h2 className={theme.FormTitle}>CREATE YOUR PASSWORD</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={theme.VerticalGroup}>
          <div className={theme.InputGroup}>
            <label htmlFor="password" className={theme.Label}>
              Password
            </label>
            <PasswordInput
              name="password"
              id="password"
              register={register({
                required: 'Enter your password',
                pattern: {
                  value: passwordRegex,
                  message:
                    // eslint-disable-next-line max-len
                    'Password must be at least 8 characters and contain one uppercase, one lowercase, and one numeric character',
                },
              })}
              error={errors.password?.message}
            />
          </div>
          <div className={theme.InputGroup}>
            <label htmlFor="confirmPassword" className={theme.Label}>
              Confirm Password
            </label>
            <PasswordInput
              name="confirmPassword"
              id="confirmPassword"
              register={register({
                required: 'Confirm your password',
                validate: (value) => {
                  const { password } = getValues();
                  if (password !== value) {
                    return 'Passwords are not matching';
                  }
                  return true;
                },
              })}
              error={errors.confirmPassword?.message}
            />
          </div>
        </div>
        <footer className={theme.Footer}>
          <Button
            style={{ position: 'relative' }}
            disabled={submitting}
            size="big"
            type="submit"
          >
            Create Password
            {submitting && <Loader size="small" loading />}
          </Button>
        </footer>
      </form>
    </div>
  );
};

CreatePasswordForm.propTypes = {
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

CreatePasswordForm.defaultProps = {
  submitting: false,
};

export default CreatePasswordForm;
