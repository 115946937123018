import { useSelector } from 'react-redux';

function useOpenModalProps(modalId) {
  return useSelector((state) => {
    if (state.modal.openModalId !== modalId) {
      return {};
    }
    return state.modal.modalProps;
  });
}
export default useOpenModalProps;
