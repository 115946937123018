import Constant from 'utils/constants';
import { buildReduxObj } from './step';

const EDITABLE_STATES = [
  Constant.stage.state.published,
  Constant.stage.state.started,
  Constant.stage.state.checkinMissed,
];

export const isEditable = ({state}) => EDITABLE_STATES.includes(state);
export const isDraft = ({state}) => state === Constant.stage.state.drafted;
export const isCompleted = ({state}) => state === Constant.stage.state.completed;

/** Build regimens and steps objects of a given stage
 * @param  {array} regimensConfig
 * @param  {array} stepsToFill
 * @returns {object} data
 */
export const buildStageData = (stage, previousStages, regimensConfig) => {
  let stepId = 0;
  return regimensConfig
    .filter((regimen) => regimen.name !== 'DIET RECOMMENDATIONS')
    .reduce((acc, regimen, regimenIndex) => {
      const regimenObj = {
        id: regimenIndex,
        name: regimen.name,
        steps: [],
      };

      let stepsToFill;
      if (isEditable(stage) || isDraft(stage)) {
        stepsToFill = stage.regimens?.find((reg) => reg.code === regimen.name)?.steps;
      } else {
        stepsToFill = previousStages
          .flatMap((previousStage) => previousStage.regimens)
          .filter((reg) => reg.code === regimen.name)
          .flatMap((reg) => reg.steps);
      }

      const steps = regimen.steps.map((step) => {

        let stageStep;
        if (step.name === 'HERBAL') {
          stageStep = stepsToFill?.find(({product}) => step.options[0].product.id === product.id);
        } else {
          stageStep = stepsToFill?.filter(({name}) => step.name === name);
          stageStep = stageStep?.slice(-1)[0];
        }

        const stepObj = buildReduxObj({
          stepId,
          regimenIndex,
          existingStep: stageStep,
          stepConfig: step,
          isNewRegimen: !isEditable(stage) && !isDraft(stage),
        });
        regimenObj.steps.push(stepId++);
        return stepObj;
      });

      return {
        regimens: [
          ...acc.regimens,
          regimenObj,
        ],
        steps: [
          ...acc.steps,
          ...steps,
        ],
      };
    }, {
      regimens: [],
      steps: [],
    });
};

export const getStages = (stages) => {
  const drafted = stages.find((stage) => isDraft(stage));
  const editable = stages.find((stage) => isEditable(stage));
  const completed = stages.filter((stage) => isCompleted(stage))
    .sort((a, b) => {
      return new Date(b.completed).getTime() - new Date(a.completed).getTime();
    })[0];
  return {
    drafted,
    editable,
    completed,
  };
};
