import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import logoNSAC from 'assets/images/NSAC-logo-horizontal.png';
import logoNAC from 'assets/images/nac-horizontal-logo_2x.png';
import Constant from 'utils/constants';
import { isCheckIn } from 'helpers/survey';
import { useQueryParams } from 'hooks/';
import { useGetUserProgram } from 'features/programs/program.query';
import { useBranch } from 'components/BranchProvider';
import Button from 'components/Button';
import Loader from 'components/Loader';

import theme from './CompletedSurvey.sss';

const CompletedSurvey = ({ survey }) => {
  const [branch] = useBranch();
  const logo =
    branch.name === Constant.branch.nscc ? (
      <img src={logoNSAC} className={theme.Logo} />
    ) : (
      <img src={logoNAC} className={theme.Logo} />
    );
  const history = useHistory();
  const { userId, stageId } = useQueryParams();
  const { data: userProgram, isLoading } = useGetUserProgram(userId, {
    enabled: isCheckIn(survey),
  });

  React.useEffect(() => {
    if (isCheckIn(survey) && userProgram?.id) {
      history.replace(
        `/order-products/${userId}/${userProgram.id}/${stageId}/`,
      );
    }
  }, [userId, survey, history, stageId, userProgram?.id]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={theme.CompletedSurvey}>
      {logo}
      <div className={theme.Modal}>
        <header className={theme.Header}>
          <h2 className={theme.ModalTitle}>{`${survey.title} Complete`}</h2>
        </header>
        <div className={theme.ModalContent}>
          <h3>Your responses have been recorded.</h3>
        </div>
        <footer className={theme.ModalFooter}>
          <Button
            size="big"
            onClick={() => {
              history.replace('/');
            }}
          >
            Back to Dashboard
          </Button>
        </footer>
      </div>
    </div>
  );
};

CompletedSurvey.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default CompletedSurvey;
