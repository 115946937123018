import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ChatIcon } from 'components/Icon';
import { InboxWidget } from 'containers/InboxWidget';

import MenuItem from './MenuItem';

const InboxItem = ({ unreadCount, onClick }) => (
  <MenuItem onClick={onClick} target={InboxWidget.displayName}>
    <ChatIcon count={unreadCount} />
  </MenuItem>
);

InboxItem.propTypes = {
  unreadCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ userInbox }) => ({
  unreadCount: userInbox.unreadCount,
});

export default connect(mapStateToProps)(InboxItem);
