import moment from 'moment';
import isPlainObject from 'lodash/isPlainObject';
import { isClient, isCoach } from './user';

import Constant from 'utils/constants';
import { getIdFromUrl } from './formatHelpers';

export const FILTER_TYPES = {
  unread: 'unread',
  all: 'all',
};

export const hasParticipant = ({ participants }, { id: userId }) =>
  participants.some(
    (participant) =>
      participant.id === userId ||
      (!isPlainObject(participant) && getIdFromUrl(participant) === userId),
  );

export const sortThreads = (a, b) => {
  const sortedMessagesA = a.messages.sort(
    (m1, m2) => moment(m2.created).format('x') - moment(m1.created).format('x'),
  );

  const sortedMessagesB = b.messages.sort(
    (m1, m2) => moment(m2.created).format('x') - moment(m1.created).format('x'),
  );

  const messageA = sortedMessagesA[0];
  const messageB = sortedMessagesB[0];

  if (!messageA || !messageB) {
    return 0;
  }

  return (
    moment(messageB.created).format('x') - moment(messageA.created).format('x')
  );
};

export const isRead = (thread, userId) =>
  thread.messages
    .filter((message) => message.recipient.id === userId)
    .every((message) => message.status === 'read');

export const isUnread = (thread, userId) =>
  thread.messages
    .filter((message) => message.recipient.id === userId)
    .some((message) => message.status === 'unread');

export const isUnreadByCoach = (thread) =>
  thread.messages
    .filter((message) => message.recipient.is_staff)
    .some((message) => message.status === 'unread');

export const hasMessages = (thread) => !!thread.messages.length;

export const filterThreadsWithMessages = (threads) =>
  threads.filter((thread) => hasMessages(thread));

export const filterUnreadThreadsForUser = (threads, userId) =>
  threads.filter((thread) => isUnread(thread, userId));

export const filterUnreadThreadsByCoach = (threads) =>
  threads.filter((thread) => isUnreadByCoach(thread));

export const mostRecentUnreadMessageForUser = (thread, user) => {
  const messages = [...thread.messages].sort(
    (a, b) => new Date(b.created) - new Date(a.created),
  );

  const lastUnreadMessage = messages.find(
    (message) =>
      message.status === Constant.message.status.unread &&
      message.recipient.id === user.id,
  );

  return lastUnreadMessage || messages[0];
};

export const mostRecentMessage = (thread) =>
  [...thread.messages].sort(
    (a, b) => new Date(b.created) - new Date(a.created),
  )[0];

export const unreadMessagesForUser = (thread, { id: userId }) =>
  thread.messages
    .filter(
      ({ recipient, status }) =>
        recipient.id === userId && status === Constant.message.status.unread,
    )
    .sort((a, b) => a.created - b.created);

export const getUnreadMessageCountForUser = (threads, { id: userId }) =>
  threads.reduce((count, thread) => {
    const unreadInThread = thread.messages.filter((message) => {
      return (
        message.status === 'unread' &&
        Number(message.recipient.id) === Number(userId)
      );
    });

    return count + unreadInThread.length;
  }, 0);

export const getRecipientForUser = ({ participants }, { id: userId }) =>
  participants.find(({ id: participantId }) => participantId !== userId);

export const getClientInThread = ({ participants }) =>
  participants.find((participant) => isClient(participant));

export const getCoachInThread = ({ participants }) =>
  participants.find((participant) => isCoach(participant));
