import React from 'react';
import PropTypes from 'prop-types';

import { RegimenOrderProduct } from './components';
import theme from './CustomizeOrder.sss';

const CustomizeOrder = ({
  productCategories,
  orderItemLimit,
  onUpdateProductQuantity,
}) => (
  <div className={theme.Order}>
    <div>
      <h4 className={theme.OrderTitle}>Review Your Order</h4>
      <p>
        Please review your order here to make sure it is correct.
        If it is not correct, you can return to the regimen to make any necessary updates.
        You can use this screen to order clients any products that are used outside this weeks regimen.
      </p>
    </div>
    <div className={theme.ProductsContainer}>
      <ul className={theme.CategoriesList}>
        {productCategories.filter((category) => category.products?.length).map((category, cIndex) => (
          <li key={cIndex}>
            <h3>{category.name}</h3>
            <div className={theme.ProductGrid}>
              {category.products.map((product, pIndex) => {
                return (
                  <RegimenOrderProduct
                    key={pIndex}
                    product={product}
                    limit={orderItemLimit}
                    onUpdateQty={onUpdateProductQuantity}
                  />
                );
              })}
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

CustomizeOrder.propTypes = {
  productCategories: PropTypes.array.isRequired,
  orderItemLimit: PropTypes.number,
  onUpdateProductQuantity: PropTypes.func.isRequired,
};

export default CustomizeOrder;
