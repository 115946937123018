import { useQuery, useMutation } from 'react-query';

import TaskService from 'services/task.service';

export function useGetClientTasks(clientId, params, options) {
  return useQuery(
    ['client-tasks', clientId, params],
    () => TaskService.getClientTasks(clientId, params),
    options,
  );
}

export function useGetAllClientsTasks(params, options) {
  return useQuery(
    ['all-clients-tasks', params],
    () => TaskService.getAllClientsTasks(params),
    options,
  );
}

export function useUpdateTask(options) {
  return useMutation(
    ({ taskId, payload }) => TaskService.update(taskId, payload),
    options,
  );
}
