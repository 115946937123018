import React from 'react';

import MenuItem from './MenuItem';
import { DocumentIcon } from 'components/Icon';

const DocumentItem = () => (
  <MenuItem link="/documents">
    <DocumentIcon />
  </MenuItem>
);

export default DocumentItem;
