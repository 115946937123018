/* eslint-disable no-use-before-define */
import axios from 'config/axios';
import { getErrorMessage } from 'helpers/errorHandling';
import { apiBaseUrl as api } from 'config/config';

import { toast } from 'react-toastify';

// Actions
const INITIALIZE = 'cc/CreateTreatmentPlan/INITIALIZE';
const INITIALIZE_SUCCESS = 'cc/CreateTreatmentPlan/INITIALIZE_SUCCESS';
const INITIALIZE_FAIL = 'cc/CreateTreatmentPlan/INITIALIZE_FAIL';
const SAVE_AS_DRAFT = 'cc/CreateTreatmentPlan/SAVE_AS_DRAFT';
const SAVE_AS_DRAFT_SUCCESS = 'cc/CreateTreatmentPlan/SAVE_AS_DRAFT_SUCCESS';
const SAVE_AS_DRAFT_FAILED = 'cc/CreateTreatmentPlan/SAVE_AS_DRAFT_FAILED';
const SEND = 'cc/CreateTreatmentPlan/SEND';
const SEND_SUCCESS = 'cc/CreateTreatmentPlan/SEND_SUCCESS';
const SEND_FAIL = 'cc/CreateTreatmentPlan/SEND_FAIL';

const initialState = {
  loading: false,
  profile: {},
  program: {},
  treatmentPlan: {},
};

// Reducer
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        loading: true,
      };
    case INITIALIZE_SUCCESS: {
      const { treatmentPlan, profile, program } = action.payload;
      return {
        ...state,
        treatmentPlan,
        profile,
        program,
        loading: false,
      };
    }
    default:
      return state;
  }
};

// Action Creators

const initialize = (payload) =>
  async (dispatch) => {
    dispatch({ type: INITIALIZE, payload });
    const { clientId } = payload;

    try {
      const { data: profile} = await axios.get(`users/${clientId}/profile/`);
      const { data: program} = await axios.get(`programs/acne/?user=${clientId}`);
      let treatmentPlan = {};
      if ( !program?.results[0] ) {
        const error = {message:'Program not found'};
        dispatch(initializeFail({ error }));
        return;
      }
      // eslint-disable-next-line camelcase
      const treatmentPlanUrl = program?.results[0]?.treatment_plan;
      if ( treatmentPlanUrl && treatmentPlanUrl !== '') {
        const { data: plan } = await axios.get(treatmentPlanUrl);
        treatmentPlan = plan;
      }
      dispatch(initializeSuccess({ treatmentPlan, profile, program: program.results[0]}));
    }
    catch(error) {
      dispatch(initializeFail({ error }));
    }

  };

const initializeSuccess = (payload) =>
  (dispatch) => {
    dispatch({ type: INITIALIZE_SUCCESS, payload });
  };

const initializeFail = (payload) =>
  (dispatch) => {
    dispatch({ type: INITIALIZE_FAIL, payload });
    const { error } = payload;
    toast.error(getErrorMessage(error, 'Could not initialize Create Treatment Plan'));
  };

const saveAsDraft = (payload) =>
  async (dispatch, getState) => {
    dispatch({ type: SAVE_AS_DRAFT, payload });
    const { body } = payload;
    const { treatmentPlan, program } = getState().createTreatmentPlan;
    try {
      let draftedTreatmentPlan;
      if (treatmentPlan.body) {
        const { data } = await axios.patch(`programs/acne/treatment-plans/${treatmentPlan.id}/`, {
          body,});
        draftedTreatmentPlan = data;
      } else {
        const { data } = await axios.post('programs/acne/treatment-plans/', {
          body,
          acne_program: `${api}programs/acne/${program.id}/`,
          state: 'drafted',
        });
        draftedTreatmentPlan = data;
      }
      dispatch(saveAsDraftSuccess({ treatmentPlan: draftedTreatmentPlan }));
    } catch (error) {
      dispatch(saveAsDraftFail({ error }));
    }
  };

const saveAsDraftSuccess = (payload) =>
  (dispatch) => {
    dispatch({ type: SAVE_AS_DRAFT_SUCCESS, payload });
  };

const saveAsDraftFail = (payload) =>
  (dispatch) => {
    dispatch({ type: SAVE_AS_DRAFT_FAILED, payload });
  };

const send = (payload) =>
  async (dispatch, getState) => {
    dispatch({ type: SEND, payload });
    const { body, suscriptionPlan } = payload;
    const { treatmentPlan, program } = getState().createTreatmentPlan;
    try {
      let sentTreatmentPlan;
      let acneProgram;
      if (suscriptionPlan){
        const { data } = await axios.patch(`programs/acne/${program.id}/`,
          {
            plan:suscriptionPlan.id,
          });
        acneProgram = data;
      }
      if (treatmentPlan.body) {
        const { data } = await axios.patch(`programs/acne/treatment-plans/${treatmentPlan.id}/`,
          {
            body,
            state: 'published',
          });
        sentTreatmentPlan = data;
      } else {
        const { data } = await axios.post('programs/acne/treatment-plans/', {
          body,
          acne_program: `${api}programs/acne/${program.id}/`,
          state: 'published',
        });
        sentTreatmentPlan = data;
      }
      dispatch(sendSuccess({ treatmentPlan: sentTreatmentPlan, acneProgram }));
      return true;
    } catch (error) {
      dispatch(sendFail({ error }));
      return false;
    }
  };

const sendSuccess = (payload) =>
  (dispatch) => {
    dispatch({ type: SEND_SUCCESS, payload });
  };

const sendFail = (payload) =>
  (dispatch) => {
    dispatch({ type: SEND_FAIL, payload });
  };

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_SUCCESS,
  INITIALIZE_FAIL,
  SAVE_AS_DRAFT,
  SAVE_AS_DRAFT_SUCCESS,
  SAVE_AS_DRAFT_FAILED,
  SEND,
  SEND_SUCCESS,
  SEND_FAIL,

  // Action Creators
  initialize,
  initializeFail,
  initializeSuccess,
  saveAsDraft,
  saveAsDraftFail,
  saveAsDraftSuccess,
  send,
  sendSuccess,
  sendFail,
};
