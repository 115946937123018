import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { surveyIds } from 'config/config';
import { TR, TD } from 'components/Table';
import Avatar from 'components/Avatar';

import theme from './CheckInFollowupRow.sss';

const CheckInFollowupRow = ({ checkinFollowup }) => (
  <TR>
    {checkinFollowup.client.profile.coach && (
      <TD>
        <div className={theme.ClearSkinSpecialist}>
          <Avatar user={checkinFollowup.client.profile.coach} />
          <h1 className={theme.ClearSkinSpecialistName}>
            {checkinFollowup.client.profile.coach?.profile?.full_name}
          </h1>
        </div>
      </TD>
    )}
    <TD>
      <Link
        className={theme.Link}
        to={`/client-details/${checkinFollowup.client.id}`}
      >
        {checkinFollowup.client.profile.full_name}
      </Link>
    </TD>
    <TD>
      <div className={theme.CheckIn}>
        <Link
          className={theme.Link}
          // eslint-disable-next-line max-len
          to={`/client-details/${checkinFollowup.client.id}/surveys/${surveyIds.checkin}/${checkinFollowup.response.id}`}
        >
          Week {checkinFollowup.response.week_period} Check-In
        </Link>
        <span>
          {format(
            new Date(checkinFollowup.response.completed),
            'MM/dd/yyyy hh:mm a',
          )}
        </span>
      </div>
    </TD>
    <TD>
      <Link
        className={[theme.Link]}
        to={`/create-regimen/${checkinFollowup.client.id}/${checkinFollowup.task.context.program_id}`}
      >
        Create Regimen
      </Link>
    </TD>
  </TR>
);

CheckInFollowupRow.propTypes = {
  checkinFollowup: PropTypes.object.isRequired,
};

export default CheckInFollowupRow;
