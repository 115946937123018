import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';

import { TextInput, PhoneNumberInput, Autocomplete } from 'components/Form';
import Button from 'components/Button';
import { getTimeZones } from 'helpers/getTimeZones';

import theme from './GeneralInformationForm.sss';

const GeneralInformationForm = ({ onSubmit }) => {
  const { register, control, handleSubmit, errors } = useForm();

  return (
    <form
      className={theme.GeneralInformationForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className={theme.SectionTitle}>Tell us more about you</h2>
      <div className={theme.HorizontalGroup}>
        <div className={theme.InputGroup}>
          <label htmlFor="occupation" className={theme.Label}>
            Occupation*
          </label>
          <TextInput
            name="occupation"
            id="occupation"
            register={register({ required: 'Enter your occupation' })}
            error={errors.occupation?.message}
          />
        </div>
        <div className={theme.InputGroup}>
          <label htmlFor="timeZone" className={theme.Label}>
            Time Zone*
          </label>
          <Controller
            control={control}
            name="timeZone"
            id="timeZone"
            render={({ onChange }) => (
              <Autocomplete
                name="timeZone"
                id="timeZone"
                onChange={(_, data) => onChange(data)}
                getOptionLabel={(tz) => tz?.name}
                renderOption={(tz) => `(GMT ${tz?.utcOffsetStr}) ${tz?.name}`}
                options={getTimeZones()}
                error={errors.timeZone?.message}
              />
            )}
            rules={{ required: 'Please select your Time zone' }}
          />
        </div>
      </div>
      <h2 className={theme.SectionTitle}>How can we contact you?</h2>
      <div className={theme.HorizontalGroup}>
        <div className={theme.InputGroup}>
          <label htmlFor="phone" className={theme.Label}>
            Phone*
          </label>
          <Controller
            as={PhoneNumberInput}
            name="phone"
            id="phone"
            placeholder="Enter your phone number"
            control={control}
            rules={{ required: 'Enter your phone number' }}
            error={errors.phone?.message}
            defaultValue=""
          />
        </div>
        <div className={theme.InputGroup}>
          <label htmlFor="altPhone" className={theme.Label}>
            Alt Phone
          </label>
          <Controller
            as={PhoneNumberInput}
            name="altPhone"
            id="altPhone"
            placeholder="Enter your phone number"
            control={control}
            defaultValue=""
          />
        </div>
      </div>
      <footer className={theme.FormFooter}>
        <Button size="big" type="submit">
          Continue
        </Button>
      </footer>
    </form>
  );
};

GeneralInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default GeneralInformationForm;
