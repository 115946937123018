/* eslint-disable max-len */

import React from 'react';

const StomachIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M 10 2 C 10 6.601563 10.792969 7.585938 11.59375 8.6875 C 11.59375 5.886719 13.5 5 13.5 5 C 12.898438 4.601563 13 2 13 2 Z M 17 5.3125 C 14.5 5.3125 12.8125 6.898438 12.8125 10 C 12.8125 11.199219 12.988281 17 9.6875 17 C 7.1875 17 7.3125 16.1875 5.3125 16.1875 C 2.8125 16.1875 2 20.3125 2 20.3125 C 2.101563 20.511719 2.789063 21.292969 4.1875 21.09375 C 4.289063 20.695313 4.695313 19 6.09375 19 C 8.394531 19 9.90625 22 12.40625 22 C 16.605469 22 20.585938 19.386719 21.6875 14.1875 C 23.085938 7.886719 19.5 5.3125 17 5.3125 Z" />
  </svg>
);

StomachIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default StomachIcon;
