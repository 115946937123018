import React from 'react';
import PropTypes from 'prop-types';

import Wysiwyg from 'components/Wysiwyg';

import { useComposeMessage } from './ComposeMessage';

const TextArea = (props) => {
  const { messagePlaceholder, setMessage, message } = useComposeMessage();

  return (
    <Wysiwyg
      data-testid="ComposeInput"
      value={message}
      defaultValue={messagePlaceholder}
      size="xsmall"
      {...props}
      onChange={setMessage}
    />
  );
  // return (
  //   <textarea
  //     className={classNames(theme.TextArea, className)}
  //     data-testid="ComposeInput"
  //     placeholder={messagePlaceholder}
  //     {...props}
  //     onChange={(e) => {
  //       setMessage(e.target.value);
  //     }}
  //     value={message}
  //   />
  // );
};

TextArea.propTypes = {
  className: PropTypes.string,
};

export default TextArea;
