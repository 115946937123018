import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Constant from 'utils/constants';
import strings from 'assets/strings';

import theme from './Rejection.sss';

const Rejection = ({
  classes,
  rejection,
}) => (
  <div className={classNames(theme.Rejection, classes)}>
    <header>
      <h4 className={theme.Title}>The Treatment Plan was rejected</h4>
    </header>
    <p className={theme.Subtitle}>Reasons of rejection:</p>
    <ul className={theme.ReasonList}>
      {rejection.reason?.map((reason, index) => (
        <li key={index}>
          <p>{strings[Constant.treatmentPlanRejections[reason]]}</p>
        </li>
      ))}
    </ul>
    {rejection.text?.length > 0 &&
      <>
        <p className={theme.Subtitle}>Other Reasons:</p>
        <p className={theme.OtherReason} dangerouslySetInnerHTML={{ __html: rejection.text }} />
      </>
    }
  </div>
);

Rejection.propTypes = {
  classes: PropTypes.array,
  rejection: PropTypes.object.isRequired,
};

export default Rejection;
