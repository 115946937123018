import Constant from 'utils/constants';

export const canViewMessages = ({ balance }) =>
  balance[Constant.maintenancePlan.constraints.canViewMessages];

export const canViewNotifications = ({ balance }) =>
  balance[Constant.maintenancePlan.constraints.canViewNotifications];

export const canViewAcneProgramPDFs = ({ balance }) =>
  balance[Constant.maintenancePlan.constraints.canViewAcneProgramPDFs];
