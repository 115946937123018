import { useQuery, useMutation } from 'react-query';

import TreatmentPlanService from 'services/treatmentPlan.service';

export function useGetTreatmentPlan(treatmentPlanId, options) {
  return useQuery(
    ['treatmentPlans', treatmentPlanId],
    () => TreatmentPlanService.get(treatmentPlanId),
    options,
  );
}

export function useUpdateTreatmentPlan(options) {
  return useMutation(
    ({ treatmentPlanId, payload }) =>
      TreatmentPlanService.update(treatmentPlanId, payload),
    options,
  );
}
