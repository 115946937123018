import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FILTER_TYPES } from 'helpers/thread';

import Filter from './Filter';
import theme from './MessageFilter.sss';

const MessageFilter = ({
  defaultValue,
  onChange,
}) => {
  const [ selectedFilter, setSelectedFilter ] = useState(defaultValue);
  return (
    <ul className={theme.MessageFilter}>
      <Filter
        name={FILTER_TYPES.unread}
        selected={selectedFilter === FILTER_TYPES.unread}
        onClick={() => {
          setSelectedFilter(FILTER_TYPES.unread);
          onChange(FILTER_TYPES.unread);
        }}
      />
      <Filter
        name={FILTER_TYPES.all}
        selected={selectedFilter === FILTER_TYPES.all}
        onClick={() => {
          setSelectedFilter(FILTER_TYPES.all);
          onChange(FILTER_TYPES.all);
        }}
      />
    </ul>
  );
};

MessageFilter.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

MessageFilter.defaultProps = {
  defaultValue: FILTER_TYPES.unread,
};

export default MessageFilter;
