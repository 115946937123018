import { useQuery } from 'react-query';

import ProductService from 'services/product.service';

export function useGetOrderMoreProducts(options) {
  return useQuery(
    ['products', 'orderMoreProducts'],
    () => ProductService.getOrderMoreProducts(),
    options,
  );
}
