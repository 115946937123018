import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import { useGetNextAppointment } from 'features/users/user.query';

import Loader from 'components/Loader';

function NextAppointment({ className, userId }) {
  const { data: nextAppointment, isLoading } = useGetNextAppointment(userId);
  if (isLoading) {
    return <Loader />;
  }
  const message = nextAppointment.start ? (
    <>
      Your next appointment date is on:&nbsp;
      {format(new Date(nextAppointment.start), 'MMM d, hh:mm a')}
    </>
  ) : (
    'Please schedule your next appointment'
  );
  return <h2 className={className}>{message}</h2>;
}

NextAppointment.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

export default NextAppointment;
