import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { useBranch } from 'components/BranchProvider';
import Loader from 'components/Loader';
import { useLoggedInUser } from 'hooks';

import * as modalActions from 'components/Modal/Modal.ducks';

import * as actions from './ClientOrderProducts.ducks';

import { OrderProducts, SuccessModal, NextAppointment } from './components';

import theme from './ClientOrderProducts.sss';

const ORDER_SUCCESS_MODAL_ID = 'ClientOrderProductsPage/OrderSuccess';
function ClientOrderProductsPage({
  loading,
  createOrder,
  initialize,
  setOpenModalId,
}) {
  const history = useHistory();
  const { programId, clientId, stageId } = useParams();
  const user = useLoggedInUser();
  const [branch] = useBranch();

  React.useEffect(() => {
    if (!clientId || !programId || !stageId) {
      history.replace('/');
      return;
    }
    initialize({ programId, stageId, clientId });
  }, [clientId, programId, stageId, history, initialize]);

  if (loading) {
    return <Loader loading type="fixed" size="large" />;
  }

  return (
    <>
      <div className={theme.Container}>
        {branch.show_next_appointment_enabled && (
          <NextAppointment className={theme.NextAppointment} userId={user.id} />
        )}
        <OrderProducts
          onExitClick={() => {
            history.replace('/');
          }}
          onOrderProductsClick={async (orderedProducts) => {
            const succeeded = await createOrder({
              stageId: parseInt(stageId, 10),
              orderedProducts,
            });
            if (succeeded) {
              setOpenModalId(ORDER_SUCCESS_MODAL_ID);
            }
          }}
        />
      </div>
      <SuccessModal
        modalId={ORDER_SUCCESS_MODAL_ID}
        onExit={() => {
          history.replace('/');
        }}
      />
    </>
  );
}

ClientOrderProductsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ clientOrderProducts }) => ({
  ...clientOrderProducts,
});

export default connect(mapStateToProps, {
  ...actions,
  setOpenModalId: modalActions.setOpenModalId,
})(ClientOrderProductsPage);
