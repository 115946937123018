import React from 'react';

import theme from './HappyFoxChat.sss';

function HappyFoxChat() {
  const containerRef = React.useRef();

  React.useEffect(() => {
    const embedHFWidget = () => {
      const embedButton = document.createElement('a');
      embedButton.style.zIndex = '2147483646';
      embedButton.style.backgroundColor = '#00B3A6';
      embedButton.innerHTML = 'Need Help?';
      embedButton.style.color = '#FFF';
      const buttonCss =
        'font-family: "OpenSansRegular", sans-serif; position: fixed; padding: 10px 20px 10px 20px; letter-spacing: 1px; -webkit-border-bottom-left-radius: 10px; -moz-border-radius-bottomleft: 10px; border-bottom-left-radius: 10px; -webkit-border-bottom-right-radius: 10px; -moz-border-radius-bottomright: 10px; border-bottom-right-radius: 10px;'; // eslint-disable-line max-len
      if ('r' === 'l') {
        embedButton.style.cssText +=
          buttonCss +
          ' -webkit-transform-origin: 0% 50%; -webkit-transform: rotate(-90deg) translate(-50%, 50%); -moz-transform-origin: 0 50%; -moz-transform: rotate(-90deg) translate(-50%, 50%); -ms-transform-origin: 0 50%; -ms-transform: rotate(-90deg) translate(-50%, 50%); -o-transform-origin: 0 50%; -o-transform: rotate(-90deg) translate(-50%, 50%); transform-origin: 0 50%; transform: rotate(-90deg) translate(-50%, 50%); top: 40%; left: 0;'; // eslint-disable-line max-len
      } else {
        embedButton.style.cssText +=
          buttonCss +
          ' -webkit-transform-origin: 100% 50%; -webkit-transform: rotate(90deg) translate(50%, 50%); -moz-transform-origin: 100% 50%; -moz-transform: rotate(90deg) translate(50%, 50%); -ms-transform-origin: 100% 50%; -ms-transform: rotate(90deg) translate(50%, 50%); -o-transform-origin: 100% 50%; -o-transform: rotate(90deg) translate(50%, 50%); transform-origin: 100% 50%; transform: rotate(90deg) translate(50%, 50%); top: 40%; right: 0;'; // eslint-disable-line max-len
      }
      embedButton.style.cursor = 'pointer';
      embedButton.style.textDecoration = 'none';
      embedButton.addEventListener(
        'click',
        () => {
          const embedDiv = document.createElement('div');
          embedDiv.style.border = '0';
          embedDiv.style.position = 'fixed';
          embedDiv.style.top = '0';
          embedDiv.style.left = '0';
          embedDiv.style.right = '0';
          embedDiv.style.bottom = '0';
          embedDiv.style.width = '100%';
          embedDiv.style.height = '100%';
          embedDiv.style.overflow = 'hidden';
          embedDiv.style.zIndex = '2147483647';

          function removeDiv() {
            const divToRemove = document.getElementById('embedDiv');
            if (divToRemove) {
              divToRemove.parentNode.removeChild(divToRemove);
            }
          }
          embedDiv.id = 'embedDiv';
          embedDiv.addEventListener('click', removeDiv, false);
          document.body.appendChild(embedDiv);
          const iframe = document.createElement('iframe');
          iframe.src =
            'https://naturalacneclinic.happyfox.com/supportwidgets/code_support_widget/2';
          iframe.style.position = 'absolute';
          iframe.style.border = '0';
          iframe.style.background = 'none transparent';
          iframe.style.height = '80%';
          iframe.style.maxWidth = '960px';
          iframe.style.width = '100%';
          iframe.style.left = '50%';
          iframe.style.top = '50%';
          iframe.style.transform = 'translate(-50%, -50%)';
          iframe.addEventListener(
            'load',
            () => {
              const grayDiv = document.getElementById('embedDiv');
              grayDiv.style.backgroundColor = 'rgba(0,0,0,0.3)';
              grayDiv.appendChild(closeIframe); // eslint-disable-line no-use-before-define
            },
            false,
          );
          iframe.id = 'embedWidget';
          iframe.frameborder = '0';
          iframe.allowTransparency = 'true';
          embedDiv.appendChild(iframe);
          const closeIframe = document.createElement('img');
          closeIframe.type = 'button';
          closeIframe.addEventListener('click', removeDiv, false);
          closeIframe.src =
            'https://naturalacneclinic.happyfox.com/media/img/1415967259__close.png';
          closeIframe.className = theme.HappyFoxChat;
        },
        false,
      );
      containerRef.current.appendChild(embedButton);
    };
    embedHFWidget();
  }, []);

  return <div ref={containerRef} />;
}

export default HappyFoxChat;
