import { useState, useCallback, useEffect } from 'react';

import axios from 'config/axios';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

import usePaginatorReducer, {
  fetchDataInitialized,
  fetchDataSuceeded,
  fetchDataFailed,
  reset,
  setData,
} from './usePaginator.ducks';

function usePaginator({
  debugId = 'usePaginator',
  endpoint: initialEndpoint,
  params: initialParams = {},
  pageSize: initialPageSize,
  direction = 'bottom',
}) {
  const [state, dispatch] = usePaginatorReducer(debugId);
  const [endpoint, setEndpoint] = useState(initialEndpoint);
  const [params, setParams] = useState(initialParams);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const setDataCallback = useCallback(
    (data) => {
      dispatch(setData(data));
    },
    [dispatch],
  );

  const resetCallback = useCallback(() => {
    setEndpoint(initialEndpoint);
    setParams(initialParams);
    dispatch(reset());
  }, [initialEndpoint, initialParams, dispatch]);

  const memoFetchData = useCallback(async () => {
    dispatch(fetchDataInitialized());
    try {
      const { data } = await axios.get(state.next || endpoint, {
        params: {
          ...params,
          limit: pageSize,
        },
      });
      dispatch(
        fetchDataSuceeded({
          data: data.results,
          total: data.count,
          next: data.next,
        }),
      );
    } catch (err) {
      dispatch(fetchDataFailed(err));
    }
  }, [endpoint, pageSize, params, state.next, dispatch]);

  useEffect(() => {
    dispatch(reset());
  }, [params, pageSize, dispatch]);

  useEffect(() => {
    if (state.didReset) {
      memoFetchData();
    }
  }, [state.didReset, memoFetchData]);

  const [containerRef, loaderRef] = useInfiniteScroll({
    hasNextPage: !!state.next,
    onLoadMore: memoFetchData,
    loading: state.isLoading,
    direction,
  });

  return [
    containerRef,
    loaderRef,
    state,
    {
      setEndpoint,
      setPageSize,
      setParams,
      reset: resetCallback,
      setData: setDataCallback,
    },
  ];
}

export default usePaginator;
