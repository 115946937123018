import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { getIdFromUrl } from 'helpers/formatHelpers';
import { useGetClient, useGetProfile } from 'features/users/user.query';
import { useUpdateTask } from 'features/contact/task.query';
import Constant from 'utils/constants';

import Task from 'components/Task';
import Loader from 'components/Loader';

const WatchVideoWidget = ({ task }) => {
  const queryClient = useQueryClient();
  const { link } = task.context;
  const userId = getIdFromUrl(task.linked_user);

  const { data: client, isLoading: isClientLoading } = useGetClient(userId);
  const { data: clientProfile, isLoading: isClientProfileLoading } =
    useGetProfile(userId);
  const { mutate: updateTask } = useUpdateTask();

  const isLoading = isClientLoading || isClientProfileLoading;
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Task
      task={task}
      title="Watch Pre-Consultation Video"
      action="Watch"
      link={`${link}/?Id=${clientProfile.service_object_id}&Email=${client.email}`}
      onClick={() => {
        // TODO: Complete the task
        updateTask(
          {
            taskId: task.id,
            payload: {
              state: Constant.task.state.completed,
            },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['tasks']);
            },
            onError: (error) => {
              toast.error(getErrorMessage(error));
            },
          },
        );
      }}
    />
  );
};

WatchVideoWidget.propTypes = {
  task: PropTypes.object.isRequired,
};

export default WatchVideoWidget;
