/* eslint-disable max-len */

import React from 'react';

const EnvelopeIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M20,4H4C2.895,4,2,4.895,2,6v12c0,1.105,0.895,2,2,2h16c1.105,0,2-0.895,2-2V6C22,4.895,21.105,4,20,4z M20,8l-8,5L4,8V6 l8,5l8-5V8z" fill="#000000" />
  </svg>
);

EnvelopeIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: 'black',
};

export default EnvelopeIcon;
