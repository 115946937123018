import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import nacLogo from 'assets/images/nac-logo.png';
import { userAware } from 'components/UserProvider';

import {
  Banner,
  Highlights,
  StayClear,
  Plans,
  SuccessStory,
  Footer,
  ConfirmPlanModal,
} from './components';
import { CONFIRM_MAINTENANCE_PLAN_MODAL_ID } from './components/ConfirmPlanModal';
import * as actions from './MaintenancePlan.ducks';
import * as modalActions from 'components/Modal/Modal.ducks';
import theme from './MaintenancePlan.sss';

const PLANS = [
  {
    title: 'BASIC',
    price: '$49.99 / year',
    styleClass: 'Basic',
    benefits: [
      'Full access to your Regimen History',
      'Access to your Custom Treatment Plan',
      'Full access to your Check-in History',
      '10 private messages per year with your Clear Skin Specialist for ongoing support',
    ],
  },
  {
    title: 'CLEAR FOR A YEAR',
    price: '$149.99 / year',
    styleClass: 'ClearForAYear',
    benefits: [
      <>
        <strong>Free shipping</strong> in the US
      </>,
      <>
        <strong>5% off</strong> all future products purchases
      </>,
      'Full access to your Regimen History',
      'Access to your Custom Treatment Plan',
      'Full access to your Check-in History',
      'Access to all messages with your Clear Skin Specialist',
      '20 private messages per year with your Clear Skin Specialist for ongoing support',
    ],
  },
  {
    title: 'CLEAR FOR LIFE',
    price: '$499 (one time)',
    styleClass: 'ClearForLife',
    benefits: [
      <>
        <strong>Free shipping</strong> in the US
      </>,
      <>
        <strong>5% off</strong> all future products purchases
      </>,
      'Full access to your Regimen History',
      'Access to your Custom Treatment Plan',
      'Full access to your Check-in History',
      'Access to all messages with your Clear Skin Specialist',
      <>
        <strong>Unlimited</strong> private messages per year with your Clear
        Skin Specialist
      </>,
      '1 complimentary, 30-minute coaching session per year',
    ],
  },
];

const MaintenancePlan = ({ history, submitting, setOpenModalId, user }) => {
  const plansContainer = useRef();
  const isOldCC = user.migrated;
  return (
    <div className={theme.MaintenancePlan}>
      <div className={theme.Header}>
        <img src={nacLogo} />
      </div>
      <div className={theme.Banner}>
        <Banner
          isOldCC={isOldCC}
          onViewPlansClick={() => {
            plansContainer.current.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      </div>
      <div className={theme.Highlights}>
        <Highlights
          isOldCC={isOldCC}
          onSubscribeClick={() => {
            plansContainer.current.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      </div>
      {!isOldCC && (
        <div className={theme.StayClear}>
          <StayClear />
        </div>
      )}
      <div className={theme.Plans}>
        <Plans
          isOldCC={isOldCC}
          ref={plansContainer}
          plans={PLANS}
          onPlanClick={(plan) => {
            setOpenModalId(CONFIRM_MAINTENANCE_PLAN_MODAL_ID, { plan });
          }}
        />
      </div>
      <div className={theme.SuccessStory}>
        <SuccessStory />
      </div>
      <div className={theme.Plans}>
        <Plans
          isOldCC={isOldCC}
          altHeader
          ref={plansContainer}
          plans={PLANS}
          onPlanClick={(plan) => {
            setOpenModalId(CONFIRM_MAINTENANCE_PLAN_MODAL_ID, { plan });
          }}
        />
      </div>
      <div className={theme.Footer}>
        <Footer
          onSubscribeClick={() => {
            plansContainer.current.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      </div>
      <ConfirmPlanModal
        submitting={submitting}
        onConfirm={() => {
          setOpenModalId(null);
          history.replace('/');
        }}
      />
    </div>
  );
};

MaintenancePlan.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  savePlan: PropTypes.func.isRequired,
  setOpenModalId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ maintenancePlan }) => ({
  ...maintenancePlan,
});

export default withRouter(
  connect(mapStateToProps, {
    ...actions,
    setOpenModalId: modalActions.setOpenModalId,
  })(userAware(MaintenancePlan)),
);
