/* eslint no-use-before-define: 0 */

import { toast } from 'react-toastify';
import axios from 'config/axios';

import Constant from 'utils/constants';
import { getErrorMessage } from 'helpers/errorHandling';

// Actions
const FETCH_TASK = 'cc/app/TaskData/FETCH_TASK';
const FETCH_TASK_SUCCESS = 'cc/app/TaskData/FETCH_TASK_SUCCESS';
const FETCH_TASK_FAIL = 'cc/app/TaskData/FETCH_TASK_FAIL';
const COMPLETE_TASK = 'cc/app/TaskData/COMPLETE_TASK';

// per task state
const initialState = {
  task: {},
  extraData: {},
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASK:
      return {
        ...state,
      };
    case FETCH_TASK_SUCCESS: {
      const { task, extraData } =  action.payload;
      return {
        ...state,
        task,
        extraData,
      };
    }
    case COMPLETE_TASK: {
      const { taskId } = action;
      return {
        ...state,
        [taskId]: {
          ...state[taskId],
          state: Constant.task.state.completed,
        }
      };
    }
    default:
      return state;
  }
};

// Helpers

const mapExtraDataToKeys = (extraData, keys) => {
  return keys.reduce((acc, key, index) => {
    if (extraData[index].data.results) {
      return {
        ...acc,
        [key]: extraData[index].data.results,
      };
    }
    return {
      ...acc,
      [key]: extraData[index].data,
    };
  }, {});
};

// Action Creators

const fetchTask = (taskId, extraDataCalls) =>
  async (dispatch) => {
    dispatch({ type: FETCH_TASK, taskId, extraDataCalls });
    try {
      const { data: task } = await axios.get(`tasks/${taskId}/`);
      const apiPaths = Object.values(extraDataCalls);
      const results = await Promise.all(apiPaths.map((path) => axios.get(path)));
      const extraData = mapExtraDataToKeys(results, Object.keys(extraDataCalls));
      dispatch(fetchTaskSuccess({ task, extraData }));
    } catch(error) {
      dispatch(fetchTaskFail({ error }));
    }
  };

const fetchTaskSuccess = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_TASK_SUCCESS, payload });
  };

const fetchTaskFail = (payload) =>
  (dispatch) => {
    dispatch({ type: FETCH_TASK_FAIL, payload });
    const { error } = payload;
    toast.error(getErrorMessage(error));
  };

const completeTask = (taskId) =>
  (dispatch) => {
    dispatch({ type: COMPLETE_TASK, taskId});
  };

export default reducer;
export {
  // Actions
  FETCH_TASK,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAIL,

  // Action Creators
  fetchTask,
  fetchTaskSuccess,
  fetchTaskFail,
  completeTask,
};
