/* eslint no-use-before-define: 0 */

import axios from 'config/axios';

import history from 'global-history';
import Constant from 'utils/constants';
import { userTypeIds } from 'config/config';
import { getIdFromUrl } from 'helpers/formatHelpers';
import { isActive, hasCompletedTasks } from 'helpers/task';
import * as surveyActions from 'actions/surveyActions';

// Actions
const INITIALIZE = 'cc/survey/INITIALIZE';
const INITIALIZE_FAILED = 'cc/survey/INITIALIZE_FAILED';
const INITIALIZE_SUCCEEDED = 'cc/survey/INITIALIZE_SUCCEEDED';

const initialState = {
  loading: true,
  clientName: '',
  survey: {},
  answers: [],
  createRegimenTask: {},
  completed: false,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state, loading: true };
    case INITIALIZE_SUCCEEDED: {
      const {
        clientName,
        survey,
        completed,
        answers,
        createRegimenTask,
      } = action.payload;
      return {
        ...state,
        clientName,
        survey,
        completed,
        answers,
        createRegimenTask,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const initialize = (payload) => async (dispatch, getState) => {
  dispatch({ type: INITIALIZE, payload });
  const { clientId, surveyId, responseId } = payload;
  const { userType } = getState().user;

  // 1. Populate survey
  const { data: surveyDetail } = await axios.get(
    `surveys/${surveyId}/details/`,
  );

  let createRegimenTask;

  // check if we have a pending completeSurveyResponse task
  if (surveyDetail.title.toLowerCase() === 'checkin') {
    const { data } = await axios.get('tasks/');
    const tasks = data.results;
    const completeSurveyTasks = tasks.filter(
      (task) => task.action === Constant.task.action.completeSurveyResponse,
    );
    if (
      !hasCompletedTasks(completeSurveyTasks) &&
      userType === userTypeIds.client
    ) {
      history.replace('/');
      return;
    }

    createRegimenTask = tasks.find(
      (task) =>
        task.action === Constant.task.action.publishStage &&
        isActive(task) &&
        parseInt(getIdFromUrl(task.linked_user), 10) === parseInt(clientId, 10),
    );
  }

  let answers = [];
  let clientName = '';
  let completed = false;
  if (responseId) {
    const { data: response } = await axios.get(
      `surveys/responses/${responseId}/details/`,
    );
    answers = response.answers;
    clientName = response.user.profile.full_name;
    completed = response.state === Constant.survey.state.completed;
  }
  dispatch(
    surveyActions.setSurveyQuestions(
      surveyDetail.pages.flatMap((page) => page.questions),
    ),
  );
  dispatch(
    initializeSuceeded({
      clientName,
      survey: surveyDetail,
      completed,
      answers,
      createRegimenTask: createRegimenTask || {},
    }),
  );
};

const initializeSuceeded = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_SUCCEEDED, payload });
};

const initializeFailed = (payload) => (dispatch) => {
  dispatch({ type: INITIALIZE_FAILED, payload });
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_FAILED,
  INITIALIZE_SUCCEEDED,
  // Action Creators
  initialize,
  initializeSuceeded,
  initializeFailed,
};
