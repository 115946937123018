import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { TR, TD } from 'components/Table';
import Avatar from 'components/Avatar';

import theme from './UpcomingCheckInRow.sss';

const UpcomingCheckInRow = ({ upcomingCheckin }) => (
  <TR>
    {upcomingCheckin.profile.coach && (
      <TD>
        <div className={theme.ClearSkinSpecialist}>
          <Avatar user={upcomingCheckin.profile.coach} />
          <h1 className={theme.ClearSkinSpecialistName}>
            {upcomingCheckin.profile.coach.profile.full_name}
          </h1>
        </div>
      </TD>
    )}
    <TD>
      <Link className={theme.Link} to={`/client-details/${upcomingCheckin.id}`}>
        {upcomingCheckin.profile.full_name}
      </Link>
    </TD>
    <TD>
      <span>
        {format(
          new Date(upcomingCheckin.incoming_check_in_stage.check_in_scheduled),
          'MM/dd/yyyy hh:mm a',
        )}
      </span>
    </TD>
    <TD>
      <span>Week {upcomingCheckin.incoming_check_in_stage.week_period}</span>
    </TD>
  </TR>
);

UpcomingCheckInRow.propTypes = {
  upcomingCheckin: PropTypes.object.isRequired,
};

export default UpcomingCheckInRow;
