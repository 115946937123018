import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { isCoach } from 'helpers/user';
import { questionIds, checkinFemaleQuestions } from 'config/config';
import { followupQuestions } from 'config/surveys';
import { userAware } from 'components/UserProvider';

import theme from './SurveyResults.scss';
import SurveyAnswer from './SurveyAnswer';

const UPLOAD_PHOTOS_QUESTION_IDS = [
  questionIds.checkin.uploadPhotosFace,
  questionIds.checkin.uploadPhotosChest,
  questionIds.checkin.uploadPhotoBack,
];

const SurveyQuestion = ({
  question,
  surveyType,
  existingCheckins,
  checkinStage,
}) => {
  const checkinLabel = checkinStage
    ? `Week ${checkinStage.next_week_period} Checkin`
    : '';
  const text = UPLOAD_PHOTOS_QUESTION_IDS.includes(question.id)
    ? checkinLabel
    : question.text;

  function renderAnswers() {
    switch (question.category) {
      case 'multiple_choice':
        return <SurveyAnswerChoice question={question} />;
      default:
        return (
          <SurveyAnswer
            question={question}
            surveyType={surveyType}
            existingCheckins={existingCheckins}
            checkinStage={checkinStage}
          />
        );
    }
  }

  return (
    <div className={theme.questionWrapper}>
      <div
        className={theme.question}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {question.answers && question.answers.length
        ? renderAnswers()
        : 'No Answer Given'}
    </div>
  );
};

const FollowupQuestion = ({ question }) => {
  if (question.category === 'datetime') {
    return (
      <Fragment>
        <div className={theme.question}>-{question.text}</div>
        <div className={theme.answerWrapper}>
          {question.answers[0]?.text}
          {question.answers[1]
            ? ' - ' + question.answers[1].text || 'Current'
            : ''}
        </div>
      </Fragment>
    );
  }
  if (question.category === 'open_ended') {
    return (
      <Fragment>
        <div className={theme.question}>-{question.text}</div>
        <div className={theme.answerWrapper}>{question.answers[0]?.text}</div>
      </Fragment>
    );
  }
  return <SurveyQuestion question={question} />;
};

function getFollowUpIds(answer) {
  const possibleFollowups = followupQuestions[answer?.choice?.question];
  return possibleFollowups?.[answer?.choice?.id];
}

const SurveyAnswerChoice = ({ question }) => {
  const { answers } = question;
  return answers.map((answer, i) => {
    const followupsIds = getFollowUpIds(answer);
    const followups = question.followups?.filter((followup) =>
      // eslint-disable-next-line prettier/prettier
      followupsIds.includes(followup.id),
    );
    if (!question.choices.find((c) => c.id === answer.choice?.id)) {
      if (answer.text) {
        return (
          <div className={theme.choiceWrapper} key={i}>
            {answer.text}
          </div>
        );
      }

      return false;
    }

    return (
      <>
        <div className={theme.choiceWrapper} key={i}>
          {question.choices.find((c) => c.id === answer.choice.id).text}
        </div>
        {followups?.map((fu, index) => (
          <div key={index} className={theme.followupQ}>
            <FollowupQuestion question={fu} />
          </div>
        ))}
      </>
    );
  });
};

class ResultsSection extends Component {
  getClassName = (question) => {
    switch (question.category) {
      case 'multiple_choice':
        return theme.columns;
      case 'datetime':
      case 'open_ended': {
        const displayType =
          question.display_options && question.display_options.display_type
            ? question.display_options.display_type
            : false;

        if (displayType) {
          switch (displayType) {
            case 'file_upload':
              return theme.fileUpload;

            case 'slider':
              return theme.columns;
            default:
              break;
          }
        }

        if (question.subcategory.toLowerCase() === 'multi') {
          return theme.openEndedMulti;
        }
        return theme.columns;
      }
      case 'single_choice': {
        // eslint-disable-next-line max-len
        const isCheckinImageChoice =
          this.props.survey.title.toLowerCase() === 'checkin' &&
          question.id === questionIds.checkin.selectASR;
        if (isCheckinImageChoice) {
          return theme.imageChoice;
        }

        return theme.columns;
      }
      case 'matrix': {
        const subcategory = question.subcategory;
        switch (subcategory) {
          case 'ranking':
            return question.answers.length === 1
              ? theme.columns
              : theme.matrixRanking;
          case 'rating':
            return theme.matrixRating;
          default:
            return theme.columns;
        }
      }
      default:
        return theme.columns;
    }
  };

  shouldRenderQuestion = (question) => {
    const { user, gender, page } = this.props;
    if (page.id === 2) {
      if (gender === 'male' && question.id !== 5 && question.id !== 6) {
        return false;
      } else if (
        gender === 'female' &&
        question.id === questionIds.healthQuestionnaire.facialHair
      ) {
        return false;
      }
    } else if (page.id === 11 || page.id === 13) {
      if (gender === 'male' && checkinFemaleQuestions.includes(question.id)) {
        return false;
      } else if (
        question.id === questionIds.healthQuestionnaire.adhereToCriteria ||
        question.id === questionIds.checkin.adhereToCriteria
      ) {
        return false;
      } else if (
        // eslint-disable-next-line camelcase
        question.display_options?.display_type === 'file_upload' &&
        !question.answers?.length
      ) {
        return false;
      }
    }

    if (
      isCoach(user) &&
      (question.id === questionIds.checkin.coachReview ||
        question.id === questionIds.checkin.feedback)
    ) {
      return false;
    }

    return true;
  };

  renderFollowups = (question) => {
    if (question.followups) {
      return question.followups.map((fu, i) => (
        <div key={i} className={theme.followupQ}>
          {this.renderQuestion(fu, true)}
        </div>
      ));
    }
    return null;
  };

  renderQuestion = (question, isFollowup = false) => {
    const { gender, page, existingCheckins, checkinStage } = this.props;
    if (page.id === 2) {
      if (gender === 'male' && question.id !== 5 && question.id !== 6) {
        return null;
      } else if (
        gender === 'female' &&
        question.id === questionIds.healthQuestionnaire.facialHair
      ) {
        return null;
      }
    } else if (page.id === 13) {
      if (gender === 'male' && checkinFemaleQuestions.includes(question.id)) {
        return null;
      }
    }
    if (isFollowup) {
      return <FollowupQuestion question={question} />;
    }

    const surveyType = this.props.survey.title.toLowerCase();
    return (
      <SurveyQuestion
        question={question}
        surveyType={surveyType}
        existingCheckins={existingCheckins}
        checkinStage={checkinStage}
      />
    );
  };

  render() {
    const { page, sectionRef } = this.props;
    return (
      <div className={theme.pageSection}>
        <header ref={sectionRef}>
          <h2>{page.title}</h2>
        </header>
        <div className={theme.questions}>
          {page.questions
            .filter((question) => this.shouldRenderQuestion(question))
            .map((question, i) => (
              <div
                className={[
                  theme.questionContainer,
                  theme.row,
                  this.getClassName(question),
                ].join(' ')}
                key={i}
              >
                {question && this.renderQuestion(question)}
                {question?.category !== 'multiple_choice' &&
                  this.renderFollowups(question)}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

FollowupQuestion.propTypes = {
  question: PropTypes.object,
};

ResultsSection.propTypes = {
  user: PropTypes.object.isRequired,
  gender: PropTypes.string,
  page: PropTypes.object,
  sectionRef: PropTypes.object,
  survey: PropTypes.object,
  existingCheckins: PropTypes.array,
  checkinStage: PropTypes.object,
};

SurveyQuestion.propTypes = {
  question: PropTypes.object,
  surveyType: PropTypes.string,
  existingCheckins: PropTypes.array,
  checkinStage: PropTypes.object,
};

export default userAware(ResultsSection);
