import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { passwordRegex} from 'helpers/validators';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { PasswordInput } from 'components/Form';

import theme from './ResetPasswordForm.sss';

const ResetPasswordForm = ({
  submitting,
  onSubmit,
}) => {
  const { register, handleSubmit, errors } = useForm();
  return (
    <div className={theme.ResetPasswordForm}>
      <h2 className={theme.Title}>PASSWORD RECOVERY</h2>
      <h2 className={theme.SectionTitle}>Set a password for getting access to Clear Connection</h2>
      <h2 className={theme.FormTitle}>CHOOSE YOUR NEW PASSWORD</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={theme.VerticalGroup}>
          <div className={theme.InputGroup}>
            <label htmlFor="password" className={theme.Label}>Password</label>
            <PasswordInput
              name="password"
              id="password"
              register={register({
                required: 'Enter your new password',
                pattern: {
                  value: passwordRegex,
                  // eslint-disable-next-line max-len
                  message: 'Password must be at least 8 characters and contain one uppercase, one lowercase, and one numeric character',
                },
              })}
              error={errors.password?.message}
            />
          </div>
        </div>
        <footer className={theme.Footer}>
          <Button
            style={{ position: 'relative' }}
            disabled={submitting}
            size="big"
            type="submit"
          >
            Save Password
            {submitting &&
              <Loader size="small" loading />
            }
          </Button>
        </footer>
      </form>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

ResetPasswordForm.defaultProps = {
  submitting: false,
};

export default ResetPasswordForm;
