import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import theme from './FileInput.sss';

const FileInput = forwardRef(
  ({ children, className, error, maxSize, ...others }, ref) => {
    const onFileChange = (ev) => {
      const file = ev.target.files[0];

      if (!file.type.match(/image-*/)) {
        toast.error('Sorry, invalid file format.');
        return;
      }

      const size = file.size / 1024 / 1024;
      if (size > maxSize) {
        toast.error(
          'Sorry, this file size is too large. Please upload a smaller file.',
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        others.onChange?.({ file, preview: reader.result });
      };
      reader.readAsDataURL(file);
    };

    return (
      <div className={classNames(theme.FileInput, className)}>
        <label>
          {React.cloneElement(React.Children.only(children), {
            ...children.props,
            className: classNames(children.props.className, theme.Content),
          })}
          <input
            {...others}
            ref={ref}
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </label>
        {error && <span className={theme.ErrorText}>{error}</span>}
      </div>
    );
  },
);

FileInput.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  maxSize: PropTypes.number,
};

FileInput.defaultProps = {
  maxSize: 16, // MB
};

export default FileInput;
