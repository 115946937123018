import React from 'react';
import PropTypes from 'prop-types';

import { formatUpdateAnswer } from 'helpers/answer';

import Form, { Ranking, Rating } from 'components/Form';

import SingleMatrix from './SingleMatrix';

const SUBCATEGORIES = {
  single: 'single',
  rating: 'rating',
  ranking: 'ranking',
  menu: 'menu',
  multi: 'multi',
};

const Matrix = ({ question, answers }) => {
  const getSingleMatrixInputDefaultValue = () => {
    if (!answers.length) {
      return undefined;
    }
    return answers.map(({ row, choice }) => ({
      row,
      defaultValue: choice.id,
    }));
  };
  const getRankingInputDefaultValue = () => {
    if (!answers.length) {
      return question.rows.map(({ id }) => id.toString());
    }
    if (answers.length > 1) {
      return answers.map(({ row }) => row.toString());
    }
    return answers[0].choice.id.toString();
  };
  const renderInput = () => {
    switch (question.subcategory) {
      case SUBCATEGORIES.single:
        return (
          <Form.Item
            name={question.id.toString()}
            label={<label>{question.text}</label>}
            onChange={(values) => {
              if (!values.length) {
                return undefined;
              }
              return values.map((value) => ({
                ...value,
                question: question.id,
              }));
            }}
            input={
              <SingleMatrix
                id={question.id.toString()}
                rows={question.rows}
                choices={question.choices}
                defaultValue={getSingleMatrixInputDefaultValue()}
              />
            }
            registerOptions={{
              required:
                question.is_required &&
                JSON.stringify(
                  question.rows.map(({ id }) => ({
                    row: id,
                    error: 'Please select a choice',
                  })),
                ),
              validate: (values) => {
                if (question.is_required) {
                  const errors = question.rows.reduce((acc, { id }) => {
                    const rowValue = values.find(({ row }) => row === id);
                    if (!rowValue) {
                      return [
                        ...acc,
                        {
                          row: id,
                          error: 'Please select a choice',
                        },
                      ];
                    }
                    return acc;
                  }, []);
                  if (errors.length) {
                    return JSON.stringify(errors);
                  }
                }
                return true;
              },
            }}
          />
        );
      case SUBCATEGORIES.rating:
        return (
          <Form.Item
            name={question.id.toString()}
            defaultValue={
              answers[0] ? [formatUpdateAnswer(answers[0])] : undefined
            }
            label={<label>{question.text}</label>}
            onChange={(value) => [
              {
                question: question.id,
                choice: Number(value),
                row: question.rows[0].id,
              },
            ]}
            input={
              <Rating
                id={question.id.toString()}
                choices={question.choices.map(({ text, id }) => ({
                  text,
                  value: id.toString(),
                }))}
                defaultValue={answers[0]?.choice?.id.toString() ?? ''}
              />
            }
            registerOptions={{
              required: question.is_required && 'Please select a choice',
            }}
          />
        );
      case SUBCATEGORIES.ranking: {
        const naChoice = question.choices.find((choice) => choice.is_na);
        return (
          <Form.Item
            name={question.id.toString()}
            label={<label>{question.text}</label>}
            onChange={(choices) => {
              if (Array.isArray(choices)) {
                return choices.map((rowId, index) => ({
                  question: question.id,
                  row: Number(rowId),
                  text: index.toString(),
                }));
              }
              return [
                {
                  question: question.id,
                  choice: Number(choices),
                },
              ];
            }}
            input={
              <Ranking
                id={question.id.toString()}
                choices={question.rows.map(({ text, id }) => ({
                  text,
                  value: id.toString(),
                }))}
                naChoice={
                  naChoice
                    ? {
                        text: naChoice.text,
                        value: naChoice.id.toString(),
                      }
                    : undefined
                }
                defaultValue={getRankingInputDefaultValue()}
              />
            }
            registerOptions={{
              required: question.is_required && 'Please select a choice',
            }}
          />
        );
      }
      case SUBCATEGORIES.menu:
      case SUBCATEGORIES.multi:
      default:
        throw new Error(`subcategory ${question.subcategory} not supported`);
    }
  };
  return renderInput();
};

Matrix.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
};

Matrix.defaultProps = {
  answers: [],
};

export default Matrix;
