import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Loader from 'components/Loader';
import PhotoViewer from '../components';

import * as actions from './HQPhotosWidget.ducks';
import theme from './HQPhotosWidget.sss';

class HQPhotosWidget extends Component {
  componentDidMount() {
    const { clientId, fetchPhotos } = this.props;
    fetchPhotos({ clientId });
  }

  render() {
    const { loading, photos } = this.props;

    let content = isEmpty(photos) ? (
      <h2 className={theme.HQMessage}>You have not uploaded any photos yet</h2>
    ) : (
      <>
        <PhotoViewer images={photos?.face} />
        {photos?.chest && <PhotoViewer images={photos?.chest} />}
        {photos?.back && <PhotoViewer images={photos?.back} />}
      </>
    );
    if (loading) {
      content = <Loader />;
    }

    return (
      <div className={theme.HQPhotosWidget}>
        <h1 className={theme.HQPhotosHeader}>Health Questionnaire Photos</h1>
        {content}
      </div>
    );
  }
}

HQPhotosWidget.propTypes = {
  clientId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchPhotos: PropTypes.func.isRequired,
  photos: PropTypes.object.isRequired,
};

const mapStateToProps = ({ hqPhotos }) => ({
  ...hqPhotos,
});

export default connect(mapStateToProps, actions)(HQPhotosWidget);
