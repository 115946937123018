import React from 'react';
import PropTypes from 'prop-types';

import theme from './ProductList.sss';

const ProductList = ({
  products
}) => (
  <ul className={theme.ProductList}>
    {products.map(({ name, quantity }, key) => (
      <li key={key}>{name} <span>x {quantity}</span></li>
    ))}
  </ul>
);

ProductList.propTypes = {
  products: PropTypes.array,
};

ProductList.defaultProps = {
  products: [],
};

export default ProductList;
