import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import { CloseIcon, EditIcon, SaveIcon } from 'components/Icon';

import { Select } from 'components/Form';
import { getWeekPeriod } from 'helpers/program';
import { useBranch } from 'components/BranchProvider';

import theme from './ProgramWeekPeriod.sss';
import { orange01 } from 'styles/colors.sss';

const ProgramWeekPeriod = ({ programIndex, onSave }) => {
  const [branch] = useBranch();
  const [editingWeek, setEditingWeek] = useState(false);
  const [newProgramIndex, setNewProgramIndex] = useState(programIndex);
  const weeks = [];
  for (let i = 0; i <= branch.program_config.duration; i++) {
    weeks.push({ value: i, title: `${i * 2 + 1}-${i * 2 + 2}` });
  }
  return (
    <div>
      Current Week in Program:
      {editingWeek ? (
        <>
          <Select
            defaultValue={programIndex}
            choices={weeks}
            onChange={(event) => {
              setNewProgramIndex(event.target.value);
            }}
          />
          <IconButton
            onClick={() => {
              onSave(newProgramIndex);
              setEditingWeek(false);
            }}
            icon={<SaveIcon width={18} height={18} fill={orange01} />}
          />
          <IconButton
            onClick={() => {
              setNewProgramIndex(programIndex);
              setEditingWeek(false);
            }}
            icon={<CloseIcon width={18} height={18} fill={orange01} />}
          />
        </>
      ) : (
        <>
          <em>{getWeekPeriod(programIndex)}</em>
          {programIndex <= branch.program_config.duration && (
            <IconButton
              color="sensitive"
              classes={[theme.EditWeek]}
              onClick={() => {
                setEditingWeek(true);
              }}
              icon={<EditIcon width={18} height={18} fill={orange01} />}
            />
          )}
        </>
      )}
    </div>
  );
};

ProgramWeekPeriod.propTypes = {
  programIndex: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ProgramWeekPeriod;
