import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import theme from './QuantityControl.scss';
import { useEffect } from 'react';

const QuantityControl = forwardRef(
  ({ value: defaultValue, small, max, onChange, ...others }, ref) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      if (value !== defaultValue) {
        setValue(defaultValue);
      }
    }, [defaultValue]);

    return (
      <div className={[theme.qtyControl, small ? theme.small : ''].join(' ')}>
        <div
          className={theme.qtyDecrement}
          onClick={() => {
            if (value > 0) {
              setValue(value - 1);
              onChange(value - 1);
            }
          }}
        >
          <span>-</span>
        </div>
        <input
          ref={ref}
          min="0"
          max={max}
          type="number"
          readOnly
          {...others}
          value={value}
        />
        <div
          className={theme.qtyIncrement}
          onClick={() => {
            if (!max || value < max) {
              setValue(value + 1);
              onChange(value + 1);
            }
          }}
        >
          <span>+</span>
        </div>
      </div>
    );
  },
);

QuantityControl.propTypes = {
  value: PropTypes.number,
  small: PropTypes.bool,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

QuantityControl.defaultProps = {
  value: 0,
  small: false,
  onChange: noop,
};

export default QuantityControl;
