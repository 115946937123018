import pickBy from 'lodash/pickBy';
import * as questionHelpers from 'helpers/question';

export const formatUpdateAnswer = ({
  question,
  choice,
  row,
  text,
  text_answer: textAnswer,
}) => {
  return pickBy({
    question: question.id,
    choice: choice?.id,
    text,
    text_answer: textAnswer,
    row,
  });
};

export const isGender = ({ question }) => questionHelpers.isGender(question);
