import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import theme from './FilterItem.sss';

const FilterItem = ({
  name,
  selected,
  onClick,
}) => (
  <li
    className={classNames(theme.FilterItem, {
      [theme.SelectedFilter]: selected,
    })}
    onClick={(ev) => { ev.preventDefault(); onClick(); }}
  >
    {name}
  </li>
);

FilterItem.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

FilterItem.defaultProps = {
  selected: false,
};

export default FilterItem;
