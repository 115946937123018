import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loader from 'components/Loader';

import * as actions from './UpcomingCheckInWidget.ducks';
import UpcomingCheckInTable from './components';

import theme from './UpcomingCheckInWidget.sss';

class UpcomingCheckInWidget extends Component {
  componentDidMount() {
    const { fetchUpcomingCheckin } = this.props;
    fetchUpcomingCheckin();
  }

  render() {
    const { loading, upcomingCheckins } = this.props;
    if (loading) {
      return (
        <div className={theme.UpcomingCheckInWidget}>
          <Loader />
        </div>
      );
    }
    return (
      <div className={theme.UpcomingCheckInWidget}>
        <UpcomingCheckInTable upcomingCheckins={upcomingCheckins} />
      </div>
    );
  }
}

UpcomingCheckInWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  upcomingCheckins: PropTypes.array.isRequired,
  fetchUpcomingCheckin: PropTypes.func.isRequired,
};

const mapStateToProps = ({ upcomingCheckin }) => ({
  ...upcomingCheckin,
});

export default connect(mapStateToProps, actions)(UpcomingCheckInWidget);
