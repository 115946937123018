/* eslint no-use-before-define: 0 */

import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

import localStorage from 'local-storage';
import axios from 'config/axios';

import history from 'global-history';
import { getErrorMessage } from 'helpers/errorHandling';
import { UPDATE_USER_TREATMENT_PLAN } from 'actions/actionTypes';
import { getNotifications } from 'actions/notificationActions';
import { getSurveyTypes } from 'actions/surveyActions';
import { resetLogin } from 'pages/Login/Login.ducks';

import {
  setTokenFromStorage,
  refreshToken as refreshTokenAction,
  universeCall,
} from 'actions/userActions';

// Actions
const INITIALIZE = 'cc/app/INITIALIZE';
const INITIALIZE_FAILED = 'cc/app/INITIALIZE_FAILED';
const INITIALIZE_SUCCEEDED = 'cc/app/INITIALIZE_SUCCEEDED';
const BOOTSTRAP_APP = 'cc/app/BOOTSTRAP_APP';
const BOOTSTRAP_APP_FAILED = 'cc/app/BOOTSTRAP_APP_FAILED';
const BOOTSTRAP_APP_SUCCEEDED = 'cc/app/BOOTSTRAP_APP_SUCCEEDED';
const LOGIN = 'cc/app/LOGIN';
const LOGOUT = 'cc/app/LOGOUT';

const initialState = {
  appIsInitialized: false,
  isLoggedIn: false,
  programDocuments: [],
  productCategories: [],
  stageConfigs: [],
  programPlans: [],
  maintenancePlans: [],
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state, appIsInitialized: false };
    case INITIALIZE_SUCCEEDED:
      return { ...state, appIsInitialized: true };
    case BOOTSTRAP_APP_SUCCEEDED: {
      const {
        programDocuments,
        productCategories,
        stageConfigs,
        programPlans,
        maintenancePlans,
      } = action.payload;
      return {
        ...state,
        programDocuments,
        productCategories,
        stageConfigs,
        programPlans,
        maintenancePlans,
      };
    }
    case LOGIN:
      return { ...state, isLoggedIn: true };
    case LOGOUT:
      return { ...initialState, appIsInitialized: true, isLoggedIn: false };
    default:
      return state;
  }
};

const initialize = (payload) => async (dispatch) => {
  dispatch({ type: INITIALIZE, payload });
  try {
    const { bootstrapApp: loadDependencies } = payload;
    const token = localStorage.get('token');
    const refreshToken = localStorage.get('refreshToken');
    const userId = localStorage.get('user');
    const branch = localStorage.get('branch');
    if (token && branch) {
      dispatch(setTokenFromStorage(refreshToken));
      await dispatch(refreshTokenAction(refreshToken));
      await dispatch(universeCall(userId));
      await dispatch(bootstrapApp());
      await dispatch(login());
      await loadDependencies(branch);
      dispatch(initializeSuceeded());
    } else {
      dispatch(logout());
    }
  } catch (error) {
    dispatch(initializeFailed({ error }));
  }
};

const initializeSuceeded = (payload) => (dispatch, getState) => {
  dispatch({ type: INITIALIZE_SUCCEEDED, payload });
  const { user } = getState();

  Sentry.setUser({
    id: user.id,
    email: user.email,
  });
};

const initializeFailed = (payload) => (dispatch) => {
  const { error } = payload;
  dispatch({ type: INITIALIZE_FAILED, payload });
  dispatch(logout());
  toast.error(getErrorMessage(error));
};

const bootstrapApp = () => async (dispatch, getState) => {
  dispatch({ type: BOOTSTRAP_APP });
  const { user } = getState();

  try {
    dispatch(getSurveyTypes(user.jwt));
    await dispatch(getNotifications(user.jwt));

    const { data: acneProgram } = await axios.get(
      `programs/acne/?user=${user.id}`,
    );
    if (acneProgram.results.length) {
      const treatmentPlan = acneProgram.results[0].treatment_plan
        ? acneProgram.results[0].treatment_plan.split('v1')[1]
        : '';
      dispatch({ type: UPDATE_USER_TREATMENT_PLAN, treatmentPlan });
    }

    const [
      { data: stageConfigs },
      { data: programPlansResponse },
      { data: maintenancePlansResponse },
      { data: productCategoriesResponse },
      { data: programDocuments },
    ] = await Promise.all([
      axios.get('programs/acne/stages/configs/'),
      axios.get('programs/acne/plans/'),
      axios.get('programs/acne/maintenance-plans/'),
      axios.get('products/categories/?limit=100'),
      axios.get('users/clients/program-documents/'),
    ]);

    const productCategories = productCategoriesResponse.results;
    dispatch(
      bootstrapAppSuceeded({
        programDocuments,
        productCategories,
        stageConfigs,
        programPlans: programPlansResponse.results,
        maintenancePlans: maintenancePlansResponse.results,
      }),
    );
  } catch (error) {
    dispatch(bootstrapAppFailed({ error }));
    throw error;
  }
};

const bootstrapAppSuceeded = (payload) => (dispatch) => {
  dispatch({ type: BOOTSTRAP_APP_SUCCEEDED, payload });
};

const bootstrapAppFailed = (payload) => (dispatch) => {
  const { error } = payload;
  dispatch({ type: BOOTSTRAP_APP_FAILED, payload });
  toast.error(getErrorMessage(error));
};

const login = () => (dispatch) => {
  dispatch({ type: LOGIN });
};

const logout = (payload) => (dispatch) => {
  localStorage.set('token', null);
  localStorage.set('refreshToken', null);
  localStorage.set('user', null);
  localStorage.set('branch', null);

  Sentry.configureScope((scope) => {
    scope.setUser(null);
  });

  const REDIRECT_BLACK_LIST = ['account-create', 'password-reset', 'eula'];

  const isBlackListUrl = REDIRECT_BLACK_LIST.some((blackListUrl) =>
    history.location.pathname.includes(blackListUrl),
  );
  dispatch(resetLogin());
  if (!isBlackListUrl) {
    history.replace('/login');
  }

  dispatch({ type: LOGOUT, payload });
};

export default reducer;
export {
  // Actions
  INITIALIZE,
  INITIALIZE_FAILED,
  INITIALIZE_SUCCEEDED,
  BOOTSTRAP_APP,
  BOOTSTRAP_APP_FAILED,
  BOOTSTRAP_APP_SUCCEEDED,
  LOGIN,
  LOGOUT,
  // Action Creators
  initialize,
  initializeSuceeded,
  initializeFailed,
  bootstrapApp,
  bootstrapAppSuceeded,
  bootstrapAppFailed,
  login,
  logout,
};
