// TODO: - this method is brittle, and likely should be handled on the server
// (by only returning required regimen in config)
export const shouldIncludeRegimenTreatmentArea = (regimen, treatmentAreas) => {
  const name = regimen.name;

  if (name.includes('DIET RECOMMENDATIONS')) {
    return false;
  }

  if (name.includes('HERBAL')) {
    return true;
  }

  return treatmentAreas.some((treatmentArea) => name.includes(treatmentArea.toUpperCase()));
};

export const regimenHasSteps = (regimen, steps) => {
  return regimen.steps.some((step) => {
    const regimenStep = steps.find((s) => s.id === step);
    return regimenStep.includeStep;
  });
};
