import React from 'react';

import { useGetProspects } from 'features/users/client.query';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/Loader';

import ProspectsTable from './components';

import theme from './ProspectsWidget.sss';

function ProspectsWidget() {
  const { data: prospects, isLoading } = useGetProspects(undefined, {
    refetchOnWindowFocus: false,
  });
  if (isLoading) {
    return (
      <div className={theme.ProspectsWidget}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={theme.ProspectsWidget}>
      <ErrorBoundary>
        <ProspectsTable prospects={prospects} />
      </ErrorBoundary>
    </div>
  );
}

export default ProspectsWidget;
