import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/Button';

import { useComposeMessage } from './ComposeMessage';

import theme from './MessageTypeButton.sss';

const MessageTypeButton = ({ id, children }) => {
  const { messageType, setMessageType } = useComposeMessage();
  return (
    <Button
      classes={[
        classNames(theme.MessageTypeButton, {
          [theme.Selected]: id === messageType,
        }).split(' '),
      ]}
      size="big"
      onClick={() => {
        setMessageType(id);
      }}
    >
      {children}
    </Button>
  );
};

MessageTypeButton.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default MessageTypeButton;
