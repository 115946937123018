import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Loader from 'components/Loader/Loader';

import SurveyOverview from './components';

import 'styles/surveys.scss';

import * as actions from './SurveyOverview.ducks';

const SurveyOverviewPage = ({ loading, match, survey, initialize }) => {
  useEffect(() => {
    const { surveyId } = match.params;
    initialize({ surveyId });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <SurveyOverview survey={survey} pageId={Number(match.params.pageId)} />
  );
};

SurveyOverviewPage.propTypes = {
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  survey: PropTypes.object.isRequired,
  history: PropTypes.object,
  initialize: PropTypes.func.isRequired,
};

const mapStateToProps = ({ surveyOverview }) => ({
  ...surveyOverview,
});

export default withRouter(
  connect(mapStateToProps, actions)(SurveyOverviewPage),
);
